<template>
  <div>
    <v-col>
      <v-card height="64px" width="100%"
        class="ml-4 pl-1 pt-5 background elevation-0 d-flex flex-row justify-space-between align-center fixBar">
        <v-card-title class="pl-0 ml-0">
          <span @click="$router.push('/')" class="breadcrumb-text-unselected underline-on-hover cursor">Dashboard</span>

          <span><v-icon class="breadcrumb-arrow-unselected">mdi-chevron-right</v-icon></span>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="breadcrumb-text">
                Campaign Result
                <v-icon class="breadcrumb-arrow cursor">mdi-chevron-down</v-icon>
              </span>
            </template>

            <v-list>
              <v-list-item @click="$router.push('/clusterjobseekersappraisal')">No. of users recommended</v-list-item>
              <v-list-item @click="$router.push('/clusterjobseekerspip')">No. of users rejected</v-list-item>
              <v-list-item @click="$router.push('/clusterjobseekerstbd')">No. of users on TBD</v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <div class="size-menu d-flex flex-row justify-start w-fit align-center fixLog">
          <div>
            <img src="../../assets/avtar.png" class="rounded-xl mr-2" width="24px" />
          </div>
          <div class="d-flex flex-column d-justify-evenly user-details">
            <v-card-title class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.name }}
            </v-card-title>
            <v-card-subtitle class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.email }}
            </v-card-subtitle>
          </div>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on, attrs }">
              <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-btn @click="logout">logout</v-btn>
          </v-menu>
        </div>
      </v-card>

      <div class="pt-12">

        <div class="background fixTop30 ml-2 pt-4">

          <v-container class="funnel-contianer pl-2 pr-7 ml-3" fluid>
            <div class="w-100 d-flex flex-row">
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      JOB SEEKERS
                    </p>
                  </v-card-title>

                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Users Invited :
                      {{ clusterJobSeekersChartData.totalJobSeekersInvited }}</span
                    >
                    <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersInvited" propertyEnd="totalJobSeekersScreeningStarted" funnelColor="#E0ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            clusterJobSeekersChartData.totalJobSeekersInvited -
                            clusterJobSeekersChartData.totalJobSeekersScreeningStarted,
                            clusterJobSeekersChartData.totalJobSeekersInvited
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersScreeningStarted" propertyEnd="totalJobSeekersScreeningPassed" funnelColor="#C3ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                     <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            clusterJobSeekersChartData.totalJobSeekersScreeningStarted -
                            clusterJobSeekersChartData.totalJobSeekersScreeningPassed,
                            clusterJobSeekersChartData.totalJobSeekersScreeningStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING RESULT
                    </p>
                  </v-card-title>
                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersScreeningPassed" propertyEnd="totalJobSeekersMainsStarted" funnelColor="#B0D644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            clusterJobSeekersChartData.totalJobSeekersScreeningPassed -
                            clusterJobSeekersChartData.totalJobSeekersMainsStarted,
                            clusterJobSeekersChartData.totalJobSeekersScreeningPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">MAINS</p>
                  </v-card-title>
                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersMainsStarted" propertyEnd="totalJobSeekersMainsPassed" funnelColor="#6AD644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            clusterJobSeekersChartData.totalJobSeekersMainsStarted -
                            clusterJobSeekersChartData.totalJobSeekersMainsPassed,
                            clusterJobSeekersChartData.totalJobSeekersMainsStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      MAINS RESULT
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersMainsPassed" propertyEnd="totalJobSeekersDemoSubmitted"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            clusterJobSeekersChartData.totalJobSeekersMainsPassed -
                            clusterJobSeekersChartData.totalJobSeekersDemoSubmitted,
                            clusterJobSeekersChartData.totalJobSeekersMainsPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      DEMO
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Submitted</span
                    >
                    <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersDemoSubmitted" propertyEnd="totalJobSeekersInterviewSubmitted"/>
                    <!-- <div class="d-flex justify-end py-1 pr-2">
                    <!-- <div class="d-flex justify-end py-1 pr-2">
                     <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            clusterJobSeekersChartData.totalJobSeekersDemoSubmitted -
                            clusterJobSeekersChartData.totalJobSeekersInterviewSubmitted,
                            clusterJobSeekersChartData.totalJobSeekersDemoSubmitted
                          )
                        }}%
                      </div>
                    </div> -->
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 w-19">
                <v-card class="cardBorder rounded-lg pr-0 pl-0" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                    <p class="font-weight-bold pl-2 funnelText">CAMPAIGN SUMMARY</p>
                  </v-card-title>

                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="32px">
                    Passed : {{ this.clusterJobSeekersChartData.totalJobSeekersMainsPassed }}
                    <v-spacer></v-spacer>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="32px">
                    Rejected - {{ this.clusterJobSeekersChartData.totalJobSeekersMainsFailed }}
                    <v-spacer></v-spacer>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="32px">
                    For TBD : {{ this.clusterJobSeekersChartData.totalJobSeekersScreeningFailed }}
                    <v-spacer></v-spacer>
                  </v-card>
                </v-card>
              </div>
            </div>
            <!-- <v-col cols="2.5">
                  <FunnelChart/>
                 </v-col>
                 <v-col cols="2.5">
                  <FunnelChart/>
                 </v-col>
                 <v-col cols="2.5">
                  <FunnelChart/>
                 </v-col> -->
          </v-container>

          <v-row class="d-flex justify-center pt-0 my-0 ml-3 pr-1" fluid>
            <v-col cols="4" md="4" sm="4">
              <div class="text-h6 font-weight-bold highEmphasis">
                Campaign Result
              </div>
            </v-col>

            <v-col cols="8" md="8" sm="8" class="d-flex flex-row justify-end">
              <v-card class="elevation-0 ma-0 pa-0 transparent">
                <v-text-field hide-details solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search"
                  class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              </v-card>

              <v-btn depressed rounded outlined class="btn outline-btn mx-3"><v-icon>mdi-tune</v-icon>FILTER</v-btn>
              <!-- <v-btn  depressed rounded outlined class="border-button mx-3"><img src="../../../public/svgs/SORT.svg" alt="" />SORT</v-btn> -->

              <!-- <v-btn
                class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
              <v-btn depressed outlined rounded class="btn outline-btn mx-3"><v-icon>mdi-import
                  mdi-rotate-90</v-icon></v-btn>
            </v-col>
          </v-row>

        </div>

        <v-card elevation="0" class="ml-6 mr-4 mt-0">
          <v-card-title class="text-subtitle-1 pa-0">
            <table class="tbl">
              <thead class="t-head">
                <tr class="t-row">
                  <th class="head">Rank</th>
                  <th class="head">Full Name</th>
                  <th class="head">Campaign</th>
                  <th class="head">School Name</th>
                  <th class="head">Stage</th>
                  <th class="head">Status</th>
                  <th class="head">Screening</th>
                  <th class="head">Mains</th>
                </tr>
              </thead>
              <tbody class="t-body">
                <tr class="t-row" v-for="(teacher, index) in displayedClusterJobSeeker" :key="teacher.id">
                  <td class="t-data">{{ index + 1 }}</td>
                  <td class="t-data">
                    {{ `${teacher.firstName} ${teacher.lastName}` }}
                  </td>
                  <td class="t-data">{{ teacher.campaignName }}</td>
                  <td class="t-data">{{ teacher.schoolName }}</td>
                  <td class="t-data">
                    {{
                      teacher.lastStatus.stageValue === "Screening" ||
                      teacher.lastStatus.stageValue === "Mains"
                      ? teacher.lastStatus.stageValue
                      : "-"
                    }}
                  </td>
                  <td class="t-data">
                    <v-chip :class="{
                      pass: teacher.lastStatus.statusValue === 'Pass', fail: teacher.lastStatus.statusValue === 'Fail',
                      'yetToAttempt': teacher.lastStatus.statusValue === 'Yet to Attempt',
                      'inProgress': teacher.lastStatus.statusValue === 'In Progress',
                    }" outlined v-if="teacher.lastStatus.stageValue === 'Screening' ||
    teacher.lastStatus.stageValue === 'Mains'
    ">
                      <div :class="{
                        dotpass: teacher.lastStatus.statusValue === 'Pass', dotfail: teacher.lastStatus.statusValue === 'Fail',
                        dotyetToAttempt: teacher.lastStatus.statusValue === 'Yet to Attempt',
                        dotinProgress: teacher.lastStatus.statusValue === 'In Progress'
                      }"></div>{{
  teacher.lastStatus.statusValue }}
                    </v-chip>
                    <template v-else>-</template>
                  </td>
                  <td class="t-data">
                    {{ teacher.screeningTotal }}
                  </td>
                  <td class="t-data">
                    {{ teacher.mainsTotal }}
                  </td>
                </tr>
              </tbody>
              <!-- <tbody class="t-body">
                <tr class="t-row">
                  <td class="t-data">1</td>
                  <td class="t-data">Rashid Ali</td>
                  <td class="t-data">Campaign 1</td>
                  <td class="t-data d-flex align-center justify-center">
                    <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                  </td>
                  <td class="t-data">VGOS- Malad</td>
                  <td class="t-data">Screening</td>
                  <td class="t-data h-100 d-flex align-center justify-center">
                    <v-chip class="ma-1 fail-color" text-color="#FF0505" size="x-small">
                      <div class="dot-fail"></div>
                      Fail
                    </v-chip>
                  </td>
                  <td class="t-data">68%</td>
                  <td class="t-data">-</td>
                </tr>
                <tr class="t-row">
                  <td class="t-data">2</td>
                  <td class="t-data">Rashid Ali</td>
                  <td class="t-data">Campaign 1</td>
                  <td class="t-data d-flex align-center justify-center">
                    <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                  </td>
                  <td class="t-data">VGOS- Malad</td>
                  <td class="t-data">Mains</td>
                  <td class="t-data d-flex h-100 align-center justify-center">
                    <v-chip class="ma-1 pass-color" text-color="#06C270" size="x-small">
                      <div class=" dot-pass"></div>
                      Pass
                    </v-chip>
                  </td>
                  <td class="t-data">74%</td>
                  <td class="t-data">74%</td>
                </tr>
                <tr class="t-row">
                  <td class="t-data">3</td>
                  <td class="t-data">Rashid Ali</td>
                  <td class="t-data">Campaign 1</td>
                  <td class="t-data d-flex align-center justify-center">
                    <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                  </td>
                  <td class="t-data">VGOS- Malad</td>
                  <td class="t-data">Mains</td>
                  <td class="t-data d-flex h-100 align-center justify-center">
                    <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                      <div class="dot-yetToAttempt"></div>
                      Yet To Attempt
                    </v-chip>
                  </td>
                  <td class="t-data">74%</td>
                  <td class="t-data">74%</td>
                </tr>
                <tr class="t-row">
                  <td class="t-data">4</td>
                  <td class="t-data">Rashid Ali</td>
                  <td class="t-data">Campaign 1</td>
                  <td class="t-data d-flex align-center justify-center">
                    <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                  </td>
                  <td class="t-data">VGOS- Malad</td>
                  <td class="t-data">Mains</td>
                  <td class="t-data d-flex h-100 align-center justify-center">
                    <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                      <div class="dot-yetToAttempt"></div>
                      Yet To Attempt
                    </v-chip>
                  </td>
                  <td class="t-data">74%</td>
                  <td class="t-data">74%</td>
                </tr>
                <tr class="t-row">
                  <td class="t-data">5</td>
                  <td class="t-data">Rashid Ali</td>
                  <td class="t-data">Campaign 1</td>
                  <td class="t-data d-flex align-center justify-center">
                    <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                  </td>
                  <td class="t-data">VGOS- Malad</td>
                  <td class="t-data">Mains</td>
                  <td class="t-data d-flex h-100 align-center justify-center">
                    <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                      <div class="dot-yetToAttempt"></div>
                      Yet To Attempt
                    </v-chip>
                  </td>
                  <td class="t-data">74%</td>
                  <td class="t-data">74%</td>
                </tr>
                <tr class="t-row">
                  <td class="t-data">6</td>
                  <td class="t-data">Rashid Ali</td>
                  <td class="t-data">Campaign 1</td>
                  <td class="t-data d-flex align-center justify-center">
                    <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                  </td>
                  <td class="t-data">VGOS- Malad</td>
                  <td class="t-data">Mains</td>
                  <td class="t-data d-flex h-100 align-center justify-center">
                    <v-chip class="ma-1 pass-color" text-color="#06C270" size="x-small">
                      <div class=" dot-pass"></div>
                      Pass
                    </v-chip>
                  </td>
                  <td class="t-data">74%</td>
                  <td class="t-data">74%</td>
                </tr>
              </tbody> -->
            </table>
          </v-card-title>
        </v-card>

      </div>

    </v-col>
  </div>
</template>
  
<script>
import AuthService from "@/services/AuthService";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  collectionGroup,
  query,
  where,
  QuerySnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import FunnelChartCommon from "../FunnelChartCommon.vue";
import { mapGetters } from "vuex";
import { liveListenerUnsubscriber } from "@/mixins/liveListenerUnsubcriber";

export default {
  name: "CampaignResult",
  components: {
    FunnelChartCommon,
  },
  mixins: [ liveListenerUnsubscriber ],
  data() {
    return {
      data: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
        },

        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
        },
      ],
      clusters: [],
      search: "",
      schoolClusterTeacher: [],
      topJobSeekerInCluster: [],
      invited: 0,
      screeningAttempted: 0,
      screeningResult: 0,
      mainsAttempted: 0,
      result: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      clusterJobSeekersChartData: {},
      emptyClusterJobSeekersChartData: {
        totalJobSeekersInvited: 0,
        totalJobSeekersScreeningStarted: 0,
        totalJobSeekersScreeningPassed: 0,
        totalJobSeekersScreeningFailed: 0,
        totalJobSeekersMainsStarted: 0,
        totalJobSeekersMainsPassed: 0,
        totalJobSeekersMainsFailed: 0,
        totalJobSeekersDemoSubmitted: 0,
        totalJobSeekersInterviewSubmitted: 0,
      },
      roleCluster: "",
      roleSchool: "",
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'getRoleFromStore', 
      userRoleId: 'getRoleId'
    }),
    displayedClusterJobSeeker() {
      return this.topJobSeekerInCluster;
    },
  },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },


    async setupSnapshotListenerJobSeekersClusterFunnel() {
      const roleId = this.userRoleId;
      console.log("roleId: " + roleId);
      const dashboardRef = doc(db, "dashboards", `${roleId}-jobseekers-dashboard`);

      if (!dashboardRef) {
          this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
          return;
      }

      const resultsRef = collection(dashboardRef, "results");

      if (!resultsRef) {
          this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
          return;
      }

      const campaignDocRef = doc(resultsRef, "campaignresults");

      if (!campaignDocRef) {
          this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
          return;
      }

      let unsubscribe = onSnapshot(campaignDocRef, (docSnapshot) => {
          if (!docSnapshot.exists()) {
              this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
              return;
          }

          const data = docSnapshot.data();

          if (
              data.invited === undefined ||
              data.screeningStarted === undefined ||
              data.screeningPassed === undefined ||
              data.screeningFailed === undefined ||
              data.mainsStarted === undefined ||
              data.mainsPassed === undefined ||
              data.mainsFailed === undefined ||
              data.demoSubmitted === undefined ||
              data.interviewSubmitted === undefined
          ) {
              this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
          } else {
              this.clusterJobSeekersChartData = {
                  totalJobSeekersInvited: data.invited,
                  totalJobSeekersScreeningStarted: data.screeningStarted,
                  totalJobSeekersScreeningPassed: data.screeningPassed,
                  totalJobSeekersScreeningFailed: data.screeningFailed,
                  totalJobSeekersMainsStarted: data.mainsStarted,
                  totalJobSeekersMainsPassed: data.mainsPassed,
                  totalJobSeekersMainsFailed: data.mainsFailed,
                  totalJobSeekersDemoSubmitted: data.demoSubmitted,
                  totalJobSeekersInterviewSubmitted: data.interviewSubmitted,
              };
          }
      });
      this.liveListenersToUnsubscribeMixin.push(unsubscribe);
    },

    calculatePercentage(value, total) {
      if (total === 0) {
        // console.log("Error: Total cannot be zero.");
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        // console.log("Error: Invalid input. Please provide valid numbers.");
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      // console.log(roundedPercentage);
      return roundedPercentage;
    },
    getClusterOrSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;

      // console.log("my cluster>>", this.roleCluster);
      // console.log("my school>>.....", this.roleSchool);

      // if (this.roleSchool == undefined) {
      //   AuthService.logout();
      //   this.$router.push("/login");
      // }
      // if (this.roleCluster != "All Clusters") {
      //   this.campaignData.clusters = [this.roleCluster];
      //   this.getClusterSchool();
      // }
      // if (this.roleSchool != "All") {
      //   this.campaignData.schools = [this.roleSchool];
      // }
    },

    async jobseekerClusterSnapshotListener() {
  const campaignRef = collection(db, "campaigns");
  const audienceTypes = ['JOB_SEEKER', 'JOBSEEKER', 'JOB SEEKER', 'JOB-SEEKER'];
  const clusterName = this.$store.state.role.clusterName;

  const jobSeekerQuery = query(
    campaignRef,
    where('audienceType', 'in', audienceTypes),
    where('clusters', 'array-contains-any', [clusterName])
  );

  let unsubscribe = onSnapshot(jobSeekerQuery, async (snapshot) => {
    const clusterJobSeeker = [];
    this.topJobSeekerInCluster = [];
    for (const campaignDoc of snapshot.docs) {
      const campaignData = campaignDoc.data();
      const audienceRef = collection(campaignRef, campaignDoc.id, "audience");
      const audienceDocs = await getDocs(audienceRef);

      for (const audienceDoc of audienceDocs.docs) {
        const data = audienceDoc.data();
        const personalInfo = data.personalInfo || {};
        const campaignStatus = data.campaignStatus || [];
        const lastStatus = campaignStatus[campaignStatus.length - 1]?.status;
        // Get the first school name from the schools array
        const schoolName = Array.isArray(data?.teachingInterests?.schools) ? data?.teachingInterests?.schools[0] ?? "" : data?.teachingInterests?.schools ?? "";
        if (schoolName) {
          // Fetch the school document using the school name
          const schoolQuery = query(collection(db, "schools"), where("name", "==", schoolName.toUpperCase()));
          const schoolSnapshot = await getDocs(schoolQuery);
          
          if (!schoolSnapshot.empty) {
            const schoolDoc = schoolSnapshot.docs[0];
            const schoolData = schoolDoc.data();
            
            // Check if the school's cluster matches the current cluster
            if (schoolData.cluster === clusterName) {
              const jobSeekerData = {
                id: audienceDoc.id,
                screeningTotal: lastStatus !== "INVITED" ? data.screeningTotal ?? "-" : "-",
                mainsTotal: lastStatus !== "INVITED" ? data.mainsTotal ?? "-"  : "-",
                firstName: personalInfo.firstName ?? "",
                lastName: personalInfo.lastName ?? "",
                schoolName: schoolName.toUpperCase(),
                lastStatus: this.getLastStatus(lastStatus),
                campaignName: campaignData.name,
              };

              clusterJobSeeker.push(jobSeekerData);

            }

          }
        }
      }
      this.topJobSeekerInCluster = clusterJobSeeker.sort((a, b) => {
      const scoreA = a.mainsTotal === "-" ? -Infinity : Number(a.mainsTotal);
      const scoreB = b.mainsTotal === "-" ? -Infinity : Number(b.mainsTotal);
      
      if (isNaN(scoreA) && isNaN(scoreB)) return 0;
      if (isNaN(scoreA)) return 1;
      if (isNaN(scoreB)) return -1;
      
      return scoreB - scoreA;
    });    }

  });
  this.liveListenersToUnsubscribeMixin.push(unsubscribe);
},

 getLastStatus(lastStatus) {
  const statusMap = {
    "INVITED": { stageValue: "Screening", statusValue: "Yet to Attempt" },
    "SCREENING STARTED": { stageValue: "Screening", statusValue: "In Progress" },
    "SCREENING PASSED": { stageValue: "Mains", statusValue: "Yet to Attempt" },
    "SCREENING FAILED": { stageValue: "Screening", statusValue: "Fail" },
    "MAINS STARTED": { stageValue: "Mains", statusValue: "In Progress" },
    "MAINS PASSED": { stageValue: "Mains", statusValue: "Pass" },
    "MAINS FAILED": { stageValue: "Mains", statusValue: "Fail" },
  };

  return statusMap[lastStatus] || { stageValue: "", statusValue: "" };
}
  },

  created() {
    this.getClusterOrSchool()
    this.jobseekerClusterSnapshotListener();
    this.setupSnapshotListenerJobSeekersClusterFunnel();
  },
};
</script>
  
<style scoped>
.tbl>.t-head>.t-row>.head {
  background-color: primary;
}

.tbl {
  height: 196px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.tbl th {
  padding: 8px;
}

.tbl td {
  padding: 5px;
}

.tbl>.t-body>.t-row {
  background-color: white;
}

.head {
  text-align: center;
}

.tbl>.t-body>.t-row>.t-data {
  text-align: center;
}

.tbl>tr:nth-child(even) {
  background-color: white !important;
}

.fail {
  color: #FF0505;
  /* background-color: #ff0505; */

}

.pass {
  color: #06C270;
  /* background-color: #06c270; */
}

.yetToAttempt {
  color: #000000DE;
  /* background-color: #000000de; */
}

.inProgress {
  color: #066ec2;
}

.dotyetToAttempt {
  width: 8px;
  height: 8px;
  background-color: #000000de;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dotinProgress {
  width: 8px;
  height: 8px;
  background-color: #066ec2;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dotfail {
  width: 8px;
  height: 8px;
  background-color: #ff0505;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dotpass {
  width: 8px;
  height: 8px;
  background-color: #06c270;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
</style>
  