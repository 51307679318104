<template>
  <div v-if="preloader">
    <template>
      <div class="d-flex align-center justify-center flex-column" style="height: 100vh;">
          <bubble-loader/>
      </div>
    </template>
  </div>

  <div v-else>
    <v-card
      height="64px"
      width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      "
    >
      <v-card-title class="ml-0">
        <p class="breadcrumb-text">Assessments</p>
        <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <UserNavMenu/>
    </v-card>
    <div fluid class="pad px-8 background">
      <v-dialog max-width="880px" class="cdz" v-model="updateAssessmentDialog" center>
        <v-form ref="updateForm" lazy-validation @submit.prevent="assessmentUpdate">
          <v-card>
            <v-card-title class="primary mb-2 pl-7">
              Update Assessment
            </v-card-title>

            <v-card-title>
              <v-col>
                <v-text-field outlined class="rounded-xl" v-model="currentSelectedAssessment.assessmentName"
                  label="Assessment Name*" :rules="[v => !!v || 'Assessment Name is required']" required>
                </v-text-field>

                <div class="text-body-1 my-2">Assessment Description*</div>

                <ckeditor :config="editorConfig" v-model="currentSelectedAssessment.assessmentDescription"
                  class="m-ckeditor" @ready="onEditorReady" :name="'ckeditor'">
                </ckeditor>
                <!-- <span v-if="ckeditorError" class="mt-2" type="error"> Assessment Description is required </span> -->
              </v-col>
            </v-card-title>

            <v-card-actions class="px-6 pb-6">
              <small>*All fields are mandatory</small>
              <v-spacer></v-spacer>
              <v-btn rounded outlined width="102px" height="48px"
                @click="() => { updateAssessmentDialog = false; }"
                class="accent--text pa-4">
                Cancel
              </v-btn>

              <v-btn width="102px" height="48px" rounded
                @click="validateAssessmentUpdateForm" class="accent pa-4"
                :loading="updateAssessmentDialogLoading"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="deleteAssessmentDialog" max-width="366px" class="cdz" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-icon size="70" class="pt-4">mdi-trash-can-outline</v-icon>
              <p class="text-h5 pt-4 pb-0">Delete Assessment</p>
              <p class="text-disabled grey--text text-subtitle-1 mb-4"
                color="rgba(0, 0, 0, 0.6)" disabled>
                This action will delete the Assessment.
              </p>

              <div class="d-flex justify-space-between" fluid>
                <v-btn depressed class="btn outline-btn" rounded large
                  width="157px" @click="deleteAssessmentDialog = false">
                  CANCEL
                </v-btn>

                <v-btn class="btn primary-btn" depressed large width="157px" rounded
                  @click="deleteAssessment(currentSelectedAssessment.id)">
                  DELETE
                </v-btn
                >
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96"
                >mdi-check-circle-outline</v-icon
              >
              <p class="text-h5 font-weight-bold py-4">Assessment Deleted</p>
              <v-btn
                class="accent"
                large
                width="157px"
                rounded
                @click="deleteSuccessDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Filter Dialog -->
    <v-dialog v-model="filterDialog" class="cdz" max-width="570px" style="z-index:1000 !important">
      <v-card width="700px" height="100vh" class="filterDialogClass">
        <div class="d-flex justify-space-between pt-2 px-4">
          <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
            Filter
          </div>
                  
          <div class="clear-filter-btn" @click="clearFilter">
            CLEAR FILTER
          </div>
        </div>
        <v-tabs vertical>   
          <div class="ml-3 mt-4 mr-4 filterLine">
            <v-tab key="grades" class="black--text justify-start" active-class="activeTab">
              <span>Grades</span>
              <span class="ml-1" v-if="selectedGradeFilter.length > 0">
              ({{ selectedGradeFilter.length }})</span>
            </v-tab>
            <v-tab key="languages" class="black--text justify-start" active-class="activeTab">
              <span>Languages</span>
              <span class="ml-1" v-if="selectedLanguageFilter.length > 0">
                ({{ selectedLanguageFilter.length }})</span>
            </v-tab>
            <v-tab key="coreSkillsSubject" class="black--text justify-start" active-class="activeTab">
              <span>Subjects</span>
              <span class="ml-1" v-if="selectedSubjectFilter.length > 0">
                ({{ selectedSubjectFilter.length }})</span>
            </v-tab>
            <v-tab key="creationDateAndTime" class="black--text justify-start" active-class="activeTab">
              <span>Creation Date & Time</span>
              <span class="ml-1" v-if="selectedCreationDateAndTimeFilter">
              (1)</span>
            </v-tab>
          </div>
          <v-tab-item key="grades">
            <v-card>
              <v-card-text>
                <v-chip-group v-model="selectedGradeFilter" active-class="active-chip" column :multiple="true">
                  <v-chip v-for="(grade,index) in gradeOptions" 
                  :key="index"
                  :value="grade"
                  elevated>
                    {{grade}}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="languages">
            <v-card>
              <v-card-text>
                <v-chip-group v-model="selectedLanguageFilter" active-class="active-chip" column :multiple="true">
                  <v-chip v-for="(language,index) in languageOptions" 
                  :key="index"
                  :value="language"
                  elevated>
                    {{language}}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="coreSkillsSubject">
            <v-card>
              <v-card-text>
                <v-chip-group v-model="selectedSubjectFilter" active-class="active-chip" column :multiple="true">
                  <v-chip v-for="(subject,index) in subjectOptions" 
                  :key="index"
                  :value="subject"
                  elevated>
                    {{subject}}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="creationDateAndTime">
            <v-card class="my-container">
              <v-card-text class="pt-0">
                <v-chip-group v-model="selectedCreationDateAndTimeFilter" active-class="active-chip" column :multiple="false">
                  <v-chip v-for="(creationDateAndTime,index) in creationDateAndTimeOptions" :key="index" :value="creationDateAndTime.value" elevated>
                    {{creationDateAndTime.label}}
                  </v-chip>
                  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" elevated active-class="active-chip">
                        Custom Dates 
                      </v-chip>
                    </template>
                    <v-card>
                      <v-card-title class="py-1 px-4">Select Dates</v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <v-menu ref="menu" v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field class="py-0" 
                                v-bind="attrs" 
                                v-on="on" 
                                label="Start Date" 
                                prepend-icon="mdi-calendar" 
                                v-model="customStartDate"
                                readonly
                                :rules="[v => !!v || 'Start date is required']" 
                                ></v-text-field>
                              </template>
                              <v-date-picker 
                              v-model="customStartDate" 
                              @input="handleStartDateChange">
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col>
                            <v-menu ref="menu" v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field class="py-0" 
                                v-bind="attrs" 
                                v-on="on" 
                                label="End Date" 
                                prepend-icon="mdi-calendar" 
                                v-model="customEndDate" 
                                :rules="[v => !!v || 'End date is required']" 
                                :disabled="!customStartDate" 
                                readonly></v-text-field>
                              </template>
                              <v-date-picker 
                              v-model="customEndDate"
                              :min="customStartDate"
                              @input="menuEnd = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="primary" text @click="addCustomDates">OK</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <div class="filterDialogButton">
          <v-card-actions class="px-6 pb-0">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              outlined
              class="btn outline-btn pa-4"
              @click="closeFilter"
              >Cancel</v-btn
            >
            <v-btn
              rounded
              class="btn primary-btn pa-4"
              @click="filterAssessments" 
              >Apply</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>                
    </v-dialog>  




    <div class="fixBarCreateCammpaign">
      <v-row>
        <v-col class="d-flex align-center">

        </v-col>
        <v-col cols="4"> </v-col>
      </v-row>

      <!-- assessment user table row with filter btn and color code -->
      <v-row class="my-8 justify-space-between" v-if="showUsers">
        <!-- filter btn and color code -->
        <v-col cols="6" sm="6" md="6" class="d-flex justify-end align-center ma-0 pa-0">
          <v-spacer></v-spacer>

          <v-spacer></v-spacer>

          <v-btn class="btn outline-btn ml-2" rounded>
            <v-icon>mdi-tune</v-icon>Filter</v-btn
          >
        </v-col>
      </v-row>

      <!-- Assessment row and fiter and export btn -->

      <v-row class="justify-space-between mr-5" v-if="!showUsers">
        <v-col class="pl-5" cols="3">
          <div class="text-h6">Assessment</div>
        </v-col>
        <v-col cols="9" class="mt-0">
          <v-row class="justify-end">
            <v-text-field solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="searchValue"
              hide-details class="search-bar border-button rounded-pill mx-2" dense clearable ref="searchField">
            </v-text-field>

            <v-btn depressed rounded outlined :loading="filterLoader" @click="openFilterDialog" class="btn outline-btn mx-2 pl-4 pr-6">
              <v-icon class="pr-2">mdi-tune</v-icon>
                <span v-if="filterCount > 0">Filter ({{ filterCount }})</span>
                <span v-else >Filter</span></v-btn>
                <v-btn depressed outlined rounded class="btn outline-btn mx-2" :loading="loadingAssessmentsToDownload" @click="downloadAssessment">
                  <v-icon>mdi-import mdi-rotate-90</v-icon>
                </v-btn>
            <!-- <v-btn class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
            <v-btn v-if="!showUsers && $store.state.role.assessment.admin"
              @click="() => { dialog = true; e1 = 1 ,goToAssessmentCreationForm();}"
              class="btn primary-btn ml-2 pl-4 pr-6"
              rounded
              elevation="0"
              ><v-icon class="pr-2">mdi-plus</v-icon>Create
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <!-- Live assessment and color code row  -->
      <v-row justify="space-between" class="mr-0 pr-4">
        <v-col class="pl-5 pt-0" cols="8">
          <v-chip-group mandatory v-model="currentSelectedAssessmentStatus" class="">
            <v-chip @click="preloader=true;assessments=[];fetchStaticAssessmentCounts();fetchAssessment('PUBLISHED')" class="mr-4" :color="currentSelectedAssessmentStatus == 0? 'active-chip' : ''"> Published ({{assessmentCounts.PUBLISHED || 0}}) </v-chip>
            <v-chip @click="preloader=true;assessments=[];fetchStaticAssessmentCounts();fetchAssessment('DRAFT')" class="mr-4" :color="currentSelectedAssessmentStatus == 1?  'active-chip' : ''"> Draft ({{assessmentCounts.DRAFT || 0}}) </v-chip>
            <!-- <v-chip disabled class="mr-4"> Sent for approval (0) </v-chip>
            <v-chip disabled class="mr-4"> Rejected (0) </v-chip> -->
            <v-chip @click="preloader=true;assessments=[];fetchStaticAssessmentCounts();fetchAssessment('LIVE')" class="mr-4" :color="currentSelectedAssessmentStatus == 2?  'active-chip' : ''"> Live ({{assessmentCounts.LIVE || 0}}) </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
    </div>

      <!------------------------------------------ Assessment card here---------------------------- -->
      <div v-if="assessmentLoader">
        <template>
          <v-dialog v-model="assessmentLoader" hide-overlay persistent width="300">
            <v-progress-linear indeterminate></v-progress-linear>
          </v-dialog>
        </template>
      </div>
      <div v-else> 
        <v-container class="stepper-content-height" style="margin-top: 132px!important;"fluid v-if="!showUsers" id="myScroll">
          <v-row>
            <v-col class="pa-2" 
              v-for="assessment in assessments" :key="assessment.id" cols="12" sm="6" md="4" lg="3" xl="3">
              <v-card
                height="100%"
                class="pa-4 cardBorder rounded-lg d-flex flex-column mr-3"
                style="font-size: 14px;  cursor: 'pointer'"
                @click=" goToViewAssessment(assessment)"            
              >
                <v-card-title class="pa-0 cursor">{{ assessment.name }}</v-card-title>
                
                <div class="d-flex flex-row justify-space-between">
                  <div class="d-flex flex-column justify-space-between">
                    <span class="i-patch">{{ assessment.assessmentName}} </span>
                    <span class="card-sub-text" style="font-size: 11px">{{ assessment.level}} </span>
                  </div>
                  <template>
                    <div class="assessmentIconColor">
                      <v-menu offset-y left transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon style="font-size: 29px !important;" class="p-0" v-bind="attrs" v-on="on">
                            mdi-dots-vertical
                          </v-icon>
                        </template>
                        <v-list>
                          <v-list-item @click="editAssessment(assessment)" style="cursor: pointer;" v-if="assessment.isEditable">
                            <v-list-item-title>Edit</v-list-item-title>
                          </v-list-item>
                          <v-list-item v-if="assessment.isQuestionUploaded" @click="initiateMockTest(assessment.id)" style="cursor: pointer;">
                            <v-list-item-title>Take Mock Test</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="triggerAssessmentSoftDelete(assessment)" class="error--text" style="cursor: pointer;" v-if="![2].includes(currentSelectedAssessmentStatus)">
                            <v-list-item-title>Delete</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                </div>
                
                <v-container>
                  <v-row>
                    <v-col class="pl-0 py-1 pr-1" cols="5">
                      <span class="label-text">Language -</span>
                    </v-col>
                    <v-col cols="7" class="pa-1">
                      <span class='label-value i-patch' v-for="(item, index) in assessment.availableLanguages" :key="index">
                        {{ item }}<span v-if="index < assessment.availableLanguages.length - 1">, </span>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pl-0 py-1 pr-1" cols="5">
                      <span class="label-text">Grade -</span>
                    </v-col>
                    <v-col cols="7" class="pa-1">
                      <span class='label-value i-patch'>{{ assessment.grade }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pl-0 py-1 pr-1" cols="5">
                      <span class="label-text">Subjects -</span>
                    </v-col>
                    <v-col cols="7" class="pa-1">
                      <v-tooltip bottom style="z-index: 999">
                        <template v-slot:activator="{ on, attrs }">
                          <span class='label-value i-patch' v-bind="attrs" v-on="on">
                            {{ assessment?.subjects[0] }}  {{assessment?.subjects?.length>1?`+${assessment?.subjects?.length-1}`:''}}
                          </span>
                        </template>
                        <span>{{ assessment?.subjects?.length ? assessment.subjects.join(', ') : 'No subjects available' }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row v-if="currentSelectedAssessmentStatus == 2">
                    <v-col class="pl-0 py-1 pr-1" cols="5">
                      <span class="label-text">Campaigns -</span>
                    </v-col>
                    <v-col cols="7" class="pa-1">
                      <v-tooltip bottom style="z-index: 999">
                        <template v-slot:activator="{ on, attrs }">
                          <span class='label-value i-patch' v-bind="attrs" v-on="on">
                            {{assessment?.linkedCampaign?.length ?`${assessment?.linkedCampaign?.length}`:'0'}}
                          </span>
                        </template>
                        <span>{{ assessment?.linkedCampaign?.length ? assessment.linkedCampaign.join(', ') : 'Not Linked to any Campaign' }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
                
                <v-spacer></v-spacer>
                
                <v-container class="gray-02 mt-auto">
                  <v-row class="d-flex align-center justify-space-between">
                    <v-col class="d-flex align-center justify-start" cols="7">
                      <span class="label-text">Total Questions -</span>&nbsp;<span class="label-value">{{assessment?.totalQuestionsUploaded || 0}}</span>
                    </v-col>
                    <v-col class="d-flex align-center justify-end" cols="5">
                      <span class="label-text">Marks -</span>&nbsp;<span class="label-value">{{assessment?.marksAllotted || 0}}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>
    <v-dialog
      v-model="loader"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
        :elevation="20"
      >
        <v-progress-linear
          indeterminate
          color="white"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

  import "../styles.css";
  import { validationMixin } from "vuelidate";
  import { required, email } from "vuelidate/lib/validators";
  import StaticAssessmentController from "@/controllers/StaticAssessmentController";
  import GradeController from "@/controllers/GradeController";
  import AuthService from "../services/AuthService";
  import Notification from "./Notification";
  import { debounce } from "lodash";
  import Papa from "papaparse";
  import { format, startOfToday, startOfYesterday, startOfWeek, startOfMonth, endOfToday, endOfYesterday, endOfWeek, endOfMonth } from 'date-fns';
import BubbleLoader from '@/components/BubbleLoader.vue';
import UserNavMenu from "@/components/UserNavMenu.vue";

  export default {
    mixins: [validationMixin],

    validations: {
      email: { required, email },
    },
    name: "StaticAssessmentView",
    components: {
      Notification,
      BubbleLoader,
      UserNavMenu
    },
    data() {
      return {
        rules: {
          required: (value) => !!value || "Field is required",
        },
        loader:false,
        loadingAssessmentsToDownload: false,
        selectedGradeFilter: [],
        selectedLanguageFilter:[],
        selectedSubjectFilter: [],
        selectedCreationDateAndTimeFilter: null,
        gradeOptions: [],
        languageOptions: [],
        subjectOptions: [],
        creationDateAndTimeOptions: [
          { label: 'Today', value: 'today' },
          { label: 'Yesterday', value: 'yesterday' },
          { label: 'This Week', value: 'this_week' },
          { label: 'Last Week', value: 'last_week' },
          { label: 'This Month', value: 'this_month' },
          { label: 'Last Month', value: 'last_month' },
        ],
        filterLoader: false,
        currentSelectedAssessmentStatus: this.getStatusFromRoute(),
        e1: 1,
        showUsers: false,
        notCleared: [],
        name: "",
        screeningMainsUser: "true",
        filterDialog: false,
        filterCount: 0,
        assessments: [],
        preloader: true,
        assessmentLoader: false,
        assessmentCounts: {},
        updateAssessmentDialog: false,
        updateAssessmentDialogLoading: false,
        deleteAssessmentDialog: false,
        deleteAssessmentDialogLoading: false,
        deleteSuccessDialog: false,
        editorConfig: {
        extraPlugins: "ckeditor_wiris",
        },
        assessmentDescription: "",
        assessmentName: "",
        // ckeditorError: false,
        currentSelectedAssessment: {},
        searchValue: this.$route.query.search || "",
        debouncedSearch: null,
        menu: false,
        menuStart: false,
        menuEnd: false,
        customStartDate: null,
        customEndDate: null,
        isCustomDate :false,
        sendSelectedAssessmentsFilterRange: {},
      };
    },

    watch: {
      searchValue(value) {
        this.handleSearchChange(value);
      },
      currentSelectedAssessmentStatus() {
        this.updateUrlParams();
      }
    },

    methods: {
      handleStartDateChange(date) {
        this.menuStart = false;
        if (this.customEndDate && date > this.customEndDate) {
          this.customEndDate = null;
        }
      },
      goToViewAssessment(assessment) {
        if(assessment.isQuestionUploaded == true){
          this.$router.push({
          path: "/assessment-listing-view",
          name: "assessmentListingView",
          query: { id: assessment.id ,status : assessment.status}
        });
        } 
      },
      addCustomDates() {
        this.isCustomDate = true;
        
        if (this.customStartDate && this.customEndDate) {
          this.sendSelectedAssessmentsFilterRange = {
            start: format(new Date(this.customStartDate), 'yyyy-MM-dd 00:00:00'),
            end: format(new Date(this.customEndDate), 'yyyy-MM-dd 23:59:59')
          };
          this.menu = false;
        }
      },
      async getGrades() {
        const response = await GradeController.getAllGrades();
        if (response.status == 200) {
          if (response.data.grades.length > 0) {
            // this.gr = response.data.grades;
            this.gradeOptions = await response.data.grades.map((e) => e.name);
          if(this.filterLoader){
            this.filterDialog = true;
            this.filterLoader = false;
          }
          } else {
            this.gradeOptions = [];
          }
        } else {
          alert(response.data.error);
        }
      },
      async downloadCSV(csvData, fileName) {
        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement("a");
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
        this.loadingAssessmentsToDownload = false;
      },
      downloadAssessment() {
        this.loadingAssessmentsToDownload = true;
        const fileName = "assessments.csv";
        let csvData = this.assessments.map(({id, assessmentName, modeOfAssessment ,status, availableLanguages, createdOn, updatedOn, linkedCampaign })=>{
          return {
            "Assessment Id": id || 'NA',
            "Name":assessmentName || 'NA',
            "Mode of Assessment": modeOfAssessment || 'NA',
            "Status": status || 'NA',
            "Available Languages": this.convertArrayToCommaSepratedVals(availableLanguages) || 'NA',
            "Created On": this.formatDate(new Date(createdOn)) || 'NA',
            "Updated On": this.formatDate(new Date(updatedOn)) || 'NA',
            "Campaigns": linkedCampaign?.length ? linkedCampaign?.join(",") : ""
          }
        })
        this.downloadCSV(csvData, fileName);
      },
      convertArrayToCommaSepratedVals(data){
        return data.length ? data.join(", "): "NA"
      },
      formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth is zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
      },
      openFilterDialog(){
        this.filterLoader = true;
        if(this.gradeOptions.length > 0 && this.subjectOptions.length > 0){
          this.filterLoader = false;
          this.filterDialog = true;
        }
      },
      closeFilter(){
        this.filterDialog = false;
      },
      clearFilter(){
        this.selectedGradeFilter = [];
        this.selectedLanguageFilter = [];
        this.selectedSubjectFilter= [];
        this.selectedCreationDateAndTimeFilter = null;
        this.getFilterCount();
        // this.filterDialog = false;
        if (this.currentSelectedAssessmentStatus === 1) {
          this.fetchAssessment('DRAFT');
        } else if(this.currentSelectedAssessmentStatus === 0){
          this.fetchAssessment('PUBLISHED');
        } else {
          this.fetchAssessment('LIVE');
        }
      },
      filterAssessments(){
        this.getFilterCount();
        this.filterDialog = false;
        if (this.currentSelectedAssessmentStatus === 1) {
          this.fetchAssessment('DRAFT');
        } else if(this.currentSelectedAssessmentStatus === 0){
          this.fetchAssessment('PUBLISHED');
        } else {
          this.fetchAssessment('LIVE');
        }
      },
      getFilterCount(){
        if (!this.isCustomDate) {
          this.getDateRange();
        }
        this.filterCount = [
          this.selectedGradeFilter,
          this.selectedLanguageFilter,
          this.selectedSubjectFilter,
          this.selectedCreationDateAndTimeFilter
        ].filter(filter => filter?.length > 0).length;
      },
      goToAssessmentCreationForm() {
            this.$router.push({
                path: "/create-static-assessment",
                name: "CreateStaticAssessment", //use name for router push
            });
        },
      logout() {
        AuthService.logout();
        this.$router.push("/login");
      },

      triggerAssessmentSoftDelete(assessment) {
        this.currentSelectedAssessment = { ...assessment };
        this.deleteAssessmentDialog = true;
      },

      validateAssessmentUpdateForm() {
        // Validate the CKEditor field
        if (!this.assessmentDescription || this.assessmentDescription.trim() === '') {
          // this.ckeditorError = true;
        } else {
          // this.ckeditorError = false;
        }

        // If the form is valid, proceed with submission
        if (!this.$refs.updateForm.validate()) {
          return; // Stop submission if form is invalid
        }
        this.updateAssessmentDialogLoading = true;
        // Call the assessmentUpdate method if everything is valid
        this.assessmentUpdate();
      },

      onEditorReady(editor) {
        editor.on("afterCommandExec", function(event) {
          var commandName = event.data.name;
          if(['ckeditor_wiris_openFormulaEditor', 'ckeditor_wiris_openFormulaEditorChemistry'].includes(commandName) ) {
            setTimeout(() => {
                const wiris_license_overlays = document.querySelectorAll('.wrs_tickContainer')
                wiris_license_overlays.forEach(element => {
                  element.remove();
                });
              }, 2000)
          }
        })
      },
      clearSearch() {
        if (!this.$route.name || this.$route.name !== 'assessmentListingView') {
          this.searchValue = "";
          // localStorage.removeItem('assessmentSearchValue');
          // localStorage.removeItem('assessmentTabState');
        }
      },

      async assessmentUpdate() {
        if (this.$refs.updateForm.validate()) {
          const obj = {
            assessmentId: this.currentSelectedAssessment.id,
            assessmentName: this.currentSelectedAssessment.assessmentName,
            assessmentDescription: this.currentSelectedAssessment.assessmentDescription
          }
          const response = await StaticAssessmentController.updateStaticAssessment(obj);
          this.updateAssessmentDialogLoading = false;
          if(response.status) {
            this.updateAssessmentDialog = false;
            this.currentSelectedAssessmentStatus === 1 ? this.fetchAssessment('DRAFT') : this.fetchAssessment('PUBLISHED');
            this.fetchStaticAssessmentCounts();
          } else {
            this.updateAssessmentDialog = false;
          }
        } else {
          this.updateAssessmentDialog = false;
        }
      },

      editAssessmentName(assessment) {
        this.currentSelectedAssessment = { ...assessment };
        this.updateAssessmentDialog = true;
      },

      editAssessment(assessment) {
        if(assessment && assessment.id) {
          this.$router.push({ path: "/create-static-assessment", query: { id: assessment.id }});
        }
      },
      
      editAssessmentQuestions(assessment) {
        if(assessment && assessment.id) {
          this.$router.push({ path: "/static-assessment/edit", query: { id: assessment.id }});
        }
      },

      async deleteAssessment(documentId) {
        this.deleteAssessmentDialog = false;
        const response = await StaticAssessmentController.deleteStaticAssessmentById(documentId);
        if(response.status == 200) {
          this.currentSelectedAssessmentStatus === 1 ? this.fetchAssessment('DRAFT') : this.fetchAssessment('PUBLISHED');
          this.fetchStaticAssessmentCounts();
          this.deleteSuccessDialog = true;
        }
        else{
          alert(response.data.result);
        }
      },

      async initiateMockTest(assesmentId) {
        this.loader = true;
        const response = await StaticAssessmentController.getAccessToken(assesmentId);
        if(response.status === 200) {
          this.loader = false;
          window.open(`https://${this.$FRONTEND_DOMAIN}/#/staticassessment?token=${response.data.token}`,'_blank');        } else {
          alert("Cann't authenticate to assessment server, Failed to start mock text");
          this.loader = false;
        }
      },

      formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        //const remainingSeconds = seconds % 60;
        if (hours == 0) {
          return String(minutes).padStart(2, "0") + " mins";
        } else {
          return (
            String(hours).padStart(2, "0") +
            " h and " +
            String(minutes).padStart(2, "0") +
            " mins"
          );
        }
      },
      async fetchAssessment(status, searchedValue = false) {
        // Update localStorage when changing tabs
        const statusMap = {
          'PUBLISHED': 0,
          'DRAFT': 1,
          'LIVE': 2
        };
        
        this.currentSelectedAssessmentStatus = statusMap[status];
        this.updateUrlParams();
        
        // this.preloader = true;
        if (!this.isCustomDate) {
          this.getDateRange();
        }
        this.assessmentLoader = true;
        let filterDateRange = {};
        if(this.sendSelectedAssessmentsFilterRange?.end != "" && this.sendSelectedAssessmentsFilterRange?.start != "") {
          filterDateRange = JSON.stringify(this.sendSelectedAssessmentsFilterRange);
        };
        const response = await StaticAssessmentController.getStaticAssessments(status, this.searchValue, btoa(this.selectedGradeFilter), btoa(this.selectedSubjectFilter), btoa(this.selectedLanguageFilter), filterDateRange);
        if (response.status == 200) {
          if (response.data.assessments.length > 0) {
            this.assessments = response.data.assessments;
          } else {
            this.assessments = [];
          }
          this.setFilterParams();

          this.filterLoader = false;
          this.preloader = false;
          this.assessmentLoader = false;
        } else {
          alert(response.data.error);
          this.preloader = false;
          this.assessmentLoader = false;
        }
        if(searchedValue) {
          this.$nextTick(() => {
            this.$refs.searchField.focus();
          });
        }
      },

      setFilterParams(){
        this.gradeOptions = [... new Set(this.assessments.map(({grade})=>grade))];
        this.subjectOptions = [... new Set(...this.assessments.map(({subjects})=>subjects))];
        this.languageOptions = [... new Set(...this.assessments.map(({availableLanguages})=>availableLanguages))];
      },

      async fetchStaticAssessmentCounts() {
        const response = await StaticAssessmentController.getStaticAssessmentCounts();
        if (response.status == 200) {
          if (response.data.counts) {
            this.assessmentCounts = response.data.counts;
          } else {
            this.assessmentCounts = {};
          }

          this.preloader = false;
        }
      },
      getDateRange() {
        switch (this.selectedCreationDateAndTimeFilter) {
          case 'today':
            this.sendSelectedAssessmentsFilterRange = {
              start: format(startOfToday(), 'yyyy-MM-dd 00:00:00'),
              end: format(endOfToday(), 'yyyy-MM-dd 23:59:59')
            };
            break;
          case 'yesterday':
            this.sendSelectedAssessmentsFilterRange = {
              start: format(startOfYesterday(), 'yyyy-MM-dd 00:00:00'),
              end: format(endOfYesterday(), 'yyyy-MM-dd 23:59:59')
            };
            break;
          case 'this_week':
            this.sendSelectedAssessmentsFilterRange = {
              start: format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd 00:00:00'),
              end: format(endOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd 23:59:59')
            };
            break;
          case 'last_week':
            const lastWeekDate = new Date(new Date().setDate(new Date().getDate() - 7));
            this.sendSelectedAssessmentsFilterRange = {
            start: format(startOfWeek(lastWeekDate, { weekStartsOn: 1 }), 'yyyy-MM-dd 00:00:00'),
            end: format(endOfWeek(lastWeekDate, { weekStartsOn: 1 }), 'yyyy-MM-dd 23:59:59')
            };
            break;
          case 'this_month':
            this.sendSelectedAssessmentsFilterRange = {
              start: format(startOfMonth(new Date()), 'yyyy-MM-dd 00:00:00'),
              end: format(endOfMonth(new Date()), 'yyyy-MM-dd 23:59:59')
            };
            break;
          case 'last_month':
            const lastMonthDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
            this.sendSelectedAssessmentsFilterRange = {
              start: format(startOfMonth(lastMonthDate), 'yyyy-MM-dd 00:00:00'),
              end: format(endOfMonth(lastMonthDate), 'yyyy-MM-dd 23:59:59')
            };
            break;
          default:
            this.sendSelectedAssessmentsFilterRange = {};
            break;
        }
    },
    getStatusFromRoute() {
      const status = this.$route.query.status;
      switch(status) {
        case 'PUBLISHED': return 0;
        case 'DRAFT': return 1;
        case 'LIVE': return 2;
        default: return 0; // Default to PUBLISHED
      }
    },

    getStatusString(statusValue) {
      switch(statusValue) {
        case 0: return 'PUBLISHED';
        case 1: return 'DRAFT';
        case 2: return 'LIVE';
        default: return 'PUBLISHED';
      }
    },

    updateUrlParams() {
      const query = {
        status: this.getStatusString(this.currentSelectedAssessmentStatus)
      };
      
      if (this.searchValue) {
        query.search = this.searchValue;
      }

      this.$router.replace({ 
        query: query 
      }).catch(() => {});
    },

    handleSearchChange(value) {
      // if (value === null) {
      //   this.searchValue = "";
      // }
      // localStorage.setItem('assessmentSearchValue', value);
      this.updateUrlParams();
      this.debouncedSearch();
    },
    },
    mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
    created() {
      if (!this.$store.state.role.assessment.read) {
        this.$router.push("/notFound");
      }
      
      // Initialize with the stored tab state
      // const storedTabState = parseInt(localStorage.getItem('assessmentTabState'));
      const statusFromRoute = this.getStatusFromRoute();
      this.currentSelectedAssessmentStatus = statusFromRoute;
      
      const statusMap = {
        0: 'PUBLISHED',
        1: 'DRAFT',
        2: 'LIVE'
      };
      
      // this.currentSelectedAssessmentStatus = storedTabState || 0;
      this.fetchAssessment(statusMap[statusFromRoute] || 'PUBLISHED');
      this.fetchStaticAssessmentCounts();
      this.debouncedSearch = debounce(() => {
        if (this.currentSelectedAssessmentStatus === 1) {
          this.fetchAssessment('DRAFT', true);
        } else if(this.currentSelectedAssessmentStatus === 0){
          this.fetchAssessment('PUBLISHED', true);
        } else {
          this.fetchAssessment('LIVE', true);
        }
      }, 500);
      this.$store.state.breadcrumb = "Assessment";
    },

    unmounted() {
      this.debouncedSearch.cancel();
    },

    beforeRouteLeave(to, from, next) {
      if (to.name !== 'assessmentListingView') {
        this.clearSearch();
      }
      next();
    },

    
    beforeDestroy() {
      window.removeEventListener("resize", this.onResize);
    },

    beforeRouteUpdate(to, from, next) {
      this.searchValue = to.query.search || "";
      const status = to.query.status || 'PUBLISHED';
      this.currentSelectedAssessmentStatus = this.getStatusFromRoute();
      next();
    }
  };
</script>

<style>
  .pad {
    padding-top: "80px";
  }
.menuable__content__active {
  z-index: 1034 !important;
  margin: 6px 0px 0px -10px;
  .v-picker {
    .v-picker__body {
      width: 218px !important   
    }
    .v-date-picker-title__date {
      font-size: 24px;
    }
    .v-date-picker-header .v-btn {
      height: 36px !important;
    }
    .v-date-picker-table--date .v-btn {
      height: 26px !important;
      width: 26px;
    }
    .v-date-picker-table {
      height: 190px;
    }
  }
}

</style>
