import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.VUE_APP_CAMPAIGNS_API_URL
});

export default {
    getFeedbackFormData: async function (query) {
        try {
            const response = await instance.get('/interviewFeedbackForm'+query)
            return response;
        } catch (error) {
            return error.response;
        }
    },
    

}
