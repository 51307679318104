<template>
  <div class="personal-info-container">
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:253; top:0; width: 98%; padding-top: 16px;">
      <v-card-title class="ml-0 pl-0">
        <span @click="$router.push('/users')" class="breadcrumb-text cursor">
          Users
        </span>
        <v-icon class="breadcrumb-arrow">mdi-chevron-right</v-icon>
        <p class="breadcrumb-text">Create User(Teacher)</p>
        <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
    <UserNavMenu/>
    </v-card>
    <div fluid class="pad pb-8 px-8">


      <div class="cdz">
        <v-card class="background elevation-0">

          <v-card-text>
            <v-container>
            <v-stepper elevation="0" class="background" v-model="e1">
              <v-card outlined elevation="0" class="pb-5 background fixCreateBar">

                <div class="w-100 d-flex flex-row justify-space-between pt-4">

                  <div class="text-h6 w-50">
                    {{ getStepName(this.e1) }}
                  </div>
                  <div class="w-50 d-flex flex-row justify-end">
                    <v-btn depressed rounded class="btn outline-btn mx-2 mb-5">

                      <span v-if="e1 === 1" @click="$router.back()">CANCEL</span>
                      <span v-else @click="e1 = e1 - 1">BACK</span>
                    </v-btn>
                    <v-btn :disabled="checkForDuplicateEntry" depressed rounded class="ml-2 mb-5 btn primary-btn"
                      @click="goToNextStep">
                      <span v-if="e1 < 4">NEXT</span>
                      <span v-else>SUBMIT</span>

                    </v-btn>

                  </div>
                </div>
                <v-stepper-header class="text-subtitle-2 white">
                  <v-stepper-step color="blue" :complete="e1 > 1" step="1">
                    PERSONAL INFORMATION
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step color="blue" :complete="e1 > 2" step="2">
                    ACADEMICS INFORMATION
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step color="blue" :complete="e1 > 3" step="3">
                    PROFESSIONAL INFORMATION
                  </v-stepper-step>
                  <v-divider></v-divider>

                  <v-stepper-step color="blue" step="4"> VGOS ONBOARDING </v-stepper-step>
                </v-stepper-header>
              </v-card>
              <!-- <div class="pb-5 text-h6">
                  {{ getStepName(this.e1) }}
                </div> -->

              <v-card outlined elevation="0" class="padCreateUser">
                <v-stepper-items>
                  <!------------------------------------------ STEP 1 ------------------------------------------>
                  <v-stepper-content step="1">
                    <v-form lazy-validation ref="step1">
                      <v-card elevation="0">
                        <v-row>
                          <v-col cols="12">
                            <v-card class="pa-4" elevation="0">
                              <v-row class="py-0">
                                <v-col cols="2" class="py-0">
                                  <v-autocomplete v-model="personalInfo.title" label="Title *"
                                    :items="['Ms', 'Mrs', 'Mr']" outlined class="rounded-xl" :rules="[
          (v) => !!v || 'Title is required',
        ]" required attach density="comfortable">
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="4" class="py-0"><v-text-field v-model="personalInfo.firstName" :rules="[
          (v) => !!v || 'First Name is required',
        ]" required outlined label="First Name *" rounded class="rounded-xl"></v-text-field></v-col><v-col cols="3"
                                  class="py-0"><v-text-field v-model="personalInfo.middleName" outlined
                                    label="Middle Name" rounded class="rounded-xl"></v-text-field></v-col><v-col
                                  cols="3" class="py-0"><v-text-field v-model="personalInfo.lastName" outlined
                                    label="Last Name*" rounded class="rounded-xl" :rules="[
          (v) => !!v || 'Last Name is required',
        ]"></v-text-field></v-col>
                              </v-row>
                              <v-row class="py-0">
                                <v-col class="py-0">
                                  <v-text-field v-model="personalInfo.contactInfo.email" outlined ref="emailField"
                                    label="Email Address *" suffix='@vgos.org' rounded class="rounded-xl" required
                                    :rules="vgosRules"></v-text-field></v-col>
                              </v-row>
                              <v-row class="py-0">
                                <v-col class="py-0"><v-text-field label="Your 10-digit mobile no. *" outlined rounded
                                    class="rounded-xl" counter="10" prefix="+91" :rules="[
          (v) =>
            !!v || 'Mobile number is required',
          (v) =>
            (v &&
              v.length >= 10 &&
              v.length <= 10) ||
            'Mobile number must be 10 digit',
        ]" @keypress="isNumber($event)" maxLength="10" v-model="personalInfo.contactInfo.phoneNumber"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row class="py-0">
                                <v-col cols="4" class="py-0">
                                  <v-text-field outlined label="Date of Birth (DDMMYY) *" rounded class="rounded-xl"
                                    type="date" :rules="[dobRule]" v-model="personalInfo.dob" max="9999-12-31"></v-text-field></v-col>
                                <v-col cols="2" class="py-0">
                                  <v-autocomplete label="Gender *" :items="['MALE', 'FEMALE', 'OTHERS']" outlined
                                    class="rounded-xl" v-model="personalInfo.gender" :rules="[
          (v) => !!v || 'Gender is required',
        ]" required>
                                  </v-autocomplete>
                                </v-col>
                              </v-row>

                              <v-row class="py-0">
                                <v-col cols="6" class="py-0">
                                  <v-autocomplete v-model="personalInfo.addressInfo.countryName
          " readonly label="Country*" :items="countries" item-value="name" item-text="name" outlined class="rounded-xl"
                                    :rules="[
          (v) => !!v || 'Country is required',
        ]" required>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                  <v-autocomplete v-model="personalInfo.addressInfo.stateName
          " label="State*" :items="states" outlined class="rounded-xl" item-value="state" item-text="state"
                                    @change="fetchDistricts" :rules="[
          (v) => !!v || 'State is required',
        ]" required>
                                  </v-autocomplete>
                                </v-col>
                              </v-row>
                              <v-row class="py-0">
                                <v-col cols="6" class="py-0">
                                  <v-autocomplete v-model="personalInfo.addressInfo.districtName
          " label="District" :items="districts" outlined class="rounded-xl" item-value="district" item-text="district"
                                    @change="fetchCitiesAndTalukas">
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                  <v-text-field v-model="personalInfo.addressInfo.tulakName
          " label="Taluka / Tehsil" outlined class="rounded-xl">
                                  </v-text-field>
                                </v-col> </v-row><v-row class="py-0">
                                <v-col cols="6" class="py-0">
                                  <v-autocomplete v-model="personalInfo.addressInfo.cityName
          " label="City / Village" :items="cities" outlined class="rounded-xl" item-value="city" item-text="city">
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                  <v-text-field v-model="personalInfo.addressInfo.pincode" outlined label="Pin Code*"
                                    rounded :rules="[
          (v) => !!v || 'Pincode is required',
          (v) =>
            (v &&
              v.length >= 6 &&
              v.length <= 6) ||
            'Pincode must be 6 digit',
        ]" @keypress="isNumber($event)" maxLength="6" counter="6" class="rounded-xl" required></v-text-field>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                  <v-text-field v-model="personalInfo.addressInfo.address" outlined label="Address"
                                    rounded class="rounded-xl"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-form>

                    <!-- <div class="w-100 d-flex flex-row justify-end">
                        <v-btn
                        depressed
                        rounded
                       
                        class="mx-4 my-4 btn-color white--text"
                        @click="goToStep2"
                      >
                        NEXT
                      </v-btn>
                      </div> -->



                  </v-stepper-content>
                  <!------------------------------------------ STEP 2 ------------------------------------------>
                  <v-stepper-content step="2" class="elevation-0">
                    <v-form lazy-validation ref="step2">
                      <v-card elevation="0">
                        <v-card class="pa-1" elevation="0">
                          <v-expansion-panels class="transparent elevation-0" v-model="expandedPanelIndex">
                            <v-expansion-panel v-for="(qualification, index) in academicsInfo" :key="index"
                              elevation="0" class="elevation-0">
                              <v-expansion-panel-header>
                                <div class="d-flex flex-column" v-if="expandedPanelIndex != index">
                                  <div class="font-weight-regular">
                                    {{
          index +
          1 +
          ". " +
          qualification.programme
        }}
                                  </div>
                                  <div class="text-body-2 grey--text pt-2 pb-2">
                                    {{ qualification.institution }}
                                  </div>
                                  <div class="text-body-2 grey--text">
                                    {{
          new Date(
            qualification.startDate
          ).getFullYear() +
          " - " +
          new Date(
            qualification.endDate
          ).getFullYear()
        }}
                                  </div>
                                </div>
                              </v-expansion-panel-header>

                              <v-expansion-panel-content>
                                <v-row class="py-0">
                                  <v-col class="py-0"><v-text-field v-model="qualification.institution" outlined
                                      label="School/ College/ University *" rounded class="rounded-xl" :rules="[
          (v) =>
            !!v ||
            'School/ College/ University is required',
        ]" required></v-text-field></v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col class="py-0"><v-text-field v-model="qualification.programme" outlined
                                      label="Degree/ Diploma/ Certification *" rounded class="rounded-xl" :rules="[
          (v) =>
            !!v ||
            'Degree/ Diploma/ Certification is required',
        ]" required></v-text-field></v-col> </v-row><v-row class="py-0">
                                  <v-col class="py-0"><v-text-field v-model="qualification.fieldOfStudy" outlined
                                      label="Field of Study" rounded class="rounded-xl"></v-text-field></v-col>
                                </v-row>

                                <v-row class="py-0">
                                  <v-col cols="6" class="py-0"><v-text-field v-model="qualification.startDate" outlined
                                      label="Start Date*" rounded class="rounded-xl" type="date" :max="maxDate"
                                      :rules="[dateRule]"></v-text-field></v-col>
                                  <v-col @hover="selectedFormIndex = index" cols="6" class="py-0"><v-text-field
                                      v-model="qualification.endDate" outlined label="End Date*" rounded
                                      class="rounded-xl" type="date" max="9999-12-31" :rules="[value => AcadEndDateRule(value,index)]"></v-text-field></v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col cols="12" class="py-0">
                                    <v-text-field v-model="qualification.gradeScore" outlined label="Score / Percentage"
                                      rounded class="rounded-xl"></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col cols="12" class="py-0">
                                    <v-text-field v-model="extraCarricularActivities" outlined
                                      label="Extra Curricular Activities" rounded class="rounded-xl"></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col cols="12" class="py-0">
                                    <v-text-field outlined label="Achievements" rounded
                                      class="rounded-xl"></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" class="d-flex justify-space-between">
                                    <v-btn v-if="academicsInfo.length != 1" @click="removeDataFromSteps(index)" text
                                      class="d-flex justify-end btn danger-btn elevation-0">Remove</v-btn>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>

                          <v-row>
                            <v-col class="mt-4">
                              <v-btn class="btn outline-btn elevation-0"
                                :class="isLastFormfilled ? 'outline-disabled-btn': ''"
                                :disabled="isLastFormfilled"
                                @click="addAcademicQualification">Add More
                                Qualification</v-btn>
                            </v-col>
                          </v-row>

                        </v-card>
                      </v-card>
                    </v-form>

                    <div class="w-100 d-flex flex-row justify-end">

                      <!-- <v-btn
                      rounded
                      text
                      class="mx-4 my-4"
                      color="brown-text"
                      @click="e1 = 1"
                      >
                      BACK
                    </v-btn>
                    
                    <v-btn
                    depressed
                    rounded
                    class="my-4 btn-color white--text"
                    @click="goToStep3"
                    >
                    NEXT
                  </v-btn> -->

                    </div>

                  </v-stepper-content>
                  <!------------------------------------------ STEP 3 ------------------------------------------>
                  <v-stepper-content step="3">
                    <v-form lazy-validation ref="step3">
                      <v-card elevation="0">
                        <v-card class="pa-4" elevation="0">
                          <v-expansion-panels v-model="expandedPanelIndex">
                            <v-expansion-panel v-for="(
                                  professional, index
                                ) in professionalInfo" :key="index" elevation="0">
                              <v-expansion-panel-header>
                                <div class="d-flex flex-column" v-if="expandedPanelIndex != index">
                                  <div class="font-weight-regular">
                                    {{
          index + 1 + ". " + professional.position
        }}
                                  </div>
                                  <div class="text-body-2 grey--text">
                                    {{
            new Date(
              professional.startDate
            ).getFullYear() +
            " - " +
            new Date(
              professional.endDate
            ).getFullYear()
          }}
                                  </div>
                                </div>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row class="py-0">
                                  <v-col class="py-0">
                                    <v-card width="100%" height="110px" elevation="0"
                                      class="rounded-xl mb-10 d-flex flex-column justify-center" outlined>
                                      <div class="text-body-1 ml-8 mb-4 mt-2">
                                        Experience
                                      </div>
                                      <v-row class="py-0 px-6 ml-2">
                                        <v-col cols="1" class="py-0 px-0">
                                          <v-text-field hide-details type="number" outlined @keypress="isNumber($event)"
                                            class="rounded-xl" v-model="professional.experienceYear
          ">
                                          </v-text-field>
                                        </v-col>
                                        <v-col cols="1 center" class="py-0 px-0">
                                          <div class="pt-4 ml-4">Years</div>
                                        </v-col>
                                        <v-col cols="1" class="py-0 px-0">
                                          <v-text-field hide-details type="number" @keypress="isNumber($event)" outlined
                                            class="rounded-xl" v-model="professional.experienceMonth
          ">
                                          </v-text-field>
                                        </v-col>
                                        <v-col cols="4 center" class="py-0 px-0">
                                          <div class="pt-4 ml-4">
                                            Months of Experiences
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </v-col>
                                </v-row>
                                <div v-if="experience == 'Experienced'">
                                  <v-row class="py-0">
                                    <v-col class="py-0"><v-text-field outlined label="Role/ Position*" rounded
                                        class="rounded-xl" counter="100" maxLength="100" :rules="[
          (v) =>
            !!v ||
            'Role/ Position is required',
        ]" required v-model="professional.position"></v-text-field></v-col>
                                  </v-row><v-row class="py-0">
                                    <v-col class="py-0"><v-autocomplete label="Employment Type" :items="employeeType"
                                        outlined item-value="id" item-text="name" class="rounded-xl"
                                        v-model="professional.employeeTypeId">
                                      </v-autocomplete></v-col>
                                  </v-row>

                                  <v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-text-field v-model="professional.school" outlined label="School / Institute"
                                        rounded class="rounded-xl"></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-checkbox @click='currentlyWorking(index)' class="py-0" v-model="professional.isCurrentlyWorking"
                                        label="I am currently working on this role / position."></v-checkbox>
                                    </v-col>
                                  </v-row>
                                  <v-row class="py-0">
                                    <v-col cols="6" class="py-0"><v-text-field outlined label="Start Date*" rounded
                                        class="rounded-xl" v-model="professional.startDate" type="date"  :rules="[dateRule]" max="9999-12-31"></v-text-field></v-col>
                                    <v-col  @mouseover="setIndex(index)" cols="6" class="py-0"><v-text-field v-if="!professional.isCurrentlyWorking" outlined
                                        label="End Date*" rounded class="rounded-xl" v-model="professional.endDate" max="9999-12-31"
                                        type="date" :rules="[value => endDateRule(value,index)]"></v-text-field>
                                      </v-col>
                                  </v-row>
                                  <v-row class="py-0">
                                    <v-col class="py-0"><v-autocomplete label="Board" :items="boardsData"
                                        item-text="name" item-value="name" outlined class="rounded-xl"
                                        v-model="professional.board">
                                      </v-autocomplete></v-col> </v-row><v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-autocomplete clearable deletable-chips label="Levels" outlined
                                        class="rounded-xl" small-chips :items="tableLevels" multiple item-text="name"
                                        v-model="professional.levels" item-value="name" @change="event => getSubjectsForLevels(event, professional, index, true)">
                                      </v-autocomplete>
                                    </v-col> </v-row><v-row class="py-0">
                                    <!-- <v-col class="py-0">
                                  
                                      <v-autocomplete v-model="professional.grades" clearable deletable-chips
                                        label="Grades" outlined class="rounded-xl" small-chips :items="gradesData"
                                        multiple item-text="name" item-value="name">
                                      </v-autocomplete></v-col> </v-row><v-row class="py-0"> -->
                                    <v-col class="py-0">
                                      <!-- v-model="professional.subjects" -->

                                      <v-autocomplete v-model="professional.subjects" clearable deletable-chips
                                        label="Subjects" outlined class="rounded-xl" small-chips :items="filteredSubjects[index]" :disabled="checkLevels(professional.levels)"
                                        multiple item-text="name" item-value="name">
                                      </v-autocomplete></v-col>
                                  </v-row>
                                </div>
                                <div v-if="experience != 'Experienced'">
                                  <v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-autocomplete clearable deletable-chips label="board" outlined
                                        class="rounded-xl" small-chips :items="boardsData" item-text="name"
                                        item-value="name" multiple>
                                      </v-autocomplete>
                                    </v-col> </v-row><v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-autocomplete clearable deletable-chips label="Level" outlined
                                        class="rounded-xl" small-chips :items="tableLevels" multiple item-text="name"
                                        item-value="name">
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>

                                  <v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-autocomplete clearable deletable-chips label="Subject" outlined
                                        class="rounded-xl" small-chips :items="subjectsData" multiple item-text="name"
                                        item-value="name">
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                </div>
                                <v-row>
                                  <v-col cols="12" class="d-flex justify-space-between">
                                    <v-btn v-if="experience == 'Experienced' &&
                                      professionalInfo.length != 1
                                      "@click="removeDataFromSteps(index)"
                                      class="d-flex justify-end btn danger-btn elevation-0">Remove</v-btn>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>

                          <v-row>
                            <v-col class="mt-4" v-if="experience == 'Experienced'">
                              <v-btn 
                              class="btn outline-btn elevation-0"
                              :class="ProfessionalFormfilled ? 'outline-disabled-btn': ''"
                              :disabled="ProfessionalFormfilled" @click="addProfessionalInfo">Add More Professional Info</v-btn>
                            </v-col>
                          </v-row>

                        </v-card>
                      </v-card>
                    </v-form>
                    <!-- <div class="w-100 d-flex flex-row justify-end">

                        <v-btn
                            rounded
                            text
                            class="ma-4"
                            color="brown-text"
                            @click="e1 = e1 - 1"
                            >
                            BACK
                        </v-btn>
                          
                        <v-btn
                            depressed
                            rounded
                            @click="e1++"
                            class="mx-4 my-4 btn-color white--text"
                          >
                            Next
                        </v-btn>
                          
                        </div> -->
                  </v-stepper-content>

                  <!-------------------------------------------STEP 4------------------------------------------>

                  <!-- <v-stepper-content step="4">
                    <v-form lazy-validation ref="step4">
                      <v-card elevation="0">
                        <v-card class="pa-4" elevation="0">
                          <v-row class="py-0 px-0">
                            <v-col cols="6" class="py-0">
                              <div class="text-body-1">Cluster*</div>
                              <v-autocomplete clearable deletable-chips label="Choose Clusters*" outlined
                                :disabled="roleCluster != 'All Clusters'" v-model="cluster"
                                :rules="[(v) => !!v || 'Cluster is required']" required class="rounded-xl"
                                :items="clusters" item-text="name" item-value="name" solo flat @change="getClusterSchool">
                              </v-autocomplete>
                            </v-col>
                            <v-col class="py-0" cols="6" md="6" sm="6">
                              <div class="text-body-1">School*</div>
                              

                              <v-autocomplete clearable deletable-chips label="Choose School*" outlined v-model="school"
                                :disabled="roleSchool != 'All'" :rules="[v => !!v || 'School is required']" required
                                class="rounded-xl" :items="schools" item-text="name" item-value="name" solo flat>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="py-0">
                              <v-autocomplete clearable deletable-chips label="Level*" outlined class="rounded-xl"
                                :items="tableLevels" :rules="[(v) => !!v || 'level is required']" required
                                v-model="levels" item-text="name" total-items @change="getGrades()">
                              </v-autocomplete>
                            </v-col>
                            <v-col class="py-0">
                              <v-autocomplete clearable deletable-chips label="Grade*"
                                :rules="[(v) => !!v || 'grade is required']" required outlined v-model="grades"
                                class="rounded-xl" :items="gradesData" item-text="name" item-value="name">
                              </v-autocomplete></v-col>
                          </v-row>

                          <v-row>

                            <v-col class="py-0"><v-autocomplete label="Board" :items="boardsData" item-text="name"
                                item-value="name" outlined class="rounded-xl" v-model="board">
                              </v-autocomplete>
                            </v-col>
                            <v-col class="py-0">
                              <v-autocomplete clearable deletable-chips label="Subject*" outlined class="rounded-xl"
                                v-model="subjects" :rules="[(v) => !!v || 'subject is required']" required
                                :items="subjectsData" item-text="name" item-value="name">
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-card>
                    </v-form>

                   

                  </v-stepper-content> -->

                  <v-stepper-content step="4">
                    <v-form lazy-validation ref="step4">
                      <v-card class="pa-4 " elevation="0">
                        <v-row class="py-0 px-0">
                          <v-col cols="4" sm="4" md="4" class="py-0">
                            <div class="text-body-1">Cluster*</div>
                            <v-autocomplete clearable deletable-chips label="Choose Cluster*" outlined
                              :disabled="roleCluster != 'All Clusters'" v-model="cluster" required
                              :rules="[(v) => !!v || 'Cluster is required']" class="rounded-xl" :items="clusters"
                              item-text="name" item-value="name" solo flat @change="getClusterSchool">
                            </v-autocomplete>
                          </v-col>
                          <v-col class="py-0" cols="4" md="4" sm="4">
                            <div class="text-body-1">School*</div>

                            <v-autocomplete clearable deletable-chips label="Choose School*" outlined v-model="school"
                              :disabled="roleSchool != 'All'" required:rules="[v => !!v || 'School is required']"
                              class="rounded-xl" :items="schools" item-text="name" item-value="name" solo flat>
                            </v-autocomplete>
                          </v-col>
                          <v-col class="py-0" cols="4" md="4" sm="4">
                            <div class="text-body-1">Board</div>

                            <v-autocomplete label="Board" :items="boardsData" item-text="name" item-value="name"
                              outlined class="rounded-xl" v-model="board">
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card>


                      <v-card outlined v-for="(data, i) in responsibleTeachingAt" :key="i" class="pt-12 mb-8 px-4 "
                        elevation="0">
                        <v-btn v-if="i !== 0" class="close-icon" icon @click="removeItem(i)">
                          <v-icon size="20" class="red--text">mdi-trash-can-outline</v-icon>
                        </v-btn>
                        <v-row>
                          <v-col class="py-0">
                            <v-autocomplete clearable deletable-chips label="Level*"
                              :rules="[(v) => !!v || 'level is required']" outlined class="rounded-xl"
                              :items="tableLevels" v-model="data.level" item-text="name" total-items
                              @change="event => {getSubjectsForLevels([event], data, i); getGrades(i)}">
                            </v-autocomplete>
                          </v-col>
                          <v-col class="py-0">
                            <v-autocomplete clearable deletable-chips label="Grade*" outlined v-model="data.grade"
                              :rules="[(v) => !!v || 'grade is required']" class="rounded-xl" :items="data.gradesData" :disabled="checkLevels([data.level])">
                            </v-autocomplete>

                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col class="py-0">
                            <v-autocomplete clearable deletable-chips label="Subject*"
                              :rules="[(v) => !!v || 'subject is required']" outlined class="rounded-xl"
                              v-model="data.subject" :items="subjectsData[i]" item-text="name" item-value="name" :disabled="checkLevels([data.level])">
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card>

                      <p v-if="checkForDuplicateEntry" class="red--text mb-2">{{ duplicateErrorMessage }}</p>
                      <v-btn
                        class="btn outline-btn mb-2"
                        @click="addSubject">
                        Add More <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-form>

                    <!-- <div class="w-100 d-flex flex-row justify-end">
 
                         <v-btn
                         rounded
                         text
                         class="ma-4"
                         color="brown-text"
                         @click="e1 = e1 - 1"
                         >
                         BACK
                           </v-btn>
                           
                           <v-btn
                           :loading="isCreatingUser"
                           rounded
                           depressed
                           
                           class="mx-4 my-4 btn-color white--text"
                           @click="updateUser()"
                           >
                           Update Details
                           </v-btn>
                           
                         </div> -->

                  </v-stepper-content>

                </v-stepper-items>
              </v-card>
            </v-stepper>
          </v-container>
          </v-card-text>
        </v-card>
      </div>
      <!-- Success Dialog -->
      <v-dialog v-model="successDialog" max-width="366px" style="z-index: 1000 !important;" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">
                New User Created
              </p>
              <v-btn class="accent" large width="157px" rounded
                @click="successDialog = false, $router.back()">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="primary" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import "../styles.css";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import AddressController from "@/controllers/AddressController";
import UsersController from "@/controllers/UsersController";
import LevelController from "@/controllers/LevelController";
import SubjectController from "@/controllers/SubjectController";
import ClusterController from "@/controllers/ClusterController";

import BoardController from "@/controllers/BoardController";
import SchoolController from "@/controllers/SchoolController";
import GradeController from "@/controllers/GradeController";
import AuthService from "../services/AuthService";
import { onSnapshot, doc } from "firebase/firestore";

import algoliasearch from "algoliasearch";
import { collection, getDocs, getDoc, query, where } from "firebase/firestore";
import { db } from "../firebase";
import CampTotalPieChart from "./CampTotalPieChart.vue";
import CampTotalPieChartLevel from "./CampTotalPieChartLevel.vue";

import HorizontalBarChartVue from "./HorizontalBarChart";
import debounce from 'lodash/debounce';
import UserNavMenu from "@/components/UserNavMenu.vue";
export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
  },
  components: {
    CampTotalPieChart,
    HorizontalBarChartVue,
    CampTotalPieChartLevel,
    UserNavMenu
  },
  name: "UsersView",
  data() {
    return {
      clusters: [],
      cluster: null,
      allGrades:[],
      filteredSubjects:[],
      questionTypeList: null,


      searchSchool: "",
      levelTypeFilter: [],
      subjectTypeFilter: [],
      clusterTypeFilter: [],
      schoolTypeFilter: [],
      showClusterSchool: [],
      schools: [],
      statusType: null,
      graphSchools: [],
      filterVal: "",
      horizontalBarData: [],
      completionStatusGraph: [],
      levelChartData: [["", ""]],
      dLoading: false,
      roleType: "",

      school: null,

      roleCluster: "",
      roleSchool: "",
      responsibleTeachingAt: [{
        grade: 0,
        level: "",
        subject: ""
      }],
      duplicateErrorMessage: 'Error: Duplicate Entry',

      dobRules: [
        (v) => !!v || "Date of Birth is required",
        // (v) =>
        //   /^\d{4}-\d{2}-\d{2}$/.test(v) ||
        //   "Date of Birth must be in YYYY-MM-DD format",
      ],

      rules: {
        required: (value) => !!value || "Field is required",
      },

      vgosRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[a-zA-Z0-9_.-]{3,25}$/.test(v) || "E-mail must be valid",
        (v) => !this.emailExists || "This E-mail already exists!",
      ],
      debouncedDoesEmailExist: null,
      emailExists: false,
      options: {},
      isLoading: true,
      pageSize: 10,
      clustersSnap: [],
      page: 1,
      count: 0,
      e1: 1,
      editId: null,
      formbtnBool: false,
      experience: "Experienced",
      levels: null,
      status: ["active", "Inactive", "Invited"],
      ScreeningTestStatus: ["Passed", "Failed", "Yet to Start"],
      role: ["Teacher", "Job Seekers"],

      screeningId: -1,
      clusterSchool: "",
      hover: "",
      subjects: null,

      subjectId: [],
      isCurrentlyWorking: false,
      errorMessage: "Failed",
      selectedFormIndex: 0,
      dialog: false,

      selected: [],
      experienceYear: -1,
      deleteUserDetails: false,
      experienceMonth: -1,
      filterDialog: false,
      rolePosition: "",
      employeeTypeId: -1,
      board: null,
      gradesData: [],
      filterData: false,
      grades: null,
      clustersSchool: [],
      gradeId: -1,
      startDate: -1,
      endDate: -1,
      extraCarricularActivities: "",
      isCreatingUser: false,
      successDialog: false,
      resendInviteSuccessDialog: false,
      deleteSuccessDialog: false,
      errorDialog: false,
      boardsData: [],
      tableLevels: [],
      subjectsData: [],
      schoolData: "",
      store: "",
      search: "",
      vgosCount: 0,
      jobSeekerCount: 0,


      personalInfo: {
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
        isEmailVerified: false,
        isPhoneVerified: false,
        contactInfo: {
          email: "",
          phoneNumber: "",
        },
        addressInfo: {
          countryName: "India",
          stateName: "",
          districtName: "",
          tulakName: "",
          cityName: "",
          pincode: null,
          address: "",
        },
      },
      academicsInfo: [
        {
          institution: "",
          programme: "",
          startDate: "",
          endDate: "",
          fieldOfStudy: "",
          gradeScore: 0,
          gradeType: "",
          certificateUrl: "",
        },
      ],
      professionalInfo: [
        {
          experienceYear: 0,
          experienceMonth: 0,
          position: "",
          employeeTypeId: 0,
          board: 0,
          startDate: Date.now(),
          endDate: Date.now(),
          levels: [],
          grades: [],
          subjects: [],
          school: "",
          otherName: "",
          isCurrentlyWorking: false
        },
      ],
      expandedPanelIndex: 0, // set the last panel as expanded by default
      countries: [
        {
          name: "India",
        },
      ],
      states: [],
      districts: [],
      cities: [],
      talukas: [],
      users: [],

      employeeType: [
        {
          id: 1,
          name: "Permanent",
        },
        {
          id: 2,
          name: "Contract",
        },
        {
          id: 3,
          name: "Prohibition",
        },
      ],


      preloader: true,
      // loader: false,
    };
  },
  watch: {
     'personalInfo.contactInfo.email'(newEmail) {
      if (newEmail) {
        this.debouncedDoesEmailExist("Teacher", `${newEmail}@vgos.org`);
      }
    }
  },


  methods: {
    async doesEmailExist(userType, email){
      let response = await AuthService.doesEmailExist(userType, email)
      this.emailExists = false;
      if(response){
       this.emailExists = response;
      }
      this.$refs.emailField.validate();
    },
    currentlyWorking(index){
      if(this.professionalInfo[index].isCurrentlyWorking){  
        this.professionalInfo[index].endDate = null
      }

    },
    endDateRule(value,index) {
      const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
      if (!regex.test(value)) {
        return 'Please enter a valid date (YYYY-MM-DD)';
      }
      const startDateObj = new Date(
        this.professionalInfo[index].startDate
      );
      const endDateObj = new Date(value);
      if (startDateObj >= endDateObj) {
        return 'End date must be greater than start date';
      }
      const currentDate = new Date();
      if (currentDate < endDateObj) {
          return "End date must be lower than current date";
        }

      return true;
    },
    setIndex(index){

      this.selectedFormIndex = index
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },


    removeItem(i) {
      this.responsibleTeachingAt.splice(i, 1);
    },



    async getClusterSchool() {
      const response = await SchoolController.getClusterSchool([this.cluster]);
      if (response.data.flag) {
        var schools = response.data.schools;

        this.schools = schools;
      } else {
        alert(response.data.error);
      }
    },
    async fetchAllClusters() {
      const response = await ClusterController.getAllClusters();

      if (response.data.flag) {
        this.clusters = response.data.clusters;
      } else {
        alert(response.data.error);
      }
    },
    getClusterOrSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;
      if (this.roleSchool == undefined) {
        AuthService.logout();
        this.$router.push("/login");
      }

      if (this.roleCluster != "All Clusters") {
        this.cluster = this.roleCluster;
        this.clusterSchool = this.roleCluster;
        this.getClusterSchool();
      }
      if (this.roleSchool != "All") {
        this.school = this.roleSchool;
        this.clusterSchool = this.roleSchool;
      }
    },
    getStepName(step) {
      var name = "";
      if (step == 1) {
        name = "Personal Information";
      } else if (step == 2) {
        name = "Academics";
      } else if (step == 3) {
        name = "Professional Info";
      } else if (step == 4) {
        name = "VGOS Onboarding";
      }
      return name;
    },
    resetProgress() {
      this.progress = 0;
      this.buffer = 100;
      this.dialogVisible = false;
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },



    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },
    async updateProfessionalInfo(data, userId) {
      const response = await UsersController.updateProfessionalInfo(
        data,
        userId,
        data.id
      );
    },

    addSubject() {
      this.responsibleTeachingAt.push({
        grade: 0,
        level: "",
        subject: ""
      })
    },

    // goToStep2() {
    //   if (this.formbtnBool) {
    //     if (this.$refs.step1.validate()) {      
    //       this.e1 = 2;
    //     }
    //   } else {
    //     if (this.$refs.step1.validate()) {
    //       this.e1 = 2;
    //       this.expandedPanelIndex = 0;
    //     }
    //   }
    // },

    updateData(item) {
      this.editId = item.userId;
      this.formbtnBool = true; // change update/create btn value
      (this.personalInfo.title = item.title),
        (this.personalInfo.firstName = item.personalInfo.firstName),
        (this.personalInfo.middleName = item.personalInfo.middleName),
        (this.personalInfo.lastName = item.personalInfo.lastName),
        (this.personalInfo.contactInfo.email =
          item.personalInfo.contactInfo.email + '@vgos.org'),
        (this.personalInfo.dob = item.personalInfo.dob),
        (this.personalInfo.gender = item.personalInfo.gender),
        (this.personalInfo.contactInfo.phoneNumber =
          item.personalInfo.contactInfo.phoneNumber),
        (this.personalInfo.addressInfo.countryName =
          item.personalInfo.addressInfo.countryName),
        (this.personalInfo.addressInfo.stateName =
          item.personalInfo.addressInfo.stateName),
        (this.personalInfo.addressInfo.cityName =
          item.personalInfo.addressInfo.cityName),
        (this.personalInfo.addressInfo.districtName =
          item.personalInfo.addressInfo.districtName),
        (this.personalInfo.addressInfo.address =
          item.personalInfo.addressInfo.address),
        (this.personalInfo.addressInfo.pincode =
          item.personalInfo.addressInfo.pincode),
        (this.academicsInfo = item.academicsInfo);
      this.professionalInfo = item.professionalInfo;

      this.cluster = this.$store.state.user.cluster
      this.school = this.$store.state.user.schoolName;
      this.board = this.$store.state.user.board
      this.responsibleTeachingAt = this.$store.state.user.responsibleTeachingAt != undefined ? this.$store.state.user.responsibleTeachingAt : this.responsibleTeachingAt;
      this.getClusterSchool();


      // this.professionalInfo.experienceYear = item.experienceYear,
      // this.professionalInfo.experienceMonth = item.experienceMonth,
      // this.professionalInfo.position = item.position,
      // this.professionalInfo.employeeTypeId = item.employeeTypeId,
      // this.professionalInfo.board = item.board,
      this.dialog = true;
      // this.getAcademics(this.editId),
      // this.getProfessionalInfo(this.editId)
    },

    async getLevels() {
      const response = await LevelController.getLevel();
      if (response.status == 200) {
        this.tableLevels = response.data.levels;
      } else {
        alert("Levels Not Found!!");
      }
    },
    async getGrades(i) {

      // const array = this.tableLevels.filter((level) =>
      //   this.levels.includes(level.name));

      const array = this.tableLevels.filter((level) =>
        level.name == this.responsibleTeachingAt[i].level
      );

      // this.gradesData = [];
      // array.forEach((subArray) => {
      //   subArray.grades.forEach((grade) => {
      //     this.gradesData.push(grade);
      //   });
      // });

      this.responsibleTeachingAt[i].gradesData = array[0].grades


    },


    goToNextStep() {

      if (this.e1 === 1) {

        if (this.formbtnBool) {
          if (this.$refs.step1.validate()) {
            // this.updateUser(this.personalInfo, this.editId);
            this.e1 = 2;
          }
        } else {
          if (this.$refs.step1.validate()) {
            this.e1 = 2;
            this.expandedPanelIndex = 0;
          }
        }
      }
      else if (this.e1 === 2) {
        if (this.formbtnBool) {
          // this.updateAcademics();
          this.e1 = 3;
        } else {
          if (this.$refs.step2.validate()) {
            this.e1 = 3;
            this.expandedPanelIndex = 0;
          }
        }
      }

      else if (this.e1 === 3) {
        if (this.$refs.step3.validate()) {
          this.e1 = 4;
        }
      }

      else if (this.e1 === 4) {
        this.createUser()
      }

    },

    // goToStep3() {
    //   if (this.formbtnBool) {
    //     // this.updateAcademics();
    //     this.e1 = 3;
    //   } else {
    //     if (this.$refs.step2.validate()) {
    //       this.e1 = 3;
    //       this.expandedPanelIndex = 0;
    //     }
    //   }
    // },


    async createUser() {
      if (this.$refs.step4.validate()) {
        console.log("888888888888888888888888888", Array.isArray(this.responsibleTeachingAt))
        for (var i = 0; i < this.responsibleTeachingAt.length; i++) {
          delete this.responsibleTeachingAt[i].gradesData
        }

        // this.responsibleTeachingAt.forEach((obj)=>{
        //     delete obj.gradesData
        //  })

        (this.personalInfo.contactInfo.phoneNumber =
          "+91" + this.personalInfo.contactInfo.phoneNumber),
          (this.isCreatingUser = true);

        this.personalInfo.contactInfo.email = this.personalInfo.contactInfo.email + '@vgos.org';
        const response = await UsersController.createUser({
          userType: "Teacher",
          status: "INACTIVE",

          cluster: this.cluster,
          schoolName: this.school,

          board: this.board,
          responsibleTeachingAt: this.responsibleTeachingAt,
          subjects: this.subjects,
          personalInfo: this.personalInfo,
          academicsInfo: this.academicsInfo,
          professionalInfo: this.professionalInfo,
        });
        console.log("888888888888888888888888888")

        if (response.status == 200) {
          this.isCreatingUser = false;
          this.successDialog = true;
          this.dialog = false;
          this.fetchUsers();
        } else {
          this.isCreatingUser = false;
          this.errorMessage = response.data.error;
          this.errorDialog = true;
        }
      }
    },

    async updateAcademics(qualification, usrid) {
      const response = await UsersController.updateAcademics(
        qualification,
        usrid,
        qualification.id
      );
    },
    async getAcademics(id) {
      const response = await UsersController.getAcademics(id);
      this.academicsInfo = response.data.data;
    },

    async getProfessionalInfo(id) {
      const response = await UsersController.getprofessionalInfo(id);
      this.professionalInfo = response.data.data;
    },
    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },

    async deleteUser(items) {
      var ids = [];
      this.dLoading = true;
      items.forEach((doc) => {
        ids.push(doc.userId);
      });
      const response = await UsersController.deleteUser({
        ids: ids,
      });
      if (response.status == 200) {
        this.selected = [];
        this.selected.length = 0;
        ids = [];
        this.dLoading = false;
        setTimeout(() => {
          // This code will execute after a 1 second delay
          this.initAlgoliaClient();
          this.fetchUsers();
          this.getTotalCount();
        }, 5000);
      } else {
        alert(response.data.error);
        this.dLoading = false;
      }
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },

    addAcademicQualification() {
      this.academicsInfo.push({
        institution: "",
        programme: "",
        startDate: "",
        endDate: "",
        fieldOfStudy: "",
        gradeScore: 0,
        gradeType: "",
        certificateUrl: "",
      });
      this.expandedPanelIndex = this.academicsInfo.length - 1;
    },

    addProfessionalInfo() {
      this.professionalInfo.push({
        experienceYear: 0,
        experienceMonth: 0,
        position: "",
        employeeTypeId: 0,
        board: 0,
        startDate: "",
        endDate: "",
        school: "",
        isCurrentlyWorking: false
      });
      this.expandedPanelIndex = this.professionalInfo.length - 1;
    },

    async fetchCountries() {
      const response = await AddressController.getCountries();

      if (response.data.success) {
        this.countries = response.data.data.rows;
      } else {
        alert(response.data.error);
      }
    },

    async fetchStates() {
      var states = [];
      const querySnapshot = await getDocs(collection(db, "states"));
      if (querySnapshot.empty) {
        alert("States Not Found!!");
        return;
      } else {
        querySnapshot.forEach((doc) => {
          states.push(doc.data());
        });
      }
      states.sort((a, b) => a.state.localeCompare(b.state));
      this.states = states;
    },

    async fetchDistricts() {

      const q = query(
        collection(db, "districts"),
        where("state", "==", this.personalInfo.addressInfo.stateName)
      );
      const querySnapshots = await getDocs(q);

      querySnapshots.forEach((doc) => {
        this.districts.push(doc.data());
      });
      this.districts.sort((a, b) => a.district.localeCompare(b.district))

    },

    async fetchCitiesAndTalukas() {
      const q = query(
        collection(db, "cities"),
        where("district", "==", this.personalInfo.addressInfo.districtName)
      );
      const querySnapshots = await getDocs(q);
      this.cities = [];
      querySnapshots.forEach((doc) => {
        this.cities.push(doc.data());
      });
      this.cities.sort((a, b) => a.city.localeCompare(b.city))

    },

    async fetchTalukas() {
      const response = await AddressController.getTalukas(
        this.personalInfo.addressInfo.districtName
      );

      if (response.data.success) {
        this.talukas = response.data.data.rows;
      } else {
        alert(response.data.error);
      }
    },

    async fetchCities() {
      const response = await AddressController.getCities(
        this.personalInfo.addressInfo.stateName,
        this.personalInfo.addressInfo.tulakName
      );
      if (response.data.success) {
        this.cities = response.data.data.rows;
      } else {
        alert(response.data.error);
      }
    },

    removeDataFromSteps(index) {
      this.deleteUserDetails = true;
      if (this.e1 == 2) {
        this.academicsInfo.splice(index, 1);
      } else if (this.e1 == 3) {
        console.log("removig index is ", index)
        this.professionalInfo.splice(index, 1);
      }
      this.deleteUserDetails = false;
      this.indexValue = null;
    },

    openDeleteDiolog(index) {
      this.indexValue = index;
      this.deleteUserDetails = true;
    },

    async getBoards() {
      const response = await BoardController.getBoards();

      if (response.status == 200) {
        this.boardsData = response.data.boards;
      } else {
        alert("Boards Not Found!!");
      }
    },

    async getSchool() {
      const response = await SchoolController.getSchool();
      if (response.status == 200) {
        this.schoolData = response.data.levels;
      } else {
        alert("Schools Not Found!!");
      }
    },
    async getGradesMaster(){
      const response = await GradeController.getAllGrades();
      if (response.status == 200) {
        if (response.data.grades.length > 0) {
          this.allGrades = response.data.grades;
        }
      } else {
        alert(response.data.error);
      }
    },
    getSubjectsForLevels(event, data, index, forProfessionalInfo = false){
      let filteredGrades=[];
      //for Professional Information (Step 3)
      if(forProfessionalInfo) {
        data.subjects = [];
        this.filteredSubjects[index] = [];
        event.forEach(level => {
          this.tableLevels.forEach(tableLevel => {
            if(tableLevel.name == level){
              filteredGrades.push(...tableLevel.grades)
            }
          })
        })
        filteredGrades.forEach( grade => {
          this.allGrades.forEach(item=>{
            if(item.name == grade){
              this.filteredSubjects[index].push(...item.subjects)
            }
          })
        })
        this.filteredSubjects[index] = [...new Set(this.filteredSubjects[index])];
      }
      // forVGOS Onboarding (step 4)
      else {
        data.subject = [];
        this.subjectsData[index] = [];
        data.grade = null;
        event.forEach(level => {
        this.tableLevels.forEach(tableLevel => {
          if(tableLevel.name == level){
            filteredGrades.push(...tableLevel.grades)
          }
          })
        })
        filteredGrades.forEach( grade => {
          this.allGrades.forEach(item=>{
            if(item.name == grade){
              this.subjectsData[index].push(...item.subjects)
            }
          })
        })
        this.subjectsData[index] = [...new Set(this.subjectsData[index])];
      }
      
    },
    checkLevels(levels){
      if(levels !== undefined){
        return levels.length==0
      }
      else if(levels == undefined){
        return true
      }
    },

  },
  computed: {
    maxDate() {
      // Get today's date and format it to be used as the maximum date
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1; // Month is zero-based
      let day = today.getDate();

      // Ensure month and day are formatted correctly (with leading zeros if needed)
      month = month < 10 ? `0${month}` : month;
      day = day < 10 ? `0${day}` : day;

      // Format the date as 'YYYY-MM-DD'
      return `${year}-${month}-${day}`;
    },
    checkForDuplicateEntry() {
      console.log(this.responsibleTeachingAt);
      if (this.responsibleTeachingAt.length > 1) {
        let seen = new Set();
        for (let i = 0; i < this.responsibleTeachingAt.length; i++) {
          let key = this.responsibleTeachingAt[i].grade + this.responsibleTeachingAt[i].level + this.responsibleTeachingAt[i].subject;
          if (seen.has(key)) {
            console.log('Found a duplicate');
            return true; // Found a duplicate
          } else {
            seen.add(key);
          }
        }
        console.log('No duplicate found');

        return false; // No duplicates found
      }
      return false;
    },
    isLastFormfilled() {
      var keys = ["institution",
        "programme",
        "startDate",
        "endDate"]


      var flag = false
      keys.forEach(obj => {
        if (this.academicsInfo[this.academicsInfo.length - 1][obj] == "") {
          flag = true
        }
      })
      return flag
    },


   ProfessionalFormfilled() {
    var keys = ["position",
  "programme",
      "startDate",
     ]

     if(!this.professionalInfo[ this.professionalInfo.length - 1].isCurrentlyWorking){
      keys.push("endDate")
     }


      var flag = false
      keys.forEach(obj=>{
        if(this.professionalInfo[ this.professionalInfo.length - 1][obj] == ""){
          flag = true
        }
      })
      return flag
    },


    dobRule() {
      return (value) => {
        const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
        if (!regex.test(value)) {
          return "Please enter a valid date (DD-MM-YYYY)";
        }

        const selectedDate = new Date(value);
        const currentDate = new Date();

        // Calculate the age based on the selected date and current date
        const age = currentDate.getFullYear() - selectedDate.getFullYear();

        if (age < 18) {
          return "You must be at least 18 years old";
        }

        return true;
      };
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    dateRule() {
      return (value) => {
        const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
        if (!regex.test(value)) {
          return "Please enter a valid date (DD-MM-YYYY)";
        }

        const selectedDate = new Date(value);
        const currentDate = new Date();
        if (selectedDate > currentDate) {
          return "Selected date must be less than or equal to current date";
        }
        return true;
      };
    },
    // endDateRule() {
    //   return (value) => {
    //     console.log("value--",value)
    //     const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
    //     if (!regex.test(value)) {
    //       return "Please enter a valid date (DD-MM-YYYY)";
    //     }
    //     const startDateObj = new Date(
    //       this.professionalInfo[this.selectedFormIndex].startDate
    //     );
    //     const endDateObj = new Date(value);
    //     if (startDateObj > endDateObj) {
    //       return "End date must be greater than start date";
    //     }
  
      
    //     return true;
    //   };
    // },
    AcadEndDateRule() {
      return (value,index) => {  
        const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
        if (!regex.test(value)) {
          return "Please enter a valid date (DD-MM-YYYY)";
        }
        const startDateObj = new Date(
          this.academicsInfo[index].startDate
        );


        const endDateObj = new Date(value);
        if (startDateObj >= endDateObj) {
          return "End date must be greater than start date";
        }

        const currentDate = new Date();
        if(index == 0){
          if (currentDate < endDateObj) {
          return "End date must be lower than current date";
        }
        }
       


        return true;
      };
    },


  },
  created() {
    this.fetchStates();


    this.getLevels();
    // this.getGrades();
    this.getGradesMaster();
    this.getBoards();
    this.getSchool();
    this.fetchAllClusters();


    this.$store.state.breadcrumb = "Users";
    this.debouncedDoesEmailExist = debounce(this.doesEmailExist, 500);
    // this.updateUsers()
  },
  mounted() {
    if (this.$store.state.role.createdOn == undefined) {
      this.logout();
    }
    if (!this.$store.state.role.teacherOnboarding.read) {
      this.$router.push("/notFound");
    }
    this.getClusterOrSchool();
  },
  unmounted(){
    this.debouncedDoesEmailExist.cancel()
  }
};
</script>

<style scoped>
.btnStyle.v-btn--outlined {
  border: thin solid #190cca;
}

.blue-border {
  border-color: blue !important;
}

.graph {
  border-radius: 8px !important;
  margin-left: 8px;
  padding: 0;
}
</style>
