<template>
    <div v-if="preloader">
        <template>
          <v-dialog v-model="preloader" hide-overlay persistent width="300">
            <v-progress-linear indeterminate></v-progress-linear>
          </v-dialog>
        </template>
      </div> 
    <div v-else> 
        <v-card height="64px" width="100%"
        class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
        style="position: fixed; z-index: 200; top: 0; width: 98%; padding-top: 16px;">
        <v-card-title class="ml-0 pl-0">
            <p class="breadcrumb-text">Assessments</p>
            <v-icon
            class="breadcrumb-arrow-unselected"
            style="margin-top: 6px !important"
            >mdi-chevron-right
            </v-icon>
            <p class="breadcrumb-text" style="margin-top: 2px">{{ this.assessmentDetails.assessmentName }}</p>
            <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
        </v-card-title>
        <UserNavMenu/>
        </v-card>
        <div fluid class="pad px-8 background">
            <v-row>
                <v-col class="d-flex align-center">
                <!-- <div class="mt-4" v-if="showUsers">
                    <span class="m-4 cursor" @click="showUsers = false"
                    >Assessments</span
                    >
                    <v-icon>mdi-chevron-right</v-icon>
                    <span class="text--secondary">{{ this.assessmentDetails.assessmentName }}</span>
                    <v-icon>mdi-chevron-right</v-icon>
                </div> -->
                </v-col>
                <v-col cols="4"> </v-col>
            </v-row>

            <!-- assessment user table row with filter btn and color code -->
            <v-row class="my-8 justify-space-between" v-if="showUsers">
                <!-- filter btn and color code -->
                <v-col
                cols="6"
                sm="6"
                md="6"
                class="d-flex justify-end align-center ma-0 pa-0"
                >
                <v-spacer></v-spacer>

                <v-spacer></v-spacer>

                <v-btn class="primary ml-2" rounded>
                    <v-icon>mdi-tune</v-icon>Filter</v-btn
                >
                </v-col>
            </v-row>

            <!-- Assessment row and fiter and export btn -->

            <v-row class="justify-space-between" v-if="!showUsers">
                <v-col cols="3">
                <div class="text-h6">{{ this.assessmentDetails.assessmentName }}</div>
                </v-col>
                <v-col cols="9" class="mt-0 mb-6">
                <v-row class="justify-end">
                    <v-text-field
                    solo
                    label="Search here..."
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                    class="search-bar border-button rounded-pill mx-2"
                    dense
                    clearable
                    ></v-text-field>

                    <v-btn text depressed rounded outlined class="border-button mx-2 pl-4 pr-6">
                    <v-icon class="pr-2">mdi-tune</v-icon>
                        <span v-if="filterCount > 0" @click="filterDialog = true">Filter ({{ filterCount }})</span>
                        <span v-else @click="filterDialog = true">Filter</span></v-btn>
                        <v-btn depressed outlined rounded class="border-button mx-2">
                        <v-icon>mdi-import mdi-rotate-90</v-icon>
                    </v-btn>
                </v-row>
                </v-col>
            </v-row>

            <!------------------------------------------ Assessment card here---------------------------- -->
            <v-card> 
                <div class="container-fluid p-3 border rounded bg-light assessment-details">
                    <div class="row align-items-center">
                        <div class="col-auto col-md-2">
                            <span class='ml-2 label-text'>Language -</span> 
                            <span class='ml-2 label-value' v-for="(item, index) in assessmentDetails?.availableLanguages" :key="index">
                                {{ item }}<span  v-if="index < assessmentDetails.availableLanguages.length - 1">, </span>
                            </span>
                        </div>
                        <div class="col-auto col-md-2">
                            <span class='ml-2 label-text'>Level -</span>
                            <span class="ml-2 label-value">{{ assessmentDetails.level }}</span>
                        </div>
                        <div class="col-auto col-md-2">
                            <span class='ml-2 label-text'>Grade -</span>
                            <span class="ml-2 label-value">{{ assessmentDetails.grade }}</span>
                        </div>
                        <div class="col-auto col-md-2">
                            <span class='ml-2 label-text'>Total Questions -</span>
                            <span class="ml-2 label-value">{{ assessmentDetails.questions.length }}</span>
                        </div>
                        <div class="col-auto col-md-2">
                        <span class='ml-2 label-text'>Marks -</span>
                        <span class="ml-2 label-value">60</span>
                        </div>
                        <div class="col-auto col-md-2">
                            <span class='ml-2 label-text'>Campaign Linked -</span>
                            <span class="ml-2 label-value">00</span>
                        </div>
                        <div class="col-auto col-md-3 ">
                            <span class='ml-2 label-text'>Skills -</span>
                            <span class="ml-2 label-value">Core Skill (English, Maths, Logical, Reasoning, DL)</span>
                        </div>
                    </div>
                </div>
            </v-card>

            <v-data-table class="pt-10" :loading="isLoading" v-model="selectedTableItems" :headers="headers" height="calc(100vh - 230px)"
                fixed-header :items="questionsList" show-select :single-select="singleSelect" item-key="id"
                :items-per-page="-1" :options.sync="dataTableOptions" :server-items-length="newQuestionCount" :footer-props="{
                    itemsPerPageOptions: [10, 25, 50, 100],}">

                <template v-slot:[`item.questionStatement`]="{ item }">
                    <div v-html="item.questionStatement"></div>
                </template>
                <template v-slot:[`item.MyLo`]="{}">
                    <span><v-chip>{{ dummyLO[0].name }}</v-chip></span>
                    <span v-if="dummyLO.length > 1"><v-chip>+{{ dummyLO.length - 1 }}</v-chip></span>
                </template>
                <template v-slot:[`item.createdOn`]="{ item }">
                    {{ getDate(item.createdOn) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex flex-row">
                        <img width="36px" height="36px" class="cursor" src="../assets/edit.svg" @click="redirectToEditStaticAssessmentQues(item.questionId)" />
                        <img width="36px" height="36px" class="cursor ml-2" src="../assets/preview_1.svg" />
                    </div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>

    import AuthService from "../services/AuthService";
    import StaticAssessmentController from "@/controllers/StaticAssessmentController";
    import UserNavMenu from "@/components/UserNavMenu.vue";
  
    export default {
        name: "StaticAssessmentEdit",
        components :{
            UserNavMenu
        },
        data() {
            return {
                preloader: true,
                assessmentId: "",
                assessmentDetails: {},
                showUsers: false,
                filterDialog: false,
                filterCount: 0,
                questionsList: [],
                pageSize: 10,
                page: 1,
                dataTableOptions: {},
                selectedTableItems: [],
                newQuestionCount: 0,
                singleSelect: false,
                isLoading: true,
                headers: [
                    { text: "Question ID", value: "questionId", cellClass: "w-auto" },
                    { text: "Question Statement", value: "questionStatement", cellClass: "Qw-40" },
                    { text: "Skills", value: "skill", cellClass: "w-15" },
                    { text: "Score", value: "score", cellClass: "w-15" },
                    { text: "Difficulty Level", value: "difficultyLevel", cellClass: "w-15" },
                    { text: "Grade", value: "grade", cellClass: "w-15" },
                    { text: "Actions", value: "actions", cellClass: "w-10" },
                ],
            }
        },

        mounted() {
            this.assessmentId = this.$route.query.id;
            console.log("assessmentId is", this.assessmentId);
            this.pageSize = 10;
            this.page = 1;
            this.fetchAssessmentById(this.assessmentId);
        },

        watch: {
            dataTableOptions: {
            handler() {
                console.log("dataTableOptions entered", this.dataTableOptions);
                this.pageSize = this.dataTableOptions.itemsPerPage;
                this.page = this.dataTableOptions.page;
                this.fetchAssessmentById(this.assessmentId);
            },
            deep: true

        },
        },
        methods: {
            getDate(timeStamp) {
                return new Date(timeStamp).toString().substring(0, 16);
            },
            logout() {
                AuthService.logout();
                this.$router.push("/login");
            },
            async fetchAssessmentById(assessmentId) {
                this.isLoading = true;
                const response = await StaticAssessmentController.getStaticAssessmentsById(assessmentId, this.pageSize, this.page);
                console.log("response ==> ", response.data.assessments?.[0]?.questions)
                if(response.status == 200) {
                    this.questionsList = response.data.assessments?.[0]?.questions;
                    this.assessmentDetails = response.data.assessments[0];
                    this.newQuestionCount =  response.data.assessments?.[0]?.questions?.length;
                    console.log("this.questionsList ==> ",this.questionsList)
                    this.preloader = false;
                    this.isLoading = false;
                } else {
                    this.preloader = false;
                    this.isLoading = false;
                }
            },
            redirectToEditStaticAssessment() {
                this.$router.push("/edit-static-assessment");
            },
            redirectToEditStaticAssessmentQues(questionId) {
                this.$router.push({ path: "/edit-static-assessment", query: { assessmentId: this.assessmentDetails.id, questionId }});
            }
        },

        created() {
            this.$store.state.breadcrumb = "Assessment";
        }
    } 

</script>

<style> 
.assessment-card {
    padding: 1%
}
</style>
