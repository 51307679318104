<template>
  <div>
    <!-- QR Code Dialog -->
    <v-dialog class="cdz" v-model="startTimeDialog" max-width="500px">
          <v-card  class="px-3 pb-3 pt-1 rounded-xl gray-02" fluid>
            <div class="i-patch pa-1 mb-2">Select Time</div>
            <v-time-picker
              v-model="campaignStartTime"
              format="24hr"
              landscape="landscape"
              @change="startTimeDialog = false"
              elevation="0"
            ></v-time-picker>
          </v-card>
    </v-dialog>
    <v-dialog class="cdz" v-model="endTimeDialog" max-width="500px">
      <v-card class="px-3 pb-3 pt-1 rounded-xl gray-02" fluid>
        <div class="i-patch pa-1 mb-2">Select Time</div>
        <v-time-picker
          v-model="mainsEndTime"
          format="24hr"
          landscape="landscape"
          @change="endTimeDialog = false"
          elevation="0"
        ></v-time-picker>
      </v-card>
    </v-dialog>
    <v-dialog v-model="qrDialog" class="cdz" persistent max-width="500px">
      <div v-if="qrDialog">
        <v-card>
          <v-container fluid class="pa-8">
            <v-icon size="24" class="pt-4" @click="qrDialog = false">mdi-close</v-icon>

            <v-card-text class="text-center d-flex flex-column justify-center align-center">
              <div ref="qrDownloadRef" style="margin: 8px; padding: 16px">
                <p class="text-h5 py-1 mx-1" style="font-weight: bold">
                  {{ qrCampaignName }}
                </p>
                <VueQRCodeComponent style="
                    display: flex;
                    justify-content: center;
                    margin-bottom: 8px;
                    padding: 16px;
                  " :text="qrCampaignLink" error-level="L"></VueQRCodeComponent>
              </div>
              <v-btn class="btn primary-btn" large width="157px" rounded :loading="qrDownloadLoading"
                @click="downloadQrCode">Download</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </div>
    </v-dialog>

    <!-- Success Dialog -->

    <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
      <v-card>
        <v-container fluid class="pa-8">
          <v-card-text class="text-center d-flex flex-column align-center">
            <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
            <p class="text-h5 py-1">
              New Campaign {{ campaignButtonValues() }}
            </p>
            <div class="w-100% mb-2" color="">
              <v-btn v-if="isJobSeeker(campaignData.audienceType)"
                @click="copyToClipboardCampaign()" text color="#1B72E8"><v-icon>mdi-link-variant</v-icon>
              </v-btn>
              <v-btn v-if="isJobSeeker(campaignData.audienceType)" :loading="qrLoading" @click="generateQRCode()" text
                color="#1B72E8"><v-icon>mdi-qrcode</v-icon>
              </v-btn>
            </div>
            <v-btn class="btn primary-btn" large width="157px" rounded
              @click="(successDialog = false), $router.push('/campaign')">OK</v-btn>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userViewDialog" class="cdz" max-width="450px">
      <v-card v-if="this.viewUserIno != {}" width="450px" height="100%" class="filterDialogClass pl-2">
        <v-row class="py-2">
          <v-col cols="4">
            <img src="../assets/avtar.png" class="rounded-xl mr-2" width="132" height="132" />
          </v-col>

          <v-col align-self="end" cols="8">
            <div class="text-h6 pl-4">
              {{ this.userFullName }}
            </div>
            <div class="text-body-1 pl-4 mb-3">
              {{ this.viewUserIno.schoolName }}
            </div>
          </v-col>
        </v-row>
        <v-col>
          <v-row class="pl-1">
            <div>Subjects:-</div>
            <div class="text-body-1 pt-1 pl-1" v-for="(item, index) in this.viewUserIno.responsibleTeachingAt">
              {{ item.subject }},
            </div>
          </v-row>
          <v-row>
            <div class="text-h6 pl-1" v-for="item in this.viewUserIno.responsibleTeachingAt">
              {{ item.level }},
            </div>
          </v-row>

          <v-row><v-divider> </v-divider></v-row>
          <v-row class="pl-1 pt-5">
            <div class="text-h5">Email</div>
          </v-row>
          <v-row class="pl-1 pt-2 pb-5">
            <div>
              {{ this.userEmail }}
            </div>
          </v-row>
          <v-row>
            <v-divider> </v-divider>
          </v-row>
          <v-row class="pl-1 pt-5">
            <div class="text-h5">Phone</div>
          </v-row>
          <v-row class="pl-1 pt-2">
            <div>{{ this.userPhoneNumber }}</div>
          </v-row>
        </v-col>
        <div class="filterDialogButton">
          <v-card-actions class="px-6 pb-0">
            <v-spacer></v-spacer>
            <v-btn elevation="0" rounded class="btn primary-btn pa-4 text-body-2" @click="userViewDialog = false">Close</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      ">
      <v-card-title class="ml-0 pl-0">
        <span @click="$router.push('/campaign')" class="breadcrumb-text cursor">
          Campaign
        </span>
        <v-icon class="breadcrumb-arrow">mdi-chevron-right</v-icon>
        <p class="breadcrumb-text">Create Campaign</p>
        <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <UserNavMenu/>
    </v-card>
    <div class="background" hide-overlay>
      <v-card class="pa-6 background" elevation="0" depressed>
        <div class="background pb-4 d-flex justify-space-between fixBarCreateCammpaign">
          <div class="pt-3">
            <v-toolbar-title class="text-h6 ml-6 d-flex i-patch">Create Campaign -{{
              campaignData.audienceType == "TEACHER"? " Internal Staff":
              campaignData.audienceType == "JOB_SEEKER" ? " Job Seeker" : " MESTA Students"
            }}</v-toolbar-title>
          </div>
          <div class="mr-6 fixLog d-flex align-center">
            <v-btn text class="btn outline-btn mr-4" @click="$router.push('/campaign')">Cancel</v-btn>
            <v-btn v-if="e1 > 1 && e1 <= 4
            " outlined rounded class="btn outline-btn" @click="goBack()">back</v-btn>
            <v-btn v-if="!isLastStep" :disabled="campaignFormNotFilled" :loading="dLoading" rounded depressed class="btn primary-btn my-4 ml-4"
              @click="goto(e1)">
              next
            </v-btn>
            <v-btn  v-if="isLastStep"
             :disabled="disablePublish" :loading="dLoading" rounded depressed class="btn primary-btn my-4 ml-4"
              @click="goto(e1)">
              publish
            </v-btn>
          </div>
        </div>

        <v-stepper v-model="e1" class="background padCreateCampaign" depressed elevation="0">
          <v-stepper-header v-if="userType == 'JOB_SEEKER'"
            class="ml-6 mr-0 stepper-headder-height white rounded-xl stepper-shadow">
            <v-stepper-step class="green--text py-0" complete-icon="mdi-check-bold" color="#4D695B" :complete="e1 > 1"
              step="1">
              CAMPAIGN DETAILS
            </v-stepper-step>

            <v-divider v-if="e1 >= 2" color="#4D695B"></v-divider>
            <v-divider v-if="e1 <= 1"></v-divider>

            <v-stepper-step complete-icon="mdi-check-bold" color="#4D695B" class="py-0" :complete="e1 > 2" step="2">
              CONFIGURE ASSESSMENT
            </v-stepper-step>

            <v-divider v-if="e1 >= 3" color="#4D695B"></v-divider>
            <v-divider v-if="e1 <= 2"></v-divider>

            <v-stepper-step complete-icon="mdi-check-bold" color="#4D695B" class="py-0" :complete="e1 > 3" step="3">
              DEMO CONFIG</v-stepper-step>

            <v-divider v-if="e1 >= 4" color="#4D695B"></v-divider>
            <v-divider v-if="e1 <= 3"></v-divider>

            <v-stepper-step complete-icon="mdi-check-bold" color="#4D695B" class="py-0" step="4">
              SELECT AUDIENCE</v-stepper-step>
          </v-stepper-header>


          <v-stepper-header v-else-if="campaignData.audienceType == 'TEACHER'"
            class="ml-6 mr-0 stepper-headder-height white rounded-xl stepper-shadow">
            <v-stepper-step class="green--text py-0" complete-icon="mdi-check-bold" color="#4D695B" :complete="e1 > 1"
              step="1">
              CAMPAIGN DETAILS
            </v-stepper-step>

            <v-divider v-if="e1 >= 2" color="#4D695B"></v-divider>
            <v-divider v-if="e1 <= 1"></v-divider>

            <v-stepper-step complete-icon="mdi-check-bold" color="#4D695B" class="py-0" :complete="e1 > 2" step="2">
              CONFIGURE ASSESSMENT
            </v-stepper-step>

            <v-divider v-if="e1 >= 3" color="#4D695B"></v-divider>
            <v-divider v-if="e1 <= 2"></v-divider>

            <!-- <v-stepper-step complete-icon="mdi-check-bold" color="#4D695B" class="py-0" :complete="e1 > 3" step="3">
              DEMO CONFIG</v-stepper-step> -->

            <!-- <v-divider v-if="e1 >= 4" color="#4D695B"></v-divider>
            <v-divider v-if="e1 <= 3"></v-divider> -->

            <v-stepper-step complete-icon="mdi-check-bold" color="#4D695B" class="py-0" step="3">
              SELECT AUDIENCE</v-stepper-step>
          </v-stepper-header>
          <v-card elevation="0" class="background mt-0 stepper-content-height rounded-xl mt-6" id="myScroll">
            <v-stepper-items elevation="0" depressed>
              <!------------------------------------------ STEP 1 ------------------------------------------>
              <v-stepper-content step="1" class="pr-0 pt-0 pt-0">
                <v-form ref="step1" lazy-validation>
                  <v-card elevation="0" depressed color="transparent">
                    <v-card-text class="pa-0">
                      <v-card class="my-0 pa-4 rounded-xl" elevation="0" depressed>
                        <v-row class="pt-2 pb-0">
                          <v-col class="py-0">
                            <div class="text-body-1 pb-2">Campaign Name*</div>
                            <v-text-field outlined class="rounded-xl" solo flat label="Enter Campaign Name" :rules="[
                              (v) => !!v || 'Campaign Name is required',
                            ]" required v-model="campaignData.name"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="pt-2 pb-0">
                          <v-col class="py-0">
                            <div class="text-body-1 pb-2">
                              Campaign Description*
                            </div>
                            <v-textarea outlined class="rounded-xl" solo flat :rules="[descRule]" required
                              v-model="campaignData.description">
                              <template v-slot:label>
                                <div>
                                  <small>Character Limit (50 - 320)</small>
                                </div>
                              </template>
                            </v-textarea>
                          </v-col>
                        </v-row>

                        <v-row class="pt-0 pb-2">
                          <v-col class="py-0">
                            <v-btn-toggle @change="getCurrentDate()" v-model="isCampaignPublished" mandatory dense>
                              <v-btn width="125px" :class="isCampaignPublished == 0
                                  ? 'blue white--text'
                                  : 'gray-02'
                                ">
                                SCHEDULE
                              </v-btn>
                              <v-btn width="125px" :class="isCampaignPublished == 1
                                  ? 'blue white--text'
                                  : 'gray-02'
                                ">
                                PUBLISH NOW
                              </v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                        <v-row class="py-3">
                          <v-col cols="5" class="py-0">
                            <div class="text-body-1 pb-2">
                              Campaign Starts On*
                            </div>
                            <v-text-field outlined label="Start Date" rounded :readonly="isCampaignPublished == 0 ? false : true
                              " class="rounded-xl" type="date" flat v-model="startDate" solo max="9999-12-31"
                              :rules="[dateRule]"></v-text-field>
                          </v-col>
                          <v-col cols="2" class="py-0 ml-10">
                            <div class="text-body-1 pb-2">Campaign Start Time</div>
                            <div @click="startTimeDialog = true">
                              <v-text-field v-if="isCampaignPublished == 0" hide-details v-model="campaignStartTime"
                              outlined readonly class="rounded-xl green" solo flat
                              :rules="[(v) => !!v || 'Time is required']" required
                              append-icon="mdi-clock-outline"></v-text-field>
                            </div>
                            <div class="text-h6 pt-2" v-if="isCampaignPublished == 1">
                              Immediate
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="py-0 mt-0" v-if="campaignData.audienceType == 'JOB_SEEKER'">
                          <v-col cols="5" class="py-0 my-0">
                            <div class="text-body-1 pb-2">
                              Screening Ends on*
                            </div>
                            <v-text-field outlined label="Start Date" v-model="screeningEndDate" rounded
                              class="rounded-xl elevation-0" solo flat :rules="[screeningEndDateRule]"
                              type="date" max="9999-12-31"></v-text-field>
                          </v-col>
                          <v-col cols="2" class="ml-10 py-0 my-0">
                            <div class="text-body-1 pb-2">End Time</div>
                          <v-text-field hide-details outlined v-model="screeningEndTime" class="rounded-xl green" solo
                              flat :rules="[(v) => !!v || 'Time is required']" required readonly
                              append-icon="mdi-clock-outline" @click="screeingEndTimeDialog = true"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="py-0 my-0">
                          <v-col cols="5" class="pb-0 mb-0">
                            <div v-if="campaignData.audienceType!=='MESTA_STUDENTS'" class="text-body-1 pb-2">
                              Mains Ends on (Campaign End Date)*
                            </div>
                            <div v-else class="text-body-1 pb-2">
                              Campaign Ends on*
                            </div>
                            <v-text-field outlined label="Start Date" v-model="mainsEndDate" rounded
                              class="rounded-xl elevation-0" solo flat :rules="[mainsEndDateRule]"
                              type="date" max="9999-12-31"></v-text-field>
                          </v-col>
                          <v-col cols="2" class="ml-10 pb-0 mb-0">
                            <div class="text-body-1 pb-2">
                              Campaign End Time
                            </div>
                            <div @click="endTimeDialog = true">
                              <v-text-field hide-details outlined v-model="mainsEndTime" class="rounded-xl green" solo
                              flat :rules="[(v) => !!v || 'Time is required']" required readonly
                              append-icon="mdi-clock-outline"></v-text-field>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row v-if="campaignData.audienceType!=='MESTA_STUDENTS'" class="py-0">
                          <v-col cols="3" class="d-flex flex-row align-center">
                            <div class="text-body-1">
                              Schedule Result Publish Time*
                            </div>
                          </v-col>

                          <v-col cols="3" md="2" sm="" class="d-flex flex-row align-end">
                            <v-text-field v-model="campaignData.resultPublishTime" hide-details outlined readonly
                              class="rounded-xl green" :rules="[(v) => !!v || 'Pattern is required']" solo flat required
                              append-icon="mdi-clock-outline"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row v-if="campaignData.audienceType=='MESTA_STUDENTS'" class="py-0 px-0">
                          <v-col cols="6" class="py-0">
                            <div class="text-body-1 pb-2">Levels*</div>
                            <v-autocomplete clearable label="Choose Levels" outlined v-model="campaignData.levels"
                              required :rules="[(v) => !!v || 'Levels are required']" class="rounded-xl" :items="levels"
                              multiple item-text="name" item-value="name" flat solo
                              @change="getGradesMappedToLevels">
                              <template #selection="{ item }">
                                <v-chip @click:close="
                                  deleteChip(item.name, campaignData.levels)
                                  " close-icon="mdi-close" text-color="white" close>{{ item.name
                                  }}</v-chip>
                              </template>
                              <v-list-item slot="prepend-item" ripple @click="selectAll('levels')" @change="getGradesMappedToLevels">
                                <v-list-item-action>
                                  <v-checkbox :value="campaignData.levels.length ===
                                    levels.length
                                    ">
                                  </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="6" class="py-0">
                            <div class="text-body-1 pb-2">Grades*</div>
                            <v-autocomplete clearable label="Choose Grades" outlined v-model="campaignData.grades"
                            required :rules="[(v) => !!v || 'Grades are required']" class="rounded-xl" :disabled="mestaGrades.length==0" :items="mestaGrades"
                            multiple item-text="name" item-value="name"
                            :search-input.sync="searchInput" @change="filterStaticAssessment">
                              <template #selection="{ item }">
                                 <v-chip @click:close="
                                  deleteChip(item, campaignData.grades)
                                  " close-icon="mdi-close" text-color="white" close color="#4D695B">{{ item
                                  }}</v-chip>
                               </template>
                              <v-list-item slot="prepend-item" ripple @click="selectAll('grades')" @change="filterStaticAssessment">
                                <v-list-item-action>
                                  <v-checkbox :value="campaignData.grades.length ===
                                    mestaGrades.length
                                    ">
                                  </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row v-if="campaignData.audienceType=='MESTA_STUDENTS'" class="py-0 px-0">
                          <v-col cols="6" class="py-0">
                            <div class="text-body-1 pb-2">Proctoring Type*</div>
                            <v-autocomplete
                            label="Proctoring Type"
                            class="rounded-xl"
                            v-model="campaignData.proctoringType"
                            :items="proctoringType"
                            outlined
                            dense
                          ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row v-if="campaignData.audienceType=='MESTA_STUDENTS'">
                          <v-col cols="3" class="py-0 my-0 mt-2">
                            <div class="text-body-1 px-3">Result Publish Pattern*</div>
                            <v-radio-group class="mt-0 px-3 pb-3 radio-group-align" v-model="campaignData.resultPublishPattern" required>
                              <div class="d-flex ma-0">
                                <v-radio class="pr-6" label="DAILY" value="DAILY"></v-radio>
                                <v-radio label="NO RESULT" value="NO RESULT"></v-radio>
                            </div>
                          </v-radio-group>
                        </v-col>
                        </v-row>
                        <div v-if="campaignData.audienceType == 'MESTA_STUDENTS'" class="w-100 py-4">
                          <v-card class="d-flex flex-column rounded-xl" outlined>

                            <!-- <v-autocomplete
                              v-model="selectedAssessments"
                              :items="formattedItems"
                              item-value="id"
                              :item-text="item => item.text"
                              outlined
                              dense
                              chips
                              small-chips
                              label="Select Assessment"
                              multiple
                              return-object
                              @change="handleSelectionChange"
                            ></v-autocomplete> -->

                             <v-autocomplete outlined clearable flat solo multiple
                            label="Select Assessment" item-value="id" 
                            :items="formattedItems" @change="filterStaticAssessmentDropdown" v-model="selectedAssessments" :disabled="disableAssessments" return-object class="rounded-xl ma-2 mt-4" required>
                            <template v-slot:item="{ item, attrs, on }">
                              <v-list-item v-bind="attrs" v-on="on" :disabled="item.hideInDropdown">
                                <template v-slot:default="{ active }">
                                  <v-list-item-action>
                                    <v-checkbox
                                      :input-value="active"
                                      color="primary"
                                      :disabled="item.hideInDropdown"
                                    ></v-checkbox>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ item.text }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                            <template #selection="{ item }">
                              <v-chip @click:close="
                                deleteChip(item, selectedAssessments)
                                " close-icon="mdi-close" text-color="white" close color="#4D695B">
                                   {{ item.text }}
                              </v-chip>
                            </template>
                            <!-- <v-list-item slot="prepend-item" ripple @click="selectAll('assessments')">
                                <v-list-item-action>
                                  <v-checkbox :value="selectedAssessments.length ==assessments.length">
                                  </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                             </v-list-item> -->
                          </v-autocomplete>
                      </v-card>
                        </div>
                      <v-row class="mt-2" v-if="this.userType=='MESTA_STUDENTS'">
                        <v-col cols="2">
                          <div class="text-body-1 pb-2">Duration of Assessment*</div>
                          <v-text-field type="number" :rules="[(v) => !!v || 'Duration is required!']" 
                            outlined class="rounded-xl"  :min="0" placeholder="in Minutes" v-model="campaignData.duration" 
                            @change="forceDurationPositive" required>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      </v-card>
                      <v-card v-if="campaignData.audienceType!=='MESTA_STUDENTS'" class="mt-4 pa-4 rounded-xl stepper-shadow h-auto" depressed elevaty>
                        <v-row class="py-0 px-0">
                          <v-col cols="6" class="py-0">
                            <div class="text-body-1">Levels*</div>
                            <v-autocomplete clearable label="Choose Levels" outlined v-model="campaignData.levels"
                              required :rules="[(v) => !!v || 'Levels is required']" class="rounded-xl" :items="levels"
                              multiple item-text="name" item-value="name" flat solo
                              @change="getSubjectAccordingToLevel">
                              <template #selection="{ item }">
                                <v-chip @click:close="
                                  deleteChip(item.name, campaignData.levels, true)
                                  " close-icon="mdi-close" text-color="white" close color="#4D695B">{{ item.name
                                  }}</v-chip>
                              </template>
                              <v-list-item slot="prepend-item" ripple @click="selectAll('levels')"   @change="getSubjectAccordingToLevel">
                                <v-list-item-action>
                                  <v-checkbox :value="campaignData.levels.length ===
                                    levels.length
                                    ">
                                  </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <div class="text-body-1">Skills*</div>
                            <v-autocomplete v-model="campaignData.skills" clearable deletable-chips
                              label="Select Skills" outlined class="rounded-xl" chips :items="skills" multiple
                              item-text="name" item-value="name" flat solo @change="getSubjectAccordingToLevel" required
                              :rules="[(v) => !!v || 'Skills is required']">
                              <template #selection="{ item }">
                                <v-chip @click:close="
                                  deleteChip(item, campaignData.skills, true)
                                  " close-icon="mdi-close" text-color="white" close color="#4D695B">{{ item.name
                                  }}</v-chip>
                              </template>
                              <v-list-item slot="prepend-item" ripple @click="selectAll('skills')"
                                @change="getSubjectAccordingToLevel">

                                <v-list-item-action>
                                  <v-checkbox :value="campaignData.skills.length ===
                                    skills.length
                                    ">
                                  </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row class="py-0 px-0">
                          <v-col cols="6" class="py-0">
                            <!-- level -->
                            <div class="text-body-1">Subjects*</div>
                            <v-autocomplete v-model="campaignData.subjects" clearable
                              :disabled="!campaignData.skills.includes('Core Skills') || this.campaignData.levels.length === 0" label="Select Subjects" outlined
                              class="rounded-xl" chips :items="uniqueSubjects" multiple flat solo
                              :rules="[(v) => !!v || 'Subject is required']">
                              <template #selection="{ item }">
                                <v-chip @click:close="deleteChip(item, campaignData.subjects)" close-icon="mdi-close"
                                  text-color="white" close color="#4D695B">{{ item }}</v-chip>
                              </template>
                              <v-list-item slot="prepend-item" ripple @click="selectAll('subjects')">
                                <v-list-item-action>
                                  <v-checkbox :value="campaignData.subjects.length ===
                                    uniqueSubjects.length
                                    ">
                                  </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <div class="text-body-1">Clusters*</div>
                            <v-autocomplete v-model="campaignData.clusters" clearable deletable-chips
                              :disabled="roleCluster != 'All Clusters'" label="Select Clusters" outlined
                              class="rounded-xl" chips :items="clusters" multiple item-text="name" item-value="name"
                              flat solo @change="getClusterSchool" required
                              :rules="[(v) => !!v || 'Clusters is required']">
                              <template #selection="{ item }" v-if="roleCluster != 'All Clusters'">
                                <v-chip text-color="white" color="#4D695B">{{ item.name
                                  }}</v-chip>
                              </template>
                              <template #selection="{ item }" v-else>
                                <v-chip @click:close="
                                  deleteChip(item, campaignData.clusters)
                                  " close-icon="mdi-close" text-color="white" close color="#4D695B">{{ item.name
                                  }}</v-chip>
                              </template>
                              <v-list-item slot="prepend-item" ripple @click="selectAll('clusters')"
                                @change="getClusterSchool">
                                <v-list-item-action>
                                  <v-checkbox :value="campaignData.clusters.length ==
                                    clusters.length
                                    ">
                                  </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row class="py-0 px-0">
                          <v-col cols="12" class="py-0">
                            <div class="text-body-1">Schools*</div>
                            <v-autocomplete v-model="campaignData.schools" clearable deletable-chips
                              :disabled="roleSchool != 'All' || campaignData.clusters.length == 0" label="Select Schools"
                              outlined class="rounded-xl" chips :items="schools" multiple item-text="name"
                              item-value="name" flat solo @click="getClusterSchool" required
                              :rules="[(v) => !!v || 'Schools is required']">
                              <template #selection="{ item }">
                                <v-chip @click:close="
                                  deleteChip(item, campaignData.schools)
                                  " close-icon="mdi-close" text-color="white" close color="#4D695B">{{ item.name
                                  }}</v-chip>
                              </template>
                              <v-list-item slot="prepend-item" ripple @click="selectAll('schools')">
                                <v-list-item-action>
                                  <v-checkbox :value="campaignData.schools.length ==
                                    schools.length
                                    ">
                                  </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-stepper-content>
              <!------------------------------------------ STEP 2 ------------------------------------------>
              <v-stepper-content class="background pr-0 pt-0" step="2">
                <v-card elevation="0" height="650px" id="myScroll" class="pa-4 pb-0 mt-2 rounded-xl elevation-0">
                  <v-card elevation="0" color="#F7F7F3" class="pa-4 rounded-xl elevation-0 cardBorder campain-border-card">
                    <div class="d-flex justify-space-between w-100">
                      <div>
                        Level - <b>{{ this.campaignData.levels.join(', ') }}</b>
                      </div>
                      <div>Type - <b>Dynamic</b></div>
                      <div>
                        Adaptive Assessment -
                        <b>{{
                          this.campaignData.campaignConfigration
                            .screeningConfigration.adaptiveAssessment
                        }}</b>
                      </div>
                      <div>
                        Negative Marking -
                        <b>{{
                          this.campaignData.campaignConfigration
                            .screeningConfigration.negativeMarking
                        }}</b>
                      </div>
                      <div>
                        Shuffle Options -
                        <b>{{
                          this.campaignData.campaignConfigration
                            .screeningConfigration.shuffleQuestions
                        }}</b>
                      </div>
                    </div>
                  </v-card>

                  <div class="d-flex mt-8 pa-1 justify-space-between w-100">
                    <div>Skill Details</div>
              
                    <div>
                      <p class="d-flex align-center">       <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span  v-bind="attrs"
                v-on="on"><v-icon class="mr-1 mb-1">mdi-information-outline</v-icon></span>
              </template>
              <div style="width: 200px;"> The number of questions in both screening and mains tests depends on the subjects that are selected by the candidate, ranging from a minimum of 40 to a maximum of 60 questions for screening test, and 110 to 150 questions for the mains test.</div>
            </v-tooltip> Total No. Of Question</p>
                      <p v-if="userType !== 'TEACHER'">
                        Screening -
                        <b>{{
                          this.campaignData.campaignConfigration
                            .screeningConfigration.totalNoOfQuestions
                        }}</b>
                        Mains
                        <b>{{
                          this.campaignData.campaignConfigration
                            .mainsConfigration.totalNoOfQuestions
                        }}</b>
                      </p>
                      <p v-else>
                       <!--  Screening -
                        <b>{{
                          this.campaignData.campaignConfigration
                            .screeningConfigration.totalNoOfQuestions
                        }}</b> -->
                        Mains
                        <b>{{
                          this.campaignData.campaignConfigration
                            .mainsConfigration.totalNoOfQuestions
                        }}</b>
                      </p>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap pb-4 w-100">
                    <section class="w-100">
                      <v-expansion-panels v-model="skillArrayLevelPanel" multiple>
                        <v-expansion-panel v-for="(levelData, level) in showSkillArray" :key="level">
                          <v-expansion-panel-header>{{ level }}</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-card
                              v-for="(data, i) in levelData"
                              :key="i"
                              elevation="1"
                              width="32%"
                              min-height="300"
                              color="#F7F7F3"
                              class="ma-2 overflow-hidden rounded-xl cardBorder campain-border-card d-inline-block"
                            >
                              <div class="d-flex px-5 pt-5 pb-3 w-100">
                                <div>
                                  {{ data.skillName }}
                                  <v-icon class="pb-1 pl-2">mdi-alert-circle-outline</v-icon>
                                </div>
                              </div>
                              <div class="d-flex px-5 pb-2 justify-space-between w-100" v-if="userType !== 'TEACHER'">
                                <div class="text-subtitle-2 pt-1">Screening</div>
                                <div v-if="data.skillName != 'Psychometry'" class="chip-border">
                                  <v-chip outlined color="#06C2700F" class="success--text chip-border" small pill>
                                    {{ data.screening.isRequired ? "MUST PASS" : "OPTIONAL" }}
                                  </v-chip>
                                </div>
                              </div>
                              <v-card
                                elevation="1"
                                width="92%"
                                min-height="55px"
                                class="mx-5 pr-1 mb-2 overflow-hidden rounded-xl cardBorder campain-border-card"
                                v-if="userType !== 'TEACHER'"
                              >
                                <div class="d-flex justify-space-between pa-3 mx-0 my-0">
                                  <div class="flex-column">
                                    <div class="campaign-card-title" align="start">Weightage</div>
                                    <div class="font-weight-bold" align="start">{{ data.screening.weightage }}%</div>
                                  </div>
                                  <div class="flex-column">
                                    <div class="campaign-card-title" align="end">Threshold</div>
                                    <div class="font-weight-bold" align="end">{{ data.screening.threhold }}</div>
                                  </div>
                                  <div class="flex-column">
                                    <div class="campaign-card-title" align="end">No. of Questions</div>
                                    <div class="font-weight-bold" align="end">{{ data.screening.noOfQuestion }}</div>
                                  </div>
                                </div>
                              </v-card>
                              <div class="d-flex px-5 pt-3 pb-2 justify-space-between w-100" >
                                <div class="text-subtitle-2 pt-2">Mains</div>
                                <div class="chip-border mt-1">
                                  <v-chip outlined color="#06C2700F" class="success--text success-01" small pill>
                                    {{ data.mains.isRequired ? "MUST PASS" : "OPTIONAL" }}
                                  </v-chip>
                                </div>
                              </div>
                              <v-card
                                elevation="1"
                                width="92%"
                                min-height="55px"
                                class="mx-5 mb-5 pr-1 overflow-hidden rounded-xl cardBorder campain-border-card"
                                
                              >
                                <div class="d-flex justify-space-between pa-3 mx-0 my-0">
                                  <div class="flex-column">
                                    <div class="campaign-card-title" align="start">Weightage</div>
                                    <div class="font-weight-bold" align="start">{{ data.mains.weightage }}%</div>
                                  </div>
                                  <div class="flex-column">
                                    <div class="campaign-card-title" align="end">Threshold</div>
                                    <div class="font-weight-bold" align="end">{{ data.mains.threhold }}</div>
                                  </div>
                                  <div class="flex-column">
                                    <div class="campaign-card-title" align="end">No. of Question</div>
                                    <div class="font-weight-bold" align="end">{{ data.mains.noOfQuestion }}</div>
                                  </div>
                                </div>
                              </v-card>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </section>
                  </div>
                  <v-row>
                    <v-col cols="6" v-if="userType !== 'TEACHER'">
                      <b>Screening Instructions</b>
                      <div class="rounded-xl w-100 my-instruction-div">
                        <ol>
                          <li>The maximum test duration is 38 minutes and will depend on the subjects that are selected
                          </li>
                          <ol>
                            <li>For 1 Subject, the duration is 20 minutes</li>
                            <li>For 2 Subjects, the duration is 29 minutes</li>
                            <li>For 3 Subjects, the duration is 38 minutes</li>
                          </ol>
                          <li>The test comprises of 4 sections</li>
                          <ol>
                            <template v-for="data of campaignData.skills">
                              <li v-if="data !== 'Psychometry'" :key="data">
                                {{ data }}
                              </li>
                            </template>
                          </ol>
                          <!-- <li>Each section is comprised of 10 questions</li> -->
                          <li>Each question carries 1 mark</li>
                          <li>There is no negative marking</li>
                          <li>Options with lengthy descriptions will be displayed in a popup when you click on the
                            option
                          </li>
                        </ol>
                      </div>
                    </v-col>


                    <v-col cols="6" v-if="userType !== 'TEACHER'">
                      <b>Mains Instructions</b>
                      <div class="rounded-xl my-instruction-div">
                        <ol>
                          <li>
                            The maximum test duration is 115 minutes and will depend on the subjects that are selected
                          </li>
                          <ol>
                            <li>For 1 Subject, the duration is 75 minutes</li>
                            <li>For 2 Subjects, the duration is 95 minutes</li>
                            <li>For 3 Subjects, the duration is 115 minutes</li>
                          </ol>
                          <li>The test comprises of {{ campaignData.skills?.length }} sections</li>
                          <ol>
                            <li v-for="data of campaignData.skills" :key="data">
                              {{ data }}
                            </li>
                          </ol>
                          <!-- <li>Each section is comprised of 20 questions</li> -->
                          <li>Each question carries 1 mark</li>
                          <li>There is no negative marking</li>
                          <li>Options with lengthy descriptions will be displayed in a popup when you click on the
                            option
                          </li>
                        </ol>
                      </div>
                    </v-col>

                    
                      <v-col cols="12" v-else>
                      <b>Mains Instructions</b>
                      <div class="rounded-xl my-instruction-div">
                        <ol>
                          <li>
                            The maximum test duration is 120 minutes and will depend on the subjects that are selected
                          </li>
                          <ol>
                            <li>For 1 Subject, the duration is 90 minutes</li>
                            <li>For 2 Subjects, the duration is 105 minutes</li>
                            <li>For 3 Subjects, the duration is 120 minutes</li>
                          </ol>
                          <li>The test comprises of {{ campaignData.skills?.length }} sections</li>
                          <ol>
                            <li v-for="data of campaignData.skills" :key="data">
                              {{ data }}
                            </li>
                          </ol>
                          <li>Each section is comprised of 20 questions</li>
                          <li>Each question carries 1 mark</li>
                          <li>There is no negative marking</li>
                          <li>Options with lengthy descriptions will be displayed in a popup when you click on the
                            option
                          </li>
                        </ol>
                      </div>
                    </v-col>



                  <!-- </v-col>
                  </v-col> -->
                  </v-row>
                </v-card>
              </v-stepper-content>
              <!------------------------------------------ STEP 3 ------------------------------------------>
              <v-stepper-content class="background pr-0 pt-0" :step="3" v-if="userType !== 'TEACHER'">
                <v-card elevation="0" height="650px" id="myScroll"
                  class="pa-4 pb-0 mt-2 rounded-xl elevation-0">
                  <v-card elevation="0" color="#F7F7F3" class="pa-4 rounded-xl elevation-0 cardBorder campain-border-card">
                    <div class="w-100">
                      <div>Demo Duration - <b>15 mins per subject</b></div>
                    </div>
                  </v-card>

                  <div class="mt-8 pa-1 w-100">
                    <div><b>TOPICS -</b> {{ this.campaignData.levels.join(', ') }}</div>
                  </div>

                  <v-card elevation="0" color="#F7F7F3" v-for="topic in topics"
                    class="pa-4 rounded-xl elevation-0 cardBorder campain-border-card" :key="topic">
                    <div class="w-100">
                      <div v-html="topic">
                      </div>
                    </div>
                  </v-card>
                  <v-row>
                    <v-col cols="6">
                      <b>Do's</b>
                      <div class="rounded-xl w-100 h-40 my-div">
                        <ol>
                          <li>
                            Plan and Script: Outline the key points you want to
                            cover and create a script if needed. Being organized
                            will make the recording process smoother.
                          </li>
                          <li>
                            Use Quality Equipment: Invest in a good microphone
                            and camera. Clear audio and video quality are
                            crucial for an effective demo.
                          </li>
                          <li>
                            Lighting and Background: Ensure adequate lighting to
                            avoid shadows or dark spots. Choose a clean,
                            uncluttered background that won't distract from your
                            content.
                          </li>
                          <li>
                            Practice and Rehearse: Familiarize yourself with the
                            content and practice your delivery. Rehearse until
                            you feel comfortable and confident.
                          </li>
                          <li>
                            Speak Clearly and Slowly: Enunciate your words and
                            speak at a pace that is easy to follow. Avoid
                            rushing through explanations.
                          </li>
                          <li>
                            Show, Don't Just Tell: Use visuals, screenshots, or
                            live demos to illustrate your points. Visual aids
                            can significantly enhance understanding.
                          </li>
                          <li>
                            Engage Your Audience: Maintain eye contact with the
                            camera, use a conversational tone, and try to
                            connect with your audience.
                          </li>
                          <li>
                            Edit and Polish: Trim unnecessary parts, add
                            captions or annotations, and ensure a smooth flow in
                            your video. Editing can significantly enhance the
                            final product.
                          </li>
                          <li>
                            Include a Call to Action: Whether it's subscribing
                            to your channel or trying out a product, guide your
                            viewers on what to do next.
                          </li>
                        </ol>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <b>Don'ts</b>
                      <div class="rounded-xl my-div">
                        <ol>
                          <li>
                            Don't Overload with Information: Keep your demo
                            focused and concise. Avoid overwhelming your
                            audience with too much information.
                          </li>
                          <li>
                            Avoid Poor Audio and Video Quality: Low-quality
                            audio or video can detract from your message. Invest
                            time and resources in ensuring good quality.
                          </li>
                          <li>
                            Don't Rush: Speak at a natural pace and avoid
                            rushing through your content. Take pauses where
                            needed to allow information to sink in.
                          </li>
                          <li>
                            Avoid Distractions: Choose a quiet environment to
                            record, free from background noises or
                            interruptions.
                          </li>
                          <li>
                            Don't Read Directly from a Script: Reading
                            word-for-word can sound monotonous. Instead,
                            familiarize yourself with the content and speak
                            naturally.
                          </li>
                          <li>
                            Avoid Cluttered Visuals: Keep your visuals clean and
                            easy to follow. Too much information on-screen can
                            confuse your audience.
                          </li>
                          <li>
                            Don't Forget a Clear Introduction and Conclusion:
                            Start with an introduction and end with a summary or
                            conclusion to tie up the video.
                          </li>
                          <li>
                            Don't Skip Editing: Editing can significantly
                            improve the quality of your video. Avoid skipping
                            this crucial step.
                          </li>
                          <li>
                            Avoid Lack of Engagement: Maintain energy and
                            enthusiasm throughout the demo. Keep your audience
                            engaged and interested in what you're presenting.
                          </li>
                        </ol>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>

                
              </v-stepper-content>
              <!------------------------------------------ STEP 4 ------------------------------------------>
              <v-stepper-content  :step="configStep" class="pr-0 pt-0">

                <v-form v-if="this.campaignData.audienceType == 'TEACHER'">
                  <v-card class="rounded-xl px-4 py-4" outlined>
                    <div class="d-flex align-center justify-space-between mb-7">

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span class="campaign-data" v-bind="attrs" v-on="on">
                            <b>{{ campaignData.name }}</b>
                          </span>
                        </template>
                        <span>{{ campaignData.name }}</span>
                      </v-tooltip>

                      <span class="campaign-data">
                        <b>Type - </b>
                        {{
                          this.campaignData.campaignConfigration
                            .screeningConfigration.adaptiveAssessment == "Yes"
                            ? "Adaptive"
                            : "Non Adaptive"
                        }}
                      </span>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span class="campaign-data" v-bind="attrs" v-on="on">
                            <b>Subject - </b>
                            {{ getSelectedSubject() }}
                          </span>
                        </template>
                        <span>{{ getSelectedSubject() }}</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span class="campaign-data" v-bind="attrs" v-on="on">
                            <b>Level - </b>
                            {{ getSelectedLevels() }}
                          </span>
                        </template>
                        <span> {{ getSelectedLevels() }}</span>
                      </v-tooltip>
                      <span class="campaign-data">
                        <b>Timer Type - </b>
                        Per Question Timer
                      </span>
                    </div>
                    <div class="d-flex">
                      <div class="d-flex flex-column">
                        <div class="d-flex">
                          <b class="campaign-question-content">Shuffle Option -</b>
                          <div>
                            {{
                              this.campaignData.campaignConfigration
                                .screeningConfigration.shuffleQuestions
                            }}
                          </div>
                        </div>
                        <div class="d-flex">
                          <b class="campaign-question-content">Negative Marking -</b>
                          <div>
                            {{
                              this.campaignData.campaignConfigration
                                .screeningConfigration.negativeMarking
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="px-4">
                        <v-divider vertical></v-divider>
                      </div>
                      <div class="d-flex flex-column">
                        <div class="d-flex" v-if="userType !== 'TEACHER'"> 
                          <b class="campaign-question-content">Screening No Of Questions-</b>
                          <div>
                            {{
                              this.campaignData.campaignConfigration
                                .screeningConfigration.totalNoOfQuestions
                            }}
                          </div>
                        </div>
                        <div class="d-flex">
                          <b class="campaign-question-content">Mains No Of Questions-</b>
                          <div>
                            {{
                              this.campaignData.campaignConfigration
                                .mainsConfigration.totalNoOfQuestions
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="px-4">
                        <v-divider vertical></v-divider>
                      </div>
                      <div class="d-flex align-center">
                        <b>Skills- </b>
                        <div>
                          {{ getSelectedSkills() }}
                        </div>
                      </div>
                    </div>
                  </v-card>
                  <v-row v-if="campaignData.audienceType == 'TEACHER'">
                    <v-col cols="6">
                      <v-card-title class="font-weight-bold pl-0">
                        Select Audience
                      </v-card-title>
                      <v-card-subtitle class="pl-0">
                        ({{ users.length }}) Teachers Are Eligible For This
                        Campaign
                      </v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end align-center">
                      <v-text-field solo label="Search here..." prepend-inner-icon="mdi-magnify"
                        v-model="searchAudeience" hide-details class="search-bar border-button rounded-pill mx-1" dense
                        clearable></v-text-field>
                    </v-col>
                  </v-row>
                  <v-data-table v-if="campaignData.audienceType == 'TEACHER'" :loading="isLoading" v-model="selected"
                    :headers="headers" :items="filterUser" show-select :single-select="singleSelect" item-key="userId">
                    <template v-slot:[`item.fullname`]="{ item }">
                      {{
                        item.personalInfo.firstName +
                        " " +
                        item.personalInfo.lastName
                      }}
                    </template>

                    <template v-slot:[`item.levels`]="{ item }">
                      <span v-if="responsibleTeachingAt[item.userId]">
                        {{
                          responsibleTeachingAt[item.userId].join(", ")
                        }}</span>
                    </template>

                    <template v-slot:[`item.subjects`]="{ item }">
                      <span v-if="teachingSubjects[item.userId]">
                        {{ teachingSubjects[item.userId].join(", ") }}</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <div class="d-flex flex-row">
                        <v-btn @click="viewUserInfoFun(item)" small outlined color="#1B72E8" class="mr-5">View</v-btn>
                      </div>
                    </template>
                  </v-data-table>
                  <!-- Upload CVs -->
                  <div v-if="campaignData.audienceType != 'TEACHER'">
                    <div v-if="!cvsFetched" style="display: flex; justify-content: center" class="mt-16">
                      <div>
                        <div class="text-body-1">
                          Please select the cvs of the Job Seekers you want to
                          invite
                        </div>

                        <input type="file" ref="fileInput" 
                        accept="application/pdf, 
                          application/msword, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                            multiple @change="handleFiles" label="fileInputLabel" style="
                            border: 1px solid #ccc;
                            position: relative;
                            margin-left: 65px;
                            opacity: 1;
                            padding: 10px;
                            border-radius: 5px;
                            cursor: pointer;
                          " />
                      </div>
                    </div>
                    <div v-else>
                      <v-data-table :single-select="singleSelect" show-select v-model="selected" item-key="emails"
                        :items="candidateInfo" :headers="usersFromCvs" v-if="candidateInfo.length">
                        <template v-slot:[`item.emails`]="{ item }">
                          <span>{{
                            item.emails.length ? item.emails[0] : "No Email"
                          }}</span>
                        </template>

                        <template v-slot:[`item.phones`]="{ item }">
                          <span>{{
                            item.phones.length ? item.phones[0] : "No Number"
                          }}</span>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </v-form>
                <v-form v-if="this.campaignData.audienceType != 'TEACHER'">
                  <v-card class="rounded-xl px-4 py-4" outlined>
                    <div class="d-flex align-center justify-space-between mb-7">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span class="campaign-data" v-bind="attrs" v-on="on">
                            <b>{{ campaignData.name }}</b>
                          </span>
                        </template>
                        <span>{{ campaignData.name }}</span>
                      </v-tooltip>

                      <span>
                        <b>Type:</b>
                        {{
                          this.campaignData.campaignConfigration
                            .screeningConfigration.adaptiveAssessment == "Yes"
                            ? "Adaptive"
                            : "Non Adaptive"
                        }}
                      </span>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span class="campaign-data" v-bind="attrs" v-on="on">
                            <b>Subject - </b>
                            {{ getSelectedSubject() }}
                          </span>
                        </template>
                        <span>{{ getSelectedSubject() }}</span>
                      </v-tooltip>

                      <span><b>Level -</b>{{ getSelectedLevels() }}</span>

                      <div><b>Timer Type - </b> Per Question Timer</div>
                    </div>
                    <div class="d-flex">
                      <div class="d-flex flex-column">
                        <div class="d-flex">
                          <b class="campaign-question-content">Suffle Option-</b>
                          <div>
                            {{
                              this.campaignData.campaignConfigration
                                .screeningConfigration.shuffleQuestions
                            }}
                          </div>
                        </div>
                        <div class="d-flex">
                          <b class="campaign-question-content">Negative Marking-</b>
                          <div>
                            {{
                              this.campaignData.campaignConfigration
                                .screeningConfigration.negativeMarking
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="px-4">
                        <v-divider vertical></v-divider>
                      </div>
                      <div class="d-flex flex-column">
                        <div class="d-flex">
                          <b class="campaign-question-content">Screening No Of Questions-</b>
                          <div>
                            {{
                              this.campaignData.campaignConfigration
                                .screeningConfigration.totalNoOfQuestions
                            }}
                          </div>
                        </div>
                        <div class="d-flex">
                          <b class="campaign-question-content">Mains No Of Questions-</b>
                          <div>
                            {{
                              this.campaignData.campaignConfigration
                                .mainsConfigration.totalNoOfQuestions
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="px-4">
                        <v-divider vertical></v-divider>
                      </div>
                      <div class="d-flex align-center">
                        <b>Skills- </b> {{ getSelectedSkills() }}
                      </div>
                    </div>
                  </v-card>
                  <v-row v-if="campaignData.audienceType == 'TEACHER'">
                    <v-col cols="6">
                      <v-card-title class="font-weight-bold pl-0">
                        Select Audience
                      </v-card-title>
                      <v-card-subtitle class="pl-0">
                        ({{ users.length }}) Teachers Are Eligible For This
                        Campaign
                      </v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end align-center">
                      <v-text-field solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search"
                        hide-details class="search-bar border-button rounded-pill mx-1" dense clearable></v-text-field>
                    </v-col>
                  </v-row>
                  <v-data-table v-if="campaignData.audienceType == 'TEACHER'" :loading="isLoading" v-model="selected"
                    :headers="headers" :items="users" show-select :search="searchAudeience"
                    :single-select="singleSelect" item-key="userId">
                    <template v-slot:[`item.fullname`]="{ item }">
                      {{
                        item.personalInfo.firstName +
                        " " +
                        item.personalInfo.lastName
                      }}
                    </template>

                    <template v-slot:[`item.levels`]="{ item }">
                      <span v-if="responsibleTeachingAt[item.userId]">
                        {{
                          responsibleTeachingAt[item.userId].join(", ")
                        }}</span>
                    </template>

                    <template v-slot:[`item.subjects`]="{ item }">
                      <span v-if="teachingSubjects[item.userId]">
                        {{ teachingSubjects[item.userId].join(", ") }}</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <div class="d-flex flex-row">
                        <v-btn @click="viewUserInfoFun(item)" small outlined color="#1B72E8" class="mr-5">View</v-btn>
                      </div>
                    </template>
                  </v-data-table>
                  <!-- Upload CVs -->
                  <div v-if="campaignData.audienceType != 'TEACHER'">
                    <div class="mt-16 d-flex justify-space-between ">
                      
                      <p class="text-body-1 pt-4 d-flex" :class="uploadedCvCount !== null? 'shows-it' : 'hide-it'" >{{candidateInfo.length}} of {{ uploadedCvCount }} file{{ uploadedCvCount > 1 ? "s are":" is" }} uploaded   
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span  v-bind="attrs" v-on="on">
                            <v-icon class="pt-0 ml-2 mb-1" >mdi-alert-circle-outline</v-icon>
                          </span>
                        </template>
                        Few resumes are not uploaded, please view the error log in download section
                      </v-tooltip>  </p>
                      
                      <v-btn outlined rounded class="btn outline-btn" @click="triggerFileInput">Upload Resume</v-btn>
                      <div class="d-none">
                        <input type="file" 
                          accept="application/pdf, 
                          application/msword, 
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                          ref="fileInput" multiple @change="handleFiles" label="fileInputLabel" />
                      </div>
                    </div>
                    <div v-if="cvsFetched" class="mt-4">
                      <v-data-table :single-select="singleSelect" show-select v-model="selected" item-key="emails"
                        :items="candidateInfo" :headers="usersFromCvs" v-if="candidateInfo.length">
                        <template v-slot:[`item.emails`]="{ item }">
                        <v-edit-dialog
                          :return-value.sync="item.emails"
                        >
                          {{ item.emails }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.emails"
                              label="Edit"
                              single-line
                              counter
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>

                        <template v-slot:[`item.phones`]="{ item }">
                          <span>{{ item.phones.length ? item.phones[0] : "No Number" }}</span>
                        </template>
                        <template v-slot:[`item.action`]="{ index }">
                          <v-icon @click="removecandidate(index)">mdi-trash-can-outline</v-icon>
                        </template>
                      </v-data-table>

                    </div>
                  </div>
                </v-form>
                <v-snackbar v-model="snackbar" :timeout="1000">
                  {{ "Link Copied" }}
                </v-snackbar>
              </v-stepper-content>
            </v-stepper-items>
          </v-card>
        </v-stepper>
      </v-card>
    </div>
  </div>
</template>





<script>
import CampaignController from "@/controllers/CampaignController";
import LevelController from "@/controllers/LevelController";
import AssessmentController from "@/controllers/AssessmentController";
import SchoolController from "@/controllers/SchoolController";
import SkillsController from "@/controllers/SkillsController";
import GradeController from "@/controllers/GradeController";
import SubjectController from "@/controllers/SubjectController";
import ClusterController from "@/controllers/ClusterController";
import TopicsController from "@/controllers/DemoTopicsController";
import { db, app } from "../firebase";
import mailController from "../controllers/mailController";
import AuthService from "../services/AuthService";
import axios from "axios";
import VueQRCodeComponent from "vue-qrcode-component";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import url from "pdfjs-dist/build/pdf.worker";
import SkillsWeightageThresholdsController from "@/controllers/SkillsWeightageThresholdsController";
import Papa from "papaparse";
import StaticAssessmentController from "@/controllers/StaticAssessmentController";
import pdfjsLib from 'pdfjs-dist/build/pdf';
import { mapState } from 'vuex';
import { cloneDeep } from "lodash";
import UserNavMenu from "@/components/UserNavMenu.vue";
export default {
  name: "campaignView",
  components: {
    VueQRCodeComponent,UserNavMenu
  },
  data() {
    return {
      userType: "",
      isResumeUpload :false,
      usersFromCvs: [
        { text: "PDF Name", value: "pdfName" },
        { text: "Email", value: "emails" },
        { text: "Mobile No.", value: "phones" },
        { text: "Action", value: "action" },
      ],
      topics: [],
      searchInput: '',
      shortLink: "",
      cvsFetched: false,
      cvsToggle:"",
      qrDialog: false,
      qrCampaignLink: "",
      qrCampaignName: "",
      qrLoading: false,
      qrDownloadLoading: false,
      teachersInTotalCampaign: 0,
      jobSeekerInTotalCampaign: 0,
      inProgressCount: 0,
      isAccessedCount: 0,
      isUnaccessed: 0,
      isUnaccessedJS: 0,
      inProgressCountJS: 0,
      isAccessedCountJS: 0,
      tbdCount: 0,
      appraisalCount: 0,
      pipCount: 0,
      hiredCount: 0,
      rejectedCount: 0,
      teachersInSchoolTotalCampaign: 0,
      jobSeekerInSchoolTotalCampaign: 0,
      inProgressCountAtSchool: 0,
      isAccessedCountAtSchool: 0,
      isUnaccessedAtSchool: 0,
      inProgressCountJSAtSchool: 0,
      isAccessedCountJSAtSchool: 0,
      isUnaccessedJSAtSchool: 0,
      tbdCountAtSchool: 0,
      appraisalCountAtSchool: 0,
      pipCountAtSchool: 0,
      hiredCountAtSchool: 0,
      rejectedCountAtSchool: 0,
      skillArrayLevelPanel: [0],
      disabled: false,
      readonly: false,
      grades: [],
      isCampaignPublished: 0,
      snackbar: false,
      campaignStatus: null,
      clusterSchool: "",
      dialog: false,
      e1: 1,
      plusminus1: "mdi-minus",
      plusminus2: "mdi-plus",
      updateFlag: false,
      deleteDialog: false,
      successDialog: false,
      assessments: [],
      pageName: "All",
      filterUser: [],
      schoolsData: [],
      selectedClusterFilters: [],
      selectedSchoolFilter: [],
      selectedLevelFlter: [],
      selectedSkillsFilter: [],
      search: "",
      searchAudeience: "",
      roleCluster: "",
      roleSchool: "",
      isLoading: true,
      viewUserIno: {},
      userPhoneNumber: "",
      userEmail: "",
      selectedId: null,
      startDate: "",
      topicsDos: `<html>
          <body>
            <ol>
                          <li>
                            Plan and Script: Outline the key points you want to
                            cover and create a script if needed. Being organized
                            will make the recording process smoother.
                          </li>
                          <li>
                            Use Quality Equipment: Invest in a good microphone
                            and camera. Clear audio and video quality are
                            crucial for an effective demo.
                          </li>
                          <li>
                            Lighting and Background: Ensure adequate lighting to
                            avoid shadows or dark spots. Choose a clean,
                            uncluttered background that won't distract from your
                            content.
                          </li>
                          <li>
                            Practice and Rehearse: Familiarize yourself with the
                            content and practice your delivery. Rehearse until
                            you feel comfortable and confident.
                          </li>
                          <li>
                            Speak Clearly and Slowly: Enunciate your words and
                            speak at a pace that is easy to follow. Avoid
                            rushing through explanations.
                          </li>
                          <li>
                            Show, Don't Just Tell: Use visuals, screenshots, or
                            live demos to illustrate your points. Visual aids
                            can significantly enhance understanding.
                          </li>
                          <li>
                            Engage Your Audience: Maintain eye contact with the
                            camera, use a conversational tone, and try to
                            connect with your audience.
                          </li>
                          <li>
                            Edit and Polish: Trim unnecessary parts, add
                            captions or annotations, and ensure a smooth flow in
                            your video. Editing can significantly enhance the
                            final product.
                          </li>
                          <li>
                            Include a Call to Action: Whether it's subscribing
                            to your channel or trying out a product, guide your
                            viewers on what to do next.
                          </li>
                        </ol>
          </body>
          </html>`,
      topicsDont: `<html>
          <body>
            <ol>
                          <li>
                            Don't Overload with Information: Keep your demo
                            focused and concise. Avoid overwhelming your
                            audience with too much information.
                          </li>
                          <li>
                            Avoid Poor Audio and Video Quality: Low-quality
                            audio or video can detract from your message. Invest
                            time and resources in ensuring good quality.
                          </li>
                          <li>
                            Don't Rush: Speak at a natural pace and avoid
                            rushing through your content. Take pauses where
                            needed to allow information to sink in.
                          </li>
                          <li>
                            Avoid Distractions: Choose a quiet environment to
                            record, free from background noises or
                            interruptions.
                          </li>
                          <li>
                            Don't Read Directly from a Script: Reading
                            word-for-word can sound monotonous. Instead,
                            familiarize yourself with the content and speak
                            naturally.
                          </li>
                          <li>
                            Avoid Cluttered Visuals: Keep your visuals clean and
                            easy to follow. Too much information on-screen can
                            confuse your audience.
                          </li>
                          <li>
                            Don't Forget a Clear Introduction and Conclusion:
                            Start with an introduction and end with a summary or
                            conclusion to tie up the video.
                          </li>
                          <li>
                            Don't Skip Editing: Editing can significantly
                            improve the quality of your video. Avoid skipping
                            this crucial step.
                          </li>
                          <li>
                            Avoid Lack of Engagement: Maintain energy and
                            enthusiasm throughout the demo. Keep your audience
                            engaged and interested in what you're presenting.
                          </li>
                        </ol>
          </body>
          </html>`,
      screeningEndDate: "",
      singleSelect: false,
      skills: [],
      level: "",
      userFullName: "",
      userViewDialog: false,
      topicsConfigrations: [],
      storeLiveCam: [],
      mainsEndDate: "",
      items: ["Daily", "Weekly", "Monthly"],
      firstRemainder: ["2 Min", "5 Min", "10 Min", "15 Min", "20 Min"],
      lastRemainder: ["2 Min", "5 Min", "10 Min", "15 Min", "20 Min"],
      schools: [],
      clusters: [],
      UpdateCampaignDialog: false,
      subjects: [],
      users: [],
      campaigns: {},
      allCampaigns: {},
      UpdateCampaignDialogLoading: false,
      resultTimeHrs: "",
      resultTimeMin: "",
      screeingEndTimeDialog: false,
      mainsEndTimeDialog: false,
      resultPublishTImeTimeDialog: false,
      allCampaignCount: 0,
      headers: [
        { text: "Name", value: "fullname" },
        { text: "Phone No", value: "personalInfo.contactInfo.phoneNumber" },
        { text: "Level", value: "levels" },
        { text: "Subjects", value: "subjects" },
        { text: "School Name", value: "schoolName" },
        { text: "Action", value: "actions" },
      ],
      startTimeDialog: false,
      endTimeDialog: false,
      levels: [],
      campaignStartTime: "08:00",
      startMM: "",
      screeningEndTime: "23:59",
      mainsEndTime: "18:00",
      isFilterLoading: false,
      endMM: "",
      screeningDescriptions:
        "<p>Your pre-filled <strong>HTML</strong> text here</p>",
      mainsDescriptions: "",
      editorConfigScreening: {
        extraPlugins: "ckeditor_wiris",
      },
      editorConfigMains: {
        extraPlugins: "ckeditor_wiris",
      },
      selected: [],
      dLoading: false,
      // Temp dummy Data
      negativeMarking: "Yes",
      liveCampaigns: [],
      filterLiveCampaigns: [],
      uniqueSubjects: [],
      filterScheduledCampaigns: [],
      filterExpiredCampaigns: [],
      filterDialog: false,
      preLiveCampaigns: [],
      showSkillArray: {},
      coreSkillNoOfQuestionForScreening: 10,
      coreSkillWeightageForScreening: "50",
      coreSkillIsRequiredForScreening: true,
      coreSkillNoOfQuestionForMains: 20,
      coreSkillWeightageForMains: "50",
      coreSkillIsRequiredForMains: true,
      scheduledCampaigns: [],
      mestaGrades: [],
      preScheduledCampaigns: [],
      weightages: {},
      screeningWeightages: [],
      mainsWeightages: [],
      expiredCampaigns: [],
      screeningInstruction: null,
      mainsInstruction: null,
      preExpiredCampaigns: [],
      countCampaignByLevel: {
        prePrimaryCount: 0,
        primaryCount: 0,
        secondaryCount: 0,
        seniorSecondaryCount: 0,
        middleSchoolCount: 0,
      },
      tag: false,
      selectedAssessments:[],
      resultPublishPatternOptions:["DAILY", "NO RESULT"],
      proctoringType: [
        {
          text: "No Proctoring",
          value: "NONE",
        },
        {
          text: "Smart Proctoring",
          value: "SMART",
        },
        {
          text: "AI + Smart Proctoring",
          value: "BOTH",
        },
      ],
      campaignId: null,
      disableAssessments: true,
      campaignData: {
        campaignId: "",
        name: "",
        description: "",
        startDate: "",
        screeningEndDate: "",
        mainsEndDate: "",
        campaignStartTime: "",
        screeningEndTime: "",
        mainsEndTime: "",
        endDate:"",
        endTime: "",
        resultPublishPattern: "DAILY",
        resultPublishTime: "08:00",
        assessments: [],
        duration: null,
        audienceType: "",
        proctoringType: "",
        clusters: [],
        skills: [],
        subjects: [],
        schools: [],
        levels: [],
        grades: [],
        seekerLiveCampaignsCount: 0,
        seekerScheduledCampaignsCount: 0,
        seekerExpiredCampaignsCount: 0,
        vgosLiveCampaignsCount: 0,
        vgosScheduledCampaignsCount: 0,
        vgosExpiredCampaignsCount: 0,
        // audiance:this.selected,
        campaignConfigration: {
          type: "DYNAMIC",
          assessmentIds: [],
          screeningConfigration: {
            instruction: "",
            subjectsDistribution: [],
            skillsDistributions: [],
            totalNoOfQuestions: 0,
            levels: [],
            adaptiveAssessment: "No",
            negativeMarking: "No",
            shuffleQuestions: "No",
            // passingCriteria: 60,
            timeUpFirstRemainder: "0",
            timeUpLastRemainder: "0",
          },
          mainsConfigration: {
            instruction: "",
            subjectsDistribution: [],
            skillsDistributions: [],
            totalNoOfQuestions: 0,
            levels: [],
            adaptiveAssessment: "No",
            negativeMarking: "No",
            shuffleQuestions: "No",
            // passingCriteria: 60,
            timeUpFirstRemainder: "0",
            timeUpLastRemainder: "0",
          },
        },
      },
      subjectDistributions: {},
      skillsDistributions: {},
      subjectDistributionsForMains: {},
      skillsDistributionsForMains: {},

      rules: {
        required: (value) => !!value || "Field is required",
      },
      preloader: true,
      chartSwitch: "",
      campaignStats: {
        totalCampaigns: 0,
        totalTeachersCampaign: 0,
        totalJobSeekersCampaign: 0,
      },
      outcomeTeachers: {
        assessedTeacher: 0,
        unAssessedTeacher: 0,
        inProgressTeacher: 0,
      },
      outcomeJobSeekers: {
        assessedJobSeeker: 0,
        unAssessedJobSeeker: 0,
        inProgressJobSeeker: 0,
      },
      recruitmentJobSeeker: {
        countHired: 0,
        countRejected: 0,
      },
      teacherAppraisal: {
        countAppraised: 0,
        countOnPip: 0,
        countTbd: 0,
      },

      schoolPrincipal: {
        totalInvited: 0,
        totalScreeningStarted: 0,
        totalScreeningPassed: 0,
        totalScreeningFailed: 0,
        totalMainsStarted: 0,
        totalMainsPassed: 0,
        totalMainsFailed: 0,
      },
      clusterPrincipal: {
        totalInvited: 0,
        totalScreeningStarted: 0,
        totalScreeningPassed: 0,
        totalScreeningFailed: 0,
        totalMainsStarted: 0,
        totalMainsPassed: 0,
        totalMainsFailed: 0,
      },

      responsibleTeachingAt: {},
      teachingSubjects: {},
      candidateInfo: [],
      pdfjsLib: null,
      fileInputLabel: "Browse here",
      levelSubjectCountMap: {},
      uploadedCvCount: null,
      configStep:0,
      jobseekersQuestionsCountBaseConfig: {
        "screening": {
          "Core Skills": 9,
          "Pedagogy": 5,
          "Communication Skills": 3,
          "Digital Literacy": 3,
          "Psychometry": 0, 
        },
        "mains": {
          "Core Skills": 20,
          "Pedagogy": 10,
          "Communication Skills": 9,
          "Digital Literacy": 6,
          "Psychometry": 30, 
        },
      },
      vgosQuestionsCountBaseConfig: {
        "screening": {
          "Core Skills": 0,
          "Pedagogy": 0,
          "Communication Skills": 0,
          "Digital Literacy": 0,
          "Psychometry": 0, 
        },
        "mains": {
          "Core Skills": 20,
          "Pedagogy": 20,
          "Communication Skills": 20,
          "Digital Literacy": 20,
          "Psychometry": 30, 
        },
      }
    };
  },
  computed: {
    // ...mapState(['userType']),
    adjustedQuestionCounts(){
      let config = {};
      let baseConfig = this.userType == 'JOB_SEEKER'? this.jobseekersQuestionsCountBaseConfig : this.vgosQuestionsCountBaseConfig
      for (const level in this.levelSubjectCountMap){
        config[level] = cloneDeep(baseConfig);
        config[level]["screening"]["Core Skills"]*= this.levelSubjectCountMap[level];
        config[level]["mains"]["Core Skills"]*= this.levelSubjectCountMap[level];
      }
      return config;
    },
    isLastStep(){
      if(this.userType == 'JOB_SEEKER'){
        return this.e1 == 4;
      }else if(this.userType == 'TEACHER'){
        return this.e1 == 3;
      }
      else if(this.userType == 'MESTA_STUDENTS'){
        return this.e1 == 1;
      }
    },
    disablePublish(){
      let disablePublish =  this.campaignData.audienceType == "MESTA_STUDENTS" ?
        (this.campaignData.name === '' ||
        this.startDate === '' ||
        this.campaignStartTime === '' ||
        this.mainsEndDate === '' ||
        this.mainsEndTime === '' ||
        this.campaignData.resultPublishPattern === '' ||
        this.selectedAssessments.length==0 ||
        this.campaignData.levels == 0 ||
        this.campaignData.grades == 0 ||
        this.campaignData.duration == null)
      : (this.campaignData.audienceType == 'TEACHER' &&
        this.selected.length == 0)
      return disablePublish;
    },
    campaignFormNotFilled(){
      return  ( this.campaignData.name === '' ||
              this.startDate === '' ||
              // this.screeningEndDate === '' ||
              this.mainsEndDate === '' ||
              this.campaignData.levels.length == 0 ||
              this.campaignData.skills.length == 0 ||
              (this.campaignData.subjects.length == 0 && this.campaignData.skills.includes('Core Skills')) ||
              this.campaignData.clusters.length == 0 ||
              this.campaignData.schools.length == 0)
    },
    descRule() {
      return (value) => {
        if (!value) {
          return "Please enter campaign Description ";
        }
        var count = value.length;
        if (count < 50) {
          return "Less Than 50 characters are not allowed";
        }

        if (count > 320) {
          return "More Than 320 characters are not allowed";
        }
        return true;
      };
    },
    dateRule() {
      return (value) => {
        if (!value) {
          return "Please enter campaign start date";
        }
        if (this.isCampaignPublished == 0) {
          const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
          if (!regex.test(value)) {
            return "Please enter a valid date (YYYY-MM-DD)";
          }
          const selectedDate = new Date(value);
          const currentDate = new Date();
          if (selectedDate <= currentDate) {
            return "Campaign Start Date must be greater than current date";
          }
        }

        return true;
      };
    },
    screeningEndDateRule() {
      return (value) => {
        if (!value) {
          return "Screening end date is required";
        } else if (this.startDate == "") {
          return "Please enter campaign start date firstly";
        } else {
          const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
          if (!regex.test(value)) {
            return "Please enter a valid date (YYYY-MM-DD)";
          }
          const startDateObj = new Date(this.startDate);
          const endDateObj = new Date(value);
          if (startDateObj >= endDateObj) {
            return "Campaign end date must be greater than campaign start date";
          }
        }
        return true;
      };
    },
    mainsEndDateRule() {
      return (value) => {
        if (!value) {
          return "Mains end date is required";
        } 
        // else if (this.screeningEndDate == "") {
        //   return "Please enter screening end  date firstly";
        // } 
        else {
          const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
          if (!regex.test(value)) {
            return "Please enter a valid date (YYYY-MM-DD)";
          }
          const startDateObj = this.userType !== "JOB_SEEKER" ? new Date(this.startDate): new Date(this.screeningEndDate);
          const endDateObj = new Date(value);
          if (this.userType === "JOB_SEEKER") {
            if (startDateObj >= endDateObj) {
              return "Campaign mains end date must be greater than campaign screening end date";
            }
          }
          else if (this.userType === "TEACHER") {
            if (startDateObj >= endDateObj) {
              return "Campaign mains end date must be greater than campaign start date";
            }
          }
          else if (this.userType === "MESTA_STUDENTS") {
            if (startDateObj > endDateObj) {
              return "Campaign end date must be greater than campaign start date";
            }
          }
        }
        return true;
      };
    },
    firstLevelSkillsArray() {
      return this.campaignData.skills
    },
    formattedItems() {
        return this.assessments.map(item => ({
          id: item.id,
          grade: item.grade,
          subjects: [...item.subjects],
          assessmentName: item.assessmentName,
          text: `${item.assessmentName}(${item.grade})`,
          availableLanguages: item.availableLanguages,
          hideInDropdown: item.hideInDropdown,
        }));
    }
  },
  watch: {
    searchAudeience(newValue) {
      if (newValue == "" || newValue == null) {
        this.filterUser = this.users;
      } else {
        this.filterUser = [];
        this.users.forEach((element) => {
          if (
            JSON.stringify(element)
              .toLocaleLowerCase()
              .includes(newValue.toLocaleLowerCase())
          ) {
            this.filterUser.push(element);
          }
        });
      }
    },
    search(newValue) {
      if (newValue == "" || newValue == null) {
        this.liveCampaigns = this.filterLiveCampaigns;
        this.scheduledCampaigns = this.filterScheduledCampaigns;
        this.expiredCampaigns = this.filterExpiredCampaigns;
      } else {
        this.searchData(newValue);
      }
    },
    selectedClusterFilters(newValue) {
      if (newValue.length == 0 || newValue == null) {
        this.schoolsData = [];
      } else {
        this.getClusterSchoolForFilter(newValue);
      }
    },
    level(newLevel) {
      this.campaignData.levels = [newLevel];
      this.getSubjectAccordingToLevel();
    },
    cvsToggle: {
      handler(value,oldValue) {
        if (value !== oldValue) {
          this.selected = [...this.candidateInfo];
        }
      },
    },
  },
  methods: {
    isJobSeeker(audienceType = '') {
      return audienceType?.toLowerCase() === "job_seeker";
    },
    forceDurationPositive(inputNumber){
      this.campaignData.duration = Math.max(0, inputNumber);
    },
    handleSelectionChange() {
      // Check if "Select All" was selected
      if (this.selectedAssessments.some(item => item.id === 'select-all')) {
        // Select all items if "Select All" is checked
        this.selectedAssessments = this.selectedAssessments.length === this.items.length + 1 ? [] : [...this.items];
      } else {
        // Remove "Select All" if any other item is selected
        this.selectedAssessments = this.selectedAssessments.filter(item => item.id !== 'select-all');
      }
    },
    getGradesMappedToLevels(){
      this.mestaGrades = [];
      this.campaignData.grades = [];
      this.levels.forEach(level=>{
        if(this.campaignData.levels.includes(level.name)){
          this.mestaGrades.push(...level.grades)
        }
        this.mestaGrades.sort((a, b) => {
          const gradeA = parseInt(a.split(' ')[1], 10); 
          const gradeB = parseInt(b.split(' ')[1], 10);
          return gradeA - gradeB;
        });
      })
    },
    async filterStaticAssessment(){
      this.disableAssessments = true;
      this.searchInput = '';
      if(this.campaignData.levels.length>0){
        const response = await StaticAssessmentController.filterStaticAssessmentList({assessmentStatus: ["PUBLISHED", "LIVE"], levels: this.campaignData.levels, grades: this.campaignData.grades});
        if(response.data){
          this.campaignData.assessments = [];
          this.assessments = [...response.data.data.assessments];
          this.disableAssessments = false;
        }
      }
      else{
        this.campaignData.assessments = [];
        this.assessments = [];
      }
    },
    filterStaticAssessmentDropdown(){
      let selectedAssessmentGrades = this.selectedAssessments.map(assessment => assessment.grade)
      let selectedAssessmentIds = this.selectedAssessments.map(assessment => assessment.id)
      this.assessments = this.assessments.filter((assessment) => {
        // hiding assessment in dropdown if another assessment of same grade is selected
        assessment.hideInDropdown = (!selectedAssessmentIds.includes(assessment.id) && selectedAssessmentGrades.includes(assessment.grade))
        return assessment;
      })
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    getGroupedTopics() {
      const topics = [];
      const groupedTopics = Object.groupBy(this.topicsConfigrations, ({ subject }) => subject);
      for (const [key, value] of Object.entries(groupedTopics)) {
        topics.push(`<span>${key}- <b>${(value.map(item => item.topic)).join(", ")}</b></span>`);
      }
      this.topics = topics
    },
    selectAll(item) {
      switch (item) {
        case "levels":
          if (this.campaignData.levels.length === this.levels.length) {
            this.campaignData.levels = [];
          } else {
            this.campaignData.levels = this.levels.map((obj) => obj.name);
          }
          break;
        case "grades":
        if (this.campaignData.grades.length === this.mestaGrades.length) {
            this.campaignData.grades = [];
          } else {
            this.campaignData.grades = [...this.mestaGrades];
          }
        case "skills":
          if (this.campaignData.skills.length === this.skills.length) {
            this.campaignData.skills = [];
          } else {
            this.campaignData.skills = this.skills.map((obj) => obj.name);
          }
          break;
        case "subjects":
          if (this.campaignData.subjects.length === this.uniqueSubjects.length) {
            this.campaignData.subjects = [];
          } else {
            this.campaignData.subjects.splice(0, this.campaignData.subjects.length, ...this.uniqueSubjects);
          }
          break;
        case "schools":
          this.getClusterSchool();
          if (this.campaignData.schools.length === this.schools.length) {
            this.campaignData.schools = [];
          } else {
            this.campaignData.schools = this.schools.map((obj) => obj.name);
          }
          break;
        case "clusters":
          if (this.campaignData.clusters.length === this.clusters.length) {
            this.campaignData.clusters = [];
          } else {
            this.campaignData.clusters = this.clusters.map((obj) => obj.name);
          }
        case "assessments":
        if (this.selectedAssessments.length === this.assessments.length) {
            this.selectedAssessments = [];
          } else {
            this.selectedAssessments = [...this.assessments]
          }
          break;
        default:
      }
    },

    async loadPDFLib() {
    if (!this.pdfjsLib) {
      this.pdfjsLib = await import('pdfjs-dist/build/pdf');
      const workerSrc = '/pdf.worker.mjs'; // Adjust as per your setup
      this.pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;
    }
    return this.pdfjsLib;
    },
    downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    createErrorCSV(data) {
      let csv = data.filter(item => item.emails.length == 0 || item.emails[0] == 'N0 Email');
      if (csv.length > 0) {
        csv = csv.map((item) => {
          return { 'phone': item.phones[0], 'fileName': item.pdfName }
        })
        this.downloadCSV(csv, 'resume_upload_error')
      }

    },
    removeDuplicates(data) {
      let errorData = [];
      let uniqueCvData = data.filter((obj, index, array) => {
        if (index === array.findIndex((t) => (
          t.emails[0] === obj.emails[0] && obj.emails[0] !== 'No Email' && obj.emails[0] !== undefined
        ))) {
          return true;
        } else {
          errorData.push(obj)
          return false;
        }
      }
      );
      this.selected = this.selected.filter(item => uniqueCvData.includes(item));
      if (errorData.length > 0) {
        this.createErrorCSV(errorData)
      }
      return uniqueCvData
    },
    removecandidate(index) {
      this.candidateInfo.splice(index, 1);
      this.selected.splice(index, 1) 
      this.uploadedCvCount-=1
    },

    async handleFiles() {
      let promises = []
      const selectedFiles = this.$refs.fileInput.files;
      this.uploadedCvCount = this.uploadedCvCount? (this.uploadedCvCount + selectedFiles.length) : 1;
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type === "application/msword"
        ) {
          promises.push(this.extractWordInfo(file));
        } else if (file.type === "application/pdf") {
          promises.push(this.extractInfo(file))
        }
      }
    Promise.allSettled(promises).then(()=>{
      this.candidateInfo = this.removeDuplicates(this.candidateInfo);
         
    })
   

    },

    extractWordInfo(file) {
      return new Promise(async (resolve) => {
        const reader = new FileReader();
        reader.onload = async () => {
          const buffer = reader.result;
          const zip = new JSZip();
          const docx = await zip.loadAsync(buffer);
          const content = await docx.file("word/document.xml").async("text");
          const phoneRegex =
            /(\+\d{1,2}\s*)?(\(\d{3}\)|\d{3})[- .]?\d{3}[- .]?\d{4}/g;
          const phones = content.match(phoneRegex);
          const emailRegex =
            /\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/g;
          const emails = content.match(emailRegex);
          const extractedInfo = {};
          extractedInfo.pdfName = file.name;
          extractedInfo.phones = phones || [];
          extractedInfo.emails = emails[0] || "";
          if (extractedInfo.emails?.length || extractedInfo.phones?.length) {
            this.candidateInfo.push(extractedInfo);
            this.cvsFetched = true;
            this.cvsToggle = !this.cvsToggle;
            resolve()
           
          }
        };

        reader.readAsArrayBuffer(file);
        
      })
    },

    extractInfo(file) {
      return new Promise(async (resolve) => {
        const pdfjsLib = await this.loadPDFLib();
        if (pdfjsLib) {
          const reader = new FileReader();
          reader.onload = async () => {
            const data = new Uint8Array(reader.result);
            try {
              const loadingTask = await pdfjsLib.getDocument(data);
              const doc = await loadingTask.promise;
              const numPages = doc.numPages;
              const countPromises = [];
              for (let i = 1; i <= numPages; i++) {
                const page = doc.getPage(i);
                countPromises.push(
                  page.then(function (page) {
                    return page.getTextContent().then(function (text) {
                      return text.items
                        .map(function (s) {
                          return s.str;
                        })
                        .join(" ");
                    });
                  })
                );
              }

              const pageTextContents = await Promise.all(countPromises);
              for (let i = 0; i < pageTextContents.length; i++) {
                const extractedInfo = this.extractEmailAndPhone(
                  pageTextContents[i]
                );
                extractedInfo.pdfName = file.name;

                if (extractedInfo.emails.length || extractedInfo.phones.length) {
                  this.candidateInfo.push(extractedInfo);
                  this.cvsFetched = true;
                  this.cvsToggle = !this.cvsToggle;
                  
                }
              }
              resolve()
            } catch (err) {
              console.error("Error loading document:", err);
            }
          };
          reader.readAsArrayBuffer(file);
  
        }
      })
    },

    extractEmailAndPhone(text) {
      const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
      const phoneRegex =
        /(?:\+\d{1,2}\s*)?(?:\(\d{3,}\)|\d{3,})[\s.-]?\d{2,}[\s.-]?\d{2,}/g;
      const emails = text.match(emailRegex)[0] || "";
      const phones = text.match(phoneRegex) || [];
      return {
        emails,
        phones,
      };
    },
    async downloadQrCode() {
      // qrDownloadRef
      try {
        this.qrDownloadLoading = true;
        html2canvas(this.$refs.qrDownloadRef).then((canvas) => {
          let link = document.createElement("a");
          link.download = `${this.qrCampaignName}.jpg`;
          link.href = canvas.toDataURL("image/jpeg", 0.5);
          link.click();
        });
        this.qrDownloadLoading = false;
      } catch (err) {
        this.qrDownloadLoading = false;
        alert("Something went wrong");
      }
    },

    async generateQRCode() {
      try {
        const data = this.campaignData;
        this.qrLoading = true;
        const id = data.campaignId;
        const audienceType = data.audienceType;
        let shortCampaignLink =
          `https://${this.$FRONTEND_DOMAIN}/#/campaignInvite?campaignId=` + id;

        if (audienceType.toLowerCase() !== "teacher") {
          const campaignLink = `https://${this.$DEEPLINK}/?link=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId%3D${id}&apn=${this.$PACKAGE_NAME}&afl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId%3D${id}&isi=6446600030&ibi=${this.$PACKAGE_NAME}&ifl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId%3D${id}`;

          const apiRes = await axios.post(
            `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${app.options.apiKey}`,
            {
              longDynamicLink: campaignLink,
              suffix: {
                option: "SHORT",
              },
            }
          );

          if (apiRes?.data?.shortLink) {
            shortCampaignLink = apiRes?.data?.shortLink;
          }
        }
        this.qrCampaignName = data?.name ? data.name : "Campaign";
        this.qrCampaignLink = shortCampaignLink;
        this.qrLoading = false;
        this.qrDialog = true;
      } catch (err) {
        this.qrLoading = false;
        alert("Something went wrong");
      }
    },

    goBack() {
      this.e1 = this.e1 - 1;
      this.campaignData.campaignConfigration = {
        type: "DYNAMIC",
        assessmentIds: [],
        screeningConfigration: {
          instruction: "",
          subjectsDistribution: [],
          skillsDistributions: [],
          totalNoOfQuestions: 0,
          levels: [],
          adaptiveAssessment: "No",
          negativeMarking: "No",
          shuffleQuestions: "No",
          timeUpFirstRemainder: 0,
          timeUpLastRemainder: 0,
        },
        mainsConfigration: {
          instruction: "",
          subjectsDistribution: [],
          skillsDistributions: [],
          totalNoOfQuestions: 0,
          levels: [],
          adaptiveAssessment: "No",
          negativeMarking: "No",
          shuffleQuestions: "No",
          timeUpFirstRemainder: 0,
          timeUpLastRemainder: 0,
        },
      };
    },

    countWords(inputString) {
      const words = inputString.split(/\s+/);
      const nonEmptyWords = words.filter((word) => word.length > 0);
      return nonEmptyWords.length;
    },
    plusMinus(n) {
      if (n == 1) {
        if (this.plusminus1 == "mdi-plus") {
          this.plusminus1 = "mdi-minus";
        } else {
          this.plusminus1 = "mdi-plus";
        }
      } else {
        if (this.plusminus2 == "mdi-plus") {
          this.plusminus2 = "mdi-minus";
        } else {
          this.plusminus2 = "mdi-plus";
        }
      }
    },

    deleteChip(item, array, isLevelOrGrade=false) {
      if (typeof item == 'object') {
        if (typeof array == 'object') {
          let index = array.findIndex(element=> this.userType=="MESTA_STUDENTS" ? item.id==element.id : item.name==element)
          array.splice(index,1);
          if(this.userType=="MESTA_STUDENTS")this.filterStaticAssessmentDropdown();
        }
        else {
          this.level = ''
        }
      }
      else {
        let index = array.indexOf(item);
        array.splice(index, 1);
      }
      if(isLevelOrGrade)this.getSubjectAccordingToLevel();
    },

    filterGradesByNames(mainArray, targetNames) {
      const filteredGrades = [];
      for (const item of mainArray) {
        if (targetNames.includes(item.name)) {
          filteredGrades.push(...item.grades);
        }
      }
      return filteredGrades;
    },
    filterSubjectByGrades(mainArray, targetNames) {
      const filteredGrades = [];
      for (const item of mainArray) {
        if (targetNames.includes(item.name)) {
          filteredGrades.push(...item.subjects);
        }
      }
      return filteredGrades;
    },
    async getSubjectAccordingToLevel() {
      this.campaignData.subjects = [];
      this.uniqueSubjects = [];
      var uniArray = [];
      const filteredGrades = await this.filterGradesByNames(
        this.levels,
        this.campaignData.levels
      );
      const filteredSubject = await this.filterSubjectByGrades(
        this.grades,
        filteredGrades
      );
      uniArray = Array.from(new Set(filteredSubject))
      uniArray.sort(function (a, b) {
        var nameA = a.toUpperCase(); 
        var nameB = b.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.uniqueSubjects = uniArray;
    },

    getCurrentDate() {
      if (this.isCampaignPublished != 0) {
        var date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        if (day < 10) {
          day = "0" + day;
        }
        if (month < 10) {
          month = `0${month}`;
        }
        this.startDate = `${year}-${month}-${day}`;
      } else {
        this.startDate = "";
      }
    },

    getClusterOrSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;

      if (this.roleSchool == undefined) {
        AuthService.logout();
        this.$router.push("/login");
      }
      if (this.roleCluster != "All Clusters") {
        this.campaignData.clusters = [this.roleCluster];
        this.getClusterSchool();
      }
      if (this.roleSchool != "All") {
        this.campaignData.schools = [this.roleSchool];
      }
    },

    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    async copyToClipboardCampaign(onlyGenerateLink) {
      let shortCampaignLink =
        `https://${this.$DEEPLINK}/?link=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId=` +
        this.campaignData.campaignId +
        `&apn=${this.$PACKAGE_NAM}E&afl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId=` +
        this.campaignData.campaignId +
        `&isi=6446600030&ibi=${this.$PACKAGE_NAME}&ifl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId=` +
        this.campaignData.campaignId;

      if (this.campaignData.audienceType.toLowerCase() !== "teacher") {
        const longLink = `https://${this.$DEEPLINK}/?link=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId%3D${this.campaignData.campaignId}&apn=${this.$PACKAGE_NAME}&afl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId%3D${this.campaignData.campaignId}&isi=6446600030&ibi=${this.$PACKAGE_NAME}&ifl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2FcampaignInvite%3FcampaignId%3D${this.campaignData.campaignId}`;

        const apiRes = await axios.post(
          `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${app.options.apiKey}`,
          {
            longDynamicLink: longLink,
            suffix: {
              option: "SHORT",
            },
          }
        );
        if (apiRes?.data?.shortLink) {
          shortCampaignLink = apiRes.data.shortLink;
        }
      }
      this.shortLink = shortCampaignLink;
      if ((typeof onlyGenerateLink) == 'undefined') {
        navigator.clipboard.writeText(shortCampaignLink)
          .then(() => {
            this.snackbar = true;
          }).catch((error) => {
            console.error("Failed to copy text: ", error);
          });
      }
    },
    getSelectedSubject() {
      var sub = "";
      this.campaignData.subjects.forEach((subject) => {
        sub = sub + subject + ",";
      });
      sub = sub.slice(0, -1);
      return sub;
    },
    getSelectedLevels() {
      var levels = "";
      this.campaignData.levels.forEach((level) => {
        levels = levels + level + ",";
      });
      levels = levels.slice(0, -1);
      return levels;
    },

    getSelectedSkills() {
      var skills = "";
      this.campaignData.skills.forEach((skill) => {
        skills = skills + skill + ",";
      });
      skills = skills.slice(0, -1);
      return skills;
    },
    goToUser(id, campaignName) {
      this.$router.push({
        path: "/campaignUser",
        name: "CampaignUserView", 
        query: {
          id: id,
          campaignName: campaignName,
        },
      });
    },
    countNoOfQuestionMains() {
      let skillsQuestionCounts = [];
      for (const level in this.skillsDistributionsForMains) {
        if (this.skillsDistributionsForMains.hasOwnProperty(level)) {
          const skills = this.skillsDistributionsForMains[level];
          for (const skill of skills) {
            skillsQuestionCounts.push({ name: skill.skillName, noOfQuestion: skill.noOfQuestion });
          }
        }
      }

      let subjectQuestionCounts = [];
      for (const level in this.subjectDistributionsForMains) {
        if (this.subjectDistributionsForMains.hasOwnProperty(level)) {
          const subjects = this.subjectDistributionsForMains[level];
          for (const subject of subjects) {
            subjectQuestionCounts.push({ name: subject.subjectName, noOfQuestion: subject.noOfQuestion });
          }
        }
      }

      let combinedQuestionCounts = [...skillsQuestionCounts, ...subjectQuestionCounts].filter(item => item.name !== "Core Skills");
      this.campaignData.campaignConfigration.mainsConfigration.totalNoOfQuestions =
        combinedQuestionCounts.reduce((acc, curr) => acc + curr.noOfQuestion, 0);
    },

    countNoOfQuestionScreening() {
      let skillsQuestionCounts = [];
      for (const level in this.skillsDistributions) {
        if (this.skillsDistributions.hasOwnProperty(level)) {
          const skills = this.skillsDistributions[level];
          for (const skill of skills) {
            skillsQuestionCounts.push({ name: skill.skillName, noOfQuestion: skill.noOfQuestion });
          }
        }
      }

      let subjectQuestionCounts = [];
      for (const level in this.subjectDistributions) {
        if (this.subjectDistributions.hasOwnProperty(level)) {
          const subjects = this.subjectDistributions[level];
          for (const subject of subjects) {
            subjectQuestionCounts.push({ name: subject.subjectName, noOfQuestion: subject.noOfQuestion });
          }
        }
      }

      let combinedQuestionCounts = [...skillsQuestionCounts, ...subjectQuestionCounts].filter(item => item.name !== "Core Skills");
      this.campaignData.campaignConfigration.screeningConfigration.totalNoOfQuestions =
        combinedQuestionCounts.reduce((acc, curr) => acc + curr.noOfQuestion, 0);
    },

    getPercentage(value, totalValue) {
      var data = (value / totalValue) * 100;
      data = Math.trunc(data);

      return data + "%";
    },
    campaignButtonValue() {
      return this.updateFlag == true ? "Update" : "Create";
    },
    campaignButtonValues() {
      return this.updateFlag == true ? "Updated" : "Published";
    },

    convertDateFormat(dateString) {
      const [year, month, day] = dateString.split("-");

      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    },
    convertDateFormatForUpdate(dateString) {
      const [year, month, day] = dateString.split("-");

      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    },
    convertStartTimeFormatForUpdate(timeString) {
      const [hours, min] = timeString.split(":");
      this.campaignStartTime = hours;
      this.startMM = min;
    },
    convertEndTimeFormatForUpdate(timeString) {
      const [hours, min] = timeString.split(":");
      this.screeningEndTime = hours;
      this.endMM = min;
    },
    role(data) {
      (this.campaignData.campaignId = ""),
        (this.campaignData.name = ""),
        (this.campaignData.description = ""),
        (this.campaignData.startDate = ""),
        (this.campaignData.screeningEndDate = ""),
        (this.campaignData.mainsEndDate = ""),
        (this.campaignData.campaignStartTime = ""),
        (this.campaignData.screeningEndTime = ""),
        (this.campaignData.mainsEndTime = ""),
        (this.campaignData.resultPublishTime = "10:00"),
        (this.campaignData.audienceType = ""),
        (this.campaignData.skills = []),
        (this.campaignData.subjects = []),
        (this.campaignData.levels = []),
        (this.campaignData.seekerLiveCampaignsCount = 0),
        (this.campaignData.seekerScheduledCampaignsCount = 0),
        (this.campaignData.seekerExpiredCampaignsCount = 0),
        (this.campaignData.vgosLiveCampaignsCount = 0),
        (this.campaignData.vgosScheduledCampaignsCount = 0),
        (this.campaignData.vgosExpiredCampaignsCount = 0),
        (this.campaignData.campaignConfigration = {
          type: "DYNAMIC",
          assessmentIds: [],
          screeningConfigration: {
            instruction: "",
            subjectsDistribution: [],
            skillsDistributions: [],
            totalNoOfQuestions: 0,
            levels: [],
            adaptiveAssessment: "No",
            negativeMarking: "No",
            shuffleQuestions: "No",
            timeUpFirstRemainder: 0,
            timeUpLastRemainder: 0,
          },
          mainsConfigration: {
            instruction: "",
            subjectsDistribution: [],
            skillsDistributions: [],
            totalNoOfQuestions: 0,
            levels: [],
            adaptiveAssessment: "No",
            negativeMarking: "No",
            shuffleQuestions: "No",
            timeUpFirstRemainder: 0,
            timeUpLastRemainder: 0,
          },
        }),
        (this.startDate = ""),
        (this.screeningEndDate = ""),
        (this.mainsEndDate = ""),
        (this.isCampaignPublished = 0),
        (this.dialog = true);
      this.campaignData.audienceType = data;
      this.updateFlag = false;
    },
    campaignUpdate(data) {
      this.campaignId = data.id;
      this.UpdateCampaignDialog = true;
      this.startDate = this.convertDateFormatForUpdate(data.startDate);
    },

    findWeightage(array, skillName) {
      return (
        array?.find((item) => item.skillName === skillName)?.weightage ?? 0
      );
    },
    findThreshold(array, skillName) {
      let threshold = array?.find((item) => item.skillName === skillName)?.threshold
      return (threshold !== undefined ? threshold : 0);
    },
    findIsMustPass(array, skillName) {
      return array?.find((item) => item.skillName === skillName)?.isMustPass;
    },

    async tempFunctions() {
      this.showSkillArray = {};
      try {
        var data = await SkillsWeightageThresholdsController.getWeightages();
        this.weightages = data;
        this.campaignData.campaignConfigration.screeningConfigration.levels = this.campaignData.levels;
        this.campaignData.campaignConfigration.mainsConfigration.levels = this.campaignData.levels;
        this.subjectDistributions = {};
        this.skillsDistributions = {};
        this.subjectDistributionsForMains = {};
        this.skillsDistributionsForMains = {};

        for (let level of this.campaignData.levels) {
          let screeningWeightages = this.weightages.data.data.screening?.find(
            (item) => item.id === level + "SCREENING"
          );
          let mainsWeightages = this.weightages.data.data.mains?.find(
            (item) => item.id === level + "MAINS"
          );

          if (screeningWeightages !== undefined && mainsWeightages !== undefined) {
            let gradesForLevel = this.levels.find(({ name }) => name === level).grades;
            let subjectsForLevel = Array.from(new Set(this.filterSubjectByGrades(this.grades, gradesForLevel)))
            subjectsForLevel = subjectsForLevel.filter(subject=>this.campaignData.subjects.includes(subject));
            const _count = subjectsForLevel?.length;
            this.$set(this.levelSubjectCountMap, level, _count)
            let adjustedQuestionCounts = this.adjustedQuestionCounts;

            if (!this.showSkillArray[level]) {
              this.showSkillArray[level] = [];
            }

            if (!this.subjectDistributions[level]) {
              this.subjectDistributions[level] = [];
            }

            if (!this.subjectDistributionsForMains[level]) {
              this.subjectDistributionsForMains[level] = [];
            }

            this.campaignData.subjects.forEach((item) => {
              if (subjectsForLevel.includes(item)) {
                this.subjectDistributions[level].push({
                  subjectName: item,
                  noOfQuestion: this.coreSkillNoOfQuestionForScreening,
                  weightage: this.findWeightage(screeningWeightages.skills, "Core Skills"),
                  isRequired: this.findIsMustPass(screeningWeightages.skills, "Core Skills"),
                  threhold: this.findThreshold(screeningWeightages.skills, "Core Skills"),
                });

                this.subjectDistributionsForMains[level].push({
                  subjectName: item,
                  noOfQuestion: this.coreSkillNoOfQuestionForMains,
                  weightage: this.findWeightage(mainsWeightages.skills, "Core Skills"),
                  isRequired: this.findIsMustPass(mainsWeightages.skills, "Core Skills"),
                  threhold: this.findThreshold(mainsWeightages.skills, "Core Skills"),
                });
              }
            });

            if (!this.skillsDistributions[level]) {
              this.skillsDistributions[level] = [];
            }

            if (!this.skillsDistributionsForMains[level]) {
              this.skillsDistributionsForMains[level] = [];
            }
            this.campaignData.skills.forEach((item) => {
              let weightageScreening = this.findWeightage(screeningWeightages.skills, item);
              let weightageMains = this.findWeightage(mainsWeightages.skills, item);
              let totalWeightageScreening = this.campaignData.skills.reduce((sum, skill) => {
                return sum + this.findWeightage(screeningWeightages.skills, skill);
              }, 0);

              let totalWeightageMains = this.campaignData.skills.reduce((sum, skill) => {
                return sum + this.findWeightage(mainsWeightages.skills, skill);
              }, 0);
              
              // Adjust weightages to sum up to 100
              weightageScreening = (weightageScreening / totalWeightageScreening) * 100;
              weightageMains = (weightageMains / totalWeightageMains) * 100;
              let isRequiredScreening = this.findIsMustPass(screeningWeightages.skills, item);
              let threholdScreening = this.findThreshold(screeningWeightages.skills, item);
              let isRequiredMains = this.findIsMustPass(mainsWeightages.skills, item);
              let threholdMains = this.findThreshold(mainsWeightages.skills, item);

              this.skillsDistributions[level].push({
                skillName: item,
                noOfQuestion: item == "Psychometry" ? 0 : 10,
                weightage: weightageScreening,
                isRequired: isRequiredScreening,
                threhold: threholdScreening,
              });

              this.skillsDistributionsForMains[level].push({
                skillName: item,
                noOfQuestion: item == "Psychometry" ? 30 : 20,
                weightage: weightageMains,
                isRequired: isRequiredMains,
                threhold: threholdMains,
              });
                this.showSkillArray[level].push({
                skillName: item,
                screening: {
                  weightage: weightageScreening,
                  noOfQuestion: adjustedQuestionCounts[level]["screening"][item],
                  threhold: threholdScreening,
                  isRequired: isRequiredScreening,
                },
                mains: {
                  weightage: weightageMains,
                  noOfQuestion: adjustedQuestionCounts[level]["mains"][item],
                  threhold: threholdMains,
                  isRequired: isRequiredMains,
                },
              });
            });
          } else {
            alert("Weightages not found for " + level + " Level");
            this.dLoading = false;
            return;
          }
        }

        this.countNoOfQuestionScreening();
        this.countNoOfQuestionMains();
        let skillNamesHTML = "", skillNamesScreening = '';
        for (const obj of this.campaignData.skills) {          
          skillNamesHTML = skillNamesHTML.concat(`<li>${obj}</li>`);
          if (obj !== 'Psychometry') skillNamesScreening = skillNamesScreening.concat(`<li>${obj}</li>`);
        }
        this.campaignData.campaignConfigration.screeningConfigration.instruction = `<html>
        <body>
          <ol>
            <li>The duration is up to ${this.calculateScreeningDuration(this.campaignData.subjects)} minutes</li>
            <li>The test comprises of 4 sections</li>
            <ol>${skillNamesScreening}</ol>
            <li>Each section is comprised of 10 questions</li>
            <li>Each question carries 1 mark</li>
            <li>There is no negative marking</li>
          </ol>
        </body>
        </html>`;

        this.campaignData.campaignConfigration.mainsConfigration.instruction = `<html>
        <body>
          <ol>
            <li>The duration is up to ${this.calculateMainsDuration(this.campaignData.subjects)} minutes</li>
            <li>The test comprises of ${this.campaignData.skills.length} sections</li>
            <ol>${skillNamesHTML}</ol>
            <li>Each section is comprised of 20 questions</li>
            <li>Each question carries 1 mark</li>
            <li>There is no negative marking</li>
          </ol>
        </body>
        </html>`;

        this.createCampaign();
      } catch (error) {
        alert(error);
      }
    },
    calculateScreeningDuration(subjects) {
      const len = subjects?.length ?? 0;
      if (len === 1) {
        return 30;
      }

      if (len === 2) {
        return 37.5;
      }

      if (len === 3) {
        return 45;
      }
      return 0;
    },

    calculateScreeningQuestions(skills) {
      const len = skills?.length ?? 0;

      return 10;
    },

    calculateMainsDuration(subjects) {
      const len = subjects?.length ?? 0;

      if (len === 1) {
        return 90;
      }

      if (len === 2) {
        return 105;
      }

      if (len === 3) {
        return 120;
      }

      return 0;
    },

    calculateMainsQuestions(skills) {
      const len = skills?.length ?? 0;
      return 20;
    },

    async goto(step) {
      switch (step) {
        case 1:
          if (this.$refs.step1.validate()) {
            this.dLoading = true;
            this.userType == "MESTA_STUDENTS" ? await this.createCampaign() : await  this.tempFunctions();
          }
          break;
        case 2:
          this.dLoading = true;
            try {
            if(this.userType !== "TEACHER" && this.campaignData.skills.includes('Core Skills')){
              var data = await TopicsController.getCamapignConfifrationTopics(
                this.campaignData.levels,
                this.campaignData.subjects
              );
              var topicsData = data.data;
              if (topicsData.flag) {
                if (topicsData?.data?.length != 0) {
                  this.topicsConfigrations = topicsData.data;
                  this.getGroupedTopics();
                  this.createCampaignConfigration();
                  this.dLoading = false
                } else {
                  alert("Topics not found");
                  this.dLoading = false
                }
              }
            }
            else{
              this.createCampaignConfigration();
              this.dLoading = false
            }
            } catch (error) {
              alert(error);
            }
          break;
        case 3:
          if(this.userType !== "TEACHER"){
          this.dLoading = true;
            var topicsConfigrations = {
              topicsDos: this.topicsDos,
              topicsDont: this.topicsDont,
              topics: this.topicsConfigrations,
            };
            var deomoConfigration =
              await CampaignController.demoTopicsConfigration(
                this.campaignData.campaignId,
                topicsConfigrations
              );
            if (deomoConfigration.data.flag) {
              this.dLoading = false;
              this.e1++;
            } else {
              alert(deomoConfigration.data.message);
              this.dLoading = false;
            }
          }
          else{
            this.dLoading = true;
            this.createCampaignAudiance();
          }
          
          break;
        case 4:
          this.dLoading = true;
          this.createCampaignAudiance();
          break;

        default:
          this.e1++;
      }
    },
    getUniqueSubjects(data){
      return  [...new Set(data.flatMap(item => item.subjects))];
    },
    async createCampaign() {
      // redundant properties to be removed
      this.campaignData.screeningEndTime = this.screeningEndTime;

      this.campaignData.startDate = this.convertDateFormat(this.startDate);
      if(this.userType=="MESTA_STUDENTS"){
        this.campaignData.startTime = this.campaignStartTime;
        this.campaignData.endDate = this.convertDateFormat(this.mainsEndDate);
        this.campaignData.endTime = this.mainsEndTime;
      }
      else{
        this.campaignData.campaignStartTime = this.campaignStartTime;
        this.campaignData.mainsEndDate = this.convertDateFormat(this.mainsEndDate);
        this.campaignData.mainsEndTime = this.mainsEndTime;
      }

      this.campaignData.screeningEndDate = this.convertDateFormat(
        this.screeningEndDate
      );
      if(this.userType == 'TEACHER'){
        this.campaignData.screeningEndTime = this.mainsEndTime;
          this.campaignData.screeningEndDate = this.convertDateFormat(
            this.mainsEndDate
          );
      }
      if (this.updateFlag == false) {
        console.log('user type ----------->',this.userType)
        const stages = this.userType == 'TEACHER' || this.userType == 'NTP' ? ['MAINS'] : [];
        const assessmentType = this.userType == 'MESTA_STUDENTS' ? 'static' : 'dynamic';
        const response = await CampaignController.createCampaign({
          isCampaignPublished: this.isCampaignPublished == 0 ? false : true,
          name: this.campaignData.name,
          description: this.campaignData.description,
          startDate: this.campaignData.startDate,
          startTime: this.campaignData.startTime,
          campaignStartTime: this.campaignData.campaignStartTime,
          endDate: this.campaignData.endDate,
          screeningEndDate: this.campaignData.screeningEndDate,
          mainsEndDate: this.campaignData.mainsEndDate,
          endTime: this.campaignData.endTime,
          resultPublishPattern:this.campaignData.resultPublishPattern,
          screeningEndTime: this.campaignData.screeningEndTime,
          mainsEndTime: this.campaignData.mainsEndTime,
          resultPublishTime: this.campaignData.resultPublishTime,
          publishPattern: this.campaignData.publishPattern,
          audienceType: this.campaignData.audienceType,
          clusters: this.campaignData.clusters,
          skills: this.campaignData.skills,
          subjects: this.userType=='MESTA_STUDENTS'? this.getUniqueSubjects(this.selectedAssessments) : this.campaignData.subjects,
          schools: this.campaignData.schools,
          levels: this.campaignData.levels,
          duration: this.campaignData.duration || 0,
          assessments: this.userType=='MESTA_STUDENTS'? this.selectedAssessments.map(assessment => {
            return { assessmentId:assessment.id,  grade:assessment.grade, name: assessment.assessmentName,
              availableLanguages: assessment.availableLanguages }}) : [],
          proctoringType: this.userType=='MESTA_STUDENTS'? this.campaignData.proctoringType : "BOTH",
          stages : stages,
          assessmentType,
        });
        if (response.data.flag) {
          this.successDialog = this.userType=='MESTA_STUDENTS'
          if(this.userType !== 'MESTA_STUDENTS')this.e1++;
          this.dLoading = false;
          this.campaignData.campaignId = response.data.id;
        } else {
          this.dLoading = false;
          alert(response.data.error);
        }
      } else {
        const response = await CampaignController.updateCampaign(
          {
            name: this.campaignData.name,
            startDate: this.campaignData.startDate,
            screeningEndDate: this.campaignData.screeningEndDate,
            campaignStartTime: this.campaignData.campaignStartTime,
            screeningEndTime: this.campaignData.screeningEndTime,
            mainsEndTime: this.campaignData.mainsEndTime,
            resultPublishTime: this.campaignData.resultPublishTime,
            publishPattern: this.campaignData.publishPattern,
            audienceType: this.campaignData.audienceType,
            clusters: this.campaignData.clusters,
            skills: this.campaignData.skills,
            subjects: this.campaignData.subjects,
            schools: this.campaignData.schools,
            levels: this.campaignData.levels,
            proctoringType: "BOTH",
          },
          this.campaignId
        );
        if (response.data.flag) {
          this.e1++;
          this.campaignId = null;
        } else {
          alert(response.data.error);
          this.campaignId = null;
        }
      }
    },
    filterBySubjects(mainArray, givenSubjects) {
      return mainArray.filter(async (obj) => {
        const subjects = await obj.responsibleTeachingAt.map(
          (responsibility) => responsibility.subject
        );
        return givenSubjects.every((subject) => subjects.includes(subject));
      });
    },
    async createCampaignConfigration() {
      this.campaignData.campaignConfigration.screeningConfigration.subjectsDistribution =
        this.subjectDistributions;
      this.campaignData.campaignConfigration.mainsConfigration.subjectsDistribution =
        this.subjectDistributionsForMains;
      this.campaignData.campaignConfigration.screeningConfigration.skillsDistributions =
        this.skillsDistributions;
      this.campaignData.campaignConfigration.mainsConfigration.skillsDistributions =
        this.skillsDistributionsForMains;
      if (this.updateFlag == false) {
        const response = await CampaignController.createCampaignConfigration(
          this.campaignData.campaignConfigration,
          this.campaignData.campaignId
        );
        if (response.data.flag) {
          this.e1++;
          this.dLoading = false;
          this.count = response.data.users.length;
          this.isLoading = false;
          if (this.campaignData.audienceType == "TEACHER") {
            var userData = response.data.users;
            this.users = await this.filterBySubjects(
              userData,
              this.campaignData.subjects
            );
            this.users.forEach((obj) => {
              obj.personalInfo.fullName = obj.personalInfo.firstName + ' ' + obj.personalInfo.lastName
            })
            this.filterUser = this.users
            this.responsibleTeachingAt = {};
            this.teachingSubjects = {};
            for (const user of this.users) {
              const lvl = [];
              const sub = [];
              for (const res of user.responsibleTeachingAt) {
                lvl.push(res.level);
                sub.push(res.subject);
              }
              this.responsibleTeachingAt[user.userId] = lvl;
              this.teachingSubjects[user.userId] = sub;
            }
          }
        } else {
          this.dLoading = false;
          alert(response.data.error);
        }
      } else {
        const response = await CampaignController.createCampaignConfigration(
          this.campaignData.campaignConfigration,
          this.campaignData.campaignId
        );
        if (response.data.flag) {
          this.e1++;
          this.campaignId = null;
        } else {
          alert(response.data.error);
          this.campaignId = null;
        }
      }
    },
    async sendMailToJobseekers() {
      await this.copyToClipboardCampaign(true);
      var mailTemplate = `<html>
          <body>
          <p>Dear User,</p>
    
          <p>We hope this email finds you well and that your passion for education is as strong as ever. We are reaching out to you today to invite you to be a vital part of our upcoming assessment initiative. As a dedicated and influential educator, your expertise and commitment are instrumental in shaping the future of our students.</p>
    
          <p>At VIBGYOR Group of Schools, we firmly believe in the power of assessments to enhance teaching and learning outcomes; hence, we have designed a teacher assessment program to provide you with a comprehensive assessment of your teaching practices and offer valuable feedback. We are launching this program to promote effective assessment practices.</p>
    
          <p>We strongly believe that this assessment program will enable you to further excel in your teaching journey, benefiting you and your students. Your passion for education and commitment to ongoing growth and improvement makes you an ideal candidate for this program.</p>
    
          <p>Together, let's create a transformative impact on the educational landscape. The last date of <b>Screening</b> test is <b>${this.campaignData.screeningEndDate}</b></p>
    
          <p>Please click on the <b>START NOW</b> button to login to the <b>VIBGYOR Hubble STAR</b> application and start the <b>Screening</b> test.</p>
    
          <div class="w-100 d-flex justify-center mt-2">          
            <a type="button" href=${this.shortLink} style="text-decoration:none; border: 2px solid black; border-radius: 6px; padding: 8px; color: black; background-color: white; cursor: pointer"><b>START NOW</b></a>
          </div>
          
          <p>All The Best!</p>
          <p><b>Team VIBGYOR Group Of Schools</b></p>
    
    
          </body>
          </html>`;

      this.selected.forEach((user) => {
        mailController.messageToHR(
          mailTemplate,
          "Text Not Supported",
          user.emails,
          "Invitation to participate in assessment campaign"
        );
      });
    },
    async createCampaignAudiance() {
      const isAudianceAdded = this.selected && this.selected.length > 0;
      this.selected = this.selected.map((item)=>{
        return {...item, emails:[item.emails]}
      })
      if (this.updateFlag == false) {
        const response = await CampaignController.createCampaignAudiance(
          this.selected,
          this.campaignData.campaignId,
          isAudianceAdded ? true : false
        );

        if (response.data.flag) {
          this.dLoading = false;
          this.successDialog = true;
        } else {
          this.dLoading = false;
          alert(response.data.error);
        }
      } else {
        const response = await CampaignController.createCampaignAudiance(
          this.campaignData.audiance,
          this.campaignData.campaignId,
          false
        );
        if (response.data.flag) {
          this.campaignId = null;
        } else {
          alert(response.data.error);
          this.campaignId = null;
        }
      }
    },

    async getClusterSchool() {
      if (this.campaignData.clusters.length > 0) {
        const response = await SchoolController.getClusterSchool(
          this.campaignData.clusters
        );

        if (response.status == 200) {
          var schools = response.data.schools;
          this.schools = schools;
        } else {
          alert(response.data.error);
        }
      } else {
        this.schools = [];
      }
    },
    async getClusterSchoolForFilter(clusters) {
      if (this.clusters.length > 0) {
        const response = await SchoolController.getClusterSchool(clusters);
        if (response.status == 200) {
          var schools = response.data.schools;
          this.schoolsData = schools;
        } else {
          alert(response.data.error);
        }
      } else {
        this.schoolsData = [];
      }
    },

    async getSkills() {
      const response = await SkillsController.getSkills();
      if (response.status == 200) {
        if (response.data.skills.length > 0) {
          this.skills = response.data.skills;
        } else {
          this.skills = [];
        }
      }
    },
    async getSubjects() {
      const response = await SubjectController.getSubject();
      if (response.status == 200) {
        if (response.data.subjects.length > 0) {
          this.subjects = response.data.subjects;
        } else {
          this.subjects = [];
        }
      }
    },
    async fetchAllClusters() {
      const response = await ClusterController.getAllClusters();
      if (response.status == 200) {
        if (response.data.clusters.length > 0) {
          this.clusters = response.data.clusters;
        } else {
          this.clusters = [];
        }
    
      }
    },

    showAlert(event) {
      if (event) {
        confirm("Press a button!");
      }
      event.stopImmediatePropagation();
    },

    viewUserInfoFun(item) {
      this.viewUserIno = item;
      this.userViewDialog = true;
      this.userFullName =
        item.personalInfo.firstName + item.personalInfo.lastName;
      this.userPhoneNumber = item.personalInfo.contactInfo.phoneNumber;
      this.userEmail = item.personalInfo.contactInfo.email;
    },

    async getLevels() {
      const response = await LevelController.getLevel();
      if (response.status == 200) {
        if (response.data.levels.length > 0) {
          this.levels = response.data.levels;
        } else {
          this.levels = [];
        }
      }
    },
 
   async fetchAssessment(status) {
        const response = await StaticAssessmentController.getStaticAssessments(status);
        if (response.status == 200) {
          if (response.data.assessments.length > 0) {
            this.assessments = response.data.assessments;
          } else {
            this.assessments = [];
          }

          this.preloader = false;
        } else {
          alert(response.data.error);
          this.preloader = false;
        }
        console.log(response.data.data);
      },
    async getGrades() {
      const response = await GradeController.getAllGrades();
      if (response.status == 200) {
        if (response.data.grades.length > 0) {
          this.grades = response.data.grades;
        }
      } else {
        alert(response.data.error);
      }
    },
  },
  mounted() {
    this.getClusterOrSchool();
    this.campaignData.audienceType = this.$route.query.role;
    this.userType = this.$route.query.role;
   
    this.configStep = this.userType !== "TEACHER" ? "4" : "3"; 
    window.addEventListener("beforeunload", this.showAlert);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.showAlert);
  },

  created() {
    this.getLevels();
    this.getSkills();
    this.fetchAllClusters();
    this.getGrades();
    this.fetchAssessment('PUBLISHED');
  },
};
</script>



<style scoped>
.chart-clas {
  height: 132px;
}

.graph {
  border-radius: 8px !important;
}

.card-container {
  position: relative;
  perspective: 1000px;
}

.card-content {
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background-color: #ffffff;
}

.card-content.flipped {
  transform: rotateY(180deg);
}

.link-container {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2px;
}

.flip-link {
  color: #007fff;
  cursor: pointer;
  font-weight: bold;
}

.v-application .primary--text {
  color: #864f20 !important;
  caret-color: #864f20 !important;
}

.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border-color: rgba(0, 0, 0, 0) !important;
}

.step3 {
  height: 300;
}

.campaign-question-content {
  width: 234px;
  margin-bottom: 6px;
}

.campaign-data {
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hide-it{
visibility: hidden;
}
.shows-it{
  visibility: visible;
}
.h-auto{
  height:auto;
}
.radio-group-align{
  margin-top: -8px !important;
}

.v-chip.v-chip--removable {
  background: var(--gray-03) !important;
  color: var(--primary) !important;
}

</style>
