<template>
    <canvas ref="chart" :id="chartName" class="gaugeChart"></canvas>
</template>
  
<script>
import "chartjs-gauge";

export default {

    props: ['chartName' , 'data'],

    mounted() {
        this.initializeChart();
    },
    methods: {
        initializeChart() {

            var config = {
                type: "gauge",
                data: {
                    datasets: [
                       this.data
                    ]
                },
                options: {
                    maintainAspectRatio: true,
                    aspectRatio: 2.25,
                    cutoutPercentage: 80,
                    needle: {
                        radiusPercentage: 4,
                        widthPercentage: 6,
                        lengthPercentage: 5,
                        color: "rgba(95, 159, 104, 1)"
                    },
                    valueLabel: {
                        display: false
                    },
                    animation: false,
                }
            };

            var ctx = document.getElementById(this.chartName).getContext("2d");
            this.myGauge = new Chart(ctx, config);
        },

    },
};
</script>

<style scoped>
.gaugeChart {
    width: 100% !important;
    height: 50% !important;
}
</style>