<template>
    <div v-if="preloader">
      <template>
        <v-dialog v-model="preloader" hide-overlay persistent width="300">
          <v-progress-linear indeterminate></v-progress-linear>
        </v-dialog>
      </template>
    </div> 
    <div v-else>
        <v-dialog v-model="restartAssessmentDialog" max-width="380px" class="cdz" persistent>
            <v-card fluid>
              <v-container fluid class="p-6">
                <v-card-text class="text-center">
                  <p class="text-h5 pb-0">Restart Assessment
                  </p>
                  <p
                    class="text-disabled grey--text text-subtitle-1 pt-3"
                    color="rgba(0, 0, 0, 0.6)"
                    disabled>
                    Do you want to restart the assessment?
                  </p>
    
                  <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                    <v-btn
                      depressed
                      class="btn-color--text"
                      large
                      width="157px"
                      outlined
                      rounded
                      @click="restartAssessmentDialog = false;"
                      >CANCEL</v-btn>
                    <v-btn
                      class="accent white--text"
                      depressed
                      large
                      width="157px"
                      rounded
                      :loading="restartAssessmentLoader"
                      @click="clearUserAssessment(userForClearAssessment)"
                      >RESTART
                    </v-btn>
                  </div>
                </v-card-text>
              </v-container>
            </v-card>
        </v-dialog>
        <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
            <v-card width="570px" height="100%" class="filterDialogClass">
            <v-row class="mt-4 mx-1">
                <v-col>
                <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                    Filter
                </div>
                </v-col>
                <v-col class="d-flex justify-end">
                <div class="clear-filter-btn" @click="clearFilter">
                    CLEAR FILTER
                </div>
                </v-col>
            </v-row>
            <v-tabs vertical>
                <div class="ml-3 mt-4 mr-4 filterLine">
                <v-tab class="black--text justify-start" active-class="activeTab"
                    >
                    <span>Levels</span>
                    <span class="ml-1"></span
                    >
                </v-tab>
                <v-tab class="black--text justify-start" active-class="activeTab"
                    >
                    <span>Grades</span>
                    <span class="ml-1"></span
                    >
                </v-tab>
                <v-tab class="black--text justify-start" active-class="activeTab"
                    >
                    <span>Assessment Name</span>
                    <span class="ml-1"></span
                    >
                </v-tab>
                </div>
                <v-tab-item>
                <v-card flat class="school-filter">
                    <v-card-text>
                    <v-chip-group v-model="selectedLevelFilter" 
                    active-class="active-chip" column :multiple="true">
                        <v-chip v-for="(level,index) in levels"                    
                        :key="index"
                        :value="level"
                        elevated>
                            {{ level }}
                        </v-chip>
                    </v-chip-group>
                    </v-card-text>
                </v-card>
                </v-tab-item>
                <v-tab-item>
                <v-card flat class="school-filter">
                    <v-card-text>
                    <v-chip-group v-model="selectedGradeFilter"                 
                    active-class="active-chip"
                    column
                    :multiple="true">
                        <v-chip v-for="(grade,index) in grades"                    
                        :key="index"
                        :value="grade"
                        elevated>
                        {{ grade }}
                        </v-chip>
                    </v-chip-group>
                    </v-card-text>
                </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat class="school-filter">
                        <v-card-text>
                        <v-chip-group v-model="selectedAssessmentFilter"                 
                        active-class="active-chip"
                        column
                        :multiple="true">
                            <v-chip v-for="(assessment ,index) in assessments"                    
                            :key="index"
                            :value="assessment"
                            elevated>
                            {{ assessment }}
                            </v-chip>
                        </v-chip-group>
                        </v-card-text>
                    </v-card>
                    </v-tab-item>
            </v-tabs>
            <div class="filterDialogButton">
                <v-card-actions class="px-6 pb-0">
                <v-spacer></v-spacer>
                <v-btn
                    rounded
                    outlined
                     class="btn outline-btn pa-4"
                    @click="filterDialog = false"
                    >Cancel</v-btn
                >
                <v-btn                
                    rounded
                    class="btn primary-btn pa-4"
                    @click="filterUsers"     
                    >Apply</v-btn
                >
                </v-card-actions>
            </div>
            </v-card>
        </v-dialog>
      <v-card height="64px" width="100%"
        class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
        style="position: fixed; z-index: 200; top: 0; width: 98%; padding-top: 16px;">
        <v-card-title class="ml-0 pl-0">
          <p class="breadcrumb-text underline-on-hover cursor" @click="$router.push('/campaign')">Campaigns</p>
          <v-icon
            class="breadcrumb-arrow-unselected"
            style="margin-top: 6px !important"
          >mdi-chevron-right
          </v-icon>
          <p class="breadcrumb-text">{{campaignName}}</p>
        </v-card-title>
      <UserNavMenu/>
      </v-card>
      <div fluid class="pad px-8 background">
        <div class="d-flex align-center mb-8">
          <h3 class="mr-auto">{{ campaignName }}</h3>
          <v-text-field
              solo
              label="Search here..."
              v-model="search"
              hide-details
              prepend-inner-icon="mdi-magnify"                          
              class="search-bar border-button rounded-pill ml-4"
              dense
              clearable
            ></v-text-field>          
          <v-btn @click="filterDialog = true" depressed rounded outlined class="btn outline-btn mx-3"
            ><v-icon>mdi-tune</v-icon>
            <span
              >Filter</span
            >            
          </v-btn>
          <v-btn @click="downloadMestaUsers"  :loading="downloadmestaUserLoader" depressed rounded outlined class="btn outline-btn mx-3"
            ><v-icon>mdi-import mdi-rotate-90</v-icon>            
          </v-btn>
        </div>
        <v-chip-group v-model="selectedChip" class="mb-4 language-chips">
            <v-chip
            v-for="(chip, index) in chips"
            :key="index"
            :class="{'selected-chip': selectedChip === chip}"
            active-class="active-chip"            
            outlined
            rounded
            @click="selectChip(chip)"
            >
            {{ chip }} ({{ showUsersCount(chip) }})
            </v-chip>
        </v-chip-group>
        <div>
            <v-data-table
            :headers="headers"
            :items="users"
            item-value="id"
            :server-items-length="totalUsers"
            :items-per-page="options.itemsPerPage"
            :options.sync="options"
            :footer-props="{
                itemsPerPageOptions: [10, 20, 25, 30, -1],
            }"
            @update:options="fetchMestaCampaignAudience"
            class="elevation-0 custom-data-table"
            >
            <template v-slot:item.latestStatus="{ item }">
              <div class="d-flex justify-center">
                <v-chip :color="item.statusChipColor" :text-color="item.statusChipTextColor">{{item.statusLabel}}</v-chip>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-row 
                class="d-flex justify-center align-center" 
                :style="{ width: item.userType !== 'Teacher' ? '150px' : '150px' }"
                >
                  <!-- Test Report Button -->
                  <v-tooltip v-if="item.statusLabel =='Assessed'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <img 
                        v-bind="attrs" 
                        v-on="on" 
                        @click="getTestReport(item)" 
                        class="cursor mr-4" 
                        width="24px"
                        src="../assets/Assessment_Report_Enabled.svg" 
                      />
                    </template>
                    <span>Test Report</span>
                  </v-tooltip>
                  <img 
                  v-else 
                  class="mr-4 disabled-icon" 
                  width="24px" 
                  :src="require('../assets/Assessment_Report_Disabled.svg')" 
                  />
              
                  <!-- Restart Exam Button -->
                  <v-tooltip v-if="item.statusLabel !== 'Yet To Start'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        v-bind="attrs" 
                        v-on="on" 
                        @click="restartAssessmentDialog=true; userForClearAssessment=item" 
                        class="cursor"
                        width="24px" 
                        src="../assets/restart.svg" 
                      />
                    </template>
                    <span>Restart Exam</span>
                  </v-tooltip>
                  <img 
                  v-else
                  class="mr-2 disabled-icon"
                  width="24px" 
                  src="../assets/restart-disabled.svg" 
                />
                </v-row>
              </template>
            </v-data-table>
        </div>
        <div>
        </div>
      </div>
      <v-snackbar v-model="clearUserAssessmentSuccessSnackbar" :timeout="1000">
        Success
      </v-snackbar>
      <v-snackbar v-model="clearUserAssessmentFailureSnackbar" :timeout="1000">
        Application error encountered
      </v-snackbar>
    </div>
</template>
<script>
    import AuthService from "../services/AuthService";
    import CampaignController from "@/controllers/CampaignController";
    import LevelController from "@/controllers/LevelController";
    import StaticAssessmentController from "@/controllers/StaticAssessmentController";
    import Papa from "papaparse";
    import { debounce } from "lodash";
    import UserNavMenu from "@/components/UserNavMenu.vue";
    export default {
      components:{ UserNavMenu },
        data() {
            return {
                preloader: false, //
                campaignId: "",
                campaignName:"",
                campaignType: "",
                selectedChip: 0,
                latestStatus: [],
                chips: ['All', 'Yet To Start', 'In Progress', 'Assessed'],
                headers: [
                    { text: 'Name', value: 'name',width: '15%', },
                    { text: 'Email ID', value: 'email',width: '20%'  },
                    { text: 'Assessment Name', value: 'assessmentName',width: '30%'  },
                    { text: 'Grade', value: 'grade',sortable: false, width: '10%'  },
                    { text: 'Language', value: 'prefferedLanguage',sortable: false, width: '10%'  },
                    { text: 'Status', value: 'latestStatus',sortable: false, width: '10%'  },
                    { text: 'Action', value: 'actions', sortable: false,width: '5%' },
                ],
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    multiSort: false,
                    mustSort: false,
                },
                users: [],
                usersStatusCount: {},
                totalUsers: 0,
                campaignData: {},
                assessments: [],
                filterDialog: false,
                selectedLevelFilter: [],
                selectedGradeFilter: [],
                selectedAssessmentFilter: [],
                userForClearAssessment: {},
                restartAssessmentDialog: false,
                restartAssessmentLoader: false,
                levels:[],
                grades: [],
                search: "",
                debouncedSearch: null,
                clearUserAssessmentSuccessSnackbar: false,
                clearUserAssessmentFailureSnackbar: false,
                userStatusMap:  new Map([
                  ["loggedin", {label: "Yet To Start", textColor: "grey darken-1", color: "grey lighten-4"}],
                  ["started", {label: "In Progress", textColor: "amber darken-1", color: "amber lighten-5"}],
                  ["completed", {label: "Assessed", textColor: "success darken-1", color: "success-01"}],
                  ["quit", {label: "Assessed", textColor: "success darken-1", color: "success-01"}]
                ]),
                downloadmestaUserLoader: false,

            }

        },
        created() {
            this.$store.state.breadcrumb = "Assessment";
            this.campaignId = this.$route.query.id;
            this.campaignName =  this.$route.query.campaignName;
            this.campaignType = this.$route.query.campaignType;
            this.fetchMestaCampaignAudienceCount();
            this.debouncedSearch = debounce(this.fetchMestaCampaignAudience, 500);
            this.getCampaignInfo();
        },
        mounted() {
            this.getLevelsAndGrades();
            this.fetchMestaCampaignAudience();
        },
        watch: {
        search(newValue) {
            if (newValue === null)
                this.search = ""
            this.debouncedSearch();
            },
        },
        methods: {
         async downloadMestaUsers(){
          this.downloadmestaUserLoader = true
            const response = await CampaignController.fetchMestaCampaignAudience({
                    campaignId:this.campaignId,
                    itemsPerPage: -1,
                    page: 1,
                    latestStatus: this.latestStatus,
                 
                })
                let users = response.data.result.users.map(({firstName, lastName, email, latestStatus, Status, assessmentName, grade, prefferedLanguage, restartCount})=>{
                  return {
                    createdOn: this.getCreatedTime(Status),
                    name:`${firstName} ${lastName}`,
                    emailId: email,
                    assessmentName: assessmentName,
                    grade,
                    language: Array.isArray(prefferedLanguage)? prefferedLanguage?.join(", ") : prefferedLanguage,
                    status: this.userStatusMap.get(latestStatus).label,
                    restartCount: restartCount
                  }
                });
                
              
           
              this.downloadCSV(users, `mesta-users-${this.campaignId}-${this.latestStatus}`)
            this.downloadmestaUserLoader = false
           },
           getCreatedTime(arr){
            if(arr != undefined){
              return arr[0].datetime
            } else{
              return ''
            }
            
           },
           downloadCSV(csvData, fileName) {
              const csv = Papa.unparse(csvData);
              const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

              if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, fileName);
              } else {
                const link = document.createElement("a");
                if (link.download !== undefined) {
                  const url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", fileName);
                  link.style.visibility = "hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }
            },
            logout() {
                AuthService.logout();
                this.$router.push("/login");
            },
            showUsersCount(chips) {
              switch (chips) {
                case 'All':
                  return this.usersStatusCount?.totalUsers || 0;
                case 'Yet To Start':
                    return this.usersStatusCount?.usersLoggedinCount || 0;
                case 'In Progress':
                    return this.usersStatusCount?.usersStartedCount || 0;
                case 'Assessed':
                    const totalAssessed = (this.usersStatusCount?.usersQuitCount || 0) + (this.usersStatusCount?.usersCompletedCount || 0);
                    return isNaN(totalAssessed) ? 0 : totalAssessed;
                default:
                    return 0;
              }
          },
          async fetchMestaCampaignAudienceCount() {
                const response = await CampaignController.fetchMestaCampaignAudienceCount({
                    campaignId:this.campaignId,
                    levels: this.selectedLevelFilter,
                    grades: this.selectedGradeFilter,
                    assessmentName: this.selectedAssessmentFilter,
                });
                this.usersStatusCount = response.data.result;
            },
            async fetchMestaCampaignAudience(){
                const response = await CampaignController.fetchMestaCampaignAudience({
                    campaignId:this.campaignId,
                    itemsPerPage: this.options.itemsPerPage,
                    page: this.options.page,
                    latestStatus: this.latestStatus,
                    search: this.search,
                    levels: this.selectedLevelFilter,
                    grades: this.selectedGradeFilter,
                    assessmentName: this.selectedAssessmentFilter,
                })
                this.users = response.data.result.users.map(({firstName, lastName, latestStatus, ...rest})=>{
                  return {
                    name:`${firstName} ${lastName}`,
                    statusLabel: this.userStatusMap.get(latestStatus).label, 
                    statusChipColor: this.userStatusMap.get(latestStatus).color,
                    statusChipTextColor: this.userStatusMap.get(latestStatus).textColor,
                    ...rest
                  }
                });
                this.totalUsers = response.data.result.totalUsers;
                console.log("response",response);
            },
            async getCampaignInfo(){
                let query = "?id="+this.campaignId;
                const response = await CampaignController.getCampaign(query);
                if(response.status == 200){
                    this.campaignData =  response.data.data;
                    this.assessments = this.campaignData.assessments.map(assessment => assessment.name)
                }
            },
            filterUsers(){
                this.options.page = 1
                this.fetchMestaCampaignAudience();
            },
            clearFilter(){
                this.selectedLevelFilter = [];
                this.selectedGradeFilter = [];
                this.selectedAssessmentFilter = [];
                this.options.page = 1;
                this.fetchMestaCampaignAudience();
                this.filterDialog = false;
            },
            async getLevelsAndGrades() {
                const response = await LevelController.getLevel();
                if (response.status == 200) {
                    if (response.data.levels.length > 0) {
                    let levelsData = response.data.levels;
                    this.levels = levelsData.map(level=>level.name)
                    this.grades = levelsData.map(level=>level.grades).flat();
                    } else {
                    this.levels = [];
                    }
                }
            },
            selectChip(status){
                this.latestStatus = [];
                for (const [statusKey, statusDetails] of this.userStatusMap){
                  if(statusDetails.label == status)this.latestStatus.push(statusKey);
                }
                this.fetchMestaCampaignAudience();
            },
            getTestReport(item){
                console.log("item for testreport", item)
                console.log("campaignId", this.campaignId)
                this.$router.push({ path: "/userTestReport", query: {
                  studentId: item.studentId,
                  campaignId: this.campaignId,
                  campaignName: this.campaignName,
                  campaignType: this.campaignType,
                  grade: item.grade,
                  assessmentId: item.assessmentId}})
            },
            async clearUserAssessment(userForClearAssessment){
                this.restartAssessmentLoader = true;
                console.log("clear Assessment for", userForClearAssessment);
                const {grade, studentId, assessmentId} = userForClearAssessment;
                const response = await StaticAssessmentController.clearMestaUserAssesment(this.campaignId, grade, studentId, assessmentId);
                this.restartAssessmentLoader = false;
                this.restartAssessmentDialog = false;
                if(response.data.flag){
                    this.clearUserAssessmentSuccessSnackbar = true;
                    this.fetchMestaCampaignAudience();
                    this.fetchMestaCampaignAudienceCount();
                } else {
                    alert(response.data.result);
                    this.clearUserAssessmentFailureSnackbar = true;
                }
            },
        },
    } 
</script>
<style scoped>
  .m-ckeditor {
      height: 153px;
  }
  .add-options {
      color: #1B72E8;
      cursor: pointer;
  }
  .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #1B72E8;
      border-radius: 50px;
  }
  textarea {
      width: 100%;
      min-height: 73px;
      border: 1px solid rgba(0, 0, 0, 0.38);
      resize: none;
  }
  .language-chips {
  .v-chip {
      background: white !important;
  }
  .v-slide-item--active {
      font-weight: 600;
      color: var(--brown-01) !important;
      border: 1px solid var(--brown-01);
      background: rgb(217, 203, 190) !important;
  }
  .disabled-icon {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>