export const liveListenerUnsubscriber = {
    data() {
        return {
            liveListenersToUnsubscribeMixin: [],
        };
    },
    beforeDestroy() {
        if (this.liveListenersToUnsubscribeMixin.length > 0) {
            this.liveListenersToUnsubscribeMixin.forEach(unsubscribe => unsubscribe());
          }
      },
}