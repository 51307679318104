<template>
  <v-card class="pa-4">
    <div class="d-flex justify-space-between align-center mb-4">
      <div class="text-h6">Submit Decision</div>
      <v-btn 
        text 
        class="text-none blue--text"
        @click="clearForm"
      >
        CLEAR
      </v-btn>
    </div>

    <v-radio-group 
      v-model="decision"
      class="mb-4"
    >
      <!-- First line: Accept, Reject, On Hold -->
      <div class="d-flex flex-wrap gap-4 mb-2">
        <v-radio
          label="Accept Candidate"
          value="ACCEPT"
        ></v-radio>
        <v-radio
          label="Reject Candidate"
          value="REJECT"
        ></v-radio>
        <v-radio
          label="On Hold Candidate"
          value="ONHOLD"
        ></v-radio>
      </div>

      <!-- OR line -->
      <div  class="text-center my-2">OR</div>

      <!-- Last line: Notify HR -->
      <div >
        <v-radio
          label="Notify HR to schedule next round"
          value="NOTIFY_HR"
        ></v-radio>
      </div>
    </v-radio-group>

    <div class="mb-4">
      <label class="mb-1 d-block">Remark*</label>
      <ckeditor 
        :config="editorConfig" 
        v-model="remark" 
        class="m-ckeditor limited-height"
        @ready="onEditorReady"
      ></ckeditor>
    </div>

    <div class="d-flex justify-end">
      <v-btn
        class=" submit-btn white--text"
        :loading="loading"
        :disabled="!isValid"
        @click="submit"
        color="#3F41D1"
      >
        SUBMIT
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'SubmitDecision',
  
  props: {
    userId: {
      type: String,
      required: true
    },
    campaignId: {
      type: String, 
      required: true
    },
    round: {
      type: Number,
    },
    hiringManagerDetails: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      decision: '',
      remark: '',
      loading: false,
      editorConfig: {
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'Link', 
           'NumberedList', 'BulletedList', 'Undo', 'RemoveFormat']
        ],
        removeButtons: '',
        toolbarStartupExpanded: true,
        removePlugins: 'elementspath,resize',
        height: '200px'
      }
    }
  },

  computed: {
    isValid() {
      return this.decision && this.remark.trim().length > 0;
    }
  },

  methods: {
    clearForm() {
      this.decision = ''
      this.remark = ''
    },

    onEditorReady(editor) {
      editor.on("afterCommandExec", function(event) {
        var commandName = event.data.name;
        if(['ckeditor_wiris_openFormulaEditor', 'ckeditor_wiris_openFormulaEditorChemistry'].includes(commandName)) {
          setTimeout(() => {
            const wiris_license_overlays = document.querySelectorAll('.wrs_tickContainer')
            wiris_license_overlays.forEach(element => {
              element.remove();
            });
          }, 2000)
        }
      })
    },

    async submit() {
      if (!this.isValid) return

      this.loading = true
      try {
        const submitData = {
          userId: this.userId,
          interviewFinalStatus: {
            status: this.decision,
            remark: this.remark,
            hiringManagerDetails:this.hiringManagerDetails,
          },
          campaignId: this.campaignId
        }
        if (this.decision === "NOTIFY_HR") {
          submitData.interviewFinalStatus.round = this.round + 1;
        }
        await this.$emit('submit', submitData)
        this.clearForm()
        // Redirect to candidate listing page
        // this.$router.push('/candidate-list')
      } catch (error) {
        console.error('Error submitting decision:', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.gap-4 {
  gap: 1rem;
}

.submit-btn {
  text-transform: none;
  font-size: 16px;
  height: 40px;
  border-radius: 20px;
  font-weight: normal;
  letter-spacing: normal;
  background-color: #3F41D1 !important;
}

:deep(.v-radio) {
  margin-right: 16px;
}

:deep(.v-input--radio-group__input) {
  margin: 0;
}

:deep(.v-radio .v-label) {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

:deep(.limited-height) {
  max-height: 100px;  /* Adjust this value as needed */
  overflow-y: auto;
}

:deep(.limited-height .ck-editor__editable_inline) {
  min-height: 90px;
  max-height: 100px;
}

:deep(.v-radio .v-icon) {
  color: #3F41D1 !important;
}
</style>