<template>
      <v-btn-toggle
        @change="switchResult($event)"
        v-model="isSelect"
        dense
        rounded
      >
        <v-btn 
          v-for="(btn, index) of buttons"
          width="100px"
          :class="
            isSelect == index
              ? 'blue white--text text-subtitle-2 '
              : '#000000 gray-02 text-subtitle-2'
          "
          :disabled="btn.disable"
        >
         {{btn.status}}
        </v-btn>
          </v-btn-toggle>
</template>
<script>
export default {
  name: 'TestToggle',
  props: {
    isSelect: {
      type: Number,
      required: true,
    },
    buttons: {
      type: Array,
      required: true
    }
  },
  methods: {
    switchResult(event){

      this.$emit('testChange', event,)
    }
  }
}
</script>    