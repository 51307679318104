<template>
  <div>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
      <v-card-title class="pl-0 ml-0">
        <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/masters')">
          Masters
        </p>
        <v-icon class="breadcrumb-arrow-unselected" style="margin-top: 6px !important">mdi-chevron-right</v-icon>
        <v-menu offset-y class="menuZindex">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
              Levels
              <v-icon class="breadcrumb-arrow cursor" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </span>
          </template>
          <MastersDropdownComponent />
        </v-menu>
        <!-- <p class="breadcrumb-text" style="margin-top: 2px">Levels</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon> -->
      </v-card-title>
      <UserNavMenu/>
    </v-card>
    <div fluid class="px-8 pad">

      <v-row>
        <v-col>

          <v-dialog max-width="887px" v-model="dialog" class="cdz" center>
            <v-form ref="form" lazy-validation>
              <v-card>
                <v-card-title class="gray-02 mb-8">{{ formbtn() }} Level</v-card-title>
                <v-card-text class="px-6 pb-0">
                  <v-text-field outlined class="rounded-xl" v-model="name" label="Enter Level*"
                    :rules="[v => !!v || 'Level is required']" required></v-text-field>
                  <v-autocomplete v-model="grades" clearable deletable-chips label="Select or Search Grades" outlined
                    class="rounded-xl" small-chips :items="gradeData" multiple item-text="name" item-value="name">
                  </v-autocomplete>
                  <v-autocomplete
                          outlined
                          class="rounded-xl"
                          v-model="audience_role"
                          :items="audienceRoleGroups"
                          item-text="name"
                          item-value="id"
                          label="Audience Role*"
                          :rules="[(v) => !!v || 'Audience Role is required']"
                          required
                        ></v-autocomplete>
                </v-card-text>
                <v-card-actions class="px-6 pb-6">
                  <small>*All fields are mandatory</small>
                  <v-spacer></v-spacer>
                  <v-btn width="102px" height="48px" rounded outlined class="btn outline-btn pa-4"
                    @click="dialog = false">Cancel</v-btn>
                  <v-btn width="102px" height="48px" rounded @click="saveInputs" class="btn primary-btn pa-4" :loading="loading">{{
                    formbtn() }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>

          </v-dialog>


          <!-- filter dialog-->

          <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
            <v-card width="570px" height="100%" class="filterDialogClass">
              <v-row class="mt-4 mx-1">
                <v-col>
                  <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">Filter</div>
                </v-col>
                <v-col class="d-flex justify-end">
                  <div @click="clearFilter" class="clear-filter-btn">CLEAR FILTER </div>
                </v-col>
              </v-row>

              <v-tabs vertical>

                <div class="ml-3 mt-4 mr-4 filterLine">

                  <v-tab class="black--text justify-start" active-class="activeTab">
                    <span>Grades</span>
                    <span
                      v-if="selectedGradeFilter.length > 0" class="ml-1">({{ selectedGradeFilter.length
                      }})</span></v-tab>


                </div>

                <v-tab-item>
                  <v-card flat class="school-filter">
                    <v-card-text>
                      <v-chip-group v-model="selectedGradeFilter" active-class="active-chip" column :multiple="true">
                        <v-chip v-for="(gradeType, index) in gradeData" :key="index" :value="gradeType.name" elevated>
                          {{ gradeType.name }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

              </v-tabs>
              <div class="filterDialogButton">
                <v-card-actions class="px-6 pb-6">
                  <v-spacer></v-spacer>
                  <v-btn rounded outlined class="btn outline-btn pa-4" @click="filterDialog = false">Cancel</v-btn>
                  <v-btn :disabled="selectedGradeFilter.length == 0" rounded :loading="filterbtnLoading"
                    class="btn primary-btn pa-4" @click="filterLevel(selectedGradeFilter), getFilterCount()">Apply</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>

          <!-- <v-dialog v-model="filterDialog" max-width="400px">
            <v-card width="400px" height="100%">
              <v-card-text class="pa-6">
                <v-row>
                  <v-col>
                    <div class="pl-1 text-body1 font-weight-bold">FILTER</div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <div @click="clearFilter" class="text-body1 font-weight-bold black--text cursor">
                      CLEAR FILTER
                    </div>
                  </v-col>
                </v-row>
                <div>
                  <v-card height="450px" elevation="0" id="myScroll" class="pt-5 ,pb-5">
                    <v-row class="pl-1">
                      <v-col>
                        <div class="text-body1 font-weight-normal black--text">
                        Grades
                      </div>
                      </v-col>
                    </v-row>
                    <v-chip-group v-model="selectedGradeFilter" active-class="primary" column :multiple="true">
                      <v-chip  v-for="(gradeType, index) in gradeData" :key="index" :value="gradeType.name" elevated>
                        {{ gradeType.name }}
                      </v-chip>
                    </v-chip-group>
                  </v-card>
                  <div>
                    <v-card-actions class="px-6 pb-6">
                      <v-spacer></v-spacer>
                      <v-btn rounded outlined class="pa-4" @click="filterDialog = false">Cancel</v-btn>
                      <v-btn :disabled="selectedGradeFilter.length == 0" rounded :loading="filterbtnLoading" class="accent pa-4" @click="filterLevel(selectedGradeFilter)">Apply</v-btn>
                    </v-card-actions>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog> -->


        </v-col>
        <!-- <v-col cols="4">
        <v-text-field label="Search" prepend-inner-icon="mdi-magnify" v-model="search" clearable></v-text-field></v-col> -->
      </v-row>

      <v-row class="d-flex justify-cente my-0 py-0r">
        <v-col>
          <div class="text-h6">Levels</div>
        </v-col>



        <v-col cols="10" class="pl-0 ml-0">
          <v-row justify="end" class="mb-1 mt-0">
            <v-text-field solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search" hide-details
              class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="btn outline-btn elevation-0 mx-2 pl-4 pr-6"
                  :class="filterCount > 0 ? 'outline-disabled-btn' : ''"
                  rounded
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  :disabled="filterCount > 0"
                >
                <v-img
                v-bind="attrs" 
                v-on="on" 
                :style="filterCount > 0 ? 'filter: grayscale(100%) opacity(50%);' :''"
                :src="require(`@/assets/svg/sort.svg`)"
                />              
                  <span>SORT</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item 
                  v-for="option in sortOptions" 
                  :key="option.value" 
                  @click="selectSortOption(option.value)"
                  :class="{'selected-sort-option': selectedSort === option.value}"
                >
                  <v-list-item-title>
                    {{ option.label }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              </v-menu>
              <v-btn depressed outlined rounded class="btn outline-btn mx-3"><v-icon class="pr-2">mdi-tune</v-icon><span
                v-if="filterCount > 0" @click="filterDialog = true">Filter ({{ filterCount }})</span>
              <span v-else @click="filterDialog = true">Filter</span></v-btn>
            <v-btn class="btn mx-3" outlined rounded :disabled="selected.length == 0"
            :class="!selected.length == 0 ? 'outline-btn' : 'disabled-outline-btn'"
              @click="deleteDialog = true"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>
            <v-btn depressed outlined rounded class="btn outline-btn mx-3" @click="downlaodRoles"><v-icon>mdi-import
                mdi-rotate-90</v-icon></v-btn>

            <v-btn v-if="$store.state.role.Level.admin" @click="dialog = true, newLevelValue()"
              class="btn primary-btn mx-3" depressed rounded><v-icon class="pr-2">mdi-plus</v-icon>
              Create</v-btn>

          </v-row>
        </v-col>
      </v-row>
      <v-data-table fixed-header height="calc(100vh - 230px)" class="rounded-table" v-model="selected"
        :loading="dataTableLoading" loading-text="Loading Levels" :headers="headers" :items="tableData" show-select
        :disable-sort="true"
        :items-per-page="options.itemsPerPage"
        :server-items-length="count"
        :options.sync="options"
        @update:options="getLevel"
        :single-select="singleSelect" :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 50, 100]
        }">
        <template v-slot:loading>
          <bubble-loader/>
        </template>
        <template v-slot:no-results>
        <div class="custom-no-data">
          <p>No Data Available</p>
        </div>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ getDate(item.createdOn) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="$store.state.role.Level.admin" icon class="mr-2 pa-4" @click="updateData(item)">
            <img width="36px" height="36px" class="cursor" src="../../assets/edit.svg" />
          </v-btn>
        </template>
        <template v-slot:[`item.grade_`]="{ item }">
          <span v-for="data in item.grades" :key="data.name">{{ data.name }}, </span>
        </template>
        <!-- v-for="data in item.gradeIds" :key="data.id" -->

      </v-data-table>
      <v-dialog v-model="deleteDialog" class="cdz" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-container></v-container>
              <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>

              <p class="text-h5 pt-6 pb-0">Delete Level</p>
              <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>This action
                will
                permanently delete the item . This cannot be undone</p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid> <v-btn depressed class="btn outline-btn"
                  large width="157px" rounded @click="deleteDialog = false">CANCEL</v-btn> <v-btn
                  class="btn primary-btn" depressed large width="157px" rounded :loading="dLoading"
                  @click="deleteData(selected)">DELETE</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="primary" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Level Deleted</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">Level {{ formbtnValue() }}</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <!-- Notification Dialog -->
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>

  </div>
</template>

<script>
import LevelController from "@/controllers/LevelController";
import GradeController from "@/controllers/GradeController";
import AuthService from "../../services/AuthService";
import Papa from "papaparse";
import Notification from "../Notification.vue";
import MastersDropdownComponent from "../../components/MastersDropdownComponent.vue"
import { getDateFromTimestamp } from "@/helpers/utils";
import AudienceRolesController from "@/controllers/AudienceRolesController";
import BubbleLoader from "@/components/BubbleLoader.vue";
import UserNavMenu from "@/components/UserNavMenu.vue";

export default {
  name: "LevelsView",
  components: {
    Notification, MastersDropdownComponent, BubbleLoader,UserNavMenu
  },
  data() {
    return {
      filterCount: 0,
      notificationDialog: false,
      appliedFilter: false,
      options: {},
      pageSize: 10,
      page: 1,
      count: 0,
      errorDialog: false,
      successDialog: false,
      deleteSuccessDialog: false,
      errorMessage: "",
      dialog: false,
      dialogTitle: "Dialog Title",
      name: null,
      grades: [],
      gradeData: [],
      singleSelect: false,
      editId: null,
      formbtnBool: false,
      loading: false,
      filterbtnLoading: false,
      dLoading: false,
      deleteDialog: false,
      levelData: [],
      LOData: [],
      selected: [],
      filterDialog: false,
      selectedGradeFilter: [],
      filterData: false,
      search: '',
      searchBool: false,
      dataTableLoading: true,
      sortCol:"name",
      sortOrder: "asc", // Default sort order (asc or desc)
      selectedSort: "levelAsc", // Default selected sort option
      sortOptions: [
        { value: "levelAsc", label: "Level - Ascending (A-Z)" },
        { value: "levelDesc", label: "Level - Descending (Z-A)" },
        { value: "dateNewest", label: "Date - Newest" },
        { value: "dateOldest", label: "Date - Oldest" },
      ],
      headers: [
        { text: "Level Names", value: "name" },
        { text: "Grades", value: "grades" },
        { text: "Created  On", value: "createdOn" },
        { text: "Actions", value: "actions" },
      ],
      tableData: [],
      rules: {
        required: (value) => !!value || "Field is required",
      },
      audience_role:"",
      audienceRoleGroups : []
    };
  },
  watch: {
    // Add watcher for search
    search(newValue) {
      // Update URL when search changes
      this.$router.replace({ 
        query: { 
          ...this.$route.query,
          search: newValue || undefined 
        }
      }).catch(() => {});
      this.search = newValue || "";
      this.options.page = 1
      this.getLevel();
    }
  },
  // watch: {
  //   options: {
  //     handler() {
  //       console.log(this.options);
  //       this.pageSize = this.options.itemsPerPage;
  //       this.page = this.options.page;
  //       if(this.filterData){
  //         this.filterLevel(this.selectedGradeFilter);
  //       }else if(this.searchBool){
  //         this.searchData(this.search);
  //       }
  //       else{
  //         this.getLevel();
  //       }
  //     },
  //     deep: true,
  //   },
  //   search(newValue){
  //     console.log(newValue);
  //       this.searchBool=true
  //       this.pageSize = this.options.itemsPerPage;
  //       this.page = this.options.page;
  //       this.options.page=1;
  //       this.searchData(newValue);
  //       if(newValue=="" || newValue==null){
  //         this.getLevel();
  //         this.searchBool=false;
  //       }
  //   }
  // },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    async downloadCSV(csvData, fileName) {

      const csv = Papa.unparse(csvData);


      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {

        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {

          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    async downlaodRoles() {
      const fileName = 'levels.csv';
      const response = await LevelController.getLevels();
      const levels = response.data.levels;
      this.downloadCSV(levels, fileName);
    },
    // async searchData(search){
    //   const response = await LevelController.searchLevel(
    //     this.pageSize,
    //     this.page,
    //     search,
    //   );
    //   console.log(response.data);
    //   console.log(this.searchBool);
    //   this.count = response.data.data.count;
    //   this.tableData = response.data.data.rows;
    // },
    getDate(timeStamp) {
      return getDateFromTimestamp(timeStamp);
    },
    selectSortOption(sortOption) {
      this.selectedSort = sortOption;
      switch (sortOption) {
        case "levelAsc":
          this.sortCol = "name";
          this.sortOrder = "asc";
          break;
        case "levelDesc":
          this.sortCol = "name";
          this.sortOrder = "desc";
          break;
        case "dateNewest":
          this.sortCol = "createdOn";
          this.sortOrder = "desc";
          break;
        case "dateOldest":
          this.sortCol = "createdOn";
          this.sortOrder = "asc";
          break;
        default:
          this.selectedSort = "levelAsc";
          this.sortCol = "name";
          this.sortOrder = "asc";
      }
      this.options.page = 1;
      this.getLevel();
    },
    updateData(item) {
      this.editId = item.id;
      // selected id for edit
      this.formbtnBool = true; // change update/create btn value
      this.dialog = true;
      this.name = item.name;
      this.description = item.level_description;
      this.grades = item.grades;

    },
    async getGrades() {
      const response = await GradeController.getAllGrades();
      console.log(response);
      if (response.status == 200) {
        if (response.data.grades.length > 0) {
          this.gradeData = response.data.grades;
        }
      }
      else {
        alert("Something went wrong")
      }
    },

    newLevelValue() {
      this.name = '';
      this.description = '';
      this.grades = [];
      this.formbtnBool = false

    },

    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },
    formbtnValue() {
      return this.formbtnBool === false ? "Created" : "Updated";
    },
    sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },
    async saveInputs() {
      if (this.$refs.form.validate()) {
        var res;
        this.loading = true;
        // checking case for update/create
        if (this.formbtnBool == false) {
          const response = await LevelController.createLevel({
            "name": this.name,
            "grades": this.grades,
            "audienceRoleGroupId": this.audience_role
          }


          );
          console.log(response);
          res = response;
        } else {
          const response = await LevelController.updateLevel(
            {
              "name": this.name,
              "grades": this.grades,
              "audienceRoleGroupId": this.audience_role
            },
            this.editId
          );
          res = response
          console.log(response);
        }
        // Close the dialog
        this.loading = false;
        this.formbtnBool == false;
        this.dialog = false;
        if (res.data.flag) {
          this.successDialog = true;
          this.getLevel();
        } else {
          this.errorDialog = true;
          this.errorMessage = res.data.error;
        }

      }
    },

    async deleteData(data) {
      this.dLoading = true;
      // if (data.length == 1) {
      //   // if block for single delete

      //   const response = await LevelController.deleteLevel(data[0].id);
      //   // if block for check resposne  status
      //   if (response.data.flag) {
      //     this.getLevel();
      //   }
      //   else {
      //     alert(response.data.error)
      //   }
      // }
      // else {
      //   // else block for bulk delete 
      //   var ids = "";
      //   for (var i = 0; i < data.length; i++) {
      //     ids = ids + data[i].id;
      //     if (i != data.length - 1) {
      //       ids = ids + ","
      //     }
      //   }
      var ids = [];
      data.forEach((dataDelete) => {
        ids.push(dataDelete.id)
      })
      const response = await LevelController.deleteBulkLevel(ids);
      if (response.data.flag) {
        this.deleteDialog = false;
        this.dLoading = false
        this.selected = []
      }
      else {
        alert(response.data.error)
        this.deleteDialog = false;
        this.dLoading = false
        this.selected = []
      }
      this.getLevel();
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },




    async getLevel() {
      this.page = this.options.page;
      this.pageSize = this.options.itemsPerPage;
      const params = {
        page: this.page,
        pageSize: this.pageSize,
        search: this.search,
        sortCol: this.sortCol,
        sortOrder: this.sortOrder,
        grades: this.selectedGradeFilter.join(',')
      }
      const query = "?"+(new URLSearchParams(params).toString());
      const response = await LevelController.getLevelByPagination(
      query  
    );
      // this.levelData = response.data.data;     
      if (response.status == 200) {
        this.dataTableLoading = false
        this.count = response.data.count;
        if (response.data.levels.length > 0) {
          this.tableData = response.data.levels
        }
        this.getGrades();
      }
      else {
        this.dataTableLoading = false
        alert("Something went wrong")

      }

      // console.log("level data", this.levelData.rows);
    },
    clearFilter() {
      this.appliedFilter = false;
      this.selectedGradeFilter = [];
      this.getLevel();
      // this.filterDialog = false;
      this.filterData = false;
      this.filterCount = 0;
    },
    async filterLevel(selectGrades) {
      console.log("filter function call", selectGrades);
      this.filterbtnLoading = true;
      // var gradesIds = "";;
      // selectGrades.forEach((item) => {
      //   gradesIds = gradesIds + item.id + ",";
      // })
      // gradesIds = gradesIds.slice(0, -1);
        this.selectedSort = "levelAsc";
        this.sortCol = "name";
        this.sortOrder = "asc";
        this.getLevel()
        this.filterbtnLoading = false;
        this.filterDialog = false;
        this.filterData = true;
        this.appliedFilter = true;

    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedGradeFilter.length > 0) {
        this.filterCount++;
      }
    },

    async getAudienceRoleGroups(){
      const response = await AudienceRolesController.getAudienceRoleGroups();
      if(response.status==200){
        this.audienceRoleGroups = response.data.audienceRoleGroup;
        console.log(this.audienceRoleGroups)
      }
    },

  },
  created() {
    if (!this.$store.state.role.Level.read) {
      this.$router.push('/notFound')
    }
    // Get search from URL if it exists
    const urlSearch = this.$route.query.search;
    if (urlSearch) {
      this.search = urlSearch;
    }
    // this.getLevel();
    // this.getGrades();
    this.$store.state.breadcrumb = "Levels";
  },
  mounted(){
      this.getAudienceRoleGroups();
    },
};
</script>
<style></style>