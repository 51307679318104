<template>
  <!-- author: suraj -->
  <div class="background login-view-container">
    <v-app-bar
      color="transparent"
      class="rounded-xl"
      elevation="0"
      absolute
    >
  
  
      <LogoComponent />
      
    </v-app-bar>
    <v-row align="center" justify="center" style="height: 100vh" dense>
      <v-col
        cols="12"
        lg="12"
        md="12"
        class="fill-height d-flex flex-column justify-center align-center"
      >
        <v-card width="426" height="auto">
          <div class="text-center pt-3 pl-8 pr-8">
            <v-avatar
                tile
                title="Hubble STAR"
                >
                <img
                  src="../assets/icon.svg"
                  height="48px"
                />
            </v-avatar>
            
            <v-card-title class="justify-center">
              <h3>Log In</h3>
            </v-card-title>

            <v-form v-model="valid">
              <v-text-field
                label="Enter Email Id"
                placeholder=" Enter Email Id"
                outlined
                v-model="email"
                :rules="emailRules"
                class="rounded-xl"
                :disabled="isProduction"
              ></v-text-field>

              <v-text-field
                label="Password"
                placeholder=" Password"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
                :rules="[rules.required, rules.min]"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                outlined
                v-model="password"
                class="rounded-xl"
                @keydown.enter="login"
                :disabled="isProduction"
              ></v-text-field>
              <v-btn
                @click.prevent="login"
                class="btn primary-btn mb-2 text-capitalize"
                rounded
                :disabled="!email || !password || isLoginLoading || isProduction"
                :readonly="isLoginLoading"
                large
                :loading="isLoginLoading"
                width="100%"
                height="36px"
              >
                Log In
              </v-btn>
              <v-btn   
                @click="loginWithSSO"
                class="btn mb-2 text-capitalize"
                color="blue"
                rounded
                :readonly="isLoginLoading"
                large
                :loading="isLoginLoading"
                width="100%"
                outlined
                height="36px">Login with SSO</v-btn>
            </v-form>
            <v-card-title class="justify-center secondary--text cursor mb-4">
              <h5
                class="blue--text darken-3 cursor"
                @click="forgetPassword"
              >
                Forgot Password?
              </h5>
            </v-card-title>
            <!-- <div>{{errorMessage}}</div> -->
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
import AuthService from "@/services/AuthService";
import LogoComponent from "@/components/LogoComponent.vue";
import keycloakAuthMixin from '../mixins/keycloakAuthMixin';
import keycloakService from "../services/KeycloakService";
import "../styles.css";
export default {
  components: { LogoComponent },
  name: "LoginView",
  data() {
    return {
      cnfpasswd: false,
      forgetBool: false,
      phoneNumber: "",
      ctList: false,
      valid: false,
      loading: true,
      isLoginLoading: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      show2: false,
      password: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      errorMessage:null
    };
  },
  methods: {
    async loginWithSSO() {
      console.log('login with SSO')
      keycloakService.initKeycloak(this.$onAuthenticatedCallback);
    },
    forgetPassword(){
      this.$router.push('/reset')
    },
    async login() {
      this.isLoginLoading = true;
      const response = await AuthService.login(this.email, this.password);
      // console.log("ffrg", response);
      if (response.status == 200) {
        this.$store.dispatch('setRoleId', response.data.data.role.id);
        this.$store.dispatch('setRole', response.data.data.role);
        this.$store.dispatch('setUserInfo', response.data.data);
        this.$router
          .push("/")
          .then(() => {
            // Success response
            this.isLoginLoading = false;
            //next(); // Continue with the navigation
          })
          .catch((error) => {
            // Error response
            console.error("Navigation failed:", error);
            //next(false); // Abort the navigation
          });
        this.isLoginLoading = false;
      } else {
        this.isLoginLoading = false;
      }
    },
  },
  created() {
    this.errorMessage = localStorage.getItem("errorMessage");
    console.log('error',this.errorMessage)
    if (AuthService.isAuthenticated()) {
      if (this.$route.path !== "/") this.$router.replace("/");
    }
  },
  computed: {
    isProduction() {
      return process.env.VUE_APP_IS_PRODUCTION === true;
    }
  },
  mixins: [keycloakAuthMixin]
};
</script>