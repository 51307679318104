import { render, staticRenderFns } from "./ClusterTeachersTBD.vue?vue&type=template&id=2fdc0bb7&scoped=true&"
import script from "./ClusterTeachersTBD.vue?vue&type=script&lang=js&"
export * from "./ClusterTeachersTBD.vue?vue&type=script&lang=js&"
import style0 from "./ClusterTeachersTBD.vue?vue&type=style&index=0&id=2fdc0bb7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fdc0bb7",
  null
  
)

export default component.exports