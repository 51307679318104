import AuthService from "../services/AuthService";
import keycloakService from "../services/KeycloakService";
import jwtDecode from "jwt-decode";

export default {
  data() {
    return {
      errorMessage: null,
      isAuthenticated: false,
      isLoading: false
    }
  },
  methods: {
    async initializeAuth(url) {
      console.log('initialize auth');
      try {
        this.setLoading(true);
        // const urlParams = new URLSearchParams(window.location.search);
        const path = url;
        
        const ssoQueryParams = path.split('&').map(v=>v.split('='));
        const codeParam = ssoQueryParams.find(param => param[0] === 'code');
        const stateParam = ssoQueryParams.find(param => param[0] === 'state');

        const code = codeParam ? codeParam[1] : null;
        const state = stateParam ? stateParam[1] : null;

        
        console.log('code in sso',code)
        console.log('is state present', state)
        if (code && state) {
          console.log('code in sso',code)
          await this.handleSSOCallback(code,state);
          
        } else {
          console.log('in else sso')
          await this.initializeKeycloak();
        }
      } catch (error) {
        console.error('Authentication initialization failed', error);
        this.setError("Authentication failed. Please try again.");
      } finally {
        this.setLoading(false);
      }
    },

    async initializeKeycloak() {
      const isAuthenticated = await keycloakService.initKeycloak(this.$onAuthenticatedCallback);
      if (isAuthenticated) {
        this.setAuthenticated(true);
        console.log("Keycloak authentication successful");
      } else {
        console.log("User not authenticated, redirecting to login");
        // Redirection to login is handled by keycloakService
      }
    },

    async handleSSOCallback(code,state) {
      
      console.log("Handling SSO callback with code:", code);
      const res = await this.handleSSOLogin(code,state);
      console.log('response in handleSSOCallback',res)
      if (!res) {
        console.log('no response')
        console.error("No response from handleSSOLogin");
        this.setError("Authentication failed. No response from server.");
        return;
      }

      if (res.flag) {
        this.setAuthenticated(true);
        window.location.href = "/";
        console.log("SSO authentication successful");
        
      } else if (!res.flag) {
        keycloakService.doLogout();
        localStorage.setItem("errorMessage", res.message);
        //window.location.href = "/";

        this.setError(res.message);
      } else if (res.error_description) {
        this.setError(res.error_description);
      } else {
        this.setError("Authentication failed for unknown reason");
      }
    },

    handleSSOLogin(token,state) {
      console.log('in handleSSOLogin', token)
      let res = null;
      if (!AuthService.isAuthenticated()) {
        console.log('if not authenticated')
        try {
          return AuthService.validateKeycloakToken(token,state);
        } catch (error) {
          throw new Error("An error occurred during OTP validation:", error);
        }
      } else {
        console.log("Already authenticated");
      }
      return res
    },

    setLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setAuthenticated(isAuthenticated) {
      console.log('in authenticated')
      this.isAuthenticated = isAuthenticated;
      this.errorMessage = null;
    },

    setError(message) {
      this.errorMessage = message;
      this.isAuthenticated = false;
    }
    
  }
};