<template>
    <div class="not-for-screen-render">
      <!-- Cover Page -->
      <div ref="userPerformanceReportCoverPage" :style="{
        backgroundImage: `url(${performanceReportCoverImage})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        height: '10.99in',
        width: '8.5in',
        backgroundColor: 'white'
      }">
        <div style="position:relative; top:712px; left:204px;
         font-size:25px; color:#2E5EAB;  font-style: italic; font-family: 'GraphikRegular', sans-serif; font-weight:700;">
          {{ currentDate }}
        </div>
      </div>
  
      <!-- Understand Your Report -->
       <div ref="understandYourAdvanceReport" :style="{
        backgroundImage: `url(${understandYourAdvanceReport})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        minHeight: 'calc(11in - 160px)',
        backgroundColor: 'white'
      }">
       </div>
      <!-- Index -->
      <div ref="userPerformanceReportIndex" style="display:flex; flex-direction:column; gap:24px;">
        <div style="display:flex; justify-content:center; font-size:20px; font-weight:550">Index</div>
        <!-- Section -->
        <div style="display:flex; flex-direction: column;
         border: 1px solid rgba(0, 0, 0, 0.12);
         padding:24px;
         border-radius: 8px !important;
         gap:24px">
          <div style="display:flex; flex-direction: column; margin-bottom: 16px; gap: 16px">
            <div style="display:flex;">
              <span  style="font-size:16px;">1. Understanding your report</span>
              <span :style="getDottedSeparator(16)"></span>
              <span>01</span>
            </div>
          </div>
          <!-- Section -->
          <div style="display:flex; flex-direction: column; margin-bottom: 16px; gap:16px">
            <div style="display:flex;">
              <span style="font-size:16px;">2. Performance at Glance</span>
              <span :style="getDottedSeparator(16)"></span>
              <span>02</span>
            </div>
            <!-- Sub-sections -->
            <div style="display:flex; flex-direction: column; padding-left:30px; gap: 16px;">
              <div style="display:flex; margin-bottom:4px">
                <span style="font-size:14px;">2.1. Assessment Analysis</span>
                <span :style="getDottedSeparator(14)"></span>
                <span>02</span>
              </div>
              <div style="display:flex; margin-bottom:4px">
                <span style="font-size:14px;">2.2. Achievement Marker</span>
                <span :style="getDottedSeparator(14)"></span>
                <span>03</span>
              </div>
              <div style="display:flex; margin-bottom:4px">
                <span style="font-size:14px;">2.3. Assessment completion time</span>
                <span :style="getDottedSeparator(14)"></span>
                <span>03</span>
              </div>
              <div style="display:flex; margin-bottom:4px">
                <span style="font-size:14px;">2.4. Skill-wise Score Distribution</span>
                <span :style="getDottedSeparator(14)"></span>
                <span>04</span>
              </div>
            </div>
          </div>
  
           <!-- Section -->
           <div style="display:flex; flex-direction: column; gap:16px">
            <div style="display:flex;">
              <span style="font-size:16px;">3. Detailed Report</span>
              <span :style="getDottedSeparator(16)"></span>
              <span>05</span>
            </div>
            <!-- Sub-sections --> 
            <div style="display:flex; flex-direction: column; gap:16px; padding-left:30px">
              <div v-for="(skill, index) in advanceReportSkillsSections" :key="`index-skillsSections-${index}`" 
                style="display:flex; flex-direction:column; gap: 16px; width:100%;">
                <div style="display:flex;">
                  <span style="font-size:14px;"> {{` 3.${index+1} ${skill.title}`}}</span>
                  <span :style="getDottedSeparator(14)"></span>
                  <span>{{ padWithZero(skill.pageNumber) }}</span>
                </div>
                <div style="display:flex; flex-direction:column; gap:16px;" v-if="skill.title == 'Core Skills'">
                  <div v-for="(subject, i) in advanceReportSubjectsSubSection" 
                  :key="`index-subjectsSubSection-${i}`" style="display:flex; width:100%; padding-left:30px;">
                  <span style="font-size:12px;"> {{` 3.1.${i+1} ${subject.title.split('-')[1]}`}}</span>
                  <span :style="getDottedSeparator(12)"></span>
                  <span>{{ padWithZero(subject.pageNumber) }}</span>
                </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Section -->
          <div v-if="recommendationLinks.length > 0" style="display:flex; font-size:16px; margin-top: 16px;">
            <span>4. Recommendations For you</span>
            <span :style="getDottedSeparator(16)"></span>
            <span>{{padWithZero(allSections?.at(-1)?.pageNumber)}}</span>
          </div>
        </div>
      </div>
      <div ref="wrapperTemplateUserPerformanceReport">
        <div class="page-wrapper" style="
          display:flex;
          flex-direction:column;
          background-color: white;
          height:100%;
          font-family: 'GraphikRegular', sans-serif;
          min-height: 10.99in;
          max-height: 11in;
          position: relative;
        "> 
        
        <!-- Header UserPerformanceReport-->
          <div class="pdf-header" style="margin: 16px 16px 0px; width:100%; display:flex; justify-content:end; background-color: white; padding-right:16px;">
            <img style="
            height:48px;
            width: 200px;" 
            :src="userPerformanceReportHeaderImage" alt=""/>
          </div>
  
        <!-- Page Content UserPerformanceReport -->
          <div style="margin:16px; flex: 1; height:100%;  overflow:auto; font-family: 'GraphikRegular', sans-serif;" class="content">
            userPerformanceReportContent
          </div>
  
        <!-- Footer UserPerformanceReport -->
          <footer class="pdf-footer" style="
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: white;
          ">
            <div style="display: flex; justify-content:space-between;">
              <div style="padding: 8px 32px; display: grid; place-items: center;">userPerformanceReportPageNumber</div>
              <div>
                <img style="height: 64px; width: 200px" :src="userPerformanceReportFooterImage"/>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div ref="userPerformanceReport1" class="user-performance-report" 
        style="display: flex; 
        flex-direction:column;
        padding:0 16px;
        gap: 16px;
        ">
          <div style="display: flex; flex-direction:row; flex-grow: 1; align-items: stretch; justify-content: center; width:100%; gap: 16px; margin-bottom: 26px;">
            <div style="width:100%; display: flex; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); padding:14px;">
              <v-avatar v-if="userInfo?.proctoringPic" size="69">
                <img width="69px" height="69px" :src="userInfo?.proctoringPic" alt="">
              </v-avatar>
              <v-avatar v-else size="69">
                <img width="69px" height="69px" :src="userAvatarPlaceholder"/>
              </v-avatar>
              <div style="display:flex; flex-direction:column; justify-content: space-evenly; margin-left:32px;">
                <div style="font-size:16px; font-weight:400;">{{ userInfo?.name }}</div>
                <!-- <div style="display: flex; align-items:center">
                  <div style="font-size:14px; font-weight:400;">IIEP Rank</div>
                  <div
                  style="
                  height: 32px;
                  margin-left:8px;
                  display:flex;
                  justify-content:center;
                  align-items:center;
                  border-radius:16px; 
                  background-color:#E1E8F5;
                  color:#3F41D1;
                  padding: 6px 16px 8px;
                  font-size:12px;
                  font-weight:400;"> 
                    #3424
                  </div>
                </div> -->
              </div>
            </div>
            <div style="
              width:100%;
              font-size:14px;
              font-weight:400;
              display:flex;
              flex-direction:column;
              justify-content: space-evenly;
              border-radius: 8px !important; 
              border: 1px solid rgba(0, 0, 0, 0.12);
              padding:16px 20px">
              <div style="display:flex; flex-direction: row; align-items: center; gap:32px">
                <div style="width:25%; display:flex; justify-content:space-between;">
                  <span>
                  Email
                  </span>
                  <span>
                    -
                  </span>
                </div>
                <div>
                  {{ userInfo?.email }}
                </div>
              </div>
              <div style="display:flex; flex-direction: row; align-items: center; gap:32px">
                <div style="width:25%; display:flex; justify-content:space-between;">
                  <span>
                    Mobile
                  </span>
                  <span>
                    -
                  </span>
                </div>
                <div>
                  {{ userInfo?.phone }}
                </div>
              </div>
            </div>
          </div>
          <div style="display:flex; flex-direction:column; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); padding: 20px; gap:8px; margin-bottom: 16px;">
            <div style="display:flex; flex-direction:row; align-items:center; gap:16px">
              <div style="display:flex; flex-direction:row; justify-content:space-between; width:25%;">
                <div style="font-size:16px; font-weight:400;">Level</div>
                <div style="margin-right:16px;">-</div>
              </div>
              <div style="width:70%; display:flex; flex-direction:row; align-items:center;">
                <div v-for="(level, index) in userInfo?.levels" :key="`userPerformanceReport1-levels-${index}`"
                  style="
                  height: 32px;
                  display:flex;
                  justify-content:center;
                  align-items:center;
                  border: 0.5px solid #3F41D1; 
                  border-radius:16px; 
                  background-color:#E1E8F5;
                  color:#3F41D1;
                  padding: 6px 16px 8px;
                  font-size:12px;
                  font-weight:400;">
                    {{  level }}
                </div>
              </div>
            </div>
            <div style="display:flex; flex-direction:row; align-items:center; gap:16px">
              <div style="display:flex; flex-direction:row; justify-content:space-between; width:25%;">
                <div style="font-size:16px; font-weight:400;">Subjects/Skills</div>
                <div style="margin-right:16px;">-</div>
              </div>
              <div style="max-width:70%; display: flex; flex-wrap: wrap;">
                <div v-for="(subject, index) in userInfo.skillsToShow" :key="`userPerformanceReport1-skillsToShow-${index}`"
                style="
                height: 32px;
                display:flex;
                justify-content:center;
                align-items:center;
                border: 0.5px solid #3F41D1; 
                border-radius:16px; 
                background-color:#E1E8F5;
                color:#3F41D1;
                padding: 6px 16px 8px;
                margin: 0px 8px 8px 0px;
                font-size:12px;
                font-weight:400;">
                  {{ subject }}
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 16px;">
            <h4 style="font-size:20px; font-weight: 600; margin-bottom: 26px;">Performance at a Glance</h4>
            <div style="display:flex; flex-direction:column; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); padding:16px; gap:8px;">
              <h5 style="font-size: 18px; font-weight: 500;">Assessment Analysis</h5>
              <div style="position: relative; display:flex; justify-content:center; align-items:center;">
                <!-- Radial Bar Chart -->
                <VueApexCharts
                  type="radialBar"
                  :options="overallAssessmentChartOptions"
                  :series="overallAssessmentChartData"
                  :width="600"
                  :height="600"
                />
            
                <!-- Overlay Labels -->
                <div
                  v-for="(value, index) in overallAssessmentChartData"
                  :key="`userPerformanceReport1-overallAssessmentChartData-${index}`"
                  :style="getLabelStyle(index)"
                  style="pointer-events: none;"
                >
                  {{ value }}%
                </div>
              </div>
            </div>
          </div>
      </div>
      <div ref="userPerformanceReport2" class="user-performance-report" style="padding:16px;gap: 16px;"> 
        <div style="width:100%; display: flex; flex-direction:column; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); padding:16px;  margin-bottom: 20px;">
          <h5 style="font-size: 18px; font-weight: 550;">Achievement Marker</h5>
          <div style="
          font-size: 12px;
          font-weight: 400;
          padding-bottom: 8px;
          color:#666666">
            Indicates your score and the highest score overall
          </div>
          <div style="display: flex; justify-content:center;">
            <div style="display: flex; flex-direction:column; justify-content:center; width:90%;">
              <div style="display: flex; width:26px; align-items:center; flex-direction:column; gap: 4px; position:relative; transform: translateX(-13px)" 
              :style="`left:${getPercentage(scoreProfile?.userScore, 10)}%`">
                <div style="font-size: 12px;">
                  {{ scoreProfile?.userScore }}
                </div>
                <img
                :src="yourScoreImage"
                alt="pass icon"
                style="width:26px; height:24px; transform:rotate(180deg);"
                />
              </div>
              <div style="width:100%; height: 40px; background-color: #E0E0E0; border-radius:20px;">
                <div style="position:relative; left:0;height: 40px; background-color: #06C270;"
                :style="`
                width:${getPercentage(scoreProfile?.userScore, 10)}%;
                border-radius: ${scoreProfile?.userScore == 10? '20px':'20px 0px 0px 20px'};
                `">
                </div>
              </div>
              <div style="display: flex; width:26px; align-items:center; flex-direction:column; gap: 4px; position:relative; transform: translateX(-13px)" 
              :style="`left:${getPercentage(scoreProfile?.highestScore, 10)}%`">
                  <img
                  :src="highestScoreImage"
                  alt="pass icon"
                  style="width:26px; height:24px;"
                  />
                  <div style="font-size: 12px;">
                    {{scoreProfile?.highestScore}}
                  </div>
                </div>
            </div>
          </div>
          
          <div style="display: flex; justify-content: center; align-items: center; gap:16px;">
            <div style="display: flex; align-items:center">
              <img
                :src="highestScoreImage"
                alt="pass icon"
                style="width:26px; height:24px; margin-right:16px"
              />
              <div style="font-size: 14px;">Highest Score</div>
            </div>
            <div style="display: flex; align-items:center">
              <img
              :src="yourScoreImage"
              alt="pass icon"
              style="width:26px; height:24px; margin-right:16px"
              />
              <div style="font-size: 14px;">Your Score</div>
            </div>
          </div>
        </div>
        <div style="font-size:14px; width:100%; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); padding:16px;">
          <v-row>
            <v-col cols="6" style="height:100%">
              <div style="display:flex; flex-direction:column; justify-content:space-between; height:260px">
                <div>
                  <h5 style="font-size: 18px; font-weight: 550;">Assessment completion time</h5>
                  <div style="
                  font-size: 12px;
                  font-weight: 400; ; 
                  padding-bottom: 8px;
                  color:#666666">
                    Indicates your completion time vs average time
                  </div>
                </div>
                <div style="display:flex; flex-direction:column; gap:12px">
                  <div style="display:flex; gap:8px;">
                    <div style="background-color:#FFB86B; height:16px; width:24px; border-radius:4px;"></div>
                    <div>Average Time Taken (in mins)</div>
                  </div>
                  <div style="display:flex; gap:8px;">
                    <div style="background-color:#06C270; height:16px; width:24px; border-radius:4px;"></div>
                    <div>Your Time Taken (in mins)</div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div style="display: flex; gap:24px; justify-content: end; padding-right:32px">
                <div style="display: flex; gap:24px; justify-content: end;">
                  <div style="width:40px; height: 260px; background-color: #E0E0E0; border-radius:20px;
                  display:flex; flex-direction:column; align-items:center; justify-content:end;">
                    <div style="position:relative; background-color: #FFB86B;
                    border-radius: 0px 0px 20px 20px;"
                    :style="`height:${calculatePosition(scoreProfile?.averageTimeTaken, scoreProfile?.maximumTime/60)}%`">
                    <div style="
                    position:relative;
                    top: -25px;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    height:40px; width:40px; 
                    border-radius:20px; 
                    border: 2px solid #C37C2F; background-color:#FFD4B2;">{{ scoreProfile?.averageTimeTaken }}</div>
                    </div>
                  </div>
                </div>
                <div style="width:40px; height: 260px; background-color: #E0E0E0; border-radius:20px;
                display:flex; flex-direction:column; align-items:center; justify-content:end;">
                  <div style="position:relative; background-color: #06C270;
                  border-radius: 0px 0px 20px 20px;"
                  :style="`height:${calculatePosition(scoreProfile?.userTimeTaken, scoreProfile?.maximumTime/60)}%;`">
                    <div style="
                    position:relative;
                    top: -25px;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    height:40px; width:40px; 
                    border-radius:20px;
                    border: 2px solid #00A755; 
                    background-color:#6FD3A6;">
                      {{ scoreProfile?.userTimeTaken }}
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        
        </div>
        <div style="width:100%; display: flex;justify-content: space-between; align-items: center; 
          border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); 
          margin:16px; margin-top:200px; padding:16px;">
            <div style="position: relative; transform: rotate(180deg); bottom:36px; height:20px; width:22.49px;">
              <img src="../../src/assets/userPerformanceQuote2.svg" alt="">
            </div>
            <div style="display: flex; flex-direction:column; gap:4px;">
              <p style="font-size: 16px; color: #666666; font-weight: 600;">
                Education is the most powerful weapon which you can use to change the world.
              </p>
              <div style="font-size: 14px; margin-left:auto; font-weight:500; color:#B68C00;">— Pele</div>
            </div>
            <div style="position: relative; top: 36px; height:20px; width:22.49px;">
              <img src="../../src/assets/userPerformanceQuote2.svg" alt="">
            </div>
        </div>
      </div>
      <div ref="userPerformanceReport3" class="user-performance-report" style="display: flex; flex-direction:column; justify-content:space-between; padding:16px;gap: 16px;">
          <div style="width:100%; display: flex; flex-direction:column;
          border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); 
          padding:16px;">
            <div>
              <h5 style="font-size: 18px; font-weight: 550;">Skill-wise Score Distribution</h5>
            </div>
            <div style="display:flex; justify-content:center; align-items:center;">
              <VueApexCharts
              type="radar"
              :options="scoreDistributionChartOptions"
              :series="scoreDistributionChartData"
              :height="500"
              :width="600"
              />
            </div>
            <div style="display:flex; justify-content:center; align-items: center;">
              <div style="display:flex; flex-direction:row; gap:8px">
                <div style="display:flex; flex-direction:column; justify-content:center;">
                  <div style="background-color: #06C27099; width:24px; height: 16px; border-radius: 4px;"></div>
                </div>
                <div>Achieved</div>
              </div>
            </div>
          </div>
        <div style="width:100%; display: flex; flex-direction:row; 
        justify-content: space-between; align-items: center; 
        border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); 
        margin:16px; margin-top:100px; padding:16px;">
          <div style="position: relative; transform: rotate(180deg); bottom:36px; height:20px; width:22.49px;">
            <img src="../../src/assets/userPerformanceQuote1.svg" alt="">
          </div>
          <div style="display: flex; flex-direction:column; gap:4px">
            <p style="font-size: 16px; font-weight: 600; color: #666666;">
              Success is no accident. It is hard work, perseverance, learning, studying, and sacrifice.
            </p>
            <div style="margin-left:auto; font-weight:500; color:#5F347B;">— Nelson Mandela</div>
          </div>
          <div style="position: relative; top: 36px; height:20px; width:22.49px;">
            <img src="../../src/assets/userPerformanceQuote1.svg" alt="">
          </div>
        </div>
      </div>
      <div v-for="(skill,index) in skillsAnalysisForAdvanceReport" :key="`userPerformanceReport1-skillsAnalysis-${index}`" 
      :data-section-title="`${skill.isCoreSkill ? 'Subject-': ''}${skill.skillName}`" 
      :aria-label="`skills-${skill.skillName}`" 
      style="display: flex; flex-direction:column; 
      margin:0px 16px; padding:8px 0px; gap:16px" class='user-performance-report'>
        <!-- Skills Title Card Page -->
        <!-- <div v-if="skill.isTitleCard" style="height:100%; display:flex; flex-direction: column; align-items: end;"> -->
        <div v-if="skill.isTitleCard" style="height:8in; display:flex; flex-direction: column; align-items: end; margin-left:-16px;
        background-size: contain; background-repeat: no-repeat; background-size: 100% 100%;"
        :style="{ backgroundImage: `url(${skillTitleCardBackgroundImage})` }">
          <div style="display: flex; align-items:center; height:8in;">
            <div style="width:392px; min-height: 248px; display:flex; flex-direction:column;justify-content:center; text-align: center;
            padding:36px 20px; margin-right:32px; border-radius:8px;"
              :style="[skill.backgroundColor, skill.borderGradient]">
              <h3 style="font-size: 24px; font-weight: 600; color: #212121; margin-bottom: 24px;">{{ skill.skillName }}</h3>
              <div style="
              word-wrap: break-word;            
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.0025em;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #666666;">{{ skill.text }} </div>
            </div>
          </div>
          
        </div>
        <!-- Skills/Subjects Template -->
        <div style="width:100%; padding: 16px; border-radius: 8px !important;
        border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18);" 
        v-else-if="skill?.skillName !== 'Psychometry'">
          <div v-if="skill.isFirstSkillsPage">
            <div style="font-size:16px; font-weight:600; margin-bottom: 16px;">{{ skill.skillName }}</div>
            <div v-if="skill?.isCoreSkill" style="display: flex; gap: 40px; padding: 16px; background: #1B72E81F; border: 1px solid #ccc; border-radius: 8px; margin-bottom: 16px;">
              <div style="display:flex; justify-content: space-between; flex: 1">
                <span>Rank</span>
                <div style="display: flex; gap: 20px;">
                  <span> - </span>
                  <span> {{ skill?.rank == "Not Eligible" ? skill?.rank : `#${skill?.rank}`}} </span>
                </div>
              </div>
              <div style="display:flex; justify-content: space-between; flex: 1">
                <span>League</span>
                <div style="display: flex; gap: 20px;">
                  <span> - </span>
                    <div style="display: flex; flex-direction: row;">
                      <img v-if="['gold','silver', 'bronze', 'bonze'].includes(skill?.league?.toLowerCase())"
                      style="height:16px; width:20px; margin-right:8px;"
                      :src="getLeagueIcon(skill?.league)"/>
                      <div>
                        {{ skill?.league }}
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div style="display: flex; gap: 40px; padding: 16px; background: #B27CC433; border: 1px solid #ccc; border-radius: 8px; margin-bottom: 16px;">
              <div style="display:flex; justify-content: space-between; flex: 1">
                <span>Total Questions</span>
                <div style="display: flex; gap: 20px;">
                  <span> - </span>
                  <span> {{ skill?.questionCounts?.total }} </span>
                </div>
              </div>
              <div style="display:flex; justify-content: space-between; flex: 1">
                <span>Questions Attempted</span>
                <div style="display: flex; gap: 20px;">
                  <span> - </span>
                  <span> {{ (skill?.questionCounts?.correct + skill?.questionCounts?.incorrect) }} </span>
                </div>
              </div>
            </div>
            <div style="display: flex; gap: 40px; padding: 16px; background: #06C27024; border: 1px solid #ccc; border-radius: 8px; margin-bottom: 16px;">
              <div style="display:flex; justify-content: space-between; flex: 1">
                <span>Correct Answers</span>
                <div style="display: flex; gap: 20px;">
                  <span> - </span>
                  <span> {{ skill?.questionCounts?.correct }} </span>
                </div>
              </div>
              <div style="display:flex; justify-content: space-between; flex: 1">
                <span>Incorrect Answers</span>
                <div style="display: flex; gap: 20px;">
                  <span> - </span>
                  <span> {{ skill?.questionCounts?.incorrect }} </span>
                </div>
              </div>
            </div>
            <div style="width:100%; display: flex; flex-direction:column; 
            border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); 
            padding:8px;">
              <div style="color: #212121; font-size:14px; font-weight:600; margin-bottom: 8px;">Strengths & Areas of Improvement</div>
              <div style="color: #666666; font-size:10px; font-weight:400;">Displays key strengths and improvement areas based on assessment results.</div>
              <div style="display:flex; justify-content:center; align-items:center; width:100%">
                <VueApexCharts
                type="donut"
                :options="donutChartOptions"
                :series="[skill?.totalStrengths, skill?.totalWeaknesses]" 
                :height="500"
                :width="500"
                />
              </div>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; gap: 16px; margin-top: 16px">
            <h4 v-if="skill?.strengths?.length > 0" style="font-weight:600; font-size:18px; color: #212121;">
              Strengths
            </h4>
            <div v-if="skill?.strengths?.length > 0" style="display: flex; flex-direction: column; margin-bottom: 16px; gap: 8px;">
              <div v-for="(topic, index) in skill?.strengths" :key="`strengths-${index}`" style="width: 100%; height:36px; display: flex;  border-radius: 15px; background-color: #06C27023;">
                <div style="display: grid; place-items: center;  background-color:#06C2704C; width:36px; border-radius:18px; border: 0.75px solid #007C2A; font-weight:350 !important; font-size:10px;">
                  {{ skill?.strengthsInLastPage + index + 1}}
                </div>
                <div style="display: flex; justify-content:center; align-items:center; margin:4px 36px 4px 8px; font-weight:400; font-size:12px;">
                  {{topic}}
                </div>
              </div>
            </div>
            <h4 v-if="skill?.weaknesses?.length > 0" style="font-weight:600; font-size:18px; color: #212121;">
              Areas of Improvement
            </h4>
            <div v-if="skill?.weaknesses?.length > 0" style="display: flex; flex-direction: column; margin-bottom: 16px; gap: 8px;">
              <div v-for="(topic, index) in skill?.weaknesses" :key="`weaknesses-${index}`" style="width: 100%; height:36px; display: flex; border-radius: 15px; background-color: #F77CAE23;">
                <div style="display: grid; place-items: center;  background-color:#F77CAE4C; width:36px; border-radius:18px; border: 0.75px solid #C54A7C; font-weight:400; font-size:10px;">
                  {{ skill?.weaknessesInLastPage + index + 1 }}
                </div>
                <div style="display: flex; justify-content:center; align-items:center; margin:4px 36px 4px 8px; font-weight:350!important; font-size:12px;">
                  {{topic}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Psychometry Template -->
        <div v-else-if="skill?.skillName == 'Psychometry'" style="display: flex; flex-direction:column; 
        border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18);
        padding:16px; gap:16px">
          <div v-if="skill.isFirstSkillsPage" style="display: flex; flex-direction:column; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); 
          padding:8px 16px; background-color: #B27CC433; border: 1px solid #E0E0E0">
            <v-container>
              <v-row>
                <v-col cols="6" style="display: flex; justify-content: space-between;">
                  <div>
                    Total Questions
                  </div>
                  <div style="margin-right:16px">
                    -  {{ skill?.questionCounts?.total }}
                  </div>
                </v-col>
                <v-col cols="6" style="display: flex; justify-content: space-between;">
                  <div>
                    Questions Attempted
                  </div>
                  <div style="margin-right:16px">
                    -  {{ skill?.questionCounts?.correct + skill?.questionCounts?.incorrect }}
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div v-if="skill.isFirstSkillsPage" style="margin: 0px 8px 16px 0px;">
            <div style="color:#212121; font-weight:600; margin-bottom:12px;">Legend</div>
            <div style="margin-bottom: 16px;">The given rating is out of 4</div>
            <div style="display: flex; margin-bottom: 8px;">
              <div style="display: flex; justify-content: start; gap:16px; width:50%;">
                <div style="width: 20px; height:20px; border-radius: 10px; font-size: 12px;" :style="selectBackgroundColor(4, true)"></div>
                <div>Highly recommended</div>
              </div>
              <div style="display: flex; justify-content: start; gap:16px; width:50%;">
                <div style="width: 20px; height:20px; border-radius: 10px; font-size: 12px;" :style="selectBackgroundColor(3, true)"></div>
                <div>Recommended</div>
              </div>
            </div>
            <div style="display: flex; margin-bottom: 8px;">
              <div style="display: flex; justify-content: start; gap:16px; width:50%;">
                <div style="width: 20px; height:20px; border-radius: 10px; font-size: 12px;" :style="selectBackgroundColor(2, true)"></div>
                <div>Development needed</div>
              </div>
              <div style="display: flex; justify-content: start; gap:16px; width:50%;">
                <div style="width: 20px; height:20px; border-radius: 10px; font-size: 12px;" :style="selectBackgroundColor(1, true)"></div>
                <div>Not recommended at this time</div>
              </div>
            </div>
          </div>
          <div v-for="(strandData, strandName, index) in skill?.psychometryAnalysis?.strands" :key="`strands-${index}`"
          style="display:flex; flex-direction:column; gap:16px; border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 2px solid rgba(0, 0, 0, 0.18); padding:8px; margin-top: 8px"
          > 
            <div style="font-weight:600; padding:8px">{{ strandName }}</div>
            <div style="display: flex; flex-wrap: wrap;">
              <div v-for="(subStrand, index) in strandData.subStrands" :key="index"
                style="display:flex;
                height: 32px;
                justify-content:center;
                align-items:center;
                height:32px;
                border-radius:16px; 
                background-color:#E1E8F5;
                color:#3F41D1;
                padding: 6px 16px 8px;
                margin: 4px;
                margin-left:8px;
                font-size:12px;
                font-weight:400;" >
                  {{subStrand}}
                </div>
            </div>
            <div style="display: flex; justify-content:space-between">
              <div style="font-weight:600; padding:8px">Rating and Feedback</div>
              <div style="height:32px; width:56px; border-radius:28px; display:grid; place-items: center" :style="selectBackgroundColor(strandData?.normalizedScore, true)">{{ strandData.normalizedScore }}</div>
            </div>
            <div style="border-radius: 8px; padding:16px;" :style="selectBackgroundColor(strandData?.normalizedScore, false)">
              {{ strandData?.feedback }}
            </div>
          </div>
        </div>
      </div>
    <!-- Recommendation Links template -->
     <section v-if="recommendationLinks?.length > 0">
      <div v-for="(page, pageIndex) in recommendationLinks" :ref="`recommendationLinksTemplate-${pageIndex}`" :key="pageIndex">
        <div style="display:flex; flex-direction:column; gap:16px; font-size:20px; font-weight:600;">
          <div v-if="pageIndex == 0">Recommendations For you</div>
          <div v-for="(skill, key) in page" :key="key" 
          style="display: flex; flex-direction: column; gap:12px; padding:16px;
          border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); 
          border-bottom: 2px solid rgba(0, 0, 0, 0.18);">
  
            <div v-if="!skill?.isSkillContinued && (skill.recommendedReadings.videos?.length > 0 || skill.recommendedReadings.referenceDocs?.length > 0)" style="border-radius: 8px !important; border: 1px solid rgba(0, 0, 0, 0.12); 
            border-bottom: 2px solid rgba(0, 0, 0, 0.18); display:flex; align-items:center;
            height:36px; padding: 4px 12px; font-size: 14px; font-weight:600"
            :style="skill.backgroundColor"> 
              {{ skill.skillName }}
            </div>
  
            <div v-if="skill.recommendedReadings.videos?.length > 0" style="display: contents;">
  
              <div  style="padding-left:12px; font-size: 12px; font-weight:600">
                Videos
              </div>
              <div v-for="(video, videoIndex) in skill.recommendedReadings.videos" :key="`videos-${videoIndex}`"
              style="width: 100%; padding-left: 12px; font-size: 12px; font-weight: 400; word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">
                {{ video.title }}
                <span style="text-decoration:underline; color:#0A67C4">
                  ({{ encodeUnderscores(video.url) }})
                </span>
              </div>
            </div>
  
            <div v-if="skill.recommendedReadings.referenceDocs?.length > 0" style="display:contents;">
  
              <div  style="padding-left:12px; font-size: 12px; font-weight:600">
                Reference Documents
              </div>
  
              <div v-for="(doc, docIndex) in skill.recommendedReadings.referenceDocs" :key="`videos-${docIndex}`"
              style="width: 100%; padding-left: 12px; font-size: 12px; font-weight: 400; word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">
                {{ doc.title }}
                <span style="text-decoration:underline; color:#0A67C4">
                  ({{ doc.url }})
                </span>
              </div>
  
            </div>
          </div>
        </div>
      </div>
     </section>
  </div>
  </template>
  <script>
  import CampaignController from "../controllers/CampaignController";
  import html2pdf from "html2pdf.js";
  import { db, storage } from "../firebase";
  import { collection, doc, getDocs, query, where, updateDoc, serverTimestamp } from "firebase/firestore";
  import { getDownloadURL, uploadBytes, uploadBytesResumable, ref as storageRef } from "firebase/storage";
  import VueApexCharts from "vue-apexcharts";
  import { cloneDeep } from "lodash";
  export default {
      name: "UserPerformanceReport",
      props: {
        userId: {
          type: String,
          required: true,
          default: '',
        },
        campaignId: {
          type: String,
          required: true,
          default: ''
        }
      },
      components: {
          VueApexCharts
      },
      data() {
          return{
            advanceReport: null,
            basicReport: null,
            advanceReportBytesTransferred: 0,
            basicReportBytesTransferred: 0,
            totalPayloadSize: 0,
            retryUploadAttempts: 1,
            advancedReportURL: '',
            basicReportURL: '',
            currentDate: '',
            totalPages: 0,
            userInfo: {},
            answerProfile: [],
            scoreProfile: {},
            skillsAnalysisForAdvanceReport: [],
            skillsAnalysisForBasicReport: [],
            recommendationLinks: [],
            scoreDistributionChartData: [
              {
                name: 'Screening/Mains Score',
                data:[],
              },
            ],
            scoreDistributionChartOptions: {
              chart: {
                width: 600,
                height: 500,
                type: 'radar',
                toolbar: {
                  show: false,
                },
                animations: {
                  enabled: false,
                },
              },
              title: {
                text: '',
              },
              xaxis: {
                categories: [],
                labels: {
                  show: true,
                  style: {
                    colors: ["#000000DE","#000000DE","#000000DE","#000000DE","#000000DE","#000000DE", "#000000DE"],
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                }
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["#06C270"],
                dashArray: 0,
                lineCap: 'butt',
              },
              markers: {
                size:0,
              },
              fill: {
                color: ["#06C270"],
                opacity: 0.20,
              },
              dataLabels: {
                enabled: true,
                background:{
                  enabled: false,
                },
                style: {
                  colors: ["#06C270"],
                  fontWeight: 400,
                },
              },
              yaxis: {
                max: 10,
                min: 0,
                tickAmount: 5,
                labels: {
                  show: true,
                  formatter: function(val) {
                    return val
                  },
                  style: {
                    colors: "#666666",
                    backgroundColor: "#FBDDC2",
                    fontWeight: 350,
                  },
                }
              },
            },
            overallAssessmentChartData: [], // [Total Questions, Attempted, Correct]
            overallAssessmentChartOptions: {
              chart: {
                type: "radialBar",
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: "20%", // Adjust the inner circle size
                  },
                  track: {
                    background: "#D3D3D3", // Color for the unfilled track
                    strokeWidth: 0,
                    opacity: 0.8,
                    margin: 8,
                  },
                  dataLabels: {
                    show: true, // Enable data labels
                    name: {
                      show: false, // Hide names (e.g., "Correct", "Attempted")
                    },
                    value: {
                      show: true, // Always show percentage values
                      fontSize: "14px", // Adjust font size for better visibility
                      formatter: function (val) {
                        return `${val}%`; // Display the percentage
                      },
                    },
                  },
                },
              },
              colors: ["#1B72E8", "#B27CC4", "#06C270"], // Colors for Total, Attempted, Correct
              labels: ["Total Questions", "Attempted", "Correct"],
              legend: {
                show: true,
                position: "right", // Legend aligned to the right
                horizontalAlign: "center",
                fontSize: '14px',
                markers: {
                  shape: "square",
                },
              },
            },
            donutChartOptions: {
              chart: {
                type: 'donut',
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: '55%',
                    offsetX: -30,
                    hollow: {
                      size: '45%',
                    },
                    labels: {
                      show: true,
                    },
                  },
                },
              },
              dataLabels: {
                enabled: true,
                formatter: function (val) {
                  return Number.isInteger(val)? `${val.toFixed(0)}%` : `${val.toFixed(2)}%`;
                },
                style: {
                  fontSize: '14px',
                  fontWeight: 400,
                  colors: ['#212121']
                },
                dropShadow: {
                  enabled: false
                }
              },
              legend: {
                show: true,
                position: 'right',
                fontSize: '14px',
                fontWeight: 400,
                horizontalAlign: 'center',
                markers: {
                  width: 20,
                  height: 12,
                  shape: 'square',
                  radius: 1,
                },
                labels: {
                  colors: '#212121',
                  useSeriesColors: false
                },
                itemMargin: {
                  horizontal: 10,
                  vertical: 5
                }
              },
              stroke: {
                show: false
              },
              colors: ['#06C270', '#F77CAE'],
              labels: ['Strengths', 'Areas of Improvement'],
            },
            skillTitleCardMap:{
              'Core Skills': {
                skillName: 'Core Skills',
                text:'Core skills are essential abilities for teaching, that reflect a strong understanding and mastery of the teaching subject.',
                backgroundColor: {
                    backgroundColor: "#DCF6EB",
                  },
                borderGradient: {
                    border: "4px solid #007C2A",
                    borderRadius: "8px",
                    borderImage: "linear-gradient(261.89deg, #007C2A 0%, #029745 37.2%, #04AA58 62.29%, #05B866 81.08%, #06C270 94.9%)",
                    borderImageSlice: 1,
                  },
                isTitleCard: true,
              },
              'Communication Skills': {
                skillName: 'Communication Skills',
                text: 'Communication skills involve the ability to explain concepts clearly, listen actively, provide feedback, and create an engaging environment for learning.',
                backgroundColor: {
                    backgroundColor: "#DFEBFC",
                  },
                borderGradient: {
                    border: "4px solid #002298",
                    borderRadius: "8px",
                    borderImage: "linear-gradient( 261.89deg, #002298 0%, #0040B6 37.2%, #0054CA 62.29%, #075ED4 81.08%, #1B72E8 94.9%)",
                    borderImageSlice: 1,
                  },
                isTitleCard: true,
              },
              'Digital Literacy': {
                skillName: 'Digital Literacy',
                text: 'Digital literacy in teaching is the ability to use technology effectively to enhance learning and support students in using digital tools responsibly.',
                backgroundColor: {
                    backgroundColor: "#FEEDF4",
                  },
                borderGradient: {
                    border: "4px solid #A72C5E",
                    borderRadius: "8px",
                    borderImage: "linear-gradient( 261.89deg, #A72C5E 0%, #BB4072 37.2%, #CF5486 62.29%, #D95E90 81.08%, #F77CAE 94.9%)", 
                    borderImageSlice: 1,
                  },
                isTitleCard: true,
              },
              'Pedagogy':{
                skillName: 'Pedagogy',
                text: 'Pedagogy refers to the art and science of teaching, encompassing the methods, strategies, and practices used to deliver knowledge, foster understanding, and promote effective learning among students.',
                backgroundColor: {
                    backgroundColor: "#F4EDF7",
                  },
                borderGradient: {
                    border: "4px solid #622C74",
                    borderRadius: "8px",
                    borderImage: "linear-gradient( 261.89deg, #622C74 0%, #764088 37.2%, #8A549C 62.29%, #945EA6 81.08%, #B27CC4 94.9%)",
                    borderImageSlice: 1,
                  },
                isTitleCard: true,
              },
              'Psychometry':{
                skillName: 'Psychometry',
                text: 'Psychometry uses psychological assessments to measure an individual’s abilities, traits, and behavior, helping inform decision-making and personal development.',
                backgroundColor: {
                    backgroundColor: "#FFF3E5",
                  },
                borderGradient: {
                    border: "4px solid #AF5600",
                    borderRadius: "8px",
                    borderImage: "linear-gradient(261.89deg,#AF5600 0%,#C36A0B 37.2%,#D77E1F 62.29%,#E18829 81.08%,#FFA647 94.9%)",
                    borderImageSlice: 1,
                  },
                isTitleCard: true,
              }
            },
            allSections:[],
            advanceReportSkillsSections: [],
            advanceReportSubjectsSubSection:[],
            basicReportSkillsSections: [],
            basicReportSubjectsSubSection: [],
            yourScoreImage: require('@/assets/user-score.svg'),
            highestScoreImage: require('@/assets/highest-score.svg'),
            performanceReportCoverImage: require('@/assets/CoverPage.svg'),
            understandYourAdvanceReport:  require('@/assets/understandYourAdvanceReport.svg'),
            skillTitleCardBackgroundImage: require('@/assets/SkillTitleCardBackground.svg'),
            userPerformanceReportHeaderImage: require('@/assets/performanceReportHeader.svg'),
            userPerformanceReportFooterImage: require('@/assets/performanceReportFooter.svg'),
            userAvatarPlaceholder: require('@/assets/user.png'),
            goldLeagueIcon: require('@/assets/gold.svg'),
            silverLeagueIcon: require('@/assets/silver.svg'),
            bronzeLeagueIcon: require('@/assets/bronze.svg'),
          }
      },
      async created(){
          
      },
      async mounted(){
        try{
          console.log('PDF generator mounted');
          console.time('performanceReportData')
          await this.getPerformanceReport();
          console.timeEnd('performanceReportData')
          console.time('reports generation');
          await this.generateAllReports();
          console.timeEnd('reports generation');
          this.$emit('generation-completed');
        //   console.time('upload reports');
        //   await this.uploadAllReports();
        //   console.timeEnd('upload reports');
        }
        catch(error){
          console.error('PDF generator Failed', error);
          this.$emit('generation-failed')
        }
      },
      methods: {
        getLabelStyle(index) {
          const positions = [
            { top: "25%", left: "25.5%" }, // Position for Total Questions
            { top: "33%", left: "29%" }, // Position for Attempted
            { top: "40%", left: "32.5%" }, // Position for Correct
          ];
          return {
            position: "absolute",
            zIndex: 100, // Ensure the label appears above the chart
            top: positions[index].top,
            left: positions[index].left,
            transform: "translate(-50%, -50%)",
            fontSize: "14px",
            color: index==0?"white": "#FFFFFFF",
            fontWeight: 500,
          };
        },
        wrapPageContent(content, pageNumber, isIndexPage) {
          const wrapperTemplate = this.$refs.wrapperTemplateUserPerformanceReport.innerHTML;
          let wrappedPageContent = wrapperTemplate.replace('userPerformanceReportContent', content);
          wrappedPageContent = wrappedPageContent.replace('userPerformanceReportPageNumber', isIndexPage?"":pageNumber)
          return wrappedPageContent;
        },
        async generateAllReports(){
            await Promise.all([
                this.generateAdvanceReport(),
                //  this.generateBasicReport()
            ])
        },
        async generateAdvanceReport() {
          try {
            console.time('advance report generation')
            // Get all pages content
            const initialPages = [
              this.$refs.userPerformanceReportIndex,
              this.$refs.understandYourAdvanceReport,
              this.$refs.userPerformanceReport1,
              this.$refs.userPerformanceReport2,
              this.$refs.userPerformanceReport3,
            ];
            const skillsPages = Array.from(
              document.querySelectorAll('div[aria-label^="skills-"]')
            );
            const recommendationLinksTemplates = this.recommendationLinks.map(( _, index)=>this.$refs[`recommendationLinksTemplate-${index}`][0]);
  
            const allPages = [...initialPages, ...skillsPages, ...recommendationLinksTemplates];
            this.totalPages = allPages.length + 1;
  
            // creating an array to represent all content and their page numbers in the UserPerformanceReport as sections
            const sections = this.createSectionsForAdvanceReport(allPages);
  
            // wait for DOM updates
            await this.$nextTick();
            this.advanceReport = await this.generateReport(allPages, 'advance');
            console.timeEnd('advance report generation');
          } catch (error) {
            console.error('PDF generation error:', error);
            throw error;
          }
        },
        async generateBasicReport() {
          try {
            console.time('basic report generation')
  
            const initialPages = [
              this.$refs.basicReportIndex,
              this.$refs.understandYourBasicReport,
              this.$refs.basicReportTemplate1,
              this.$refs.basicReportTemplate2,
            ];
            const basicReportSkillsTemplates = this.skillsAnalysisForBasicReport.map(( _, index)=>this.$refs[`basicReportSkillsTemplate-${index}`][0])
            // Get all pages content
            const allPages = [...initialPages, ...basicReportSkillsTemplates];
  
             // creating an array to represent all content and their page numbers in the UserPerformanceReport as sections
             this.createSectionsForBasicReport(allPages);
            
            // wait for DOM updates
            await this.$nextTick();
            this.basicReport = await this.generateReport(allPages, 'basic');
              console.timeEnd('basic report generation');
          } catch (error) {
            console.error('PDF generation error:', error);
            throw error;
          }
        },
        async generateReport(allPages, reportType){
          // generate filename
          const fileName = `${this.userInfo.name}_${this.userInfo.levels[0]}_${reportType}_${this.getCurrentDate(true)}`
          
          const userPerformanceReportCoverPage = this.$refs.userPerformanceReportCoverPage.cloneNode(true);
  
          // create temporary container
          const container = document.createElement('div');
  
          //adding cover page
          container.innerHTML += userPerformanceReportCoverPage.outerHTML;
  
          // wrapping contents of each page with header and footer templates
          allPages.forEach((page, index) => {
            let isIndexPage = false;
            if(index == 0)isIndexPage=true;
  
            container.innerHTML += this.wrapPageContent(page.outerHTML, index, isIndexPage);
          });
  
          // pdf config
          const options = {
            margin: 0,
            filename: fileName,
            image: { 
              type: 'jpeg', 
              quality: 0.98 
            },
            html2canvas: { 
            scale: 1,
            useCORS: true,
            logging: false,
            letterRendering: true,
            windowHeight: 11 * 96,
            windowWidth: 8.5 * 96,
            },
            jsPDF: {
              unit: 'in',
              format: 'letter',
              orientation: 'portrait',
              compress: true
            },
            pagebreak: { 
              mode: ['avoid-all', 'css', 'legacy']
            },
            enableLinks: true,
          };
          // Generate PDF
          const report = await html2pdf()
            .set(options)
            .from(container)
            .save();
        //   report.fileName = fileName;
          return report;
        },
        async uploadAllReports(){
          try{
            this.totalBytesTransferred = 0;
            this.totalPayloadSize = this.advanceReport.size + this.basicReport.size;
            [this.advancedReportURL, this.basicReportURL] = await Promise.all([
                this.uploadReport(this.advanceReport, 'advance'),
                this.uploadReport(this.basicReport, 'basic'),
              ]);
              const audienceRef = doc(db, 'campaigns', this.campaignId, 'audience', this.userId);
              await updateDoc(audienceRef, {
                  advancedReportURL: this.advancedReportURL,
                  basicReportURL: this.basicReportURL,
              });
            this.$emit('upload-completed');
            return [this.advancedReportURL, this.basicReportURL];
          }
          catch(error){
            console.error('Upload Failed', error);
            throw error;
          }
        },
        async uploadReport(reportBlob, reportType) {
          try{
            if(reportType == 'advance')this.advanceReportBytesTransferred = 0;
            if(reportType == 'basic')this.basicReportBytesTransferred = 0;
            const reportRef = storageRef(storage, `user_reports/${this.userId}/${reportBlob?.fileName}`);
            const uploadTask = uploadBytesResumable(reportRef, reportBlob);
            return new Promise((resolve, reject) => {
              uploadTask.on('state_changed', 
                (snapshot) => {
                  const uploadProgress = this.calculateUploadProgress(snapshot.bytesTransferred, reportType)
                  this.$emit('upload-progress', uploadProgress);
                }, 
                (error) => {
                  console.error(`Error uploading-${reportType}`, error)
                  reject(error);
                }, 
                () => {
                  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    resolve(downloadURL);
                  }).catch((error) => {
                    console.error('Error fetching download URL', error)
                    reject(error);
                  });
                }
              );
            });
          }
          catch(error){
            if(this.retryUploadAttempts > 0){
              console.error(`${reportType}report-`,error);
              this.retryUpload();
            }
            else{
              console.error(`${reportType}report-`,error);
              throw error;
            }
          }
        },
        calculateUploadProgress(bytesTransferred, reportType){
          if(reportType === 'advance')this.advanceReportBytesTransferred = bytesTransferred;
          else if(reportType === 'basic') this.basicReportBytesTransferred = bytesTransferred;
  
          const uploadProgress = ( (this.advanceReportBytesTransferred + this.basicReportBytesTransferred) / this.totalPayloadSize ) * 100;
          return uploadProgress;
        },
        retryUpload(reportBlob, reportType){
          this.retryUploadAttempts--;
          this.uploadReport(reportBlob, reportType);
        },
        createSectionsForAdvanceReport(allPages){
          this.allSections = [];
          let sections = [];
  
          sections = allPages.map((page, index) => ({
              title: page?.getAttribute('data-section-title') || `Section ${index + 1}`,
              pageNumber: index,
              considerPageNumber: !!page?.getAttribute('data-section-title'), //data-* is only defined for skills & subjects
              isCoreSkill: !!page?.getAttribute('data-section-title')?.includes("Subject"),
            }));
          //retain sections objects for the lowest page number among sections objects of the same title
          let seenTitles = new Set();
          sections.forEach((section) => {
            if (!seenTitles.has(section.title)) {
              this.allSections.push(section);
              seenTitles.add(section.title);
            }
          });
  
          // set subjects & skills sections
          this.advanceReportSkillsSections = this.allSections.filter(item=>!item.isCoreSkill && item.considerPageNumber);
          this.advanceReportSubjectsSubSection = this.allSections.filter(section => section.isCoreSkill);
  
          return sections;
        },
        createSectionsForBasicReport(){
          // dynamically assign page numbers for subjects/skills
          const pageNumberOffset = 4; // offsset for pages precceding 'Detailed Report' section
          const skillSection = [];
          this.skillsAnalysisForBasicReport.forEach((grouping, index)=>{
            grouping.forEach(skillItem=>{
                  if(skillItem.isCoreSkill){
                      this.basicReportSubjectsSubSection.push({
                          ...skillItem,
                          pageNumber:index + pageNumberOffset
                      })
                      skillSection.push({
                        skillName: 'Core Skills',
                        pageNumber: index + pageNumberOffset
                      })
                  }
                  else{
                    skillSection.push({
                      ...skillItem,
                      pageNumber: index + pageNumberOffset
                    })
                  }
              })
          })
           //retain sections objects for the lowest page number among sections objects of the same title
           let seenSkillNames = new Set();
            skillSection.forEach((section) => {
              if (!seenSkillNames.has(section.skillName)) {
                this.basicReportSkillsSections.push(section);
                seenSkillNames.add(section.skillName);
              }
            });
          },
        async getPerformanceReport(){
          try {
            const response = await CampaignController.getPerformanceReport(this.userId, this.campaignId);
            if(response.flag){
              const {
                userInfo,
                answerProfile,
                scoreProfile,
                skillsAnalysis,
                recommendationLinks
                } = response.data;
  
                this.userInfo = userInfo;
                this.userInfo.levels = Array.isArray(this.userInfo?.levels)? this.userInfo?.levels : [this.userInfo?.levels];
                userInfo.skillsToShow = [...userInfo.subjects, ...userInfo.skills.filter(skill=>skill != "Core Skills")]
                this.answerProfile = answerProfile;
                this.scoreProfile = scoreProfile;
                this.skillsAnalysisForAdvanceReport = this.formatSkillsAnalysisForAdvanceReport(skillsAnalysis);
                // this.skillsAnalysisForBasicReport = this.formatSkillsAnalysisForBasicReport(skillsAnalysis);
                this.recommendationLinks = this.formatRecommendationLinksForReport(recommendationLinks);
  
                this.currentDate = this.getCurrentDate();
                this.overallAssessmentChartData = [
                    100, 
                    Math.round(this.getPercentage(answerProfile.answeredQuestions, answerProfile.totalQuestions)), 
                    Math.round(this.getPercentage(answerProfile.correctAnswers, answerProfile.totalQuestions))
                  ] // [Total Questions%, Attempted%, Correct%];
                this.scoreDistributionChartData[0].data = skillsAnalysis.map(skill=>this.normalizeToTen(skill.score, skill.maximumMarks).toFixed(2) || 0);
                this.scoreDistributionChartOptions.xaxis.categories = skillsAnalysis.map(skill=>skill.skillName);
                let [clonedScoreDistributionChartData, clonedScoreDistributionChartOptions] = 
                  [cloneDeep(this.scoreDistributionChartData),cloneDeep(this.scoreDistributionChartOptions)];
                [this.scoreDistributionChartData, this.scoreDistributionChartOptions] = 
                  [clonedScoreDistributionChartData, clonedScoreDistributionChartOptions]; // reassigning to trigger vue reactivity
            }
          }
          catch(error){
            console.error(error);
            throw error;
          }
        },
        formatSkillsAnalysisForAdvanceReport(skillsAnalysis) {
          let distributedSkills = this.getDistributedSkills(skillsAnalysis);
          let coreSkills = distributedSkills.filter(({isCoreSkill})=>isCoreSkill);
          let otherSkills = distributedSkills.filter(({isCoreSkill})=>!isCoreSkill);
          
          const indexofPsychometry = otherSkills.findIndex(skill=>!skill.isTitleCard && skill.skillName == 'Psychometry');
          const psychometryData = otherSkills.splice(indexofPsychometry, 1)[0];
          
          //distributing psychometry strands into three seperate psychometry clone objects, ensuring that only two strand are rendered per page.
          otherSkills.push(...this.splitPsychometryStrands(psychometryData,'advance', 2));
  
          //adding Title Card before first pages of every skill
          let formattedOtherSkills = []
          otherSkills.forEach((skill)=>{
            if(skill.isFirstSkillsPage){
              formattedOtherSkills.push(...[this.skillTitleCardMap[skill.skillName], skill]); // add title card before first page of each skill only
            }
            else{
              formattedOtherSkills.push(skill);
            }
          })
          return [this.skillTitleCardMap['Core Skills'],...coreSkills,...formattedOtherSkills];
        },
        getDistributedSkills(skills) {
          let distributedSkills = [];
          skills.forEach(skill => {
            if(skill.skillName !== 'Psychometry'){
              distributedSkills.push(...this.distributeStrengthWeaknessForSkill(skill, 5));
            }
            else{
              distributedSkills.push(skill);
            }
          })
          return distributedSkills;
        },
        distributeStrengthWeaknessForSkill({ strengths = [], weaknesses = [], ...rest }, maxOnFirstPage) {
          const firstPageStrengths = strengths?.slice(0, maxOnFirstPage);
          const remainingStrengths = strengths?.slice(maxOnFirstPage);
          const weaknessesOnFirstPage = weaknesses?.slice(0, maxOnFirstPage - firstPageStrengths?.length);
          const remainingWeaknesses = weaknesses?.slice(maxOnFirstPage - firstPageStrengths?.length);
  
          const firstPage = {
              isFirstSkillsPage: true,
              totalStrengths: strengths?.length,
              totalWeaknesses: weaknesses?.length,
              strengths: firstPageStrengths,
              weaknesses: weaknessesOnFirstPage,
              strengthsInLastPage: 0,
              weaknessesInLastPage: 0,
              ...rest
          };
          const secondPage = {
              strengths: remainingStrengths,
              weaknesses: remainingWeaknesses,
              strengthsInLastPage: firstPageStrengths.length,
              weaknessesInLastPage: weaknessesOnFirstPage.length,
              ...rest
          };
  
          if(remainingWeaknesses?.length == 0 && remainingStrengths?.length == 0) return[firstPage] 
          else return [firstPage, secondPage];
        },
        splitPsychometryStrands(psychometryData, reportType, strandsPerPage = 0){
          let splitStrands = [];
          let strands = Object.entries(psychometryData.psychometryAnalysis.strands);
          if(reportType == 'advance'){
            // spliting psychometry strands such that each psychometry object has two strands each
             for (let i=0; i < strands.length; i += strandsPerPage) {
              const clonedPsychometryData = cloneDeep(psychometryData);
              clonedPsychometryData.isFirstSkillsPage = (i == 0);
              clonedPsychometryData.psychometryAnalysis.strands = Object.fromEntries(strands.slice(i, i + strandsPerPage));
              splitStrands.push(clonedPsychometryData);
            }
          }
          else if(reportType === 'basic'){
            // simply formatting and returning just the psychometry strands
            splitStrands = strands.map((item,index)=>{
                if(index==0) return {
                    skillName: 'Psychometry',
                    strandName: item[0],
                    ...item[1],
                    isFirstStrand: true,
                    totalQuestions: psychometryData.psychometryAnalysis.metadata.totalQuestions,
                    attemptedQuestions: psychometryData.questionCounts.correct + psychometryData.questionCounts.incorrect,
                }
                else return {
                  skillName: 'Psychometry',
                    strandName:item[0],
                    ...item[1]
                }
            })
          }
          return splitStrands;
        },
        formatRecommendationLinksForReport(recommendationLinks){
          if(!recommendationLinks) return [];
          const consolidatedRecommendationLinks = Object.entries(recommendationLinks).map(skill=>{
            const skillName = skill[0];
            const recommendedReadingsForSkill = skill[1];
            return {
              skillName: skillName,
              recommendedReadings: this.getConsolidatedRecommendedReadings(recommendedReadingsForSkill),
              backgroundColor: this.skillTitleCardMap[skillName]?.backgroundColor,
            }
          })
          return this.distributeRecommendationLinks(consolidatedRecommendationLinks);
        },
        getConsolidatedRecommendedReadings(recommendedReadingsForSkill){
            return recommendedReadingsForSkill.reduce(
              (result, item) => {
                result.referenceDocs.push(...item?.recommendedReadings?.referenceDocs)
                result.videos.push(...item?.recommendedReadings?.videos)
                return result;
              },
              { referenceDocs: [], videos: [] }
            );
        },
        encodeUnderscores(text) {
          return text.replace(/_/g, "%5F");
        },
        distributeRecommendationLinks(recommendationLinks) {
          const pageManager = this.recommendationsPageManager();
          
          recommendationLinks.forEach(({skillName, recommendedReadings, backgroundColor}) => {
            pageManager.initializeSkillOnCurrentPage(skillName, backgroundColor);
            
            if (recommendedReadings.videos && recommendedReadings.videos.length > 0) {
              pageManager.addSubTitleWeight();
              recommendedReadings.videos.forEach(video => {
                pageManager.addItemToPage(video, "videos");
              });
            }
            
            if (recommendedReadings.referenceDocs && recommendedReadings.referenceDocs.length > 0) {
              pageManager.addSubTitleWeight();
              recommendedReadings.referenceDocs.forEach(book => {
                pageManager.addItemToPage(book, "referenceDocs");
              });
            }
          });
          
          return pageManager.getPages();
        },
        recommendationsPageManager(initialPages = [[]]) {
          let pages = initialPages;
          let currentPageIndex = 0;
          let currentPageWeight = 0;
          const mainTitleWeight = 5;  
          const subTitleWeight = 2;
          const maxWeightPerPage = 40;
          //weights here signify lines
  
          const moveToNextPage = () => {
            currentPageIndex++;
            pages.push([]);
            currentPageWeight = 0;
          };
          
          const isPageFull = (isMainTitle = false, isSubTitle = false) => {
            if (isMainTitle) return (currentPageWeight + mainTitleWeight) > maxWeightPerPage;
            if (isSubTitle) return (currentPageWeight + subTitleWeight) > maxWeightPerPage;
            return currentPageWeight > maxWeightPerPage;
          };
          
          const addMainTitleWeight = () => {
            currentPageWeight += mainTitleWeight;
            return currentPageWeight;
          };
          
          const addSubTitleWeight = () => {
            currentPageWeight += subTitleWeight;
            return currentPageWeight;
          };
          
          const addItemWeight = (item) => {
            const totalCharacters = getTotalCharactersInItem(item);
            const totalCharactersAllowedPerLine = 50;
            const totalWeight = Math.floor(totalCharacters/totalCharactersAllowedPerLine);
            currentPageWeight += (totalWeight ?? 1);
            return currentPageWeight;
          };
          const getTotalCharactersInItem = (item) => {
            return Object.entries(item).reduce((totalCharacters, entry)=> {
              const lengthOfEntry = entry[1]?.length ?? 0;
              return totalCharacters += lengthOfEntry;
            },0)
          }
          const initializeSkillOnCurrentPage = (skillName, backgroundColor) => {
            if (pages[currentPageIndex].length === 0) {
              pages[currentPageIndex].push({
                skillName,
                backgroundColor,
                recommendedReadings: {
                  referenceDocs: [],
                  videos: []
                }
              });
              addMainTitleWeight();
            } 
            else {
              if(isPageFull(true)) moveToNextPage();
              pages[currentPageIndex].push({
                skillName,
                backgroundColor,
                recommendedReadings: {
                  referenceDocs: [],
                  videos: []
                }
              });
              addMainTitleWeight();
            }
          };
          
          const addItemToPage = (item, resourceType) => {
            if (isPageFull()) {
              moveToNextPage();
              
              // Create a continuation of the current skill on the new page
              const lastSkillIndex = pages[currentPageIndex - 1].length - 1;
              const lastSkill = pages[currentPageIndex - 1][lastSkillIndex];
              
              pages[currentPageIndex].push({
                skillName: lastSkill.skillName,
                backgroundColor: lastSkill.backgroundColor,
                isSkillContinued: true,
                recommendedReadings: {
                  referenceDocs: [],
                  videos: []
                }
              });
            }
            
            const indexOfLastSkillOnPage = pages[currentPageIndex].length - 1;
            if (indexOfLastSkillOnPage < 0) {
              console.error("No skill initialized on the current page");
              return;
            }
            
            pages[currentPageIndex][indexOfLastSkillOnPage].recommendedReadings[resourceType].push(item);
            addItemWeight(item);
          };
      
          return {
            moveToNextPage,
            isPageFull,
            addMainTitleWeight,
            addSubTitleWeight,
            addItemWeight,
            initializeSkillOnCurrentPage,
            addItemToPage,
            getCurrentPageIndex: () => currentPageIndex,
            getCurrentPageWeight: () => currentPageWeight,
            getPages: () => pages,
          };
        },
        formatSkillsAnalysisForBasicReport(skillsAnalysis){
          let skillGroupings = this.makeSkillGroupings(skillsAnalysis);
          // bins base config, each bin represents a page and its items represent the subjects/skills to be rendered on that page
          const bins = [
            {items: [], max:2},
            {items: [], max:2},
            {items: [], max:2},
            {items: [], max:4},
            {items: [], max:Infinity},
          ];
  
          // removing unutilized bin before distributing subjects/skills into bins
          if(skillGroupings[0].length == 1) bins.pop();
          
          // distributing subjects/skills into bins
          const binManager = this.binManager(bins);
          skillGroupings.forEach((grouping) => {
            grouping?.forEach(skill => {
            if(binManager.isBinFull()){
              binManager.moveToNextBin();
              if(skill?.isFirstStrand)bins[binManager.getBinIndex()].max = 3;
            }
            binManager.addItemToBin(skill);
            })
          })
          return bins.map(bin=>bin.items);
        },
        makeSkillGroupings(skillsAnalysis){
          let coreSkills = skillsAnalysis.filter(skill=>skill.isCoreSkill);
          let otherSkills = skillsAnalysis.filter(skill=>!skill.isCoreSkill && skill.skillName !== 'Psychometry');
          let formattedPsychometryStrands = this.splitPsychometryStrands(skillsAnalysis.find(skill=>skill.skillName == 'Psychometry'), 'basic');
          
          let skillGroupings = this.addTitleCardToSkillGroupings([coreSkills, otherSkills, formattedPsychometryStrands]);
  
          return skillGroupings;
        },
        addTitleCardToSkillGroupings([coreSkills, otherSkills, formattedPsychometryStrands]){
          coreSkills[0] = {
            ...this.skillTitleCardMap['Core Skills'],
            ...coreSkills[0]
          }
          formattedPsychometryStrands[0] = {
            ...formattedPsychometryStrands[0],
            ...this.skillTitleCardMap['Psychometry']
          }
          otherSkills = otherSkills.map(skill=> {
            return {
              ...skill,
              ...this.skillTitleCardMap[skill.skillName]
            }
          })
          return [coreSkills, otherSkills, formattedPsychometryStrands];
        },
        binManager(bins){
          let currentBinIndex = 0;
          return {
            moveToNextBin: () => {
              if (currentBinIndex < bins.length - 1) currentBinIndex++;
            },
            isBinFull: () => bins[currentBinIndex].items.length >= bins[currentBinIndex].max,
            addItemToBin: (item) => {
              bins[currentBinIndex].items.push(item);
            },
            getBinIndex: () => currentBinIndex
          };
        },
        padWithZero(number){
          return number < 10 ? `0${number}` : `${number}`;
        },
        getPercentage(value, total){
          return (value/total)*100;
        },
        normalizeToTen(score, maxScore) {
          try{
            if(maxScore == 0 ){
              console.warn("maxScore must be a non zero value");
              return 0;
            }
            return (score / maxScore) * 10;
          }
          catch(error){
            console.error(error);
            return 0;
          }
        },
        getCurrentDate(isDateForFileName = false){
          const date = new Date();
          const day = isDateForFileName? String(date.getDate()).padStart(2, '0') : date.getDate();
          const month = isDateForFileName ? String(date.getMonth() + 1).padStart(2, '0') : date.toLocaleString('en-US', { month: 'long' });
          const year = date.getFullYear();
          const currentDate = isDateForFileName? `${day}${month}${year}` : `${month} ${day}, ${year}`;
          return currentDate;
        },
        getOrdinalSuffix(day){
          if (3 > day && day < 21) return 'th';
          switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
          }
        },
        calculatePosition(value, total){
          let position = (value / total) * 100;
          return position > 90 ? 90 : position < 5 ? 5 : position;
        },
        getLeagueIcon(league){
          const leagueMap = new Map([
            ['gold', this.goldLeagueIcon],
            ['silver', this.silverLeagueIcon],
            ['bronze', this.bronzeLeagueIcon],
            ['bonze', this.bronzeLeagueIcon],
          ])
          return leagueMap.get(league.toLowerCase());
        },
        selectBackgroundColor(score, setBorderStyle = false){
          const redBox = setBorderStyle ? 
          {backgroundColor: "#D4002829", border: "1px solid #D40028", color: "#D40028" } 
          : {backgroundColor: "#D4002829"};
  
          const orangeBox = setBorderStyle ? 
          { backgroundColor: "#FB750029", border: "1px solid #FB7500", color: "#FB7500"}
          : { backgroundColor: "#FB750029" };
  
          const yellowBox = setBorderStyle ? 
          { backgroundColor: "#FADC0029", border: "1px solid #FADC00", color: "#FADC00"} 
          : { backgroundColor: "#FADC0029" };
  
          const greenBox = setBorderStyle ? 
          { backgroundColor: "#06C27029", border: "1px solid #06C270", color: "#06C270"}
          : {backgroundColor: "#06C27029"};
  
          let backgroundColorMap = new Map([
              [1, redBox],
              [2, orangeBox],
              [3, yellowBox],
              [4, greenBox]
          ])
          return backgroundColorMap.get(score);
        },
        getDottedSeparator(dottedElementHeight){
          return {
            flex: 1,
            margin: "0 8px",
            borderBottom: "2px dotted #212121",
            alignSelf: "center",
            height: `${dottedElementHeight}px`
          }
        },
      }
  }
  </script>
  <style scoped>
    .page-wrapper {
      position: relative;
    }
  
    .pdf-header img,
    .pdf-footer img {
      max-width: 100%;
      height: auto;
    }
  
    .content {
      position: relative;
      z-index: 1;
    }
    .dots {
      flex: 1;
      margin: 0 8px;
      border-bottom: 2px dotted #212121;
    }
    .primary-box-red{
        background-color: #D4002829 !important;
        border: 1px solid #D40028;
        color: #D40028 !important;
    }
    .primary-box-orange{
        background-color: #FB750029 !important;
        border: 1px solid #FB7500 !important;
        color: #FB7500 !important;
    }
    .primary-box-yellow{
        background-color: #FADC0029 !important;
        border: 1px solid #FADC00;
        color: #FADC00 !important;
    }
    .primary-box-green{
        background-color: #06C27029 !important;
        border: 1px solid #06C270 !important;
        color: #06C270 !important;
  
    }
    .secondary-box-red{
      background-color: #D4002829 !important;
    }
    .secondary-box-orange{
      background-color: #FB750029 !important;
    }
    .secondary-box-yellow{
      background-color: #FADC0029 !important;
    }
    .secondary-box-green{
      background-color: #06C27029 !important;
    }
    .core-skills-card-border {
      border: 4px solid #007C2A;
      border-radius: 8px;
      border-image: linear-gradient(261.89deg, #007C2A 0%, #029745 37.2%, #04AA58 62.29%, #05B866 81.08%, #06C270 94.9%) 1;
      border-image-slice: 1;
    }
    .digital-literacy-card-border {
      border: 4px solid #A72C5E;
      border-radius: 8px;
      border-image: linear-gradient(
        261.89deg,
        #A72C5E 0%,
        #BB4072 37.2%,
        #CF5486 62.29%,
        #D95E90 81.08%,
        #F77CAE 94.9%
      ); 
      border-image-slice: 1;
      border-radius: 8px;
    }
    .communication-skills-card-border {
      border: 4px solid #002298;
      border-radius: 8px;
      border-image: linear-gradient(
        261.89deg,
        #002298 0%,
        #0040B6 37.2%,
        #0054CA 62.29%,
        #075ED4 81.08%,
        #1B72E8 94.9%
      );
      border-image-slice: 1;
    }
    .pedagogy-card-border {
      border: 4px solid #622C74;
      border-radius: 8px;
      border-image: linear-gradient(
        261.89deg,
        #622C74 0%,
        #764088 37.2%,
        #8A549C 62.29%,
        #945EA6 81.08%,
        #B27CC4 94.9%
      );
      border-image-slice: 1;
    }
    .psychometry-card-border {
      border: 4px solid #AF5600;
      border-radius: 8px;
      border-image: linear-gradient(
        261.89deg,
        #AF5600 0%,
        #C36A0B 37.2%,
        #D77E1F 62.29%,
        #E18829 81.08%,
        #FFA647 94.9%
      );
      border-image-slice: 1;
      border-radius: 8px;
    }
    .core-skills-background {
      background-color: #06C27024 !important;
    }
    .pedagogy-background {
      background-color: #B27CC424 !important;
    }
    .digital-literacy-background {
      background-color: #F77CAE24 !important;
    }
    .communication-skills-background {
      background-color: #1B72E824 !important;
    }
    .psychometry-background {
      background-color: #FFA64724 !important;
    }
    @media print{
      .user-performance-report {
        width: 8.27in;
        height: auto;
        margin: 0;
        font-family: "GraphikRegular", sans-serif !important;
      }
      .shadow-fallback {
        border: 1px solid rgba(0, 0, 0, 0.12); /* Slight shadow effect */
        border-bottom: 2px solid rgba(0, 0, 0, 0.18); /* Enhance bottom shadow */
      }
    }
  </style>