<template>
   <!-- author: suraj -->
   <div class="background">
     <v-dialog v-model="successDialog" max-width="366px" persistent>
     <v-card>
       <v-container fluid class="pa-8">
         <v-card-text class="text-center d-flex flex-column align-center">
           <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
           <p class="text-h5 py-4">
           Your Password Is Created Successfully
           </p>
           <v-btn
             class="btn primary-btn"
             large
             width="157px"
             rounded
             @click="invited"
             >OK</v-btn
           >
         </v-card-text>
       </v-container>
     </v-card>
   </v-dialog>
       
   <v-app-bar
      color="transparent"
      class="rounded-xl"
      elevation="0"
      absolute
    >
  
  
      <LogoComponent />
      
    </v-app-bar>
     <v-row align="center" justify="center" style="height: 100vh" dense>
       <v-col
         cols="12"
         lg="12"
         md="12"
         class="fill-height d-flex flex-column justify-center align-center"
       >
         





         <v-card width="426" height="auto">
           <div class="text-center pa-8">
            <div class="mb-2">
              <img
               src="../assets/icon.svg"
               class="pt-3"
               width="70px"
               height="70px"
             />
             <v-card-title class="justify-center">
               <h3>Reset your password</h3>
             </v-card-title>
             <v-card-subtitle class="reset-password-subtitle">
               Use 8 or more characters with a mix of small and capital letters, numbers & special characters
             </v-card-subtitle>
            </div>
 
             <v-text-field
               :disabled="isResetPasswordFailed" 
               label="Password"
               placeholder=" Password"
               :type="show2 ? 'text' : 'password'"
               @click:append="show2 = !show2"
               :rules="[rules.required, rules.min]"
               :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
               outlined
               v-model="password"
               class="rounded-xl"
               @keydown.enter="login"
             ></v-text-field>

             <v-text-field
               :disabled="isResetPasswordFailed"
               label="Confirm Password"
               placeholder=" Password"
               :type="show2 ? 'text' : 'password'"
               @click:append="show2 = !show2"
               :rules="[rules.required, rules.min]"
               :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
               outlined
               v-model="cnfPassword"
               class="rounded-xl"
               @keydown.enter="login"
             ></v-text-field>
             <v-alert v-if="isResetPasswordFailed" dense type="error" class="alert-reset-password">
                {{ failureMessage }}
             </v-alert>
             <v-btn
             :disabled="disableSetPassword()"
              @click.prevent="resetPassword(password)"                 
              class="btn primary-btn mb-2 py-4"
              rounded
              large
              width="100%"
              :loading="loading"
            >
              set Password
            </v-btn>
           </div>
         </v-card>
       </v-col>
     </v-row>
   </div>
 </template>
   
 <script>
 import AuthService from "@/services/AuthService";
 import UsersController from "@/controllers/UsersController";
 import LogoComponent from "@/components/LogoComponent.vue";
 import { getAuth, verifyPasswordResetCode  } from 'firebase/auth';

 import "../styles.css";
 export default {
   components: { LogoComponent },
   name: "ResetPasswordView",
   data() {
     return {
       cnfpasswd: false,
       forgetBool: false,
       successDialog: false,
       loading: false,
       roleId: "",
       ctList: false,
       cnfPasswordView: false,
       valid: false,
       email: "",
       password: "",
       cnfPassword: "",
       emailRules: [
         (v) => !!v || "E-mail is required",
         (v) =>
           /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
             v
           ) || "E-mail must be valid",
       ],
       show2: false,
    
       rules: {
         required: (value) => !!value || "Required.",
         min: (v) => v.length >= 8 || "Min 8 characters",
         emailMatch: () => `The email and password you entered don't match`,
       },
       token: "",
       isResetPasswordFailed: false,
       failureMessage: "",
       isResetLinkVerified: false,
     };
   },
   methods: {
    invited(){
      this.successDialog = false;
      this.logout();
      this.$router.push('/login');
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    async verifyResetToken(oobCode){
      const auth = getAuth();
     try {
       this.email = await verifyPasswordResetCode(auth, oobCode);
       this.isResetLinkVerified = true;
     } catch (error) {
       this.isResetPasswordFailed =  true;
       this.failureMessage = "Reset token invalid!";
       console.error(error);
     }
    },
    async resetPassword(newPassword){
      try{
        if (!this.isResetLinkVerified) throw "Reset Token Invalid!";
        this.loading = true;
        const response = await UsersController.resetAdminPassword(this.email, newPassword);
        if(response.flag)this.$router.push("/login");
        else{
          this.loading = false;
          this.isResetPasswordFailed = true;
          this.failureMessage = "Failed to reset password";
        }
      }
      catch(error){
        console.error(error)
      }
    },
    disableSetPassword(){
      return this.password !== this.cnfPassword || this.password.length < 8 || this.cnfPassword.length < 8;
    }
   },
   async created() {
    this.token = this.$route.query.oobCode;
    this.verifyResetToken(this.token);
   },
 };
 </script>
 <style scoped>
  .reset-password-subtitle{
    color: #00000099;
    font-size: 14px;
    font-weight: 400;
  }
  .alert-reset-password{
    border-radius: 8px
  }
</style>