<template>
  <div>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
      <v-card-title class="pl-0 ml-0">
        <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/masters')">
          Masters
        </p>
        <v-icon class="breadcrumb-arrow-unselected" style="margin-top: 6px !important">mdi-chevron-right</v-icon>
        <v-menu offset-y class="menuZindex">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
              Boards
              <v-icon class="breadcrumb-arrow cursor" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </span>
          </template>
          <MastersDropdownComponent />
        </v-menu>
        <!-- <p class="breadcrumb-text" style="margin-top: 2px">Boards</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon> -->
      </v-card-title>
    <UserNavMenu/>
    </v-card>
    <div fluid class="pad px-8">

      <v-row>
        <v-col>

          <!-- <v-btn
          @click="(dialog = true), newCreateBoards()"
          class="brown"
          large
          rounded
        v-if="$store.state.role.board.admin"

          ><v-icon>mdi-plus</v-icon>Create Board</v-btn
        > -->
          <v-dialog max-width="887px" class="cdz" v-model="dialog" center>
            <v-form ref="form" lazy-validation>
              <v-card>
                <v-card-title class="gray-02 mb-8">{{ formbtn() }} Board</v-card-title>
                <v-list-item-title class="text-center mb-4">
                  Upload board icon<sup>*</sup>
                </v-list-item-title>

                <div class="d-flex justify-center mb-6 bg-surface-variant">
                  <div class="box" @dragover.prevent @drop="onDrop" v-if="!image">
                    <div class="uploadrow">
                      <div class="uploadleft">
                        <v-icon class="uploadicon">mdi-tray-arrow-up</v-icon>
                      </div>
                      <div class="uploadright">
                        <div>
                          <p class="font-weight-light">Drag .png or .svg here</p>
                          <p class="font-weight-light">OR</p>
                          <div class="font-weight-light">
                            <div class="uplaodbtn">
                              Click here
                              <input type="file" name="image" @change="onChange" />
                            </div>
                            to browse(512 Kb max.)
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>

                  <div v-if="image">
                    <div class="imgback">
                      <img :src="image" alt="" width="100" height="auto" class="img" />
                      <div class="pencil">
                        <v-icon class="editpen">mdi-pencil-circle</v-icon>
                        <input class="editlogo" type="file" name="image" @change="onChange" />
                      </div>
                    </div>
                  </div>
                </div>

                <v-card-text class="px-5 pb-0">
                  <v-text-field outlined class="rounded-xl" v-model="name" label="Enter Board*"
                    :rules="[(v) => !!v || 'Boards name is required']"></v-text-field>
                </v-card-text>

                <v-card-actions class="px-5 pb-6">
                  <small>*All fields are mandatory</small>
                  <v-spacer></v-spacer>
                  <v-btn rounded width="102px" height="48px" outlined class="btn outline-btn pa-4" @click="() => {
                    dialog = false; //
                    formbtnBool = false;
                  }
                    ">Cancel</v-btn>
                  <v-btn :disabled="selectedFile == null" rounded width="102px" height="48px" @click="uploadIcon"
                    :loading="loading" class="btn primary-btn pa-4">{{ formbtn() }}</v-btn>
                </v-card-actions>
                <!---------------------- subject upload section ends here --------------------------->
              </v-card>
            </v-form>
            <!---------------------- subject upload section starts here --------------------------->
          </v-dialog>
        </v-col>

        <!-- <v-col cols="4">
        <v-text-field
          label="Search"
          prepend-inner-icon="mdi-magnify"
          v-model="searchBoards"
          clearable
        ></v-text-field
      ></v-col> -->

      </v-row>




      <v-row class="d-flex justify-center my-0 py-0">

        <v-col>
          <div class="text-h6">Boards</div>
        </v-col>



        <v-col cols="10" class="pl-0 ml-0">


          <v-row justify="end" class="mb-1 mt-0">
            <v-text-field solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search" hide-details
              class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
            <!-- <v-btn depressed rounded outlined class="border-button mx-3"><v-icon>mdi-tune</v-icon>FILTER</v-btn> -->
            <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="btn outline-btn elevation-0 mx-2 pl-4 pr-6"
              rounded
              depressed
              v-bind="attrs"
              v-on="on"
            >
            <v-img
            v-bind="attrs" 
            v-on="on" 
            :src="require(`@/assets/svg/sort.svg`)"
            
            />              
              <span>SORT</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item 
              v-for="option in sortOptions" 
              :key="option.value" 
              @click="selectSortOption(option.value)"
              :class="{'selected-sort-option': selectedSort === option.value}"
            >
              <v-list-item-title>
                {{ option.label }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
            <v-btn class="search-bar btn mx-3" outlined rounded :disabled="selected.length == 0"
            :class="!selected.length == 0 ? 'outline-btn' : 'outline-disabled-btn'"
              @click="deleteDialog = true"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>
            <!-- <v-btn class="primary mx-2" rounded
            ><v-icon>mdi-export</v-icon>Export</v-btn
          > -->
            <v-btn depressed outlined rounded class="btn outline-btn mx-3" @click="downlaodRoles"><v-icon>mdi-import
                mdi-rotate-90</v-icon></v-btn>

            <v-btn @click="(dialog = true), newCreateBoards()" class="btn primary-btn mx-3 white--text" rounded
              depressed><v-icon class="pr-2">mdi-plus</v-icon>Create</v-btn>


          </v-row>
        </v-col>
      </v-row>

      <v-data-table fixed-header height="calc(100vh - 230px)" class="rounded-table" v-model="selected" :headers="headers"
        :loading="dataTableLoading" loading-text="Loading Boards" :items="tableData" show-select :options.sync="options" @update:options="getBoards"
        :single-select="singleSelect" :search="search" :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 50, 100]
        }">
        <template v-slot:loading>
          <bubble-loader/>
        </template>
        <template v-slot:no-data>
          <div class="custom-no-data">
            <p>No Data Available</p>
          </div>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ getDate(item.createdOn) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="$store.state.role.board.admin" icon class="mr-2 pa-4" @click="updateData(item)">
            <img width="36px" height="36px" class="cursor" src="../../assets/edit.svg" />
          </v-btn>
        </template>

        <template v-slot:[`item.logo`]="{ item }">
          <v-img height="30px" width="30px" :src="item.logo"
            class="pa-6 bg-secondary rounded-circle d-inline-block"></v-img>
        </template>
        <template v-slot:no-results>
          <div class="mt-16 pt-16">
            <img 
          src="../../assets/noResultFound.svg"/>
          </div>
      </template>
      </v-data-table>
      <v-dialog v-model="deleteDialog" max-width="366px" class="cdz" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <!-- <v-avatar></v-avatar> -->
              <v-container></v-container>
              <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>

              <p class="text-h5 pt-6 pb-0">Delete Boards</p>
              <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn depressed class="btn outline-btn black--text" large width="157px" rounded
                  @click="deleteDialog = false">CANCEL</v-btn>
                <v-btn class="btn primary-btn white--text" depressed large width="157px" :loading="dLoading" rounded
                  @click="deleteData(selected)">DELETE</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Board Deleted</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!--error dialog-->
      <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">Board {{ formbtnValue() }}</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <!-- Notification Dialog -->
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>

  </div>
</template>
<script>
import BoardController from "@/controllers/BoardController";
import MastersDropdownComponent from "../../components/MastersDropdownComponent.vue"
import { storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import AuthService from "../../services/AuthService";
import Papa from "papaparse";
import Notification from "../Notification.vue";
import { getDateFromTimestamp } from "@/helpers/utils";
import BubbleLoader from '@/components/BubbleLoader.vue';
import UserNavMenu from "@/components/UserNavMenu.vue";

export default {
  name: "BoardsView",
  components: {
    Notification, MastersDropdownComponent,
    BubbleLoader,UserNavMenu
  },
  data() {
    return {
      notificationDialog: false,
      options: {},
      pageSize: 10,
      page: 1,
      count: 0,
      errorDialog: false,
      successDialog: false,
      deleteSuccessDialog: false,
      errorMessage: "",
      dialog: false,
      dialogTitle: "Dialog Title",
      input1: "",
      input2: "",
      preSignedUrl: '',
      name: null,
      selectedFile: null,
      loading: false,
      singleSelect: false,
      iconURl: "NA",
      selected: [],
      search: '',
      boardData: null,
      deleteDialog: false,
      dLoading: false,
      image: "",
      editId: null,
      formbtnBool: false,
      searchBoards: '',
      searchBool: false,
      dataTableLoading: true,
      sortCol:"name",
      sortOrder: "asc", // Default sort order (asc or desc)
      selectedSort: "boardAsc", // Default selected sort option
      sortOptions: [
        { value: "boardAsc", label: "Board - Ascending (A-Z)" },
        { value: "boardDesc", label: "Board - Descending (Z-A)" },
        { value: "dateNewest", label: "Date - Newest" },
        { value: "dateOldest", label: "Date - Oldest" },
      ],
      headers: [
        { text: "Board Icon", value: "logo", sortable: false },
        { text: "Board Name", value: "name", sortable: false },
        { text: "Created  On", value: "createdOn", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      tableData: [],
    };
  },
  watch: {
    // options: {
    //   handler() {
    //     console.log(this.options);
    //     this.pageSize = this.options.itemsPerPage;
    //     this.page = this.options.page;
    //     if(this.searchBool){
    //       this.searchData(this.searchBoards);
    //     }else{
    //       this.getBoards();
    //     }
    //   },
    //   deep: true,
    // },
    // searchBoards(newValue){
    //   console.log(newValue);
    //     this.searchBool=true
    //     this.pageSize = this.options.itemsPerPage;
    //     this.page = this.options.page;
    //     this.options.page=1;
    //     this.searchData(newValue);
    //     if(newValue=="" || newValue==null){
    //       this.getBoards();
    //       this.searchBool=false;
    //     }
    // }
    search(newValue) {
      this.$router.replace({ 
        query: { 
          ...this.$route.query,
          search: newValue || undefined 
        }
      }).catch(() => {});
      this.options.page = 1
      this.getBoards();
    }
  },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    // async searchData(search){
    //   const response = await BoardController.searchBoard(
    //     this.pageSize,
    //     this.page,
    //     search,
    //   );
    //   console.log(response.data);
    //   console.log(this.searchBool);
    //   this.count = response.data.data.count;
    //   this.tableData = response.data.data.rows;
    // },
    selectSortOption(sortOption) {
      this.selectedSort = sortOption;
      switch (sortOption) {
        case "boardAsc":
          this.sortCol = "name";
          this.sortOrder = "asc";
          break;
        case "boardDesc":
          this.sortCol = "name";
          this.sortOrder = "desc";
          break;
        case "dateNewest":
          this.sortCol = "createdOn";
          this.sortOrder = "desc";
          break;
        case "dateOldest":
          this.sortCol = "createdOn";
          this.sortOrder = "asc";
          break;
        default:
          this.selectedSort = "boardAsc";
          this.sortCol = "name";
          this.sortOrder = "asc";
      }
      this.options.page = 1;
      this.getBoards();
    },
    getDate(timeStamp) {
      return getDateFromTimestamp(timeStamp);
    },
    updateData(item) {
      this.editId = item.id; // selected id for edit
      this.formbtnBool = true; // change update/create btn value
      this.dialog = true;
      this.name = item.name;
      this.image = item.logo
    },

    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },
    formbtnValue() {
      return this.formbtnBool === false ? "Created" : "Updated";
    },
    async newCreateBoards() {
      this.name = null;
      this.formbtnBool = false;
      this.selectedFile = null;
      this.image = null
    },

    async saveInputs() {
      if (this.$refs.form.validate()) {
        console.log("valiadhd")
        var res;
        this.loading = true;
        // checking case for update/create
        if (this.formbtnBool == false) {
          const response = await BoardController.createBoard(
            this.name,
            "STATE",
            this.iconURl
          );
          res = response;
        } else {
          const response = await BoardController.updateBoard(
            this.name,
            "STATE",
            this.iconURl,
            this.editId
          );
          res = response;
          console.log(response);
        }
        // Close the dialog
        this.formbtnBool == false;
        console.log("flag", res.data.flag)
        if (res.data.flag) {
          this.dialog = false;
          this.loading = false;
          this.successDialog = true;
        } else {
          this.dialog = false;
          this.loading = false;
          this.errorDialog = true;
          this.errorMessage = res.data.error;
        }
        this.getBoards();
        this.name = '';
        this.removeFile();
      }
    },
    async deleteData(data) {
      // if (data.length == 0) {
      //   this.dLoading = true;
      //   this.deleteDialog = true;
      //   const response = await BoardController.deleteBoard(data[0].id);
      //   this.getBoards();
      //   this.deleteDialog = false;
      //   console.log(response);
      //   this.dLoading = false;
      //   this.selected = [];
      // } else {
      var ids = [];
      data.forEach((dataDelete) => {
        ids.push(dataDelete.id);
      })
      this.dLoading = true;
      this.deleteDialog = true;

      const response = await BoardController.deleteBulkBoard(ids);
      if (response.data.flag) {
        this.deleteDialog = false;
        console.log(response);
        this.dLoading = false;
        this.selected = [];
      } else {
        this.deleteDialog = false;
        console.log(response);
        this.dLoading = false;
        this.selected = [];
        alert(response.data.error)
      }
      this.getBoards();
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;

    },


    async getBoards() {
      this.count = 0;
      this.tableData = [];
      this.page = this.options.page;
      this.pageSize = this.options.itemsPerPage;
      const params = {
        page: this.page,
        pageSize: this.pageSize,
        search: this.search,
        sortCol: this.sortCol,
        sortOrder: this.sortOrder,
      }
      const query = "?"+(new URLSearchParams(params).toString());
      const response = await BoardController.getBoardsByPagination(
      query  
    );
      if (response.status == 200) {
        this.dataTableLoading = false;
        console.log(response);
        this.count = response.data.count;
        if (response.data.boards.length > 0) {
          this.tableData = response.data.boards;
        }
      }
      else {
        this.dataTableLoading = false;

        // alert(response.data.error)
        alert("Something went wrong")
      }

    },

    async downloadCSV(csvData, fileName) {

      const csv = Papa.unparse(csvData);


      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {

        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {

          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    async downlaodRoles() {
      const fileName = 'boards.csv';
      const response = await BoardController.getBoards();
      const boards = response.data.boards;
      this.downloadCSV(boards, fileName);
    },

    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      this.selectedFile = e.dataTransfer.files[0];
      this.createFile(this.selectedFile);
    },
    onChange(e) {
      this.selectedFile = e.target.files[0];
      this.createFile(this.selectedFile);

    },
    createFile(file) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      // var img = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = function (e) {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);

    },
    removeFile() {
      this.image = "";
      this.iconURl = "";
    },
    uploadIcon() {
      this.loading = true;
      if (this.selectedFile != null) {
        const storageRef = ref(storage, "/boards/" + this.selectedFile.name);
        uploadBytes(storageRef, this.selectedFile).then((snapshot) => {
          console.log("Uploaded");
          getDownloadURL(snapshot.ref).then((url) => {
            this.iconURl = url;
            this.saveInputs();
          });
        });
      }
    },
    sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },
    // async getPreSignedUrl() {
    //   this.loading = true;

    //   const response = await UploadController.getPreSignedUrl({
    //     context: "masters",
    //     file_name: this.selectedFile.name,
    //     mime_type: this.selectedFile.type,
    //     file_type: "images",
    //     business_type: "b2c",
    //     uuid: "123-456-7",
    //   });
    //   this.preSignedUrl = response.data.data.signed_request;
    //   this.iconURl=response.data.data.url;
    //   this.uploadToS3();
    // },
    // async uploadToS3() {
    //   console.log("Pre-Signed URL: ", this.preSignedUrl);
    //   const uploadResponse = await UploadController.uploadFile(
    //     this.preSignedUrl,
    //     this.selectedFile
    //   );
    //   console.log("Upload Response: ", uploadResponse);
    //   this.saveInputs();
    // },
  },
  created() {
    console.log("board read", this.$store.state.role.board.read)
    if (!this.$store.state.role.board.read) {
      this.$router.push('/notFound')
    }
    const urlSearch = this.$route.query.search;
    if (urlSearch) {
      this.search = urlSearch;
    }
    // this.getBoards();
    this.$store.state.breadcrumb = "Boards";
  },
};
</script>
<style scoped></style>