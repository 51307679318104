<template>
  <div v-if="preloader">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div> 
  <div v-else>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position: fixed; z-index: 200; top: 0; width: 98%; padding-top: 16px;">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text underline-on-hover cursor" @click="$router.push('/static-assessment')">Assessments</p>
        <v-icon
          class="breadcrumb-arrow-unselected"
          style="margin-top: 6px !important"
        >mdi-chevron-right
        </v-icon>
        <p class="breadcrumb-text underline-on-hover cursor" @click="backToCampaignUserListingPage">{{campaignName}}</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
        <p class="breadcrumb-text">Test Report</p>
      </v-card-title>
    <UserNavMenu/>
    </v-card>
    <div fluid class="pad px-8 background">
      <div class="d-flex align-center mb-8">
        <h3 class="mr-auto">{{ userName }}: Mesta Result</h3>
        <div>
          <v-btn
          @click="downloadPDF"
          :loading="downloadLoader"
          :disabled="disableDownload"
          depressed outlined rounded
          elevation="0"
          class="btn outline-btn">
            <v-icon>
              mdi-import
              mdi-rotate-90
            </v-icon>
            </v-btn>
        </div>
      </div>
      
      <div class="pa-4 bg-white">
        <div class="total-score-container">
          <div class="d-flex justify-space-between">
            <h4 class="mb-2">Total Score - {{displayedselectedSkill.overallScore.totalSecuredScore}} / {{displayedselectedSkill.overallScore.totalScore}}</h4>
            <!-- <span>Rank #13</span> -->
          </div>
          <div class="result-details d-flex">
            <div class="d-flex">
              <span class="mr-1">Grade - </span>
              <span class="font-weight-bold">{{ this.grade}}</span>
            </div>
            <div class="d-flex">
              <span class="mr-1">Language - </span>
              <span class="font-weight-bold">{{this.prefferedLanguage}}</span>
            </div>
            <div class="d-flex">
              <span class="mr-1">Time taken by this user - </span>
              <span class="font-weight-bold">{{ this.timeTakenByUser}} mins</span>
            </div>
            <!-- <div class="d-flex">
              <span class="mr-1">Average time taken by others - </span>
              <span class="font-weight-bold">30 mins</span>
            </div> -->
          </div>
        </div>
        <v-container fluid>
          <v-row>
            <v-col class="px-0" cols="3">
              <aside>
                <v-card
                  class="scroll-view core-skills-container"
                  height="calc(100vh - 312px)"
                  id="myScroll"
                  outlined
                  elevation="0"
                  style="position: sticky; top: 300px"
                >
                <v-btn-toggle v-model="selectedSubjectName" @change="getQuestionsForDisplay" mandatory class="d-flex flex-column pa-2">           
                  <template v-for="(subject, index) in displayedselectedSkill?.subjectsScore">
                    <v-btn  
                      depressed
                      outlined
                      class="rounded-xl pa-2 my-1 tab-btn" 
                      :key="index" :value="subject.subject" 
                      >
                      <div class="d-flex flex-row w-100 justify-space-between align-center">
                        <div>
                          {{ subject.subject }}
                        </div>
                        <div>
                          <div class="text-body-2 text--secondary">
                            Score
                          </div>
                          <div class="text-left text-body-2 font-weight-bold text--secondary">
                            {{subject.securedScore }}/ {{ subject?.totalSubjectScore }}
                          </div>
                        </div>
                      </div>
                      </v-btn>           
                  </template>
                </v-btn-toggle>
                </v-card>
              </aside>
            </v-col>
            <v-col cols="9">
              <div class="pa-3 test-heading">
                <h4>{{selectedSubjectName}}</h4>
              </div>
              <v-card
                class="scroll-view"
                height="calc(100vh - 312px)"
                id="myScroll"
                elevation="0"
              >
                <v-list>
                  <v-list-item
                    v-for="(item, index) in questionsToDisplay"
                    :key="index"
                  >
                  <div class="w-100 d-flex flex-column">
                    <div class="d-flex flex-row">
                      <div class="d-flex flex-column">
                        <div class="d-flex flex-row font-weight-bold">
                          <div>Q.{{ item.questionNumber }}</div>
                          <div class="pl-4" v-html="item[prefferedLanguage.toLowerCase()]?.questionStatement"></div>
                        </div>
                        <div class="my-4" v-if="item[prefferedLanguage.toLowerCase()]?.questionAssetUrl != 'NA'">
                          <v-img v-if="item[prefferedLanguage.toLowerCase()]?.isQuestionAssetUrlResolved" 
                          :width="100" 
                          class="option-image-border"
                          :src="item[prefferedLanguage.toLowerCase()]?.questionAssetUrl"/>
                          <v-img v-else :width="100" class="option-image-border" :src="loadingAssetURL"/>
                        </div>
                        <div class="pl-8 pt-2 my-1 ml-2">
                           <div class="mb-3 language-chips">
                            <v-chip
                            v-if="item.difficultyLevel != 'NA'"
                            small text-color="grey darken-2"
                            class="mr-2" outlined>
                              {{ item.difficultyLevel }}
                            </v-chip>
                            <v-chip
                              v-if="item.knowledgeLevel && item.knowledgeLevel != 'NA'"
                              small text-color="grey darken-2"
                              class="mr-2" outlined>
                              {{ item.knowledgeLevel }}
                            </v-chip>
                            <v-chip v-if="level != 'NA'" small class="mr-2" text-color="grey darken-2" outlined>
                              {{level}}
                            </v-chip>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex flex-column w-100 ml-1 mb-4">
                      <div class="pl-9">
                        <div v-if="!imageInOption(item[prefferedLanguage?.toLowerCase()]?.questionOptions)"
                        v-for="(option, optionIndex) in item[prefferedLanguage?.toLowerCase()]?.questionOptions" :key="optionIndex" 
                        :class="item.correctAnswer?.includes(option.optionKey)? 'correct-option d-flex':' other-option d-flex'">
                          <div class="mr-1">{{option.optionKey}}.</div>
                          <div v-html="option.optionValue"></div>
                        </div>
                        <div class="d-flex flex-row" v-if="imageInOption(item[prefferedLanguage?.toLowerCase()]?.questionOptions)">
                          <div
                          v-for="(option, i) in item[prefferedLanguage?.toLowerCase()]?.questionOptions"
                          :key="i" class="ma-4">
                            <v-img v-if="option?.isOptionAssetUrlResolved" 
                            :width="100" 
                            :class="item.correctAnswer?.includes(option.optionKey)? 'correct-answer-image':'option-image-border'" 
                            :src="option?.optionValue"/>
                            <v-img v-else class="option-image-border" :width="100" :src="loadingAssetURL"/>
                          </div>
                        </div>
                      </div>
                      <div class="px-0 pl-9 w-100 my-4">
                        <v-divider></v-divider>
                      </div>
                      <div class="d-flex align-center pl-9 questions-info-container">
                        <div>
                          Response Analytics -

                          <span v-if="!item.myAnswer" class="mr-6"
                            >Not Attempted</span
                          >

                          <span v-else class="mr-6">Attempted</span>
                        </div>

                        <div>Answered -</div>

                        <div v-if="!item.myAnswer" class="mr-6 skipped-answer">Skipped</div>
                        <div
                          class="mr-6"
                          v-else
                          :class="item.isAnswerCorrect ? 'd-flex align-center pa-1 correct-answer' : 'd-flex align-center pa-1 incorrect-answer'">
                          <v-img class="option-image-border" v-if="imageInOption(item[prefferedLanguage?.toLowerCase()]?.questionOptions)" 
                          :width="100"
                          :src="returnCorrectAnswerAssetURL(item)"/>
                          <div class="pl-2">
                            {{
                              item.isAnswerCorrect
                                ? item.myAnswer + " (Correct)"
                                : item.myAnswer + " (Incorrect)"
                            }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="my-3 customDivider"></div>
                    </div>
                  </div>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <section class="hide-it">
      <div v-for="(item, index) in displayedselectedSkill?.subjectsScore" class="pa-3" :aria-label="'pdf-page-' + (index + 1)">
        <div class="test-heading" style="padding:12px !important;">
          <h4>{{item.subject}} ({{ item?.securedScore}}/{{ item?.totalSubjectScore }})</h4>
        </div>
        <v-list-item
         v-for="(item, index) in groupedSubjects[item.subject]"
         class="pdf-item"
        :key="index" >
        <div class="w-100 d-flex flex-column">
          <div class="d-flex flex-row">
            <div class="d-flex flex-column">
              <div style="display: flex; margin-top:8px" class="d-flex flex-row font-weight-bold">
                <div>Q.{{ item.questionNumber }}</div>
                <div style="padding-left: 16px;"class="pl-4" v-html="item[prefferedLanguage.toLowerCase()]?.questionStatement"></div>
              </div>
              <div style="margin:8px 0px" class="my-4" v-if="item[prefferedLanguage.toLowerCase()]?.questionAssetUrl != 'NA'">
                <img style="border: 1px solid #0000001A; border-radius: 8px;" 
                :width="100"
                 :src="item[prefferedLanguage.toLowerCase()]?.questionAssetUrl"/>
              </div>
              <div class="pl-8 pt-2 my-1 ml-2">
                  <div style="padding: 4px 0px;" class="mb-3 language-chips">
                  <v-chip
                  v-if="item.difficultyLevel != 'NA'"
                  small text-color="grey darken-2"
                  class="mr-2" style="padding-bottom:4px" outlined>
                    {{ item.difficultyLevel }}
                  </v-chip>
                  <v-chip
                    v-if="item.knowledgeLevel && item.knowledgeLevel != 'NA'"
                    small text-color="grey darken-2"
                    class="mr-2" outlined style="padding-bottom:4px">
                    {{ item.knowledgeLevel }}
                  </v-chip>
                  <v-chip v-if="level != 'NA'" small class="mr-2" text-color="grey darken-2" outlined style="padding-bottom:4px">
                    {{level}}
                  </v-chip>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column w-100 ml-1 mb-4">
            <div class="pl-9">
              <div v-if="!imageInOption(item[prefferedLanguage?.toLowerCase()]?.questionOptions)"
              v-for="(option, optionIndex) in item[prefferedLanguage?.toLowerCase()]?.questionOptions" :key="optionIndex" 
              style="display: flex;"
              :class="item.correctAnswer?.includes(option.optionKey)? 'correct-option d-flex':' other-option d-flex'">
                <div class="mr-1">{{option.optionKey}}.</div>
                <div v-html="option.optionValue"></div>
              </div>
              <div style="display: flex; flex-direction:row;" class="d-flex flex-row" v-if="imageInOption(item[prefferedLanguage?.toLowerCase()]?.questionOptions)">
                <div
                v-for="(option, i) in item[prefferedLanguage?.toLowerCase()]?.questionOptions"
                :key="i" style="margin:16px" class="ma-4">
                  <img v-if="item.correctAnswer?.includes(option.optionKey)" 
                    style="border: 2px solid #06C270 !important; border-radius: 8px;" 
                    :width="100"
                    :src="option?.optionValue"/>
                  <img v-else style="border: 1px solid #0000001A; border-radius: 8px;" 
                    :width="100"
                    :src="option?.optionValue"/>
              </div>
              </div>
            </div>
            <div style="padding:0px 0px 0px 36px; width:100%; margin:16px 0px" class="px-0 pl-9 w-100 my-4">
              <v-divider></v-divider>
            </div>
            <div class=" questions-info-container" style="display: flex; flex-direction: row; align-items: center; padding-bottom: 12px; font-size: 14px;">
              <div>
                Response Analytics -

                <span v-if="!item.myAnswer" style="margin-right:24px" class="mr-6"
                  >Not Attempted</span
                >

                <span v-else style="margin-right:24px" class="mr-6">Attempted</span>
              </div>

              <div>Answered -</div>

              <div v-if="!item.myAnswer" class="mr-6 skipped-answer">Skipped</div>
              <div
                class="mr-6"
                v-else style="display: flex; flex-direction: row; align-items: center;"
                :class="item.isAnswerCorrect ? 'd-flex align-center pa-1 correct-answer' : 'd-flex align-center pa-1 incorrect-answer'">
                <img style="border: 1px solid #0000001A; border-radius: 8px;"  class="option-image-border" v-if="imageInOption(item[prefferedLanguage?.toLowerCase()]?.questionOptions)" 
                :width="100" :height="100"
                :src="returnCorrectAnswerAssetURL(item)"/>
                <div style="padding-left: 8px;" class="pl-2">
                  {{
                    item.isAnswerCorrect
                      ? item.myAnswer + " (Correct)"
                      : item.myAnswer + " (Incorrect)"
                  }}
                </div>
              </div>
            </div>
            
            <div class="my-3 customDivider"></div>
          </div>
        </div>
        </v-list-item>
      </div>
    </section>
    
  </div>
</template>
<script>
    import AuthService from "../services/AuthService";
    import StaticAssessmentController from "@/controllers/StaticAssessmentController";
    import AssessmentController from "@/controllers/AssessmentController";
    import html2pdf from "html2pdf.js";
    import UserNavMenu from "@/components/UserNavMenu.vue";
  
    export default {
      components:{
        UserNavMenu
      },
        name: "UserTestReport",
        data() {
            return {
              preloader: true,
              currentSelectedLanguage: null,
              selectedOptions: [],
              availableQuesLanguages: ['Easy','P1','Pre-Primary','Foundational','Musk Know'],
              timeTakenByUser:'0',
              prefferedLanguage:'',
              userName:'',
              selectedSkill: {
                questions: [
                  
                ],
                overallScore:{},
                subjectsScore:[]
              },
              selectedSubjectName: "",
              questionsToDisplay: [],
              studentId: "",
              level: "",
              grade: "",
              campaignId: "",
              campaignName: "",
              campaignType: "",
              assessmentId: "",
              groupedSubjects: {},
              downloadLoader: false,
              disableDownload: false,
              loadingAssetURL: new URL(`../assets/loading.gif`, import.meta.url).href,
          }
        },
        mounted() {
        },
        created(){
          this.studentId = this.$route.query.studentId;
          this.grade = this.$route.query.grade;
          this.campaignId = this.$route.query.campaignId;
          this.campaignName = this.$route.query.campaignName;
          this.campaignType =  this.$route.query.campaignType;
          this.assessmentId = this.$route.query.assessmentId;
          this.pageSize = 10;
          this.page = 1;
          this.fetchUserReport(this.studentId, this.campaignId, this.grade, this.assessmentId);
        },
        computed: {
          displayedselectedSkill() {
            console.log("selectedskills===>", this.selectedSkill);
            return this.selectedSkill;
          }
        },
        methods: {
            logout() {
                AuthService.logout();
                this.$router.push("/login");
            },
            async fetchUserReport(studentId, campaignId, grade, assessmentId) {
                this.isLoading = true;
                this.disableDownload = true;
                this.downloadLoader = true;
                const response = await StaticAssessmentController.getUserTestReport(studentId, campaignId, grade, assessmentId);
                if(response.status == 200) {
                  const data =  response.data.data;
                  console.log("data ==> ", data)
                    this.selectedSkill.questions = data.questionResponse??[];
                    this.selectedSkill.questions = this.selectedSkill.questions.map(({questionId, ...rest})=>{
                      return {
                          questionNumber: questionId.split("_Q")[1],
                          ...rest
                        }
                    })
                    this.prefferedLanguage = Array.isArray(data.prefferedLanguage)? data.prefferedLanguage[0]: data.prefferedLanguage ?? '';

                    let questionPromises = this.selectedSkill.questions.map(async question => {
                      let questionOfSpecificLanguage = question[this.prefferedLanguage.toLowerCase()];
                      question.isAnswerCorrect = this.isCorrectResponse(question?.myAnswer, question?.correctAnswer);

                      if(questionOfSpecificLanguage.questionAssetUrl?.startsWith('https://drive.google.com/')){
                        questionOfSpecificLanguage.questionAssetUrl = await this.resolveAssetURL(questionOfSpecificLanguage.questionAssetUrl);
                        questionOfSpecificLanguage.isQuestionAssetUrlResolved = true;
                      }
                      
                      let optionsPromises = questionOfSpecificLanguage?.questionOptions.map(async option=>{
                        if(option.optionValue?.startsWith('https://drive.google.com/')){
                          option.optionValue = await this.resolveAssetURL(option.optionValue);
                          option.isOptionAssetUrlResolved = true
                        }
                      })
                      await Promise.all(optionsPromises);
                    })
                    Promise.all(questionPromises).then(()=>{
                      this.downloadLoader = false;
                      this.disableDownload = false;
                    });
                    this.selectedSubjectName = data.subjectsScore[0].subject;
                    this.questionsToDisplay = this.selectedSkill.questions.filter(question=>question.subject.includes(this.selectedSubjectName))
                    this.userName = `${data.firstName} ${data.lastName}`                    
                    this.selectedSkill.overallScore = data.overallScore;
                    this.selectedSkill.subjectsScore = data.subjectsScore;
                    this.timeTakenByUser = data.timeTakenByUser??'';
                    this.level = data.level;
                    // this.newQuestionCount =  response.data.assessments?.[0]?.questions?.length;
                    console.log("this.timeTakenByUser",this.timeTakenByUser);
                    this.preloader = false;
                    this.isLoading = false;
                } else {
                    this.preloader = false;
                    this.isLoading = false;
                }
              },
              async resolveAssetURL(url){
                  let fileId = null
                  if(url.indexOf('&id=') > -1) {
                    fileId = url.split('&id=').pop()
                  } else {
                    // Regular expression pattern to extract file ID
                    const pattern = /\/file\/d\/([^\\/]+)\//;
                    fileId = url.match(pattern)[1];
                  }
                  const response = await AssessmentController.doFetchFileContentFromDriveUrl(fileId);
                  if(response.status === 200) {
                    return window.URL.createObjectURL(new Blob([response.data]));
                  }
              },
              groupBySubjects(questions){
                return questions.reduce((groupedSubjects, question)=>{
                  question.subject.forEach(subject=>{
                    if(!groupedSubjects[subject]) groupedSubjects[subject] = [];
                    groupedSubjects[subject].push(question);
                  })
                  return groupedSubjects;
                }, {})
              },
              async downloadPDF() {
                this.groupedSubjects = this.groupBySubjects(this.selectedSkill.questions);
                console.log(this.groupedSubjects)
                this.downloadLoader = true;
                const pages = Array.from(document.querySelectorAll('div[aria-label^="pdf-page-"]'));
                // Function to process MathML elements with MathJax
                const processMathMLElements = async (element) => {
                    const mathElements = element.querySelectorAll('math'); // Find all MathML elements
                    for (const mathElement of mathElements) {
                        const mathContent = mathElement.innerHTML.trim();
                        if (mathContent) {
                            // Use a div to allow MathJax to render properly
                            mathElement.outerHTML = `<div style="font-size: 16px; display: inline-block;">${mathContent}</div>`;
                        } else {
                            mathElement.outerHTML = '<div style="font-size: 16px; color: red;">[Empty MathML]</div>';
                        }
                    }
                    // Typeset the math elements using MathJax
                    await MathJax.typesetPromise([element]);
                };

                // Process each page to handle MathML
                for (const page of pages) {
                    await processMathMLElements(page);
                }

                // Wait for all MathJax typesetting to finish
                await MathJax.typesetPromise();

                // PDF options
                const pdfOptions = {
                    margin: 1,
                    filename: 'document.pdf',
                    image: { type: 'jpeg', quality: 1 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                };

                // Initialize the html2pdf worker
                let worker = html2pdf().set(pdfOptions).from(pages[0]);

                // If there are multiple pages, process them individually
                if (pages.length > 1) {
                    worker = worker.toPdf(); // Create the first page PDF
                    // Append the rest of the pages
                    for (let i = 1; i < pages.length; i++) {
                        await worker
                            .get('pdf')
                            .then((pdf) => {
                                pdf.addPage(); // Add a new page
                            })
                            .from(pages[i]) // Add the content of the next page
                            .toContainer()
                            .toCanvas()
                            .toPdf();
                    }
                }

                // Save the PDF once all pages are processed
                await worker.save();
                this.downloadLoader = false;
              },
              imageInOption(questionOptions){
                return questionOptions.some(option=>(option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')));
              },
              getQuestionsForDisplay(){
                this.questionsToDisplay = this.selectedSkill.questions.filter(question=>question.subject.includes(this.selectedSubjectName))
              },
              isCorrectResponse(myAnswer, correctAns) {
                if (myAnswer && correctAns) {
                  correctAns = correctAns.split(',').filter(answer => /^[A-Ea-e]$/.test(answer)).join(',');
                  var sortedStr = correctAns.split(",").sort().join("");
                  myAnswer.sort();
                  return myAnswer.join("") === sortedStr ? true : false;
                } else {
                  return false;
                }
              },
              returnCorrectAnswerAssetURL(question){
                let questionOptions = question[this.prefferedLanguage.toLowerCase()].questionOptions;
                let correctOption = questionOptions.find(({optionKey})=>question.myAnswer.includes(optionKey))
                return correctOption?.isOptionAssetUrlResolved ? correctOption.optionValue : this.loadingAssetURL;
              },
              selectChip(chip) {
                this.currentSelectedLanguage = chip; // Set the selected language
              },
              backToCampaignUserListingPage(){
                this.$router.push({
                  path: "/mestacampaignuser",
                  name: "mestaCampaignUser",
                  query: {
                    id: this.campaignId,
                    campaignName: this.campaignName,
                    campaignType: this.campaignType,
                  },
                });
              }
        }
        } 
</script>
<style scoped>
  .total-score-container {
    border: 1px solid #000;
    padding: 12px 24px;
    border-radius: 8px;
  }
  .result-details {
    gap: 16px;
  }
  .language-chips {
    display: flex;
    gap: 8px;
    .v-chip.v-chip--outlined.v-chip.v-chip {
      background-color: #e0e0e0 !important;
    }
  }
  .language-chip {
    margin: 4px;
    padding: 4px 8px;
    font-size: 0.875rem;
  }
  .questions-other-info {
    gap: 12px;
  }
  .questions-info-container {
    span {
      font-size: 14px;
    }
  }
  .option-text {
    font-size: 14px;
  }
  .test-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray-05);
    margin-bottom: 16px;
  }
  .tab-btn {
    font-size: 14px;
    border-color: inherit !important;
    width: 100% !important;
    height : auto !important;
    padding: 10px !important;
    border-left-width: thin !important;
  }
  .correct-option {
    color: #06C270;
    padding-right: 12px;
    margin-top: 4px;
  }
  
  .other-option {
    padding-right: 12px;
    margin-top: 4px;
  }
  
  .correct-answer {
    font-size: 14px;
    color: #06C270;
    padding-left: 4px;
  }
  
  .incorrect-answer {
    font-size: 14px;
    color: #EE4D37;
    padding-left: 4px;
  }
  .skipped-answer{
    font-size: 14px;
    color: #F08D32;
    padding-left: 4px;
  }
  .pdfSubTitle {
    margin: 8px 12px;
  }
  .hide-it{
    position: absolute;
    top:-100000vh;
  }
  .pdf-item{
    page-break-inside: avoid;
  }
  ::v-deep .v-image.correct-answer-image .v-image__image {
    border: 2px solid #06C270 !important;
    border-radius: 8px;
  }
  
  ::v-deep .v-image.option-image-border .v-image__image {
    border: 1px solid #0000001A;
    border-radius: 8px;
  }
</style>