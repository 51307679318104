<template>
  <div>
    <v-col>
      <v-card height="64px" width="100%"
        class="ml-4 pl-1 pt-5 background elevation-0 d-flex flex-row justify-space-between align-center fixBar">
        <v-card-title class="pl-0 ml-0">
          <span @click="$router.push('/')" class="breadcrumb-text-unselected underline-on-hover cursor">Dashboard</span>

          <span><v-icon class="breadcrumb-arrow-unselected">mdi-chevron-right</v-icon></span>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="breadcrumb-text">
                Campaign Result
                <v-icon class="breadcrumb-arrow cursor">mdi-chevron-down</v-icon>
              </span>
            </template>

            <v-list>
              <v-list-item @click="$router.push('/schoolteachersappraisal')">No. of teachers to be Appraised</v-list-item>
              <v-list-item @click="$router.push('/schoolteacherspip')">No. of teachers on PIP</v-list-item>
              <v-list-item @click="$router.push('/schoolteacherstbd')">No. of teachers on TBD</v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
      <UserNavMenu/>
      </v-card>

      <div class="pt-12">

        <div class="background fixTop30 ml-2 pt-4">

          <v-container class="funnel-contianer pl-0 pr-7 ml-3" fluid>
            <div class="w-100 d-flex flex-row">
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      TEACHERS
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Registered/Invited</span
                    >
                    <FunnelChartCommon :invites="schoolChartData" scaleMax="invited" propertyBegin="invited" propertyEnd="mainsStarted" funnelColor="#E0ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolChartData.invited -
                              schoolChartData.screeningStarted,
                            schoolChartData.invited
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <!-- <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      SCREENING
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="schoolChartData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolChartData.screeningStarted -
                              schoolChartData.screeningPassed,
                            schoolChartData.screeningStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      SCREENING RESULT
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="schoolChartData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolChartData.screeningPassed -
                              schoolChartData.mainsStarted,
                            schoolChartData.screeningPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div> -->
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      MAINS
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="schoolChartData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolChartData.mainsStarted -
                              schoolChartData.mainsPassed,
                            schoolChartData.mainsStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      MAINS RESULT
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="schoolChartData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="mainsPassed"/>
                    <!-- <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolChartData.mainsPassed -
                              schoolChartData.demoSubmitted,
                            schoolChartData.mainsPassed
                          )
                        }}%
                      </div>
                    </div> -->
                  </v-card-text>
                </v-card>
              </div>
              <!-- <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      DEMO
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Submitted</span
                    >
                    <FunnelChartCommon :invites="schoolChartData" scaleMax="invited" propertyBegin="demoSubmitted" propertyEnd="interviewSubmitted"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolChartData.demoSubmitted -
                              schoolChartData.interviewSubmitted,
                            schoolChartData.demoSubmitted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div> -->
              <!-- <div class="rounded-lg px-0 py-0 pl-1 w-19">
                <v-card class="cardBorder rounded-lg pr-0 pl-0" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                    <p class="font-weight-bold pl-2 funnelText">CAMPAIGN SUMMARY</p>
                  </v-card-title>

                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    For Appraisal : {{ schoolChartData.totalMainsPassed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    For PIP : {{ schoolChartData.totalMainsFailed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    For TBD : {{ schoolChartData.totalScreeningFailed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                </v-card>
              </div> -->
            </div>
            <!-- <v-col cols="2.5">
                <FunnelChart/>
               </v-col>
               <v-col cols="2.5">
                <FunnelChart/>
               </v-col>
               <v-col cols="2.5">
                <FunnelChart/>
               </v-col> -->
          </v-container>

          <v-row class="d-flex justify-center pt-0 my-0 ml-1 pr-1" fluid>
            <v-col cols="4" md="4" sm="4">
              <div class="text-h6 font-weight-bold highEmphasis">
                Campaign Result
              </div>
            </v-col>

            <v-col cols="8" md="8" sm="8" class="d-flex flex-row justify-end">
              <v-card class="elevation-0 ma-0 pa-0 transparent">
                <v-text-field solo hide-details label="Search here..." prepend-inner-icon="mdi-magnify"
                  class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              </v-card>

              <v-btn depressed rounded outlined class="btn outline-btn mx-3"><v-icon>mdi-tune</v-icon>FILTER</v-btn>
              <!-- <v-btn depressed rounded outlined class="border-button mx-3"
            ><img src="../../../public/svgs/SORT.svg" alt="" />SORT</v-btn
          > -->

              <!-- <v-btn
              class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
              <v-btn depressed outlined rounded class="btn outline-btn mx-3"><v-icon>mdi-import
                  mdi-rotate-90</v-icon></v-btn>
            </v-col>
          </v-row>

        </div>

        <v-card elevation="0" class="ml-6 mr-4 mt-0">
          <v-card-title class="text-subtitle-1 pa-0">
            <table class="tbl">
              <thead class="t-head">
                <tr class="t-row">
                  <th class="head">Rank</th>
                  <th class="head">Full Name</th>
                  <th class="head">Campaign</th>
                  <!-- <th class="head">School Name</th> -->
                  <th class="head">Stage</th>
                  <th class="head">Status</th>
                  <th class="head">Mains Net Score</th>
                </tr>
              </thead>
              <tbody class="t-body">
                <tr class="t-row" v-for="(teacher, index) in displayedClusterTeachers" :key="teacher.id">
                  <td class="t-data">{{ index + 1 }}</td>
                  <td class="t-data">
                    {{ `${teacher.firstName} ${teacher.lastName}` }}
                  </td>
                  <td class="t-data">{{ teacher.campaignName }}</td>
                  <!-- <td class="t-data">{{ teacher.schoolName }}</td> -->
                  <td class="t-data">
                    {{
                      teacher.stage.stage === "Mains"
                      ? teacher.stage.stage
                      : "-"
                    }}
                  </td>
                  <td class="t-data">
                    <v-chip :class="{
                      pass: teacher.stage.status === 'Pass', fail: teacher.stage.status === 'Fail',
                      'yetToAttempt': teacher.stage.status === 'Yet to Attempt'
                    }" outlined v-if="teacher.stage.stage === 'Mains'">
                      <div :class="{
                        dotpass: teacher.stage.status === 'Pass', dotfail: teacher.stage.status === 'Fail',
                        dotyetToAttempt: teacher.stage.status === 'Yet to Attempt'
                      }"></div>{{ teacher.stage.status }}
                    </v-chip>
                    <template v-else>-</template>
                  </td>
                  <td class="t-data">
                    {{ teacher.mainsTotal }}
                  </td>
                </tr>
              </tbody>

              <!-- <tbody class="t-body">
              <tr class="t-row">
                <td class="t-data">1</td>
                <td class="t-data">93423 84234</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data h-100 d-flex align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">78%</td>
                <td class="t-data">78%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">2</td>
                <td class="t-data">93423 84234</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data h-100 d-flex align-center justify-center">
                  <v-chip class="ma-1 pass-color" text-color="#06C270" size="x-small">
                    <div class=" dot-pass"></div>
                    Pass
                  </v-chip>
                </td>
                <td class="t-data">78%</td>
                <td class="t-data">78%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">3</td>
                <td class="t-data">93423 84234</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data h-100 d-flex align-center justify-center">
                  <v-chip class="ma-1 pass-color" text-color="#06C270" size="x-small">
                    <div class=" dot-pass"></div>
                    Pass
                  </v-chip>
                </td>
                <td class="t-data">78%</td>
                <td class="t-data">78%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">4</td>
                <td class="t-data">93423 84234</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data h-100 d-flex align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">78%</td>
                <td class="t-data">78%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">5</td>
                <td class="t-data">93423 84234</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Screening</td>
                <td class="t-data h-100 d-flex align-center justify-center">
                  <v-chip class="ma-1 pass-color" text-color="#06C270" size="x-small">
                    <div class=" dot-pass"></div>
                    Pass
                  </v-chip>
                </td>
                <td class="t-data">94%</td>
                <td class="t-data">-</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">6</td>
                <td class="t-data">93423 84234</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data h-100 d-flex align-center justify-center">
                  <v-chip class="ma-1 fail-color" text-color="#FF0505" size="x-small">
                    <div class="dot-fail"></div>
                    Fail
                  </v-chip>
                </td>
                <td class="t-data">65%</td>
                <td class="t-data">72%</td>
              </tr>
            </tbody> -->
            </table>
          </v-card-title>
        </v-card>

      </div>

    </v-col>
  </div>
</template>
  
<script >
import AuthService from "@/services/AuthService";

import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  collectionGroup,
  query,
  where,
  QuerySnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import FunnelChartCommon from "../FunnelChartCommon.vue";
import { liveListenerUnsubscriber } from "@/mixins/liveListenerUnsubcriber";
import UserNavMenu from "@/components/UserNavMenu.vue";
export default {
  name: "CampaignResult",
  components: {
    FunnelChartCommon,UserNavMenu
  },
  mixins: [ liveListenerUnsubscriber ],
  data() {
    return {
      data: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
        },

        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
        },
      ],

      clusters: [],
      schoolClusterTeacher: [],
      topTeacherInCluster: [],
      invited: 0,
      screeningAttempted: 0,
      screeningResult: 0,
      mainsAttempted: 0,
      result: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      chartData: {},
      schoolChartData: {},
      emptySchoolChartData: {
        invited: 0,
        screeningStarted: 0,
        screeningPassed: 0,
        screeningFailed: 0,
        mainsStarted: 0,
        mainsPassed: 0,
        mainsFailed: 0,
        demoSubmitted: 0,
        interviewSubmitted: 0,
      },
      schooolTeacherAppraisal: 0,
      schooolTeacherPip: 0,
      schooolTeacherTBD: 0,
      jobSeekerList: [],
    };
  },

  computed: {
    displayedClusterTeachers() {
      return this.topTeacherInCluster;
    },
  },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    getClusterOrSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;

      console.log("my cluster>>", this.roleCluster);
      console.log("my school>>", this.roleSchool);
    },

    async teacherSchoolSnapshotListener() {
      const roleSchool = this.$store.state.role.schools;
      const capRoleSchool = roleSchool[0].toUpperCase() + roleSchool.slice(1).toLowerCase();
      console.log("roleSchool>>", roleSchool);
      console.log("capRoleSchool>>", capRoleSchool);
      const campaignsRef = collection(db, "campaigns");
      const teacherClusterQuery = query(
        campaignsRef,
        where("audienceType", "==", "TEACHER"),
        where("schools", "array-contains-any", [roleSchool,capRoleSchool])
      );
      let unsubscribe = onSnapshot(teacherClusterQuery, (snapshot) => {
        this.passedJobSeekersCount = 0;
        this.rejectedJobSeekersCount = 0;
        this.tdbJobSeekersCount = 0;
        this.otherJobSeekersCount = 0;
        this.jobSeekerList = [];
        console.log("this.$store.state.role.schools--->", roleSchool);
        console.log(snapshot.size);
        // Loop through each document in the campaigns collection snapshot
        snapshot.docs.forEach(async (doc) => {
          const campaignName = doc.data().name;

          // Check if the document is for job seekers
            try {
              const jobSeekerRef = collection(campaignsRef, doc.id, "audience");
              const jobSeekDocs = await getDocs(jobSeekerRef);
              
              // Process each job seeker document in the audience sub-collection
              for (const d of jobSeekDocs.docs) {
                const jobSeekerData = d.data();
                const jobSeekerSchoolName = jobSeekerData.school || jobSeekerData.schoolName || "Unassigned";
                console.log(jobSeekerSchoolName);
                if (
                  roleSchool.toLowerCase() === jobSeekerSchoolName.toLowerCase()
                ) {
                      // Ensure coreSkillsSubjectScreening exists and is iterable
                        try {
                          let mainsTotal = 0;
                          let mainsNetScore = 0;
                          // console.log("campaignStatus:",jobSeekerData.campaignStatus[jobSeekerData.campaignStatus.length-1].status)

                          if (jobSeekerData?.coreSkillsSubject?.length > 0) {
                            jobSeekerData.coreSkillsSubject.forEach((jobSeekerMainsData) => {
                            mainsTotal += parseFloat(jobSeekerMainsData?.finalNetScore);
                          });
                          mainsTotal = jobSeekerData.coreSkillsSubject.length > 0 ? mainsTotal / jobSeekerData.coreSkillsSubject.length : 0;
                          mainsNetScore = (mainsTotal) ? mainsTotal.toFixed(2) : 0.00;
                        }
                          // Prepare the job seeker data for display
                          const temp = {
                            firstName: jobSeekerData?.personalInfo?.firstName || "",
                            lastName: jobSeekerData?.personalInfo?.lastName || "",
                            campaignName: campaignName,
                            schoolName: jobSeekerSchoolName,
                            mainsTotal: mainsNetScore,
                            status: jobSeekerData.campaignStatus ? jobSeekerData.campaignStatus[jobSeekerData.campaignStatus.length - 1].status : "INVITED",
                            stage: this.getLastStatus(jobSeekerData.campaignStatus),
                            campaignId: doc.id,
                            userId: jobSeekerData.userId
                          };

                          // Determine the stage based on status
                    

                          this.jobSeekerList.push(temp);
                        } catch (error) {
                          console.error("Error fetching screening or mains documents:", error);
                        }
                  }
                }
                this.jobSeekerList.sort((a, b) => {
        const mainsTotalA = isNaN(Number(a.mainsTotal)) ? 0 : Number(a.mainsTotal);
        const mainsTotalB = isNaN(Number(b.mainsTotal)) ? 0 : Number(b.mainsTotal);
        return mainsTotalB - mainsTotalA;
      });
              this.topTeacherInCluster = this.jobSeekerList;
              // console.log(this.topTeacherInCluster);
            } catch (error) {
              console.error("Error fetching audience collection:", error);
            }
        });
      });
      this.liveListenersToUnsubscribeMixin.push(unsubscribe);
    },
    getLastStatus(campaignStatus) {
      if (!campaignStatus.length) return { stageValue: "", statusValue: "" };
      
      const lastStatus = campaignStatus[campaignStatus.length - 1].status;
      const statusMap = {
        "INVITED": { stage: "Mains", status: "Yet to Attempt" },
        "SCREENING STARTED": { stage: "Mains", status: "Yet to Attempt" },
        "SCREENING PASSED": { stage: "Mains", status: "Yet to Attempt" },
        "SCREENING FAILED": { stage: "Mains", status: "Yet to Attempt" },
        "MAINS STARTED": { stage: "Mains", status: "In Progress" },
        "MAINS PASSED": { stage: "Mains", status: "Pass" },
        "MAINS FAILED": { stage: "Mains", status: "Fail" }
      };

      const { stage, status } = statusMap[lastStatus] || { stage: "", status: "" };
      return { stage: stage, status: status };
    },
    // async teacherSchoolSnapshotListener() {
    //   const campaignRef = collection(db, "campaigns"); // const schoolMarks = {}
    //   onSnapshot(campaignRef, (snapshot) => {
    //     snapshot.docs.forEach(async (doc) => {
    //       // console.log("asldfjlasdkfj...>>>", doc.id, ".....", doc.data())
    //       const campaignName = doc.data().name;
    //       // if teacher, run this
    //       // console.log("doc id >>", doc.id)
    //       if (doc.data().audienceType.toLowerCase() === "teacher") {
    //         // console.log("teacher >>", doc.id);
    //         try {
    //           const jobSeekerRef = collection(campaignRef, doc.id, "audience");
    //           const jobSeekDocs = await getDocs(jobSeekerRef);

    //           jobSeekDocs.forEach((d) => {
    //             console.log("jobSeekersDocsTraverse...", d.data());
    //             //countTeacherCluster++;
    //             //console.log("screening total >>", d.data().screeningTotal);
    //             if (d.data().schoolName == this.$store.state.role.schools) {
    //               console.log("entered...////");
    //               const topTeachersClusterData = {
    //                 screeningTotal:
    //                   d.data().screeningTotal == null
    //                     ? "-"
    //                     : d.data().screeningTotal,
    //                 mainsTotal:
    //                   d.data().mainsTotal == null ? "-" : d.data().mainsTotal,
    //                 firstName:
    //                   d.data().personalInfo.firstName == null
    //                     ? ""
    //                     : d.data().personalInfo.firstName,
    //                 lastName:
    //                   d.data().personalInfo.lastName == null
    //                     ? ""
    //                     : d.data().personalInfo.lastName,
    //                 schoolName:
    //                   d.data().schoolName == null ? "" : d.data().schoolName,
    //                 lastStatus: (() => {
    //                   let stageValue = "";
    //                   let statusValue = "";

    //                   if (
    //                     d.data().campaignStatus &&
    //                     d.data().campaignStatus.length > 0
    //                   ) {
    //                     const lastStatus =
    //                       d.data().campaignStatus[
    //                         d.data().campaignStatus.length - 1
    //                       ].status;
    //                     if (lastStatus === "INVITED") {
    //                       stageValue = "Screening";
    //                       statusValue = "Yet to Attempt";
    //                     } else if (lastStatus === "SCREENING PASSED") {
    //                       stageValue = "Mains";
    //                       statusValue = "Yet to Attempt";
    //                     } else if (lastStatus === "SCREENING FAILED") {
    //                       stageValue = "Screening";
    //                       statusValue = "Fail";
    //                     } else if (lastStatus === "MAINS PASSED") {
    //                       stageValue = "Mains";
    //                       statusValue = "Pass";
    //                     } else if (lastStatus === "MAINS FAILED") {
    //                       stageValue = "Mains";
    //                       statusValue = "Fail";
    //                     }
    //                   }

    //                   return {
    //                     stageValue: stageValue,
    //                     statusValue: statusValue,
    //                   };
    //                 })(),

    //                 campaignName: campaignName,
    //               };
    //               this.schoolClusterTeacher.push(topTeachersClusterData);
    //               console.log(
    //                 "screening total result show >>",
    //                 d.data().screeningTotal
    //               );
    //             }
    //             //console.log("naresh sir", this.schoolClusterTeacher);
    //             // data.schoolMarks[d.data().schoolName] += d.data().screeningTotal
    //           });
    //           //this.countTeacherCluster = countTeacherCluster;
    //           let sortedProducts = this.schoolClusterTeacher.sort((p1, p2) =>
    //             p1.screeningTotal < p2.screeningTotal
    //               ? 1
    //               : p1.screeningTotal > p2.screeningTotal
    //                 ? -1
    //                 : 0
    //           );
    //           this.topTeacherInCluster = this.schoolClusterTeacher;
    //         } catch (error) {
    //           console.error("Error fetching audience collection:", error);
    //         }
    //       }
    //     });
    //   });
    // },

    async setupSnapshotListenerSchoolHrFunnel() {
      const roleId = this.$store.state.role.id;
      console.log("roleId",roleId)
      const dashboardRef = doc(db, "dashboards", `${roleId}-vgos-teachers-dashboard`);

      if (!dashboardRef) {
        this.schoolChartData = this.emptySchoolChartData;
        return;
      }

      const resultsRef = collection(dashboardRef, "results");

      if (!resultsRef) {
        this.schoolChartData = this.emptySchoolChartData;
        return;
      }

      const campaignDocRef = doc(resultsRef, "campaignresults");

      if (!campaignDocRef) {
        this.schoolChartData = this.emptySchoolChartData;
        return;
      }

      let unsubscribe = onSnapshot(campaignDocRef, (docSnapshot) => {
        if (!docSnapshot.exists()) {
          this.schoolChartData = this.emptySchoolChartData;
          return;
        }

          const data = docSnapshot.data();

          if (
              data.invited === undefined ||
              data.screeningStarted === undefined ||
              data.screeningPassed === undefined ||
              data.screeningFailed === undefined ||
              data.mainsStarted === undefined ||
              data.mainsPassed === undefined ||
              data.mainsFailed === undefined ||
              data.demoSubmitted === undefined ||
              data.interviewSubmitted === undefined
          ) {
            this.schoolChartData = this.emptySchoolChartData;
          } else {
              this.schoolChartData = data
          }
      });
      this.liveListenersToUnsubscribeMixin.push(unsubscribe);
  },
    calculatePercentage(value, total) {
      if (total === 0) {
        // console.log("Error: Total cannot be zero.");
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        // console.log("Error: Invalid input. Please provide valid numbers.");
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      // console.log(roundedPercentage);
      return roundedPercentage;
    },
  },

  async created() {
    this.getClusterOrSchool();
    this.setupSnapshotListenerSchoolHrFunnel();
    this.teacherSchoolSnapshotListener();
  },
};
</script>
  
<style scoped>
.tbl>.t-head>.t-row>.head {
  background-color: primary;
}

.tbl {
  height: 196px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.tbl th {
  padding: 8px;
}

.tbl td {
  padding: 5px;
  /* Adjust the padding value as per your preference */
}

.tbl>.t-body>.t-row {
  background-color: white;
}

.head {
  text-align: center;
}

.tbl>.t-body>.t-row>.t-data {
  text-align: center;
}

.tbl>tr:nth-child(even) {
  background-color: white !important;
}


.fail {
  color: #FF0505;
  /* background-color: #ff0505; */

}

.pass {
  color: #06C270;
  /* background-color: #06c270; */
}

.yetToAttempt {
  color: #000000DE;
  /* background-color: #000000de; */
}

.dotyetToAttempt {
  width: 8px;
  height: 8px;
  background-color: #000000de;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dotfail {
  width: 8px;
  height: 8px;
  background-color: #ff0505;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dotpass {
  width: 8px;
  height: 8px;
  background-color: #06c270;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
</style>