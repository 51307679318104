<template>
  <div class="repUpDivRight">
    <div class="pentagon-first-border">
      <div class="pentagon-first-content"
        :class="{'pentagon-teacher': userType === 'teacher'}"
        @click="navigateToReport('test', 0)">
        <div class="pentCont-first">
          <div class="pentRow league-title">
            <p>Screening Test</p>
          </div>
          <p class="fs-12">Score - <span class="ff-bold">{{ formatScore(screeningData.netScore) }}/ 10</span></p>
        </div>
      </div>
    </div>
    
    <div class="pentagon-border">
      <div class="pentagon-content" 
        :class="{'pentagon-teacher': userType === 'teacher'}"
        @click="navigateToReport('test', 1)">
        <div class="pentCont">
          <div class="pentRow league-title">
            <p>Mains test</p>
          </div>
          <p class="fs-12">Score - <span class="ff-bold">{{ formatScore(mainsData.netScore) }}/10</span></p>
        </div>
      </div>
    </div>
    
    <div class="pentagon-border">
      <div class="pentagon-content" 
        :class="{'pentagon-teacher': userType === 'teacher'}"
        @click="navigateToReport('demo')">
        <div class="pentCont">
          <div class="pentRow league-title">
            <p>Demo Video</p>
          </div>
          <p class="fs-12">Score - <span class="ff-bold">{{ formatScore(demoData.netScore) }}/{{demoData.total}}</span></p>
        </div>
      </div>
    </div>

    <div class="pentagon-border">
      <div class="pentagon-content" v-if="userType !== 'teacher'"
        @click="navigateToReport('interview')">
        <div class="pentCont">
          <div class="pentRow league-title">
            <p>Interview</p>
          </div>
          <p class="fs-12">score - <span class="ff-bold">{{ formatScore(interviewScore) }}</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PentagonCharts',
  props: {
    userType: String,
    screeningData: Object,
    mainsData: Object,
    demoData: Object,
    interviewScore: Number,
    campaignId: String,
    userId: String
  },
  methods: {
    formatScore(score) {
      return score >= 0 ? score.toFixed(1) : 'N/A'
    },
    navigateToReport(type, select) {
      let path = '';
      switch(type) {
        case 'test':
          path = `/testreport/${this.campaignId}/${this.userId}?isSelect=${select}`;
          break;
        case 'demo':
          path = `/demoreport/${this.campaignId}/${this.userId}`;
          break;
        case 'interview':
          path = `/interviewReport/${this.campaignId}/${this.userId}`;
          break;
      }
      this.$router.push(path);
    }
  }
}
</script>
<style scoped>
.repUpDivRight{
  margin-bottom: 0px;
}
.pentagon-teacher{
    width: 35.5%;
    padding:  1.3vw 1.3vw 1.3vw 2vw;
}

.dual-elipsiss{
    width: 250px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.v-btn-toggle .v-btn.v-btn.v-size--default {
    min-width: 100px;
    min-height: 0;
}
.recom-labe1-typo{
    color: rgba(0, 0, 0, 0.87);
    font-family: GraphikRegular;
    font-size: 12px;
}
.chart-height{
    height: 41vh
}
.interviewer-name{
    text-wrap: nowrap;
    width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.league-info-label{
    color: rgba(0, 0, 0, 0.6);
}
.interview-remark{
    overflow: auto;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    font-size: 12px;
    height: 11vh;
    scrollbar-width: none;
}
.not-recommended,.below-standard, .moderately-recommended, .highly-recommended, .strongly-recommended{
    min-width: 140px;
}
.not-recommended{
    color: #FF2600;
    background-color: #FFE9E5 ;
    border: 1px solid #FF2600;
}
.below-standard{
    color: #E39236;
    background-color: #FCF3E9;
    border: 1px solid #E39236;
}
.moderately-recommended{
    color:#97AD39;
    background-color: #E5ECC5;
    border: 1px solid #97AD39;
}
.highly-recommended{
    color:#5F9C4A;
    background-color: #D2E6CB;
    border: 1px solid #5F9C4A;  
}
.strongly-recommended{
    color: #56903E;
    background-color: #CFE2D2;
    border: 1px solid #56903E;
}
.br-right{
    border-right: 1px solid  rgba(217, 217, 217, 1);
}

.not-recommended-remark{
    color: #FF2600;
}
.below-standard-remark{
    color: #E39236;
}
.moderately-recommended-remark{
    color:#97AD39;
    min-width: 173px;
}
.highly-recommended-remark{
    color:#5F9C4A;
}
.strongly-recommended-remark{
    color: #56903E;
}

.ff-bold{
    font-family: GraphikMedium;
}
.fs-14{
    font-size: 14px; 
}
.fs-12{
    font-size: 12px; 
}
.sub-chip{
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 4px 0px 0px 4px;
}
.fs-10{
    font-size: 10px;
    color: rgba(0, 0, 0, 0.6);
    font-family: GraphikRegular;
 
}
.br-40{
    border-radius: 40px;
}
.final-br{
  border-radius: 8px;
  background-color: #fff; 
}
.active-br, .meter-container-br{
    background-color: rgba(77, 105, 91, 0.09);
}
.meter-container-br{
    border-radius: 0px 8px 8px 0px;
}
.chip-replace{
    font-family: GraphikRegular;
    font-size: 11px;
    padding: 5px;
    text-align: center;
    font-weight: 200;
    border-radius: 8px;
    text-transform: uppercase;
}
.gap-8p{
    gap: 8px
}
.row{
    margin: 0px;
    padding: 5px;
}
.col{
    padding: 7px;
    width: 160px;
}
.v-progress-circular__overlay {
     stroke-linecap: round;
 } 

 .metChartDiv v-chip{
    font-size: 10px;
 }

 .repUpDiv .row {
    padding: 0;
 }
/*
::-webkit-scrollbar {
    display: none;
  }
*/
.repUpDivLeft-brown-bdr {
  border: 1px solid var(--blue-01);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  padding: 0.6rem;
  background-color: white;
}
.interviewRate, .interviewRate1, .interviewRate2, .interviewRate3, .interviewRate4, .interviewRate5, .interviewRate6, .interviewRate7, .interviewRate8, .interviewRate9, .interviewRate10 {
    margin: 0 16px;
    color: white !important;
}
.reportTableText{
    font-family: "GraphikRegular" !important;
    font-weight: 500 !important; 
}
.primary-box-red{
    background-color: #D4002829 !important;
    border: 1px solid #D40028;
}
.primary-box-orange{
    background-color: #FB750029 !important;
    border: 1px solid #FB7500 !important;
}
.primary-box-yellow{
    background-color: #FADC0029 !important;
    border: 1px solid #FADC00;
}
.primary-box-green{
    background-color: #06C27029 !important;
    border: 1px solid #06C270 !important;
}
.secondary-box-red{
  background-color: #D4002829 !important;
}
.secondary-box-orange{
  background-color: #FB750029 !important;
}
.secondary-box-yellow{
  background-color: #FADC0029 !important;
}
.secondary-box-green{
  background-color: #06C27029 !important;
}
</style>