<template>
  <v-app class="background">
    <div v-if="this.$route.name != 'login' && this.$route.name != 'InvitedLogin'  && this.$route.name != 'ForgetPassword' && this.$route.name != 'InterviewFeedbackForm' && this.$route.name !== 'ResetPasswordView'">
      <NavDrawer />
    </div>

    <v-main class="background">


<!-- 
      <v-app-bar elevation="0" class="ml-8 mr-4 mt-4 transparent d-flex rounded-xl" v-if="this.$route.name != 'login'">

        {{$store.state.breadcrumb}}
        
        <v-icon>mdi-chevron-down</v-icon>

        


        <p style="color: #FBF5F2;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis magnam fugit amet
          quam nemo provident minima soluta. Quam, eius voluptatem voluptate aliquid vero ghi </p>


        <v-icon class="pa-2">mdi-bell-outline</v-icon>






        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" text icon>
              <v-avatar>
                <v-icon class="pa-2">mdi-account-outline</v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>

      </v-app-bar> -->

      <router-view> </router-view>
    </v-main>
  </v-app>
</template>




<script>

import NavDrawer from "./components/NavDrawer.vue";
import AuthService from "./services/AuthService";
CKEDITOR.plugins.addExternal('ckeditor_wiris', 'https://www.wiris.net/demo/plugins/ckeditor/', 'plugin.js');
import "./styles.css"


export default {
  name: "App",
  components: {
    NavDrawer,
  },
  data() {
    return {
      drawer: false,
      mini: false,
      isLoggedIn: false,
      role: "",
      loginInfo: {},
      index: 0,
    }

  },
  methods: {
      logout() {
      AuthService.logout();
      this.$router.push("/login");
    }

,
   



    },
    mounted() {
    google.charts.load('current', {
      packages: ['corechart']
    });
},

  
};
</script>

<style>
.bgfont{
  font-family: graphikItalic !important;
}
</style>
