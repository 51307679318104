<template>
  <div>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      ">
      <v-card-title class="pl-0 ml-0">
        <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/masters')">
          Masters
        </p>
        <v-icon class="breadcrumb-arrow-unselected" style="margin-top: 6px !important">mdi-chevron-right</v-icon>
        <v-menu offset-y class="menuZindex">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
              Demo Topics
              <v-icon class="breadcrumb-arrow cursor" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </span>
          </template>
          <MastersDropdownComponent />
        </v-menu>

        <!-- <p class="breadcrumb-text" style="margin-top: 2px">Brands</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon> -->
      </v-card-title>
    <UserNavMenu/>
    </v-card>
    <div fluid class="pad px-8">
      <v-row>
        <v-col>
          <v-dialog max-width="887px" v-model="dialog" class="cdz" center>
            <v-form ref="form" lazy-validation>
              <v-card>
                <v-card-title class="gray-02 mb-8">
                  {{ formbtn() }} Topic</v-card-title>

                <v-card-text class="px-6 pb-0">
                  <v-row>
                    <v-col cols="4">
                      <v-select label="Select Level*" :items="levelsData" hide-details outlined v-model="levelName"
                        item-text="name" item-value="name" class="rounded-xl"
                        :rules="[(v) => !!v || 'Level name is required']" required @change="setGradesByLevel">
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select label="Select Grade*" :items="gradesDataByLevel" hide-details outlined
                        v-model="gradeName" item-text="name" item-value="name" class="rounded-xl"
                        :rules="[(v) => !!v || 'Grade name is required']" required @change="setSubjectsByGrade">
                      </v-select>
                    </v-col>
                    <v-col cols="4">


                      <v-select label="Select Subject*" :items="subjectsDataByGrades" hide-details outlined
                        v-model="subjectName" item-text="name" item-value="name" class="rounded-xl"
                        :rules="[(v) => !!v || 'Subject name is required']" required>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field hide-details outlined class="rounded-xl" v-model="topicName" label="Topic Name*"
                        :rules="[(v) => !!v || 'Topic name is required']" required></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- <v-text-field
                    v-for="(subtopic, index) in subTopics"
                    :key="index"
                    hide-details
                    outlined
                    class="rounded-xl mb-4"
                    v-model="subTopics[index]"
                    label="Enter Sub-topic Name*"
                    :rules="[(v) => !!v || 'Topic name is required']"
                    required
                  ></v-text-field> -->
                </v-card-text>
                <v-card-actions class="px-6 pb-6">
                  <small>*All fields are mandatory</small>
                  <v-spacer></v-spacer>
                  <v-btn width="102px" height="48px" rounded outlined class="btn outline-btn pa-4"
                    @click="dialog = false">Cancel</v-btn>
                  <v-btn width="102px" height="48px" :loading="loading" rounded @click="saveInputs"
                    class="btn primary-btn pa-4">{{
          formbtn() }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-col>
        <!-- <v-col cols="4">
        <v-text-field label="Search" prepend-inner-icon="mdi-magnify" clearable v-model="search"></v-text-field></v-col> -->
      </v-row>

      <!-- filter dialog -->
      <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
        <v-card width="570px" height="100%" class="filterDialogClass">
          <v-row class="mt-4 mx-1">
            <v-col>
              <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                Filter
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div @click="clearFilter" class="clear-filter-btn">
                CLEAR FILTER
              </div>
            </v-col>
          </v-row>

          <v-tabs vertical>
            <div class="ml-3 mt-4 mr-4 filterLine">
              <v-tab class="black--text justify-start" active-class="activeTab">
                <span>Levels</span>
                <span v-if="selectedLevelFilter.length > 0" class="ml-1">({{ selectedLevelFilter.length
                  }})</span>
              </v-tab>
              <v-tab class="black--text justify-start" active-class="activeTab">
                <span>Grades</span>
                <span v-if="selectedGradeFilter.length > 0" class="ml-1">({{ selectedGradeFilter.length
                  }})</span>
              </v-tab>
              <v-tab class="black--text justify-start" active-class="activeTab">
                <span>Subjects</span>
                <span v-if="selectedSubjectFilter.length > 0" class="ml-1">({{ selectedSubjectFilter.length
                  }})</span>
              </v-tab>
            </div>
            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="selectedLevelFilter" active-class="active-chip" column :multiple="true"
                    @change="getFilterCount">
                    <v-chip v-for="(level, index) in filteredLevels" :key="index" :value="level.name" elevated>
                      {{ level.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="selectedGradeFilter" active-class="active-chip" column :multiple="true"
                    @change="getFilterCount">
                    <v-chip v-for="(grade, index) in filteredGrades" :key="index" :value="grade.name" elevated>
                      {{ grade.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="selectedSubjectFilter" active-class="active-chip" column :multiple="true"
                    @change="getFilterCount">
                    <v-chip v-for="(subject, index) in filteredSubjects" :key="index" :value="subject.name" elevated>
                      {{ subject.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs>
          <div class="filterDialogButton">
            <v-card-actions class="px-6 pb-0">
              <v-spacer></v-spacer>
              <v-btn rounded outlined class="btn outline-btn pa-4" @click="filterDialog = false">Cancel</v-btn>
              <v-btn :disabled="selectedLevelFilter.length == 0 &&
          selectedGradeFilter.length == 0 &&
          selectedSubjectFilter.length == 0
          " rounded class="btn primary-btn pa-4" @click="getFilterCount(); getTopics();">Apply</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog max-width="887px" class="cdz" v-model="uploadTopicsUsers" center>
        <v-form ref="form" lazy-validation>
          <v-card>
            <v-card-title class="gray-02 mb-8">Upload Topics in Bulk</v-card-title>

            <div class="d-flex justify-center mb-6 bg-surface-variant">
              <div class="box2" @dragover.prevent>
                <div class="w-100 d-flex flex-row justify-center" style="margin-top: 80px">
                  <img src="../../../public/svgs/xlsx_icon 1.svg" />
                </div>

                <div class="w-100 d-flex flex-row justify-center mt-4">
                  <div>
                    <div class="font-weight-light text-center">
                      Drag and drop a file or
                      <div class="uplaodbtn2">
                        browse file
                        <input type="file" accept=".csv" @change="handleFileUpload" name="csv" />
                      </div>
                      <p class="grey--text">(Supported only .csv file)</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div v-if="image">
                    <div class="imgback">
                      <img :src="image" alt="" width="100" height="auto" class="img" />
                      <div class="pencil">
                        <v-icon class="editpen">mdi-pencil-circle</v-icon>
                        <input class="editlogo" type="file" name="csv" />
                      </div>
                    </div>
                  </div> -->
            </div>

            <v-card-actions class="px-9 pb-6">
              <v-btn @click="downloadTemplate" text class="mx-2 downloadColor">
                <img class="mr-2" src="../../../public/svgs/fi_share.svg" />
                <span class="blue--text"> Download Template Excel </span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn rounded width="102px" height="48px" outlined class="btn outline-btn pa-4" @click="() => {
          uploadTopicsUsers = false; //
          formbtnBool = false;
        }
          ">Cancel</v-btn>
              <v-btn depressed rounded width="102px" height="48px" class="btn primary-btn pa-4">Create</v-btn>
            </v-card-actions>
            <!---------------------- subject upload section ends here --------------------------->
          </v-card>
        </v-form>
        <!---------------------- subject upload section starts here --------------------------->
      </v-dialog>

      <v-dialog max-width="600px" max-height="80vh" v-model="uploadingTopicsDialog" center>
        <v-card class="pa-4">
          <v-row>
            <v-col cols="6" md="6">
              <v-card-title class="pa-0 ma-0">
                Uploading... {{ bulkTopicUploadResponse.length }} /
                {{ bulkTopicsJsonData.length }}
              </v-card-title>
            </v-col>
            <v-col cols="6" md="6">
              <v-btn class="float-right" icon
                @click="uploadingTopicsDialog = false; getTopics();"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-data-table :headers="headersresponce" :items="bulkTopicUploadResponse" :items-per-page="-1">
            <template v-slot:[`item.status`]="{ item }">
              <div class="d-flex flex-row ml-2">
                {{ item.status }}

                <v-icon v-if="item.status.includes('Uploaded Successfully!')" color="green">mdi-check-all</v-icon>
                <v-icon v-else color="red">mdi-alert-circle-outline</v-icon>
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex flex-row">
                <img width="36px" height="36" @click="updateData(item)" class="cursor" src="../../assets/edit.svg" />
                <img width="36px" height="36" class="cursor" src="../../assets/userdelete.svg" />
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-row class="d-flex justify-center my-0 py-0">
        <v-col>
          <div class="text-h6">Demo Topics</div>
        </v-col>

        <v-col cols="10" class="pl-0 ml-0">
          <v-row justify="end" class="mb-1 mt-0">
            <v-text-field solo v-model="search" label="Search here..." prepend-inner-icon="mdi-magnify" hide-details
               class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
            <!-- <v-btn  depressed outlined rounded class="border-button mx-3"
            ><v-icon>mdi-tune</v-icon>FILTER</v-btn
          >  -->
          <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="btn outline-btn elevation-0 mx-2 pl-4 pr-6"
                  :class="filterCount > 0 ? 'outline-disabled-btn' : ''"
                  rounded
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  :disabled="filterCount > 0"
                >
                <v-img
                v-bind="attrs" 
                v-on="on" 
                :style="filterCount > 0 ? 'filter: grayscale(100%) opacity(50%);' :''"
                :src="require(`@/assets/svg/sort.svg`)"
                />              
                  <span>SORT</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item 
                  v-for="option in sortOptions" 
                  :key="option.value" 
                  @click="selectSortOption(option.value)"
                  :class="{'selected-sort-option': selectedSort === option.value}"
                >
                  <v-list-item-title>
                    {{ option.label }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              </v-menu>
            <v-btn depressed outlined rounded class="btn outline-btn mx-3" @click="filterDialog = true"><v-icon class="pr-2">mdi-tune</v-icon><span
                v-if="filterCount > 0" >Filter ({{ filterCount }})</span>
              <span v-else @click="filterDialog = true">Filter</span></v-btn>
            <v-btn class="btn mx-3" :disabled="selected.length == 0"
            :class="!selected.length == 0 ? 'outline-btn' : 'disabled-outline-btn'"
            outlined rounded
              @click="deleteDialog = true"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>
            <!-- <v-btn class="primary mx-2"
            rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
            <v-btn depressed rounded outlined class="btn outline-btn mx-3" :loading="donwloadLoading" @click="downloadDemoTopics"><v-icon>mdi-import
                mdi-rotate-90</v-icon></v-btn>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="$store.state.role.school.admin" class="btn primary-btn mx-3" rounded v-bind="attrs"
                  v-on="on" depressed><v-icon class="pr-2">mdi-plus</v-icon>Create</v-btn>
              </template>
              <v-list>
                <v-list-item @click="(dialog = true), newCreateTopics()">
                  <v-list-item-title>
                    <v-icon class="pr-1">mdi-email-plus-outline</v-icon>CREATE
                    NEW TOPIC
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="uploadTopicsUsers = true">
                  <v-list-item-title>
                    <v-icon>mdi-account-multiple-plus </v-icon>

                    CREATE TOPICS IN BULK
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table fixed-header height="calc(100vh - 230px)" class="rounded-table"
        v-model="selected"  :headers="headers"
        :disable-sort="true"
        :items-per-page="pageSize"
        :loading="dataTableLoading"
        :items="topicsData" :options.sync="dataTableOptions" show-select :single-select="singleSelect" :server-items-length="demoTopicsCount" :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 100],
        }">
        <template v-slot:loading>
          <bubble-loader/>
        </template>
        <template v-slot:no-data>
        <div class="custom-no-data">
          <p>No Data Available</p>
        </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="$store.state.role.Brand.admin" icon class="mr-2 pa-4" @click="updateData(item)">
            <img width="36px" height="36px" class="cursor" src="../../assets/edit.svg" />
          </v-btn>
        </template>
        <!-- <template v-slot:no-results>
          <div class="mt-16 pt-16">
            <img 
            src="../../assets/noResultFound.svg"/>
          </div>
        </template> -->
      </v-data-table>
      <v-dialog v-model="deleteDialog" class="cdz" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-container></v-container>
            <v-card-text class="text-center">
              <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>

              <p class="text-h5 pt-6 pb-0">Delete Topics</p>
              <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn depressed class="btn outline-btn" large width="157px" rounded
                  @click="deleteDialog = false">CANCEL</v-btn>
                <v-btn class="btn primary-btn" depressed large width="157px" :loading="dLoading" rounded
                  @click="deleteData(selected)">DELETE</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="errorDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteSuccessDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Topic Deleted</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="successDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">Topic {{ formbtnValue() }}</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <!-- Notification Dialog -->
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import BrandController from "@/controllers/BrandController";
import GradeController from "@/controllers/GradeController";
import LevelController from "@/controllers/LevelController";
import SubjectController from "@/controllers/SubjectController";
import TopicsController from "@/controllers/DemoTopicsController";
import AuthService from "../../services/AuthService";
import Papa from "papaparse";
import Notification from "../Notification.vue";
import MastersDropdownComponent from "../../components/MastersDropdownComponent.vue"

import axios from "axios"
import {debounce} from "lodash";
import BubbleLoader from "@/components/BubbleLoader.vue";
import UserNavMenu from "@/components/UserNavMenu.vue";

export default {
  name: "DemoTopicsView",
  components: {
    Notification, MastersDropdownComponent, BubbleLoader,UserNavMenu
  },
  data() {
    return {
      filterCount: 0,
      selectedLevelFilter: [],
      selectedGradeFilter: [],
      selectedSubjectFilter: [],
      filterDialog: false,
      headersresponce: [
        { text: "Index in CSV", value: "topicIndex" },
        { text: "Status", value: "status" },
      ],
      demoTopicsCount : 0,
      bulkTopicUploadResponse: [],
      uploadingTopicsDialog: false,
      content: [],
      bulkTopicsJsonData: [],
      uploadTopicsUsers: false,
      search: "",
      appliedSearch: false,
      searchedResults: [],
      notificationDialog: false,
      options: {},
      dataTableOptions: {},
      pageSize: 10,
      page: 1,
      count: 0,
      errorDialog: false,
      successDialog: false,
      deleteSuccessDialog: false,
      errorMessage: "",
      dialog: false,
      topicName: "",
      focusedIndex: null,
      levelName: "",
      gradeName: "",
      topicsData: [],
      subjectName: "",
      chapterName: "",
      unitName: "",
      subTopics: [],
      subjectsData: [],
      levelsData: [],
      gradesData: [],
      filteredLevels:[],
      filteredGrades: [],
      filteredSubjects: [],
      editId: null,
      singleSelect: false,
      deleteDialog: false,
      dLoading: false,
      formbtnBool: false,
      brandData: [],
      selected: [],
      search: "",
      dataTableLoading: true,
      donwloadLoading: false,
      loading: false,
      searchBool: false,
      removedQuestions: [],
      sortCol:"topic",
      sortOrder: "asc", // Default sort order (asc or desc)
      selectedSort: "topicAsc", // Default selected sort option
      sortOptions: [
        { value: "topicAsc", label: "Topic - Ascending (A-Z)" },
        { value: "topicDesc", label: "Topic - Descending (Z-A)" },
        { value: "dateNewest", label: "Date - Newest" },
        { value: "dateOldest", label: "Date - Oldest" },
      ],
      headers: [
        { text: "Topic Name", value: "topic" },
        { text: "Sub-Topics Name", value: "subTopics" },
        { text: "Subject", value: "subject" },
        { text: "Level", value: "level" },
        { text: "Grade", value: "grade" },
        { text: "Action", value: "actions" },
      ],
      tableData: [],
      rules: {
        required: (value) => !!value || "Field is required",
      },
      gradesDataByLevel: [],
      subjectsDataByGrades: [],
    };
  },
  watch: {
    // Add watcher for search
    search(newValue) {
      // Update URL when search changes
      this.$router.replace({ 
        query: { 
          ...this.$route.query,
          search: newValue || undefined 
        }
      }).catch(() => {});
      this.search = newValue ?? "";
      this.dataTableOptions.page = 1;
      this.dataTableLoading = true;
      this.deboucedSearchTopics();
    },
    dataTableOptions: {
      handler() {
        this.pageSize = this.dataTableOptions.itemsPerPage;
        this.page = this.dataTableOptions.page;

        this.getTopics();
      },
      deep: true,
    },
    selectedGradeFilter: {
    handler() {
      console.log(this.selectedGradeFilter, "grade");
      this.selectedSubjectFilter = [];
      if (!this.selectedGradeFilter.length) {
        this.filteredSubjects = this.subjectsData;
        return;
      };

      // Find selected grades
      const selectedGrades = this.gradesData.filter(grade =>
        this.selectedGradeFilter.includes(grade.name)
      );

      // Get all subjects from the selected grades
      const subjectIds = new Set(selectedGrades.flatMap(grade => grade.subjects));
      this.filteredSubjects = this.subjectsData.filter(subject => subjectIds.has(subject.name));

    },
    deep: true
    
    },

    // selectedSubjectFilter: {
    //   handler() {
    //     console.log(this.selectedSubjectFilter, "subject");

    //     if (!this.selectedSubjectFilter.length) {
    //       this.filteredGrades = this.gradesData;
    //       this.filteredLevels = this.levelsData;
    //       return;
    //     };

    //     // Find grades that contain any of the selected subjects
    //     this.filteredGrades = this.gradesData.filter(grade =>
    //       grade.subjects.some(subjectId => this.selectedSubjectFilter.includes(subjectId))
    //     );

    //     // Get grade IDs
    //     const gradeIds = this.filteredGrades.map(grade => grade.name);

    //     // Find levels that contain these grades
    //     this.filteredLevels = this.levelsData.filter(level =>
    //       level.grades.some(grade => gradeIds.includes(grade))
    //     );
    //   },
    //   deep: true
    // },

    selectedLevelFilter: {
      handler() {
        console.log(this.selectedLevelFilter, "level");
        this.selectedGradeFilter = [];
        this.selectedSubjectFilter = [];
        if (!this.selectedLevelFilter.length) {
          this.filteredGrades = this.gradesData;
          this.filteredSubjects = this.subjectsData;
          return;
        };

        // Find selected levels
        const selectedLevels = this.levelsData.filter(level =>
          this.selectedLevelFilter.includes(level.name)
        );

        // Get grades from selected levels
        const gradeIds = new Set(selectedLevels.flatMap(level => level.grades));
        this.filteredGrades = this.gradesData.filter(grade => gradeIds.has(grade.name));

        // Get subjects from selected grades
        const subjectIds = new Set(this.filteredGrades.flatMap(grade => grade.subjects));
        this.filteredSubjects = this.subjectsData.filter(subject => subjectIds.has(subject.name));
      },
      deep: true
    }
  },
  methods: {

    async downloadTemplate(){
      let fileName = 'Hubble STAR - Demo Topics Sample File.csv'
      try {
        const fileUrl = `https://firebasestorage.googleapis.com/v0/b/vgos-sss-stag.appspot.com/o/csv_templates%2F${fileName}?alt=media`;
        const response = await axios({
          url: fileUrl,
          method: 'GET',
          responseType: 'blob' // Ensure response is treated as a Blob
        });

        // Create a temporary URL for the Blob
        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

        // Create a link element
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', fileName); // Set desired filename

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the click event on the link to initiate download
        link.click();

        // Clean up: remove the link and revoke the Blob URL
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },

    checkSearch(searchItem) {
      console.log("search", searchItem)
    },
    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedGradeFilter.length > 0) {
        this.filterCount += this.selectedGradeFilter.length
      }

      if (this.selectedLevelFilter.length > 0) {
        this.filterCount += this.selectedLevelFilter.length
      }

      if (this.selectedSubjectFilter.length > 0) {
        this.filterCount += this.selectedSubjectFilter.length
      }
    },

    clearFilter() {
      this.selectedLevelFilter = [];
      this.selectedGradeFilter = [];
      this.selectedSubjectFilter = [];
      this.selectedSort = "topicAsc";
      this.sortCol = "topic";
      this.sortOrder = "asc";
      this.filteredLevels = this.levelsData;
      this.filteredGrades = this.gradesData;
      this.filteredSubjects = this.subjectsData;
      this.getTopics();
      this.filterDialog = false;
      this.filterCount = 0;
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.csvFileToJSON(file);
      }
    },

    csvFileToJSON(file) {
      const requiredHeaders = [
        'level', 
        'grade',
        'subject',
        'topic',
      ];
      var contents = [];

      try {
        Papa.parse(file, {
          header: false,
          skipEmptyLines: true,
          complete: function (results) {
            this.content = results;

            contents = this.content.data;

            let checkHeaders = contents[0];
            const missingHeaders = requiredHeaders.filter(
              reqHeader => !checkHeaders.includes(reqHeader)
            );
            if (missingHeaders.length > 0) {
              const missingHead = "Following headers are missing:\n" + 
                    missingHeaders.join("\n") + "\n";
              this.downloadTextFile(missingHead, "uploadReport.txt");
              return;
            }

            var jsonData = [];

            var blankcell = false;
            console.log("parsed data", contents)
            let removedQuestions = [];
            for (var i = 0; i < contents.length; i++) {
              // var cells = rows[i].split(",");

              var rowData = {};
              var cells = contents[i];
              for (var j = 0; j < cells.length; j++) {
                if (i == 0) {
                  var headers = contents[i];
                } else {
                  if (cells[j] == "") {
                    this.skippedQuestion(i, headers[j]+" -> Blank Cell");
                    if(!removedQuestions.includes(i)) removedQuestions.push(i);
                    continue;
                  } else rowData[headers[j]] = cells[j].trim();
                }
              }
              if (i != 0) {
                if(!removedQuestions?.includes(i)) {
                  rowData["subject"] = [rowData["subject"]]
                  jsonData.push(rowData);
                } 
              }

            }

            if (this.removedQuestions.length != 0) {
              let msg2 = this.removedQuestions.join("\n");
              // this.downloadTextFile(msg, "uploadReport.txt");
              this.downloadTextFile(msg2, "uploadReport.txt");
            }
            this.bulkTopicsJsonData = jsonData;
            console.log("bulk data", this.bulkTopicsJsonData)
            this.uploadTopics();
          }.bind(this),
        });
      } catch (e) {
        console.error(e);
      }
    },

    uploadTopics() {
      this.uploadingTopicsDialog = true;
      this.uploadTopicsUsers = false;

      var index = 1;
      this.bulkTopicsJsonData.forEach(async (topic_) => {
        try {
          const response = await TopicsController.createTopic(topic_);
          if (response.status == 200) {
            console.log(topic_);
            this.bulkTopicUploadResponse.push({
              topicIndex: index,
              status: "Uploaded Successfully!",
            });
          } else {
            this.bulkTopicUploadResponse.push({
              topicIndex: index,
              status: "Uploaded Failed!",
            });
          }
          index++;
        } catch (error) {
          console.log(error);
        }
        console.log(this.bulkTopicUploadResponse);
      });
    },

    async searchTopics(search) {
      const response = await TopicsController.searchTopics(
        this.pageSize,
        this.page,
        search
      );

      console.log(response.data);
      console.log(this.searchBool);
      this.count = response.data.data.count;
      this.topicsData = response.data.data.rows;
    },

    async getTopics() {
      this.dataTableLoading = true;
      // const response = await TopicsController.getTopics();
      // if (response.status == 200) {
      //   this.topicsData = response.data.topics;
      //   this.dataTableLoading = false;
      // } else {
      //   console.log("subject Not Found!!");
      //   //alert("subject Not Found!!");
      // }
      if(this.filterCount > 0) {
        this.selectedSort = "topicAsc";
        this.sortCol = "topic";
        this.sortOrder = "asc";
      }
      const params = {
        page: this.page,
        pageSize: this.pageSize,
        search: this.search,
        sortCol: this.sortCol,
        sortOrder: this.sortOrder,
      }
      const query = "?"+(new URLSearchParams(params).toString());
      this.demoTopicsCount = 0;
      this.topicsData = [];
      const response = await TopicsController.getNewTopics(query,this.selectedSubjectFilter, this.selectedLevelFilter, this.selectedGradeFilter);
      if (response.status == 200) {
        this.topicsData = response.data.data.topics;
        this.dataTableLoading = false;
        this.filterDialog = false;
        this.demoTopicsCount = response.data.data.totalTopics;

      } else {
        console.log("subject Not Found!!");
        this.dataTableLoading = false;
        this.filterDialog = false;
        //alert("subject Not Found!!");
      }
    },
    async getSubjects() {
      const response = await SubjectController.getSubject();
      if (response.status == 200) {
        this.subjectsData = response.data.subjects;
        this.filteredSubjects = this.subjectsData;
      } else {
        console.log('Subjects NOt Found')
        //alert("subject Not Found!!");
      }
    },
    async getLevels() {
      const response = await LevelController.getLevel();
      if (response.status == 200) {
        this.levelsData = response.data.levels;
        this.filteredLevels = this.levelsData;
      } else {
        console.log("Levels Not Found!!");
        //alert("Levels Not Found!!");
      }
    },
    selectSortOption(sortOption) {
      this.selectedSort = sortOption;
      switch (sortOption) {
        case "topicAsc":
          this.sortCol = "topic";
          this.sortOrder = "asc";
          break;
        case "topicDesc":
          this.sortCol = "topic";
          this.sortOrder = "desc";
          break;
        case "dateNewest":
          this.sortCol = "createdOn";
          this.sortOrder = "desc";
          break;
        case "dateOldest":
          this.sortCol = "createdOn";
          this.sortOrder = "asc";
          break;
        default:
          this.selectedSort = "topicAsc";
          this.sortCol = "topic";
          this.sortOrder = "asc";
      }
      this.dataTableOptions.page = 1;
      this.getTopics();
    },
    async getGrades() {
      const response = await GradeController.getAllGrades();
      //console.log(response);

      // console.log("grade data", response);
      if (response.status == 200) {
        this.gradesData = response.data.grades;
        this.filteredGrades = this.gradesData;
      } else {
        alert("Levels Not Found!!");
      }
    },

    addSubTopic(index) {
      // Your logic to add a subtopic
      var val = index + 1;
      this.subTopics.push("");
      setTimeout(() => {
        const inputElement = document.getElementById("subTopicInput" + val);
        inputElement.focus();
      }, 10);

      // Focus on the newly added input
      // this.$nextTick(() => {
      //   console.log(this.$refs['subTopicInputRef_'+index+1])
      //   // this.$refs['subTopicInputRef_'+index+1].focus();
      // });
    },
    deleteSubTopic(index) {
      // Your logic to delete a subtopic
      this.subTopics.splice(index, 1);
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },
    updateData(item) {
      this.editId = item.id;
      this.dialog = true;
      this.formbtnBool = true; // selected id for edit
      this.topicName = item.topic;
      this.levelName = item.level;
      this.gradeName = item.grade;
      this.setGradesByLevel()
      this.subjectName = item.subject[0];
      this.setSubjectsByGrade()
    },

    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },
    formbtnValue() {
      return this.formbtnBool === false ? "Created" : "Updated";
    },

    async saveInputs() {
      if (this.$refs.form.validate()) {
        this.formbtnBool == false;
        var res;
        this.loading = true;
        // checking case for update/create
        if (this.formbtnBool == false) {
          const response = await TopicsController.createTopic({
            topic: this.topicName,
            subject: [this.subjectName],
            level: this.levelName,
            grade: this.gradeName,
          });
          res = response;
          console.log("create cate", response);
        } else {
          const response = await TopicsController.updateTopic(
            {
              topic: this.topicName,
              subject: this.subjectName,
              level: this.levelName,
              grade: this.gradeName,
            },
            this.editId
          );
          console.log(response);
          res = response;
          this.getTopics();
        }
        // Close the dialog

        this.dialog = false;
        if (res.data.flag) {
          this.loading = false;
          this.successDialog = true;
        } else {
          this.loading = false;
          this.errorDialog = true;
          this.errorMessage = res.data.error;
        }
        this.getTopics();
      }
    },

    async deleteData(data) {
      var ids = [];
      data.forEach((dataDelete) => {
        ids.push(dataDelete.id);
      });
      this.dLoading = true;
      this.deleteDialog = true;
      const response = await TopicsController.deleteBulkTopics(ids);
      if (response.data.flag) {
        this.deleteDialog = false;
        console.log(response);
        this.dLoading = false;
        this.selected = [];
      } else {
        alert(response.data.error);
        this.deleteDialog = false;
        console.log(response);
        this.dLoading = false;
        this.selected = [];
      }
      this.getTopics();
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },

    async newCreateTopics() {
      (this.topicName = ""),
        (this.levelName = ""),
        (this.gradeName = ""),
        (this.subjectName = ""),
        (this.chapterName = ""),
        (this.unitName = ""),
        (this.subTopics = []),
        (this.formbtnBool = false);
    },
    sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },

    async downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData,{
        quotes: true,  // or array of booleans
        quoteChar: '"',
        escapeChar: '"'
      });

      const bom = '\uFEFF';
      const blob = new Blob([bom + csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    async downloadDemoTopics() {
      this.donwloadLoading = true;
      const fileName = "topics.csv";
      const response = await TopicsController.getNewTopics("", [], [], []);
      if (response.status == 200) {
        const topicsData = response.data.data.topics;
        this.downloadCSV(topicsData, fileName);
      }
      else {
        alert("Error in downloading CSV");
      }
      this.donwloadLoading = false;
    },
    setGradesByLevel() {
      const gradeNames = this.levelsData?.find(levelData => levelData.name === this.levelName).grades ?? []
      this.gradesDataByLevel = this.gradesData.filter(gradeData => gradeNames.includes(gradeData.name))
    },
    setSubjectsByGrade() {
      const subjectNames = this.gradesData?.find(gradeData => gradeData.name === this.gradeName).subjects ?? []
      this.subjectsDataByGrades = this.subjectsData.filter(subjectData => subjectNames.includes(subjectData.name))
    },
    downloadTextFile(text, filename) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    skippedQuestion(id, reason) {
      console.log("reason");
      if (reason == "") {
      } else {
        this.removedQuestions.push(
          "Q" + id + ". Failed" + "->reason-> " + reason
        );
      }
    },
  },
  created() {
    if (!this.$store.state.role.Brand.read) {
      this.$router.push("/notFound");
    }
    // Get search from URL if it exists
    const urlSearch = this.$route.query.search;
    if (urlSearch) {
      this.search = urlSearch;
    }
    // this.getTopics();

    this.getSubjects();
    this.getLevels();
    this.getGrades();
    this.$store.state.breadcrumb = "Demo Topics";
    this.deboucedSearchTopics = debounce(this.getTopics, 400)
  },
};
</script>
<style>
</style>