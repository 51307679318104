<template>
    <v-col class="repUpDivLeft mr-2 ml-2 p-3">
      <div class="d-flex w-100">
        <div class="profileDiv">
          <!-- User Profile Section -->
          <div class="d-flex flex-row justify-start align-center">
            <div class="avatar-wrapper">
              <v-avatar size="84">
                <img v-if="profileData.profilePic" :src="profileData.profilePic" :alt="profileData.userName">
                <img v-else src="../../assets/avtar.png" :alt="profileData.userName">
              </v-avatar>
            </div>
            
            <div class="d-flex flex-column ml-4">
              <h3 class="user-name mb-1">{{ profileData.userName }}</h3>
              <p class="user-title mb-2">{{ profileData.designation }}</p>
              <div class="contact-info">
                <div class="d-flex align-center mb-1">
                  <v-icon small class="mr-2">mdi-phone</v-icon>
                  <span>{{ profileData.userPhone }}</span>
                </div>
                <div class="d-flex align-center mb-1">
                  <v-icon small class="mr-2">mdi-email</v-icon>
                  <span>{{ profileData.userEmail }}</span>
                </div>
                <div class="d-flex align-center">
                  <v-icon small class="mr-2">mdi-map-marker</v-icon>
                  <span>{{ profileData.displaySchoolName }}</span>
                </div>
              </div>
            </div>
          </div>


          <div class="d-flex flex-wrap">
            <v-chip small class="mt-2 mr-1" v-for="(level, index) in profileData.levels" :key="index">
              {{ level }}
            </v-chip>
          </div>
        </div>

        <!-- Recommendation Meters -->
        <div class="d-flex subject-chip-box flex-column gap-8p pl-4 flex-grow-1" :class="userType === 'teacher'? 'flex-grow-1': 'subject-tabs'">
          <div class="sub-chip d-flex flex-column pa-1 cursor" 
            v-for="(subject, index) in leagueData" 
            :class="index === selectedIndex ? 'active-br': ''" 
            @click="setSelectedSubject(index, subject)" 
            :key="index">
            <v-row class="w-100">
              <v-col class="d-flex justify-center align-center" cols="3">
                <img v-if="['gold','silver', 'bronze', 'bonze'].includes(subject?.league?.toLowerCase())"
               class="league-icon"
                :src="getLeagueIcon(subject?.league)"/>
              </v-col>
              <v-col cols="9">
                <span class="fs-12">{{subject.subjectName}}</span>
              </v-col>
            </v-row>
          </div>
        </div>
        <div>
        </div>
        <div class="d-flex flex-column meter-container-br w-100">
          <div class="d-flex px-6 pt-4 gap-8p justify-space-between meter-box-header">
            <div>
              Note - System & Interview recommendation is inclusive of all skills
            </div>
            <div v-if="selectedSubject.subjectName !== 'All'" class="d-flex justify-space-between gap-16p">
              <div v-if="['gold','silver', 'bronze', 'bonze'].includes(selectedSubject?.league?.toLowerCase())" class="d-flex">
                <div>
                  League -
                </div>
                <img class="league-icon ml-2"
                :src="getLeagueIcon(selectedSubject?.league)"/>
              </div>
              <div>
                Rank - {{ selectedSubject.rank == 'Not Eligible'? selectedSubject.rank : `# ${selectedSubject.rank}`}}
              </div>
              <div>
                Score - {{normalizeValue(selectedSubject.score,  selectedSubject.maximumMarks, 10) }}/10
              </div>
            </div>
          </div>
          <div class="d-flex" :class="userType === 'teacher' ? 'justify-center':'flex-grow-1 justify-space-between'">
            <div class="metChartDiv px-2 py-1">
              <p class="mb-2 d-flex metChartBelow text-no-wrap recom-labe1-typo">System Recommendation</p>
              <MeterChart :key="meterChartRemountKeys[0]" :data="meterData.system" :chartName="'chart-one'" ref="chart-one" />
              <p class="chip-replace mt-2" :class="getRecommendation(meterData.system.value)?.remarkClass" label>
                {{ getRecommendation(meterData.system.value)?.text }}
              </p>
            </div>
  
            <div v-if="userType !== 'teacher'" class="metChartDiv px-2 py-1">
              <p class="mb-2 d-flex metChartBelow text-no-wrap recom-labe1-typo">Interview Recommendation</p>
              <MeterChart :key="meterChartRemountKeys[1]" :data="meterData.interview" :chartName="'ChartTwo'" ref="chart-two" />
              <p class="chip-replace mt-2" :class="getRecommendation(meterData.interview.value)?.remarkClass" label>
                {{ getRecommendation(meterData.interview.value)?.text }}
              </p>
            </div>
            <div class="py-4">
              <v-divider vertical class="py-2" ></v-divider>
            </div>
            <div v-if="userType !== 'teacher'" class="metChartDiv px-2 py-1 br">
              <p class="mb-2 d-flex metChartBelow text-no-wrap recom-labe1-typo">Final Recommendation</p>
              <MeterChart :key="meterChartRemountKeys[2]" :data="meterData.final" :chartName="'Chart-three'" ref="chart-three" />
              <p class="chip-replace mt-2" :class="getRecommendation(meterData.final.value, true)?.remarkClass" label>
                {{ getRecommendation(meterData.final.value, true)?.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </v-col>
</template>

<script>
import MeterChart from '../ReportGraphs/MeterChart.vue'

export default {
  name: 'UserProfileRecommendations',
  components: { MeterChart },
  props: {
    profileData: {
      type: Object,
      required: true
    },
    userType: {
      type: String,
      required: true
    },
    leagueData: {
      type: Array,
      required: true
    },
    meterData: {
      type: Object,
      required: true,
      deep: true,
    }
  },
  data() {
    return {
      selectedIndex: 0,
      selectedSubject: {},
      meterChartRemountKeys: [0, 1, 2],
      goldLeagueIcon: require('@/assets/gold.svg'),
      silverLeagueIcon: require('@/assets/silver.svg'),
      bronzeLeagueIcon: require('@/assets/bronze.svg'),
    }
  },
  created(){
    this.selectedSubject = this.leagueData.find(({subjectName})=>subjectName == 'All');
  },
  methods: {
    setSelectedSubject(index, subject) {
      this.selectedIndex = index;
      this.selectedSubject = subject;
      this.meterChartRemountKeys = this.meterChartRemountKeys.map(key=>key + 1) //force remount MeterChart
      this.$emit('update-meters', subject.subjectName);
    },
    getLeagueIcon(league){
          const leagueMap = new Map([
            ['gold', this.goldLeagueIcon],
            ['silver', this.silverLeagueIcon],
            ['bronze', this.bronzeLeagueIcon],
            ['bonze', this.bronzeLeagueIcon],
          ])
          return leagueMap.get(league.toLowerCase());
        },
    getRecommendation(percentile){
      if (percentile < 40) {
          return {
            text: 'Not Recommended',
            lableClass: 'not-recommended',
            remarkClass: 'not-recommended-remark'
          };
        } else if (percentile >= 40 && percentile < 60) {
          return {
            text: 'On Hold',
            lableClass: 'below-standard',
            remarkClass: 'below-standard-remark'
          };
        } else {
          return {
            text: 'Recommended',
            lableClass: 'strongly-recommended',
            remarkClass: 'strongly-recommended-remark'
          }
        }
    },
    normalizeValue(value, maxValue, scaleTo){
      return (value/maxValue)*scaleTo;
    },
  }
}
</script>
<style scoped>
.subject-tabs{
  max-width: 15%;
}
.meter-box-header{
  font-family: "GraphikRegular";
  font-size: 10px !important;
  color: #666666;
}
.subject-chip-box{
  position: relative;
  z-index: 100000;
  .active-br{
    margin-right: -1px;
  }
}
.pentagon-teacher{
    width: 35.5%;
    padding:  1.3vw 1.3vw 1.3vw 2vw;
}

.dual-elipsiss{
    width: 250px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.v-btn-toggle .v-btn.v-btn.v-size--default {
    min-width: 100px;
    min-height: 0;
}
.recom-labe1-typo{
    font-family: "GraphikRegular";
    font-size: 12px;
    color: #666666;
}
.chart-height{
    height: 41vh
}
.interviewer-name{
    text-wrap: nowrap;
    width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.league-info-label{
    color: rgba(0, 0, 0, 0.6);
}
.interview-remark{
    overflow: auto;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    font-size: 12px;
    height: 11vh;
    scrollbar-width: none;
}
.not-recommended,.below-standard, .moderately-recommended, .highly-recommended, .strongly-recommended{
    min-width: 140px;
}
.not-recommended{
    color: #FF2600;
    background-color: #FFE9E5 ;
    border: 1px solid #FF2600;
}
.below-standard{
    color: #E39236;
    background-color: #FCF3E9;
    border: 1px solid #E39236;
}
.moderately-recommended{
    color:#97AD39;
    background-color: #E5ECC5;
    border: 1px solid #97AD39;
}
.highly-recommended{
    color:#5F9C4A;
    background-color: #D2E6CB;
    border: 1px solid #5F9C4A;  
}
.strongly-recommended{
    color: #56903E;
    background-color: #CFE2D2;
    border: 1px solid #56903E;
}
.br-right{
    border-right: 1px solid  rgba(217, 217, 217, 1);
}

.not-recommended-remark{
    color: #FF2600;
}
.below-standard-remark{
    color: #E39236;
}
.moderately-recommended-remark{
    color:#97AD39;
    min-width: 173px;
}
.highly-recommended-remark{
    color:#5F9C4A;
}
.strongly-recommended-remark{
    color: #56903E;
}

.ff-bold{
    font-family: "GraphikMedium";
}
.fs-14{
    font-size: 14px; 
}
.fs-12{
    font-size: 12px; 
}
.sub-chip{
  font-family: "GraphikRegular";
    color: #666666;
    background-color: #FFFFFF;
    border-radius: 4px 0px 0px 4px;
    box-shadow: 
      -4px 0px 8px -6px rgba(0, 0, 0, 0.2),  
      0px -2px 8px -6px rgba(0, 0, 0, 0.2),
      0px  2px 8px -6px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}
.fs-10{
    font-size: 10px;
    color: rgba(0, 0, 0, 0.6);
    font-family: "GraphikRegular";
 
}
.br-40{
    border-radius: 40px;
}
.final-br{
  border-radius: 8px;
  background-color: #fff; 
}
.active-br, .meter-container-br{
    background-color: #F7F7F7;
    border:  0.5px solid #00000024;
    border-right: none;
}
.meter-container-br{
    border-radius: 0px 8px 8px 0px;
    position: relative;
    z-index: 1;
    border: 0.5px solid #00000024
}
.chip-replace{
    font-family: "GraphikRegular";
    font-size: 12px;
    padding-top: 16px;
    text-align: center;
    font-weight: 200;
    border-radius: 8px;
}
.gap-8p{
    gap: 8px
}
.gap-16p{
  gap: 16px;
}
.row{
    margin: 0px;
    padding: 5px;
}
.col{
    padding: 7px;
    width: 160px;
}
.v-progress-circular__overlay {
     stroke-linecap: round;
 } 

 .metChartDiv v-chip{
    font-size: 10px;
 }

 .repUpDiv .row {
    padding: 0;
 }
/*
::-webkit-scrollbar {
    display: none;
  }
*/
.repUpDivLeft-brown-bdr {
  border: 1px solid var(--blue-01);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  padding: 0.6rem;
  background-color: white;
}
.interviewRate, .interviewRate1, .interviewRate2, .interviewRate3, .interviewRate4, .interviewRate5, .interviewRate6, .interviewRate7, .interviewRate8, .interviewRate9, .interviewRate10 {
    margin: 0 16px;
    color: white !important;
}
.reportTableText{
    font-family: "GraphikRegular" !important;
    font-weight: 500 !important; 
}
.primary-box-red{
    background-color: #D4002829 !important;
    border: 1px solid #D40028;
}
.primary-box-orange{
    background-color: #FB750029 !important;
    border: 1px solid #FB7500 !important;
}
.primary-box-yellow{
    background-color: #FADC0029 !important;
    border: 1px solid #FADC00;
}
.primary-box-green{
    background-color: #06C27029 !important;
    border: 1px solid #06C270 !important;
}
.secondary-box-red{
  background-color: #D4002829 !important;
}
.secondary-box-orange{
  background-color: #FB750029 !important;
}
.secondary-box-yellow{
  background-color: #FADC0029 !important;
}
.secondary-box-green{
  background-color: #06C27029 !important;
}
.profileDiv {
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px;
  min-width: 380px;
}

.avatar-wrapper {
  position: relative;
  padding: 8px;
}

.avatar-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #E0E0E0;
  border-radius: 50%;
}

.user-name {
  font-family: 'GraphikMedium', sans-serif;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
}

.user-title {
  font-family: 'GraphikRegular', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

.contact-info {
  font-family: 'GraphikRegular', sans-serif;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.contact-info .v-icon {
  color: rgba(0, 0, 0, 0.54);
}
.league-icon{
  height:14px;
  width:16px;
}
</style>