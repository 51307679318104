import { render, staticRenderFns } from "./DemoVideoScores.vue?vue&type=template&id=3baf8ada&scoped=true&"
import script from "./DemoVideoScores.vue?vue&type=script&lang=js&"
export * from "./DemoVideoScores.vue?vue&type=script&lang=js&"
import style0 from "./DemoVideoScores.vue?vue&type=style&index=0&id=3baf8ada&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3baf8ada",
  null
  
)

export default component.exports