import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_NOTIFICATION_API_URL,
});
export default {


   messageToHR: async function (message,text,email,subject
   
   ) {
     try {
       const response = await instance.post(
         "/sendEmail",
         {
           email:email,
           emailBody:{
               subject:subject,
               text:text,
               body:message
           }
       },
         
       );
       return response;
     } catch (error) {
       console.log(error.response);
   
       return error.response;
     }
   },

   notifyUserForAssessment : async function (userName , email, userId, campaignId, assessmentStage, deviceType) {
    try {
      const payload = { userName, email, userId, campaignId, assessmentStage, deviceType };
      const response = await instance.post("/notify-user-for-assessment", payload);
      return response;
    }
    catch(error){
        console.log(error.response);
        return error.response;
    }
   },
   sendResetAdminPasswordEmail : async function(email) {
    try{
      const payload = {email};
      const response = await instance.post("/send-password-reset-email", payload);
      return response.data;
    }
    catch(error){
      console.error(error);
      return error.response;
    }
   }
}