<template>
  <v-card outlined class="" v-if="videoData?.length > 0">
    <div class="d-flex flex-column common-border">
        <p class="pa-2  league-title">Cumulative Demo video Score</p>
        <div class="d-flex gap-8p ml-2 mb-2">
            <div class="d-flex progress-card" v-for="(item, index) in circularProgressChart"
                :key="index">
                <div>
                    <p class="mt-2 fs-14">{{ item.name }}</p>
                </div>
                <div>
                    <v-progress-circular class="v-progress-circular__overlay"
                        :value="item.score * 10" :rotate="-90" :size="80" :width="12"
                        :color="item.score > 5 ? 'rgba(95, 156, 74, 1)' : 'rgba(227, 146, 54, 1)'">

                        <span class="circular-progress-inner-text" v-if="item.score">
                            {{ Number.isInteger(item.score) ? item.score :
                            (item.score).toFixed(2) ??
                            0}} / 10
                        </span>
                        <span class="circular-progress-inner-text" v-else> 0 /10 </span>
                    </v-progress-circular>
                </div>

            </div>
        </div>
    </div>
</v-card>
<v-card width="100%" class="rounded-l mt-3" outlined v-else>
    <div class="d-flex justify-center align-center">
        <p class="league-title pa-12">Demo video data not available</p>
    </div>
</v-card>
</template>

<script>
export default {
  name: 'DemoVideoScores',
  props: {
    videoData: Array,
    circularProgressChart: Array
  },
  methods: {
    formatScore(score) {
      return Number.isInteger(score) ? score : score.toFixed(2)
    }
  }
}
</script>
<style scoped>
.pentagon-teacher{
  width: 35.5%;
  padding:  1.3vw 1.3vw 1.3vw 2vw;
}

.dual-elipsiss{
  width: 250px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 100px;
  min-height: 0;
}
.recom-labe1-typo{
  color: rgba(0, 0, 0, 0.87);
  font-family: GraphikRegular;
  font-size: 12px;
}
.chart-height{
  height: 41vh
}
.interviewer-name{
  text-wrap: nowrap;
  width: 170px;
overflow: hidden;
text-overflow: ellipsis;
}
.league-info-label{
  color: rgba(0, 0, 0, 0.6);
}
.interview-remark{
  overflow: auto;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  font-size: 12px;
  height: 11vh;
  scrollbar-width: none;
}
.not-recommended,.below-standard, .moderately-recommended, .highly-recommended, .strongly-recommended{
  min-width: 140px;
}
.not-recommended{
  color: #FF2600;
  background-color: #FFE9E5 ;
  border: 1px solid #FF2600;
}
.below-standard{
  color: #E39236;
  background-color: #FCF3E9;
  border: 1px solid #E39236;
}
.moderately-recommended{
  color:#97AD39;
  background-color: #E5ECC5;
  border: 1px solid #97AD39;
}
.highly-recommended{
  color:#5F9C4A;
  background-color: #D2E6CB;
  border: 1px solid #5F9C4A;  
}
.strongly-recommended{
  color: #56903E;
  background-color: #CFE2D2;
  border: 1px solid #56903E;
}
.br-right{
  border-right: 1px solid  rgba(217, 217, 217, 1);
}

.not-recommended-remark{
  color: #FF2600;
}
.below-standard-remark{
  color: #E39236;
}
.moderately-recommended-remark{
  color:#97AD39;
  min-width: 173px;
}
.highly-recommended-remark{
  color:#5F9C4A;
}
.strongly-recommended-remark{
  color: #56903E;
}

.ff-bold{
  font-family: GraphikMedium;
}
.fs-14{
  font-size: 14px; 
}
.fs-12{
  font-size: 12px; 
}
.sub-chip{
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 4px 0px 0px 4px;
}
.fs-10{
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
  font-family: GraphikRegular;

}
.br-40{
  border-radius: 40px;
}
.final-br{
border-radius: 8px;
background-color: #fff; 
}
.active-br, .meter-container-br{
  background-color: rgba(77, 105, 91, 0.09);
}
.meter-container-br{
  border-radius: 0px 8px 8px 0px;
}
.chip-replace{
  font-family: GraphikRegular;
  font-size: 11px;
  padding: 5px;
  text-align: center;
  font-weight: 200;
  border-radius: 8px;
  text-transform: uppercase;
}
.gap-8p{
  gap: 8px
}
.row{
  margin: 0px;
  padding: 5px;
}
.col{
  padding: 7px;
  width: 160px;
}
.v-progress-circular__overlay {
   stroke-linecap: round;
} 

.metChartDiv v-chip{
  font-size: 10px;
}

.repUpDiv .row {
  padding: 0;
}
/*
::-webkit-scrollbar {
  display: none;
}
*/
.repUpDivLeft-brown-bdr {
border: 1px solid var(--blue-01);
border-radius: 0.5rem;
display: flex;
flex-direction: row;
padding: 0.6rem;
background-color: white;
}
.interviewRate, .interviewRate1, .interviewRate2, .interviewRate3, .interviewRate4, .interviewRate5, .interviewRate6, .interviewRate7, .interviewRate8, .interviewRate9, .interviewRate10 {
  margin: 0 16px;
  color: white !important;
}
.reportTableText{
  font-family: "GraphikRegular" !important;
  font-weight: 500 !important; 
}
.primary-box-red{
  background-color: #D4002829 !important;
  border: 1px solid #D40028;
}
.primary-box-orange{
  background-color: #FB750029 !important;
  border: 1px solid #FB7500 !important;
}
.primary-box-yellow{
  background-color: #FADC0029 !important;
  border: 1px solid #FADC00;
}
.primary-box-green{
  background-color: #06C27029 !important;
  border: 1px solid #06C270 !important;
}
.secondary-box-red{
background-color: #D4002829 !important;
}
.secondary-box-orange{
background-color: #FB750029 !important;
}
.secondary-box-yellow{
background-color: #FADC0029 !important;
}
.secondary-box-green{
background-color: #06C27029 !important;
}
.progress-card {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 12px;
}
</style>