<template>
  <div class="common-border bg-white" v-if="chartData.datasets.length > 0">
    <div class="d-flex justify-space-between pa-4">
      <p>Cumulative Graph</p>
      <v-btn-toggle v-model="chartType" mandatory dense rounded>
        <v-btn width="130px" :class="getButtonClass(0)">
          RADAR CHART
        </v-btn>
        <v-btn width="100px" :class="getButtonClass(1)">
          SCORE
        </v-btn>
      </v-btn-toggle>
    </div>

    <div class="cumChartDiv" v-if="chartType === 0">
      <MultiLayerRadarChart :chartData="chartData" :id="1" />
    </div>
    
    <div v-else>
      <div class="pa-2 mt-2">
        <v-row class="header-row">
          <v-col cols="4"></v-col>
          <v-col>Screening</v-col>
          <v-col>Mains</v-col>
          <v-col>Demo</v-col>
          <v-col v-if="userType !== 'teacher'">Interview</v-col>
        </v-row>
        <v-row v-for="(subject, index) in subjectScores" 
               :key="index" 
               class="score-data-row align-center"
               justify="center">
          <v-col class="league-name" cols="4">{{subject.name}}</v-col>
          <v-col>{{subject.screeningMarks | marksformatter}}</v-col>
          <v-col>{{subject.mainsMarks | marksformatter}}</v-col>
          <v-col>{{subject.demoMarks | marksformatter}}</v-col>
          <v-col v-if="userType !== 'teacher'">{{subject.interviewMarks | marksformatter}}</v-col>
        </v-row>
      </div>
    </div>
  </div>
  <div v-else class="common-border bg-white chart-height d-flex justify-center align-center">
    <p class="pa-2 league-title">Chart Data not available</p>
  </div>
</template>

<script>
import MultiLayerRadarChart from '../ReportGraphs/MultiLayerRadarChart.vue'

export default {
  name: 'CumulativeGraph',
  components: { MultiLayerRadarChart },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    subjectScores: {
      type: Array,
      required: true
    },
    userType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chartType: 0
    }
  },
  methods: {
    getButtonClass(type) {
      return this.chartType === type
        ? 'blue white--text text-subtitle-2'
        : 'gray-02 blue--text text-subtitle-2'
    }
  },
  filters: {
    marksformatter(value) {
      if (value === '-') {
        return value;
      }
      
      const decimalRegex = /^\d*\.?\d+$/;
      const isDecimal = decimalRegex.test(value);

      if (value > 0 && value < 10 && !isDecimal) {
        return `0${Number.parseFloat(value).toFixed(1)}/10`;
      } else {
        return `${Number.parseFloat(value).toFixed(1)}/10`;
      }
    }
  }
}
</script>

<style scoped>
.common-border {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.chart-height {
  height: 41vh;
}

.league-title {
  font-family: GraphikRegular;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.league-name {
  font-family: GraphikRegular;
  font-weight: 500;
}

.header-row {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.score-data-row {
  color: rgba(0, 0, 0, 0.87);
}

.cumChartDiv {
  min-height: 300px;
  padding: 16px;
}
</style>
