<template>
  <div>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position: fixed; z-index: 200; top: 0; width: 98%; padding-top: 16px;">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text" @click="$router.push('/assessment-listing-view')">Assessments</p>
        <v-icon
          class="breadcrumb-arrow-unselected"
          style="margin-top: 6px !important"
        >mdi-chevron-right
        </v-icon>
        <p class="breadcrumb-text">Create Assessment</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
        <p class="breadcrumb-text">Edit Question</p>
      </v-card-title>
      <UserNavMenu/>
    </v-card>
    <v-container fluid class="pad px-8 background">
      <div class="d-flex align-center mb-4">
        <h3 class="mr-auto">Question</h3>
      </div>
      <v-row>
        <v-col cols="3">
          <aside class="pa-4 bg-white rounded-lg">
            <div>
              <div class="d-flex align-center questions-container"> 
                <span class="d-flex text-subtitle-2">Question ID</span>                                  
                <span> - </span>                                  
                <span class="question-label-values text-subtitle-2">{{ questionDetail.questionId }}</span>                
              </div>
              
              <div class="d-flex align-center questions-container">
                <span class="d-flex text-subtitle-2">Level</span>              
                <span> - </span>                  
                <span class="question-label-values text-subtitle-2">{{ questionDetail.level }}</span>                  
              </div>
              
              <div class="d-flex align-center questions-container">
                  <span class="d-flex text-subtitle-2">Grade</span>
                  <span> - </span>                                                                              
                  <span class="question-label-values text-subtitle-2">{{ questionDetail.grade }}</span>                    
              </div>
              
              <div class="d-flex align-center questions-container">
                <span class="d-flex text-subtitle-2">Skill</span>                                                      
                <span> - </span>                
                <span class="question-label-values text-subtitle-2">{{ assessmentDetails.skills?.length || 0 }}</span>
              </div>
              
              <div class="d-flex align-center questions-container">
                <span class="d-flex text-subtitle-2">Subject</span>                                                       
                <span> - </span>              
                <span class="question-label-values text-subtitle-2">{{ questionDetail.subject?.[0] }}</span>                    
              </div>
              
              <div class="d-flex align-center questions-container">
                <span class="d-flex text-subtitle-2">Strand</span>                
                <span> - </span>                
                <span class="question-label-values text-subtitle-2">{{ getQuestionStrand }}</span>                    
              </div>
              
              <div class="d-flex align-center questions-container">
                <span class="d-flex text-subtitle-2">Sub Strand</span>
                <span> - </span>                
                <span class="question-label-values text-subtitle-2">{{ getQuestionSubStrand }}</span>                    
              </div>
              
              <div class="d-flex align-center questions-container">
                <span class="d-flex text-subtitle-2">Topic</span>                                                 
                <span> - </span>                
                <span class="question-label-values text-subtitle-2">{{ getQuestionTopic }}</span>                    
              </div>
              
              <div class="d-flex align-center questions-container">
                <span class="d-flex text-subtitle-2">Lo's</span>                  
                <span> - </span>                      
                <span class="question-label-values text-subtitle-2">{{ "LO1" }}</span>                    
              </div>
              
              <hr />
              <br />  

              <div class="d-flex align-center questions-container">
                <span class="d-flex text-subtitle-2">Blooms Taxonomy</span>                
                <span> - </span>                                                    
                <span class="question-label-values text-subtitle-2">{{ questionDetail.bloomsTaxonomy }}</span>                    
              </div>
              
              <div class="d-flex align-center questions-container">
                <span class="d-flex text-subtitle-2">Difficulty Level</span>                  
                <span> - </span>                
                <span class="question-label-values text-subtitle-2">{{ questionDetail.difficultyLevel }}</span>                    
              </div>
              
              <div class="d-flex align-center questions-container">
                <span class="d-flex text-subtitle-2">Complexity Level</span>                  
                <span> - </span>                                    
                <span class="question-label-values text-subtitle-2">{{ questionDetail.complexityLevel }}</span>                    
              </div>
              
              <div class="d-flex align-center questions-container">
                <span class="d-flex text-subtitle-2">Proficiency Level</span>
                <span> - </span>
                <span class="question-label-values text-subtitle-2">{{ questionDetail.proficiencyLevel }}</span>                    
              </div>        
            </div>
          </aside>
        </v-col>
        <v-col cols="9">
          <section class="bg-white pa-4">
            <div class="d-flex align-center justify-between mb-4">
              <div class="question-type-chip mr-auto">
                <span class="text-subtitle-2 font-weight-bold mr-4">Question Type</span>
                <span class="text-body-1">{{ questionDetail.questionType}}</span>
              </div>
              <h5>Marks Alloted- {{ questionDetail.marksAllotted }}</h5>
            </div>

            <div class="mb-4">
              <div class="mb-4">
                <p class="text-h6 font-weight-bold" v-html="getQuestionStatement"></p>
                <div v-if="questionForSelectedLanguage.questionAssetUrl.startsWith('https://') || questionForSelectedLanguage.questionAssetUrl.startsWith('http://') || questionForSelectedLanguage.questionAssetUrl.startsWith('blob:')">
                  <v-img v-if="questionForSelectedLanguage?.isQuestionStatementAssetResolved" 
                  :src="questionForSelectedLanguage?.resolvedQuestionAssetUrl" 
                  alt=""  
                  class="radio-questions-btn-img" />
                  <v-img v-else 
                  @loadstart="resolveQuestionAssetURL" 
                  :src="getQuestionAssestURL" 
                  alt=""  class="radio-questions-btn-img" />
                </div>
              </div>
              <div class="d-flex w-100">                
                <v-radio-group v-model="questionDetail.correctAnswer"  v-if="questionDetail.questionType == 'SINGLE_CHOICE' || questionDetail.questionType == 'TRUE_FALSE'">
                  <div class="question-assessment-container">
                    <v-list-item :class="questionDetail.correctAnswer == option.optionKey ? 'lightGreen'
                                 : 'grey lighten-4'"
                                 class="py-0 options-row"
                      v-for="(option, index) in getQuestionOptions" :key="index">
                      <div class="questions-options">
                        <div class="d-flex align-center">
                          <v-list-item-avatar>
                            <v-radio :disabled="true" :value="option.optionKey">Personality traits</v-radio>
                          </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-subtitle v-if="option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')">
                            <div class="d-flex align-center">
                              <v-img v-if="option?.isOptionAssetUrlResolved" :src="option.resolvedOptionUrl" alt="" class="radio-questions-btn-img" />
                              <v-img v-else @loadstart="resolveQuestionOptionUrl" :src="option.optionValue" alt="" class="radio-questions-btn-img" />
                            </div>
                          </v-list-item-subtitle>
                          <v-list-item-title class="align-center" v-else>
                            <div class="options-text" v-html="option.optionValue"></div>
                          </v-list-item-title>
                        </v-list-item-content>
                      </div>
                    </div>                
                    </v-list-item>
                  </div>
                </v-radio-group>
 
                <v-radio-group v-model="questionDetail.correctAnswer"  v-if="questionDetail.questionType == 'MULTIPLE_CHOICE'">                  
                  <div class="question-assessment-container">
                    <div :class="selectedAnswers.includes(option.optionKey) ? 'lightGreen' : 'grey lighten-4'"
                              class="px-2 py-0 options-row"
                              v-for="(option, index) in getQuestionOptions" :key="index">
                      <div class="questions-options h-100">
                        <div class="d-flex align-center h-100">
                          <v-list-item-avatar>
                          <v-checkbox :disabled="true" v-model="selectedAnswers"
                        :value="option.optionKey">
                      </v-checkbox>
                          </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-subtitle v-if="option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')">
                            <div class="d-flex align-center">
                              <v-img @loadstart="resolveQuestionOptionUrl" :src="option.optionValue" alt="" class="radio-questions-btn-img" />
                            </div>
                          </v-list-item-subtitle>
                          <v-list-item-title class="align-center" v-else>
                            <div class="options-text" v-html="option.optionValue"></div>
                          </v-list-item-title>
                        </v-list-item-content>
                      </div>
                    </div>                
                    </div>
                  </div>
                </v-radio-group>
                    <!-- <v-card elevation="1" height="auto" class="overflow-hidden white">
                      <v-card
                        elevation="0"
                        >
                        <div class="option-left"
                        >  
                        <v-radio v-model="questionDetail.correctAnswer"
                            :class="questionDetail.correctAnswer === option.optionKey ? 'lightGreen' : 'grey lighten-4'"                        
                            class="ml-2"
                            :value="option.optionKey"
                            :label="`${option.optionValue}`"
                          ></v-radio>
                        </div>
                      </v-card> -->
                    <!-- </v-card> -->
              </div>
            </div>
            
            <div class="mb-4">
              <h6 class="question-label-values text-subtitle-2 mb-3">Answer Explanation*</h6>
              <span class="text-subtitle-1">{{ getQuestionExplanation }}</span>
            </div>
            <div>
              <h6 class="question-label-values text-subtitle-2 mb-3">Answer Hint*</h6>
              <span class="text-subtitle-1">{{ getQuestionHint }}</span>
            </div>
          </section>
        </v-col>
      </v-row>
    </v-container>  
  </div>
</template>
<script>
    import AuthService from "../services/AuthService";
    import StaticAssessmentController from "@/controllers/StaticAssessmentController";
    import QuestionsController from "../controllers/QuestionsController";
    import { mapGetters } from "vuex";
    import UserNavMenu from "@/components/UserNavMenu.vue";
  
    export default {
        name: "AssessmentListingEditQuestion",
        components:{
          UserNavMenu
        },
        data() {
            return {
              currentSelectedLanguage:"english",
              questionId: "",
              assessmentId: "",
              questionDetail: {},
              questionForSelectedLanguage: {},
              pageSize: 10,
              assessmentDetails: {},
              page: 1,
              headers: [
                { text: 'Question ID', value: 'question_id', width: '15%'},
                { text: 'Question Statement', value: 'question_statement', width: '35%'},
                { text: 'Skills', value: 'skills', width: '10%'},
                { text: 'Score', value: 'score', width: '10%'},
                { text: 'Difficulty Level', value: 'difficulty_level', width: '20%'},
                { text: 'Grade', value: 'grade', width: '15%'},
                { text: 'Actions', value: 'actions', sortable: false, width: '20%'},
              ],
              labels: ['Question ID', 'Level', 'Grade', 'Skill', 'Subject', 'Strand', 'SubStrand','Topic', 'Lo\'s'],
              labelValues: {
                questionId: 'MESTA_Q01',
                level: 'Secondary',
                grade: '01',
                skill: '01',
                subject: 'Mathematics',
                strand: 'Mathematics',
                subStrand: 'Mathematics',
                topic: 'Pythagoras',
                los: ['Lo1', 'Lo2']
              },
              otherLabels: ['Blooms Taxonomy', 'Difficult Level', 'Complexity Level', 'Proficiency Level'],
              otherLabelValues: ['NA', 'EASY', 'NA', 'NA'],
              question: {
                myAnswer: null,
                questionOptions: [
                { optionKey: 'option1', optionValue: 'Option 1' },
                { optionKey: 'option2', optionValue: 'Option 2' },
                { optionKey: 'option3', optionValue: 'Option 3' },
                { optionKey: 'option4', optionValue: 'Option 4' }
                ]
              },
              options: [
                { optionKey: 'option1', optionValue: 'Option 1' },
                { optionKey: 'option2', optionValue: 'Option 2' },
                { optionKey: 'option3', optionValue: 'Option 3' },
                { optionKey: 'option4', optionValue: 'Option 4' }
              ],
              selectedAnswers: []          
            }
        },
        watch: {
          questionDetail: {
            handler(val) {
              if (this.questionDetail.questionType == 'MULTIPLE_CHOICE') {
                // Ensure correctAnswer is an array, split if it's a comma-separated string
                if (typeof val.correctAnswer === 'string') {
                  this.selectedAnswers = val.correctAnswer.split(',');
                } else {
                  this.selectedAnswers = Array.isArray(val.correctAnswer)
                    ? val.correctAnswer
                    : [val.correctAnswer];
                }
              }
            },
            immediate: true,
            deep: true
          }

        },
        computed: {
          ...mapGetters({
            assessmentData : 'getAssessmentData',
          }),
          labelValuesArray() {
            return Object.values(this.labelValues);
          },
          getQuestionStrand: {
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetail.QuestionDetails[languageKey]?.strand || '';
              }
              return '';
            }
          },
          getQuestionSubStrand: {
            // Getter: Retrieves the current value of subStrand
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetail.QuestionDetails[languageKey]?.subStrand || '';
              }
              return '';
            }
          },
          getQuestionTopic: {
            // Getter: Retrieves the current value of topic
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetail.QuestionDetails[languageKey]?.topic || '';
              }
              return '';
            }
          },
          getQuestionStatement: {
            // Getter: Retrieves the current value of questionStatement
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetail.QuestionDetails[languageKey]?.questionStatement || '';
              }
              return '';
            }
          },
          getQuestionExplanation: {
            // Getter: Retrieves the current value of answerExplanation
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetail.QuestionDetails[languageKey]?.answerExplanation || '';
              }
              return '';
            }
          },
          getQuestionHint: {
            // Getter: Retrieves the current value of topic
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                return this.questionDetail.QuestionDetails[languageKey]?.hint || '';
              }
              return '';
            }
          },
          getQuestionOptions: {
            // Getter: Retrieves the current question options for the selected language
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                console.log(this.questionDetail.QuestionDetails[languageKey]?.questionOptions, "  ===== ")
                return this.questionDetail.QuestionDetails[languageKey]?.questionOptions || [];
              }
              return [];
            },
          },
          getQuestionAssestURL: {
            get() {
              if (this.currentSelectedLanguage && typeof this.currentSelectedLanguage === 'string') {
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                const questionDetailForLanguage = this.questionDetail.QuestionDetails[languageKey];
                return questionDetailForLanguage.questionAssetUrl || '';
              }
              return '';
            }
          }
        },
        mounted() {            
            this.questionId = this.$route.query.questionId;
            this.pageSize = 10;
            this.page = 1;
            this.fetchAssessmentById();
        },
        methods: {            
            logout() {
                AuthService.logout();
                this.$router.push("/login");
            },
            fetchAssessmentById() {
              const questionsList = this.assessmentData?.questions;
              this.assessmentDetails = this.assessmentData;
              if(questionsList?.length > 0) {
                this.questionDetail = questionsList.find(question => question.questionId === this.questionId);
                const languageKey = this.currentSelectedLanguage.toLowerCase();
                this.questionForSelectedLanguage = this.questionDetail.QuestionDetails[languageKey];
              }
            },

            resolveQuestionAssetURL: async function (url) {
              const languageKey = this.currentSelectedLanguage.toLowerCase();
              if (url.startsWith('https://www.google.com/') || url.startsWith('https://drive.google.com/')) {
                let fileId = null;
                this.questionDetail.QuestionDetails[languageKey].questionAssetUrl = new URL(`../assets/loading.gif`, import.meta.url).href
                if (url.indexOf('&id=') > -1) {
                  fileId = url.split('&id=').pop();
                } else {
                  const pattern = /\/file\/d\/([^\\/]+)\//;
                  fileId = url.match(pattern)[1];
                }
                const response = await QuestionsController.doFetchFileContentFromDriveUrl(fileId);
                if(response?.status == 200) { 
                  this.questionDetail.QuestionDetails[languageKey].questionAssetUrl = window.URL.createObjectURL(new Blob([response.data]));
                } else {
                  this.questionDetail.QuestionDetails[languageKey].questionAssetUrl = new URL(`../assets/caution.png`, import.meta.url).href;
                }
              }
            },
              
            async resolveQuestionOptionUrl (url) {
              const languageKey = this.currentSelectedLanguage.toLowerCase();
              const options = this.questionDetail.QuestionDetails[languageKey]?.questionOptions;
              
              const optionIndex = options?.findIndex(({ optionValue }) => optionValue === url);
              if (optionIndex !== -1 && url.startsWith('https://drive.google.com/')) {
                let fileId = null;
                this.questionDetail.QuestionDetails[languageKey].questionOptions[optionIndex].optionValue = new URL(`../assets/loading.gif`, import.meta.url).href
                if (url.indexOf('&id=') > -1) {
                  fileId = url.split('&id=').pop();
                } else {
                  const pattern = /\/file\/d\/([^\\/]+)\//;
                  fileId = url.match(pattern)[1];
                }
                const response = await QuestionsController.doFetchFileContentFromDriveUrl(fileId);
                if(response?.status == 200) {
                  this.questionDetail.QuestionDetails[languageKey].questionOptions[optionIndex].optionValue = window.URL.createObjectURL(new Blob([response.data]));
                } else {
                  this.questionDetail.QuestionDetails[languageKey].questionOptions[optionIndex].optionValue = new URL(`../assets/caution.png`, import.meta.url).href;
                }
              }
            }, 
          }
        } 
</script>
<style scoped>
  section {
    min-height: calc(100vh - 200px);
  }
  .question-type-chip {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(77, 105, 91, 0.10);
    border-radius: 8px;
    padding: 8px 16px;
  }
  .questions-container {
    margin-bottom: 6px;
    span {
      flex: 1;
      white-space: nowrap;
      &:nth-child(2) {
        flex: 0;
        padding: 0 16px;
      }
    }
  }
  .question-label-values {
    display: block;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .radio-questions-btn-img {
    max-width: 324px;
    height: 80px;
  }
  .question-assessment-container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 16px;
    grid-column-gap: 10px;
  }
  .v-input--selection-controls.v-input {
    flex: 1 !important;
  }
  .v-input--selection-controls .v-input__slot {
    justify-content: center !important;
  }
  .options-text{
    word-break: break-word !important;
    white-space: normal !important;
  }
</style>