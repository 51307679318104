<template>
  <div class="repUpDivLeft-brown-bdr">
    <div class="d-flex justify-space-between align-center gap-8p w-100">
        <p class="c-date">Psychometry Report</p>
        <div class="d-flex justify-end">
            <v-btn class="btn primary-btn text-subtitle-2 br-40" :loading="loading"
                :disabled="disabled" @click="generateReport">
                VIEW
            </v-btn>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PsychometryReport',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    generateReport() {
      this.$emit('generate-report');
    }
  }
};
</script>

<style scoped>
.repUpDivLeft-brown-bdr {
  border: 1px solid var(--blue-01);
  border-radius: 0.5rem;
  padding: 0.6rem;
  background-color: white;
}

.br-40 {
  border-radius: 40px;
}

.gap-8p {
  gap: 8px;
}

.c-date {
  font-family: GraphikRegular;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  line-height: 40px;
}
</style>