<template>
    <canvas class="radarChart" :height="300" :id="id"></canvas>
</template>

<script>

export default {
    props: ["chartData", "maxValue","id"],

    data() {
        return {
            labels: [],
            label: "",
            chartdata: [],

        }
    },

    methods: {
        setChartData() {
            const ctx = document.getElementById(this.id);

            new Chart(ctx, {
                type: 'radar',
                data: {
                    labels: this.chartData.labels,
                    datasets: [ ...this.chartData.datasets  ]
                },

                options: {
                    legend: {
                        display: true,
                        position: 'right',
                        align: 'start',
                        onClick: function (e) {
                            e.stopPropagation();
                        }
                        
                    },
                    elements: {
                        line: {
                            borderWidth: 2
                        }
                    },

                    scale: {
                        ticks: {
                            min: 0,
                            max: this.maxValue
                        }
                    }
                }
            });
        },
    },

    mounted() {
        this.setChartData();
    }
}
</script>

<style scoped>
.radarChart {
    padding-bottom: 0.5rem;
    padding-top: 1rem;
    height: 100% !important;
}
</style>
