<template>
  <div class="common-border bg-white mt-2">
    <!-- Header with Title and Round Selector -->
    <div class="d-flex align-center justify-space-between px-4 pt-4">
      <p class="league-title">Interviewer's Remark</p>
      <v-btn-toggle
        v-model="selectedRound"
        mandatory
        dense
        rounded
      >
        <v-btn 
          v-for="round in rounds" 
          :key="round"
          :value="round"
          :class="getButtonClass(round)"
          width="100px"
        >
          ROUND {{ round }}
        </v-btn>
      </v-btn-toggle>
    </div>

    <!-- Remarks Display -->
    <v-container class="pa-0" v-if="displayedInterviews.length > 0">
      <v-row class="pa-4">
        <v-col cols="4" v-for="(interview, index) in displayedInterviews" :key="index">
          <v-card class="h-100 rounded-l" elevation="1" outlined>
            <div class="pa-4">
              <div class="interviewer-name">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{interview.interviewerEmail || '---'}}
                    </span>
                  </template>
                  <div>{{interview.interviewerEmail || '---'}}</div>
                </v-tooltip>
              </div>
              <div class="interview-recommendation font-weight-bold" 
                   :class="shortlistVal(interview.avgScore*10)?.remarkClass">
                {{ interview.recommendation }} - {{interview.avgScore}}/10
              </div>
              <div class="interview-remark">
                <span>{{truncateRemark(interview.remark)}}</span>
                <span 
                  v-if="shouldShowViewMore(interview.remark)" 
                  class="view-more-link"
                  @click="showFullRemark(interview.remark)"
                >
                  View more...
                </span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- No Data Message -->
    <v-card width="100%" class="rounded-l mt-3" outlined v-else>
      <div class="d-flex justify-center align-center">
        <p class="league-title pa-12">No remarks available for Round {{selectedRound}}</p>
      </div>
    </v-card>

    <!-- Full Remark Dialog -->
    <v-dialog v-model="showRemarkDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Full Remark</v-card-title>
        <v-card-text>{{ selectedFullRemark }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showRemarkDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'InterviewerRemarks',
  props: {
    interviewData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedRound: 1,
      showRemarkDialog: false,
      selectedFullRemark: '',
      remarkCharLimit: 150,
      rounds: [],
    }
  },
  computed: {
    displayedInterviews() {
      return this.interviewData.filter(interview => parseInt(interview.round) == this.selectedRound);  
    }
  },
  mounted(){
    this.rounds = [...new Set(this.interviewData.map(({round})=>round))]
  },
  methods: {
    hasDataForRound(round) {
      return this.interviewData.some(interview => parseInt(interview.round) === round);
    },
    shortlistVal(value) {
      if (value < 40) {
        return {
          text: 'Not Recommended',
          remarkClass: 'not-recommended-remark'
        };
      } else if (value >= 40 && value < 60) {
        return {
          text: 'Below Standard',
          remarkClass: 'below-standard-remark'
        };
      } else if (value >= 60 && value < 75) {
        return {
          text: 'Moderately Recommended',
          remarkClass: 'moderately-recommended-remark'
        };
      } else if (value >= 75 && value < 90) {
        return {
          text: 'Highly Recommended',
          remarkClass: 'highly-recommended-remark'
        };
      } else {
        return {
          text: 'Strongly Recommended',
          remarkClass: 'strongly-recommended-remark'
        };
      }
    },
    truncateRemark(remark) {
      const strippedRemark = this.stripHtmlTags(remark)
      if (strippedRemark.length <= this.remarkCharLimit) {
        return strippedRemark
      }
      return strippedRemark.substring(0, this.remarkCharLimit) + '...'
    },
    stripHtmlTags(string) {
      return string?.replace(/<\/?[^>]+>/ig, " ") || ''
    },
    shouldShowViewMore(remark) {
      return this.stripHtmlTags(remark).length > this.remarkCharLimit
    },
    showFullRemark(remark) {
      this.selectedFullRemark = this.stripHtmlTags(remark)
      this.showRemarkDialog = true
    },
    getButtonClass(round) {
      return this.selectedRound === round
        ? 'blue white--text text-subtitle-2'
        : 'gray-02 blue--text text-subtitle-2'
    }
  },
  created() {
    // Set initial round to first round that has data
    for (let round = 1; round <= 3; round++) {
      if (this.hasDataForRound(round)) {
        this.selectedRound = round;
        break;
      }
    }
  }
}
</script>

<style scoped>
.common-border {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.league-title {
  font-family: GraphikRegular;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.interviewer-name {
  text-wrap: nowrap;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}

.interview-recommendation {
  margin: 8px 0;
  font-size: 14px;
}

.interview-remark {
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  font-size: 12px;
  max-height: 11vh;
  overflow: hidden;
}

.view-more-link {
  color: #3F51B5;
  cursor: pointer;
  margin-left: 4px;
}

.not-recommended-remark { color: #FF2600; }
.below-standard-remark { color: #E39236; }
.moderately-recommended-remark { 
  color: #97AD39;
  min-width: 173px;
}
.highly-recommended-remark { color: #5F9C4A; }
.strongly-recommended-remark { color: #56903E; }

.rounded-l {
  border-radius: 8px;
}

:deep(.v-btn-toggle) {
  background: #f5f5f5;
  border-radius: 20px;
}

:deep(.v-btn) {
  text-transform: none !important;
  letter-spacing: 0.5px;
}

:deep(.blue) {
  background-color: #3F51B5 !important;
}

:deep(.gray-02) {
  background-color: transparent !important;
}
</style>