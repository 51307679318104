<template>
	<div>
		<v-card height="56px" width="100%"
			class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
			style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      ">
			<v-card-title class="pl-0 ml-0 mt-1">
				<v-menu offset-y class="menuZindex">
					<template v-slot:activator="{ on }">
						<span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
							Support
							<v-icon class="breadcrumb-arrow cursor"
								style="margin-top: 5px !important">mdi-chevron-down</v-icon>
						</span>
					</template>
					<MastersDropdownComponent />
				</v-menu>
			</v-card-title>

			<UserNavMenu/>
		</v-card>

		<div fluid class="pad px-8 d-flex flex-wrap support-card-container">

			<v-card width="100%" outlined class="rounded-lg">
				<v-list class="py-0">
					<v-list-group :value="false">
						<template v-slot:activator>
							<v-list-item-title class="black--text"><b>User Manual for Job Seekers</b></v-list-item-title>
						</template>
						<v-list-item>
							<v-list-item-content>
								<div class="d-flex justify-end">
									<img width="30px" class="cursor" src="../assets/downloadManual.svg"
										@click="downloadManual('/files/user_manual.pdf')" />
								</div>
								<PDFPreviewer pdfPath="/files/user_manual.pdf" />
							</v-list-item-content>
						</v-list-item>
					</v-list-group>
				</v-list>
			</v-card>
			<v-card width="100%" outlined class="rounded-lg mt-4">
				<v-list class="py-0">
					<v-list-group :value="false">
						<template v-slot:activator>
							<v-list-item-title><b>FAQs for Job Seekers</b></v-list-item-title>
						</template>
						<v-list-item>
							<v-list-item-content>
								<v-card class="elevation-0 transparent">
									<v-text-field solo label="Search here..." hide-details
										prepend-inner-icon="mdi-magnify" v-model="search"
										class="search-bar search-bar2 border-button rounded-pill mx-2" dense clearable
										@click:clear="clearSearch"></v-text-field>
								</v-card>

								<v-card class="rounded-lg mt-2" outlined v-for="(item, index) in filteredFAQs"
									:key="index">
									<div class="py-2 pl-4 gray-02">
										<span>{{ item.name }}</span>
									</div>
									<v-list>
										<v-list-item v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
											<v-list-item-content>
												<v-list-item-title>{{ subItem.question }}</v-list-item-title>
												<v-list-item-subtitle class="answer"
													v-html="subItem.answer"></v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-card>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>
				</v-list>
			</v-card>
			<v-card width="100%" outlined class="rounded-lg mt-4">
				<v-list class="py-0">
					<v-list-group :value="false">
						<template v-slot:activator>
							<v-list-item-title><b>User Manual for VIBGYOR Group of Schools</b></v-list-item-title>
						</template>
						<v-list-item>
							<v-list-item-content>
								<div class="d-flex justify-end">
									<img width="30px" class="cursor" src="../assets/downloadManual.svg"
										@click="downloadManual('/files/vgos_manual.pdf')" />
								</div>
								<PDFPreviewer pdfPath="/files/vgos_manual.pdf" />
							</v-list-item-content>
						</v-list-item>
					</v-list-group>
				</v-list>
			</v-card>
			<v-card width="100%" outlined class="rounded-lg mt-4">
				<v-list class="py-0">
					<v-list-group :value="false">
						<template v-slot:activator>
							<v-list-item-title><b>Hubble STAR- VIBGYOR Organizational Leadership Training FAQs</b></v-list-item-title>
						</template>
						<v-list-item>
							<v-list-item-content>
								<v-card class="elevation-0 transparent">
									<v-text-field solo label="Search here..." hide-details
										prepend-inner-icon="mdi-magnify" v-model="vgosSearch"
										class="search-bar search-bar2 border-button rounded-pill mx-2" dense clearable
										@click:clear="clearVgosSearch"></v-text-field>
								</v-card>

								<v-card class="rounded-lg mt-2" outlined v-for="(item, index) in filterVgosFaqs"
									:key="index">
									<div class="py-2 pl-4 gray-02">
										<span>{{ item.name }}</span>
									</div>
									<v-list>
										<v-list-item v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
											<v-list-item-content>
												<v-list-item-title>{{ subItem.question }}</v-list-item-title>
												<v-list-item-subtitle class="answer"
													v-html="subItem.answer"></v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-card>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>
				</v-list>
			</v-card>
		</div>
		<v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
			<Notification @close-dialog="notificationDialog = false" />
		</v-dialog>
	</div>
</template>
<script>
import PDFPreviewer from '../components/PDFPreviewer.vue';
import UserNavMenu from "@/components/UserNavMenu.vue";
import {
	getDownloadURL,
	ref as storageRef,
} from "firebase/storage";
import { storage } from "../firebase.js";


export default {
	components: {
		PDFPreviewer,
		UserNavMenu
	},
	data() {
		return {
			notificationDialog: false,
			search: "",
      vgosSearch: "",
			items: [ // Static list item data with sub-items
				{
					name: "Hubble STAR (Smart Testing And Recruitment)  ",
					subItems: [
						{ question: '1. What is the purpose of Hubble STAR?', answer: 'a. The Smart Staff Selection application/ platform is designed to optimize the process of staff recruitment for the VIBGYOR Group of Schools, guiding job seekers through various assessment stages.' },
						{ question: '2. How can Hubble STAR help teachers?', answer: 'a. The Hubble STAR assessment offers job seekers a fair opportunity to demonstrate their skills and adaptability, facilitating entry into esteemed educational institutions for a fulfilling teaching career. It streamlines the selection process, saving time on job searches and applications. Through modern assessment tools, teachers gain exposure and self-awareness, guiding their professional growth.' },
						{ question: '3. How can the job seekers start the SSS test?', answer: 'a. Job seekers receive a personalized email containing an assessment link tailored to their expertise. Job seekers can click on the assessment link provided in the email to access the application.' },
						{ question: '4. What can job seekers do if they do not get an email for the test?', answer: 'a. In this case, they should contact Hubble STAR support at <a href="mailto:hubblestar.support@hubblehox.com">hubblestar.support@hubblehox.com</a>.' },
						{ question: '5. What are the different rounds in the Hubble STAR assessment?', answer: 'a. The assessment consists of four rounds: 1.Screening 2.Mains 3.Demo 4.Interview' },
						{ question: '6. How can the job seekers authenticate their identity on the platform?', answer: 'a. Job seekers authenticate their identity by entering an OTP sent to their phone number or email address.' },
						{ question: '7. What if the candidate does not receive the OTP?', answer: 'a. The candidate should first ensure they have entered the correct email or phone number. Then, they should check their spam or junk folder for the email. If they still do not receive the OTP, they can click the “RESEND OTP” button to try again. If the issue persists, contact support at <a href="mailto:hubblestar.support@hubblehox.com">hubblestar.support@hubblehox.com</a>.' },
						{ question: '8. What is the total duration of the test?', answer: 'a. The total duration of the test varies depending on the rounds, as each stage has a different set of timings.' },
						{ question: '9. Is there a screen with instructions explaining the test details before it begins?', answer: 'a. Job seekers are provided with instructions on the test instructions page before starting the screening or mains tests.' },
						{ question: '10. How can the job seeker select their job preferences?', answer: 'a. Job seekers choose their preferred level, school, board, and subjects from displayed lists, allowing them to select up to three subjects.' },
						{ question: '11. Can the job seeker change their job preferences?', answer: 'a. Once job seekers click on the assessment link, they must carefully choose their preference, as it cannot be changed later.' },
						{ question: '12. Can job seekers proceed to the next round if they fail in one?', answer: 'a. Candidates cannot proceed to the next round unless they clear the preceding one. For instance, if candidates fail the screening, they cannot advance to the main round. Similarly, if they do not pass the main round, they are ineligible for the demo round.' },
						{ question: '13. If the job seeker fails, can they retake the test?', answer: 'a. Job seekers are not allowed to retake the test if they fail any of the rounds.' },
						{ question: '14. Is it a proctored test?', answer: 'a. Yes, the screening and mains tests are AI-proctored to ensure a fair and transparent assessment process.' },
						{ question: '15. What are the recommended steps to set up your environment before starting an online proctored test?', answer: 'a. Ensure a quiet, well-lit room, clear your desk, and check computer and internet functionality.' },
					]
				},
				{
					name: "Screening",
					subItems: [
						{ question: '1. What is the screening test?', answer: " a. The 30-45 minute screening round assesses a candidate's core skills, pedagogical skills, digital literacy, and communication skills, which are crucial for the role and effective teamwork. " },
						{ question: '2. Is photo identity capture required for the screening test?', answer: 'a. Yes, job seekers need to capture their photo identity for authentication.' },
						{ question: '3. What happens after submitting the screening test?', answer: 'a. Upon completing and submitting the test, job seekers receive a confirmation email, acknowledging their submission and assuring them of the ongoing review of their application.' },
						{ question: '4. How are job seekers notified of their screening test results?', answer: 'a. Job seekers are notified via email regarding their test outcome the next day. ' },
						{ question: '5. What happens if a job seeker clears the screening test?', answer: 'a. After successfully clearing the screening test, the job seeker will receive an email with a link to the main test.' },
						{ question: '6. What happens if a job seeker does not clear the screening test?', answer: 'a. They receive an email informing them that they did not clear the screening test.' }
					]
				},
				{
					name: "Mains",
					subItems: [
						{ question: '1. What is the mains test?y', answer: 'a. The mains test is an online assessment at the school, lasting 90-120 minutes, covering core skills, pedagogy, digital literacy, communication skills, and psychometry' },
						{ question: '2. What happens after submitting the mains test?y', answer: 'a. Upon completing and submitting the test, job seekers receive a confirmation email, acknowledging their submission and assuring them of the ongoing review of their application.' },
						{ question: '3. How are job seekers notified of their mains test results?', answer: 'a. Job seekers are notified via email regarding their test outcome the next day.' },
						{ question: '4. What happens if a job seeker clears the mains test?', answer: 'a. After successfully clearing the mains test, the job seeker will receive an email with instructions for the demo submission, along with the assigned topic.' },
						{ question: '5. What happens if a job seeker does not clear the mains test?', answer: 'a. They receive an email informing them that they did not clear the mains test.' }
					]
				},
				{
					name: "Demo",
					subItems: [
						{ question: '1. What are the requirements for the demo submission?', answer: 'a. Job seekers need to submit a 15-minute demo on a specified subject topic adhering to the naming format "FirstName_LastName_Level_Subject".' },
						{ question: '2. Is it necessary for candidates to submit two demo videos if they choose two subjects?', answer: 'a. Yes, the candidates are required to submit two demo videos if they opt for two subjects.' }
					]
				},
				{
					name: "Interview",
					subItems: [
						{ question: '1. How will the interview be scheduled?', answer: 'a. HR will coordinate with the job seeker and schedule the interview based on the available slots.' },
						{ question: '2. When will the interviews be conducted?', answer: 'a. Interviews will be held between 3:00 PM and 5:00 PM on Wednesdays and Fridays.' },
						{ question: '3. Where will the interview be conducted?', answer: 'a. The interview process is offline, requiring the job seeker to visit the school in person for the interview.' },
						{ question: '4. What happens after the interview round?', answer: "a. The HR will review the interview feedback, decide the candidate's suitability for the role, and proceed with the appropriate next steps. " },
						{ question: '5. What if the job seeker cannot attend the scheduled interview?', answer: 'a.If a job seeker is unable to attend the scheduled interview, they should email <a href="mailto:hubblestar.support@hubblehox.com">hubblestar.support@hubblehox.com</a> to inquire about the possibility of rescheduling.' }
					]
				},
				{
					name: "Feedback",
					subItems: [
						{ question: '1. How can the job seekers provide question feedback?', answer: 'a. Job Seekers can provide feedback on individual questions by clicking the “Report the Question” icon next to each question ' },
						{ question: '2. How can job seekers provide feedback for the Smart Staff Selection application?', answer: 'a. Job seekers can access a feedback form within the application after attempting each round, such as after the Screening, Mains, and Demo rounds. If they do not pass the screening or mains test, a feedback form will be included in the email.' }
					]
				},
				{
					name: "General",
					subItems: [
						{ question: '1. What are the recommended steps to set up your environment before starting an online proctored test?', answer: 'a. Ensure a quiet, well-lit room, clear your desk, and check computer and internet functionality.' },
						{ question: '2. Can I have a break during the exam?', answer: 'a. Breaks are not permitted during the exam. However, in case of an emergency, the job seeker may leave the exam area. They should be aware that they are under AI proctoring and may receive a "No Person" popup repeatedly.' },
						{ question: '3. What technical infrastructure does the job seeker need to take the test?  ', answer: 'a. They will need a laptop or a PC with a functional webcam and a strong internet connection.' },
						{ question: '4. Do I need a specific browser?  ', answer: "a. There's no specific browser requirement. You can take the test using the latest versions of Google Chrome, Firefox, or Microsoft Edge." }
					]
				}

			],
			vogsQnA: [
	{
		"name": "Hubble STAR (Smart Testing And Recruitment)",
		"subItems": [
			{
				"question": "1. What can teachers do if they do not get an email for the assessment?",
				"answer": "In this case, the teacher can:<br>a. Raise a ticket at ISR (Internal Service Request) under the category of Hubble STAR.<br>b. They can also write to Hubble STAR support id at <a href='mailto:hubblestar.support@hubblehox.com'>hubblestar.support@hubblehox.com</a>."
			},
			{
				"question": "2. How can the teachers access the Hubble STAR application?",
				"answer": "Teachers will get a personalised email with a 'START NOW' button. This will take them to the Ampersand SSO page to log in with their VGOS credentials. After logging in, they should click the 'START ASSESSMENT' button to start the assessment. <br> OR <br>The teacher can login directly via <a href='https://gatewayclp.ampersandgroup.in/'>https://gatewayclp.ampersandgroup.in/</a>."
			},
			{
				"question": "3. Is there a screen with instructions explaining the assessment details before it begins?",
				"answer": "Yes, teachers are provided with instructions on the assessment instructions page before starting the assessment."
			},
			{
				"question": "4. Is it a proctored assessment?",
				"answer": "Yes, the assessment is AI-proctored to ensure a fair and transparent process. Please refer to the proctoring section below for more details."
			},
			{
				"question": "5. Is photo identity capture required for the assessment?",
				"answer": "Yes, teachers need to capture their photo identity for authentication."
			}
		]
	},
	{
		"name": "Assessment",
		"subItems": [
			{
				"question": "1. How many rounds are involved in the assessment?",
				"answer": "The assessment consists of one single round. The assessment duration may vary from 90 to 120 minutes based on the number of subjects the teacher will be evaluated on."
			},
			{
				"question": "2. Which teacher skills are evaluated in the assessment?",
				"answer": "This assessment will evaluate teachers based on five key parameters: Communication Skills, Psychometric Test, Pedagogy, Digital Literacy, and Cognitive Ability, which are crucial for the role and effective teamwork. The assessment will also assess the teachers’ subject expertise in their specific subject areas, viz. Science, Mathematics, English, Social Science and Hindi."
			},
			{
				"question": "3. What should I do if the images or diagrams are not visible?",
				"answer": "If the question or its image is unclear or not visible, click the <span style='font-weight: bold;'>EXPAND QUESTION</span> option to see the entire question and its related images. If the issue continues, report it by clicking the <span style='font-weight: bold;'>Report the Question</span> icon next to each question and submitting the feedback."
			},
			{
				"question": "4. Why must I click 'done' twice after answering a question?",
				"answer": "The system may require an extra confirmation to ensure the answer is saved. Click 'done' once to submit and again to confirm."
			},
			{
				"question": "5. Can I adjust the language of the assessment (e.g., to Hindi or any other language of my choice)?",
				"answer": "No, all the questions are in English, unless otherwise specified for a particular subject language."
			},
			{
				"question": "6. What happens if the question framing seems unclear?",
				"answer": "If a question seems ambiguous, answer it based on your best judgment and you may report it by clicking on the Report the Question icon next to each question and submit the feedback for further review."
			},
			{
				"question": "7. Can I select more than one correct answer for certain questions?",
				"answer": "There may be some questions that require you to choose multiple correct answers. Such multiple-choice questions will be shown with a square checkbox beside each option."
			},
			{
				"question": "8. What happens after submitting the assessment?",
				"answer": "The teacher will receive a confirmation email upon successful submission of the assessment."
			}
		]
	},
	{
		"name": "Feedback",
		"subItems": [
			{
				"question": "1. How can the teachers provide feedback on the questions asked in the assessment?",
				"answer": "Teachers can provide feedback on individual questions by clicking the <span style='font-weight: bold;'>“Report the Question”</span> icon <span style='color: red;'>“i”</span> next to each question."
			},
			{
				"question": "2. How can teachers provide feedback for the Hubble STAR application?",
				"answer": "After completing the assessment, teachers will be redirected to a page where a Google feedback form can be accessed in the bottom right-hand corner of the interface."
			}
		]
	},
	{
		"name": "General",
		"subItems": [
			{
				"question": "1. What technical infrastructure does the teacher need to take the test?",
				"answer": "To take the test, the teacher will need:<br> a. laptop or desktop computer (PC)<br>b. functional webcam<br>c. stable internet connection."
			},
			{
				"question": "2. What are the recommended steps to set up your environment before starting an online proctored test?",
				"answer": "Before starting an online proctored test, ensure you are in a quiet, well-lit room, and check that your computer/laptop is fully charged."
			},
			{
				"question": "3. Can I have a break during the exam?",
				"answer": "Breaks are not permitted during the exam. However, in an emergency, the teacher may leave the exam area. They should be aware that they are under AI proctoring and may receive a 'No Person' popup repeatedly. No additional time will be granted to teachers under any circumstances."
			},
			{
				"question": "4. What technical requirements do I need?",
				"answer": "a. Operating System: Windows 7 or higher<br>b. Screen Resolution: 1024 x 768 or higher<br>c. Internet Bandwidth: Greater than 2 Mbps."
			},
			{
				"question": "5. Do I need a specific browser?",
				"answer": "There's no specific browser requirement. The teacher can take the test using the latest versions of Google Chrome, Firefox, or Microsoft Edge."
			}
		]
	},
	{
		"name": "Proctoring",
		"subItems": [
			{
				"question": "1. When will a toast appear, and what are its implications?",
				"answer": "a. A toast will appear when any of the events listed in the <span style='font-weight: bold;'>'What events trigger proctoring toasts?'</span> section below occurs.<br>b. When a toast appears, the system simply records the events and does not prevent the assessment or impact scores.<br>c. It is advisable to minimize the events that trigger proctoring toasts as much as you can."
			},
			{
				"question": "2. What should I do if the system detects 'prohibited items' like watches?",
				"answer": "Ensure that you are following the assessment guidelines, but if watches or other items are flagged incorrectly, you may report it by clicking on the <span style='font-weight: bold;'>Report the Question</span> icon next to each question and submit the feedback."
			},
			{
				"question": "3. What events trigger proctoring toasts?",
				"answer": "a. <span style='font-weight: bold;'>No Person Detected</span> <br> i. This toast appears when the system camera cannot capture the entire face. If the user covers any part of their face, this message will be triggered.<br><br>b. <span style='font-weight: bold;'>Unknown Person Detected </span> <br> i. This notification occurs when the detected face does not match the one recorded during onboarding. Any movement by the user may result in an unknown person being identified.<br><br>c. <span style='font-weight: bold;'>Multiple Person</span> <br> i. This notification is displayed when multiple individuals are visible to the system camera, which may happen due to people walking or standing nearby.<br><br>d. <span style='font-weight: bold;'>Lip Movement</span> <br> i. This notification is triggered if the user is making any lip movements, such as talking, smirking, or whispering. <br><br>e. <span style='font-weight: bold;'>Head Movement</span> <br> i. This notification appears when the user moves their neck for any reason, which is recognised as head movement.<br><br>f. <span style='font-weight: bold;'>Tab switching detected</span> <br> i. This toast shows up when the user has switched the tabs.<br><br>g. <span style='font-weight: bold;'>Prohibited Items</span> <br> i. This notification is triggered if the system detects any prohibited items, including a cell phone, remote, camera, headphones, laptop, or tablet in the user’s vicinity."
			}

		]
	}
]



		}
	},
	methods: {
		logout() {
			AuthService.logout();
			this.$router.push("/login");
		},
		// async downloadManual() {
		//   try {
		//   	const pdfRef = storageRef(
		//         storage,
		//         "user_manual/user_manual.pdf"
		//       );
		//     const url = await getDownloadURL(pdfRef);

		//     // Create a temporary link element to trigger the download
		//     const link = document.createElement('a');
		//     link.href = url;
		//     link.target = '_blank';
		//     link.download = 'user_manual.pdf';
		//     document.body.appendChild(link);
		//     link.click();
		//     document.body.removeChild(link);
		//   } catch (error) {
		//     console.error('Error downloading manual:', error);
		//     // Handle error downloading manual
		//   }
		// },
		async downloadManual(url) {
			try {
				// Create a temporary link element to trigger the download
				const link = document.createElement('a');
				link.href = url;
				link.target = '_blank'; // Open in a new tab if necessary
				link.download = url.replace(/^.*[\\\/]/, '') // Specify the filename for download
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} catch (error) {
				console.error('Error downloading manual:', error);
				// Handle error downloading manual
			}
		},

		clearSearch() {
			this.search = "";
		},
    clearVgosSearch(){
      this.vgosSearch = ""
    }

	},
	computed: {
		filteredFAQs() {
			const searchText = this.search.toLowerCase();
			console.log('searchText', searchText)
			return this.items.map(item => {
				const filteredSubItems = item.subItems.filter(subItem => {
					const questionMatch = subItem.question.toLowerCase().includes(searchText);
					const answerMatch = subItem.answer.toLowerCase().includes(searchText);
					return questionMatch || answerMatch;
				});
				return { ...item, subItems: filteredSubItems };
			}).filter(item => item.subItems.length > 0);
		},
    filterVgosFaqs(){
      const searchText = this.vgosSearch.toLowerCase();
			console.log(' vgos searchText', searchText)
			return this.vogsQnA.map(item => {
				const filteredSubItems = item.subItems.filter(subItem => {
					const questionMatch = subItem.question.toLowerCase().includes(searchText);
					const answerMatch = subItem.answer.toLowerCase().includes(searchText);
					return questionMatch || answerMatch;
				});
				return { ...item, subItems: filteredSubItems };
			}).filter(item => item.subItems.length > 0);
    }
	}

}

</script>
<style>
.spaced-list {
	margin-top: 16px;
	/* Adjust the value as needed */
}

.cursor {
	cursor: pointer;
}

.pdf-preview {
	width: 100%;
	height: 500px;
	/* Adjust the height as needed */
	border: 1px solid #ccc;
}

.answer {
	white-space: normal;
	word-wrap: break-word;
}
</style>