<template>
    <!-- author: suraj -->
    <div class="background">
      <v-dialog v-model="successDialog" max-width="366px" persistent>
      <v-card>
        <v-container fluid class="pa-8">
          <v-card-text class="text-center d-flex flex-column align-center">
            <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
            <p class="text-h5 py-4">
            Your Password Is Created Successfully
            </p>
            <v-btn
              class="btn primary-btn"
              large
              width="157px"
              rounded
              @click="invited"
              >OK</v-btn
            >
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
        
    <v-app-bar
      color="transparent"
      class="rounded-xl"
      elevation="0"
      absolute
    >
  
  
      <LogoComponent />
      
    </v-app-bar>
      <v-row align="center" justify="center" style="height: 100vh" dense>
        <v-col
          cols="12"
          lg="12"
          md="12"
          class="fill-height d-flex flex-column justify-center align-center"
        >
          <v-card v-if="!cnfPasswordView" width="auto" height="auto">
            <div class="text-center pa-6 d-flex flex-column align-center">
              <img
                src="../assets/icon.svg"
                class="pt-3"
                width="70px"
                height="70px"
              />
              <v-card-title class="justify-center">
                <h3>Log In</h3>
              </v-card-title>
  
              <v-form v-model="valid">
                <v-text-field
                  label="Enter Your Name"
                  placeholder="Enter Your Name"
                  outlined               
                  :rules="[v => !!v || 'Name is required']"
                  v-model="name"
                  class="rounded-xl password-field"
                  disabled
                ></v-text-field>
                <!-- :rules="emailRules" -->
                <v-text-field
                  label="Enter Email Id"
                  placeholder=" Enter Email Id"
                  outlined
                  v-model="email"
               
                  disabled
                  class="rounded-xl password-field"
                ></v-text-field>
  
                <v-btn
                  @click.prevent="cnfPasswordView = true"
                  class="btn primary-btn white--text mb-2 py-4"
                  rounded
                  large
                  width="100%"
                  :disabled="!valid"
                
                >
                  Proceed To Set Password
                </v-btn>
  
             
              </v-form>
  
            </div>
          </v-card>




          
            <v-card v-else width="426" height="auto">
              <div class="text-center pa-6 d-flex flex-column align-center">
                <img
                  src="../assets/icon.svg"
                  class="pt-3"
                  width="70px"
                  height="70px"
                />
                <v-card-title class="justify-center">
                  <h3>Create Your Password</h3>
                </v-card-title>
                <v-card-subtitle>
                  Use 8 or more characters with a mix of small and capital letters, numbers & special Character
                </v-card-subtitle>
                <v-form ref="cnfPasswordViewForm">                
                  <v-text-field
                    @input="checkPasswordStrength"
                    :error-messages="passwordErrors"
                    label="Password"
                    placeholder=" Password"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="togglePasswordVisibility"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    v-model="password"
                    class="rounded-xl password-field"
                    @keydown.enter="login"
                  ></v-text-field>
    
                  <v-text-field
                    @input="checkPasswordStrength"
                    :error-messages="confirmPasswordErrors"
                    @click:append="toggleConfirmPasswordVisibility"
                    label="Confirm Password"
                    placeholder=" Password"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    v-model="cnfPassword"
                    class="rounded-xl password-field"
                    @keydown.enter="login"
                  ></v-text-field>
      
                  <v-btn
                      @click.prevent="createAdmin"
                      class="btn primary-btn white--text mb-2 py-4"
                      rounded
                      large
                      width="100%"
                      :loading="loading"
                      :disabled="!isPasswordStrong || password.length === 0" @click="createPassword"
                    >
                      create Password
                    </v-btn>
                </v-form>
              </div>
            </v-card>
        </v-col>
      </v-row>
    </div>
  </template>
    
  <script>
  import AuthService from "@/services/AuthService";
  import LogoComponent from "@/components/LogoComponent.vue";

  import "../styles.css";
  export default {
    components: { LogoComponent },
    name: "InvitedLogin",
    data() {
      return {
        cnfpasswd: false,
        forgetBool: false,
        successDialog: false,
        loading: false,
        roleId: "",
        ctList: false,
        cnfPasswordView: false,

        valid: false,
        email: "",
        password: "",
        cnfPassword: "",
        showPassword: false,
        showConfirmPassword: false,
        isPasswordStrong: false,
        passwordErrors: [],
        confirmPasswordErrors: [],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "E-mail must be valid",
        ],
        show2: false,
     
        rules: {
          required: (value) => !!value || "Required.",
          min: (v) => v.length >= 8 || "Min 8 characters",
          emailMatch: () => `The email and password you entered don't match`,
        },
      };
    },
    methods: {
      checkPasswordStrength() {
      this.passwordErrors = [];

      const hasUpperCase = /[A-Z]/.test(this.password);
      const hasNumber = /\d/.test(this.password);
      const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.password);
      const isLengthValid = this.password.length >= 8;

      this.isPasswordStrong = hasUpperCase && hasNumber && hasSpecialChar && isLengthValid;

      if (!hasUpperCase) this.passwordErrors.push('Password must contain at least one uppercase letter.');
      if (!hasNumber) this.passwordErrors.push('Password must contain at least one number.');
      if (!hasSpecialChar) this.passwordErrors.push('Password must contain at least one special character.');
      if (!isLengthValid) this.passwordErrors.push('Password must be at least 8 characters long.');

      if (this.cnfPassword !== this.password && this.cnfPassword.length > 0) {
        this.confirmPasswordErrors.push('Passwords do not match.');
      } else {
        this.confirmPasswordErrors = [];
      }
      },
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
      },
      toggleConfirmPasswordVisibility() {
        this.showConfirmPassword = !this.showConfirmPassword;
      },
      invited(){
        this.successDialog = false;
        this.logout();
        this.$router.push('/login');
      },
      logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
      async createAdmin() {
        if(this.valid && this.$refs.cnfPasswordViewForm.validate()){
          if(this.cnfPassword == this.password){
        this.loading = true;
        const response = await AuthService.createAdmin({
          email:this.email,
          password: this.password, 
          roleId: this.roleId,
          name: this.name
        });
        this.loading = false;
        if(response.data.flag){
          this.successDialog = true
        }
        else{
          alert(response.data.result)
        }
        console.log("admin response",response);
      }
      } 
      },
    },
    created() {
      console.log("email=",this.$route.query.email,"id=",this.$route.query.id)
      this.email = this.$route.query.email;
      this.name = this.$route.query.name;
      this.roleId = this.$route.query.id;
    },
  };
  </script>

<style>
  .v-messages__message {
    margin: 4px 0;
  }
  .theme--light .v-image__image--cover {
    background-size: contain;
  }
  .password-field {
  flex-grow: 1; 
  min-width: 330px; /* Set a minimum width to prevent it from shrinking too much */
}
</style>
