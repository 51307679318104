<template>
  <v-navigation-drawer app color="white" v-model="drawer" :mini-variant.sync="mini" mini-variant-width="75"
    overlay-color="white" permanent width="220" class="ma-4 elevation-4"    
    :class="{ hideNavigationPanel: $store.state.isNavBar === true }" style="z-index:210; border-radius: 15px;"
    height="95%">
    <div class="d-flex justify-center pt-3 bg-surface-variant topfixed text" fluid style="overflow:hidden;">
      <img v-if="mini" src="../assets/icon.svg" width="30px" height="35px" />
      <!-- <v-img v-if="mini" height="50px" width="50px" src="../assets/icon.svg"> -->
      <!-- </v-img> -->
      <LogoComponent v-if="!mini"/>
      <!-- <v-icon  @click.stop="mini = false" v-if="mini" class="drawerIcon pt-1"
      color="accent"  style="overflow:hidden">mdi-chevron-right-circle</v-icon
      >
      <v-icon @click.stop="mini = true" v-if="!mini" class="drawerIconRight"
      color="accent" style="overflow:hidden">mdi-chevron-left-circle</v-icon
      > -->
      <v-btn small class="drawerIcon white mt-2" style="height: 28px !important;"  icon @click.stop="mini = false" v-if="mini" color="black">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn small class="drawerIcon white mt-2" style="height: 28px !important;"  icon @click.stop="mini = true" v-else color="black">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </div>

    <v-divider class="ma-2" style="background-color: white"></v-divider>
    <div>
      <v-list>
        <!-- :height="getHeight" -->
        <v-list-item @click="handleButtonClick" @click.stop="mini = true" :ripple="false" color="grey lighten-2"
          :class="isNavItemActive('dashboard') ? 'active slider white--text blue' : ''"
          key="Home" to="/" active-class="active slider white--text blue">

          <v-tooltip bottom style="z-index: 999">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-icon class="" slider v-bind="attrs" v-on="on">
                                    <img
                                      v-if="isNavItemActive('dashboard')"
                                      src="../../public/svgs/Dashboard filled.svg" alt="SVG Image" />
                                    <img v-else src="../../public/svgs/deash board.svg" alt="SVG Image" />
                                  </v-list-item-icon>
                              
                                  </template>
                                  <span>Dashboard</span>
                                </v-tooltip>
        

          <v-list-item-content class="no-ripple">
            <v-tooltip bottom style="z-index: 999">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="" slider v-bind="attrs" v-on="on">
                                      Dashboard
                                  </v-list-item-title>
                              
                                  </template>
                                  <span>Dashboard</span>
                                </v-tooltip>
         
          </v-list-item-content>

        </v-list-item>



        <v-list-item @click="handleButtonClick" @click.stop="mini = true" :ripple="false"
          v-if="$store.state.role.teacherOnboarding?.read" color="grey lighten-2"
          :class="isNavItemActive('users') ? 'active slider white--text blue' : ''"
          key="User" to="/users" active-class="active slider white--text blue">
          <!-- :disable="!$store.state.userManagement.read" -->
          <v-tooltip bottom style="z-index: 999">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-icon class="" slider v-bind="attrs" v-on="on">
                                      <img
                                      v-if="isNavItemActive('users')"
                                      src="../../public/svgs/users filled.svg" alt="SVG Image" />
                                    <img v-else src="../../public/svgs/users.svg" alt="SVG Image" />
                                  </v-list-item-icon>
                              
                                  </template>
                                  <span>Users</span>
                                </v-tooltip>
     

          <v-list-item-content class="no-ripple">
            <v-tooltip bottom style="z-index: 999">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="" slider v-bind="attrs" v-on="on">
                                      Users
                                  </v-list-item-title>
                              
                                  </template>
                                  <span>Users</span>
                                </v-tooltip>
         
          </v-list-item-content>
        </v-list-item>



        <!-- <v-list-group
          no-action
          :disabled="!$store.state.role.userManagement.read"
          v-if="$store.state.role.userManagement.read"
        > -->



        <!-- doubt -->
        <!-- <template v-slot:activator>
            <v-list-item
              color="grey lighten-2"
            
              active-class="active slider white--text blue"
              class="mb-6"
            > -->
        <!-- :disable="!$store.state.userManagement.read" -->
        <!-- <v-list-item-icon class=" mr-3 mt-2" slider>
                <img src="../../public/svgs/users.svg" alt="SVG Image" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Users</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->

        <!-- <v-list-item
            to="/users"
            active-class="active slider white--text blue"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title>All Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$store.state.role.teacherOnboarding.read"
            to="/teachers"
            active-class="active slider white--text blue"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title>Teachers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/jobseekers"
            active-class="active slider white--text blue"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title>Job Seekers</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        <!-- </v-list-group> -->

        <v-list-item @click="handleButtonClick" @click.stop="mini = true" color="grey lighten-2"
          :class="isNavItemActive('campaigns') ? 'active slider white--text blue' : ''"
          key="Question Bank" to="/campaign"
          active-class="active slider white--text blue" class="" v-if="$store.state.role.campaign?.read"
          :ripple="false">
          <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon class="" slider v-bind="attrs" v-on="on">
                  <img
                    v-if="isNavItemActive('campaigns')"
                    src="../../public/svgs/campaign filled.svg" alt="SVG Image" />
                  <img v-else src="../../public/svgs/campaign.svg" alt="SVG Image" />
              </v-list-item-icon>
          
              </template>
              <span>Campaigns</span>
            </v-tooltip>
    
          <v-list-item-content class="no-ripple">
            <v-tooltip bottom style="z-index: 999">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title class="" slider v-bind="attrs" v-on="on">
                      Campaigns
                  </v-list-item-title>
              
                  </template>
                  <span>Campaigns</span>
                </v-tooltip>
         
          </v-list-item-content>
        </v-list-item>


        <v-list-item @click="handleButtonClick" @click.stop="mini = true" color="grey lighten-2"
          :class="isNavItemActive('static-assessment') ? 'active slider white--text blue' : ''"
          key="Assessment" to="/static-assessment"
          active-class="active slider white--text blue" class="" v-if="$store.state.role.assessment?.read"
          :ripple="false">
          <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon class="" slider v-bind="attrs" v-on="on">
                  <img
                    v-if="isNavItemActive('static-assessment')"
                    src="../../public/svgs/assessments filled.svg" alt="SVG Image" />
                  <img v-else src="../../public/svgs/assessments.svg" alt="SVG Image" />
              </v-list-item-icon>
          
              </template>
              <span>Assessment</span>
            </v-tooltip>
          <v-list-item-content class="no-ripple">
            <v-tooltip bottom style="z-index: 999">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title class="" slider v-bind="attrs" v-on="on">
                    Assessment
                </v-list-item-title>
            
                </template>
                <span>Assessment</span>
              </v-tooltip>
         
          </v-list-item-content>
         
        </v-list-item>

        <!-- <v-list-item @click="handleButtonClick" @click.stop="mini = true" color="grey lighten-2" key="Assessment"
          to="/assessment" active-class="active slider white--text blue" class=""
          v-if="$store.state.role.assessment?.read" :ripple="false">
          <v-list-item-icon slider>
            <img v-if="this.$route.path === '/assessment'" src="../../public/svgs/assessments filled.svg"
              alt="SVG Image" />
            <img v-else src="../../public/svgs/assessments.svg" alt="SVG Image" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Assessments</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item @click="handleButtonClick" @click.stop="mini = true" color="grey lighten-2"
          :disabled="!$store.state.role.questionBank?.read" v-if="$store.state.role.questionBank?.read" to="/question-bank"
         active-class="active slider white--text blue" class="" :ripple="false">
         <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon class="" slider v-bind="attrs" v-on="on">
                  <img v-if="isNavItemActive('question-bank')" src="../../public/svgs/question bank filled.svg"
              alt="SVG Image" />
            <img v-else src="../../public/svgs/question bank.svg" alt="SVG Image" />
              </v-list-item-icon>
              </template>
              <span>Question Bank</span>
            </v-tooltip>
 

          <v-list-item-content class="no-ripple">
            <v-tooltip bottom style="z-index: 999">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title class="" slider v-bind="attrs" v-on="on">
                      Question Bank
                  </v-list-item-title>
              
                  </template>
                  <span>Question Bank</span>
                </v-tooltip>
         
          </v-list-item-content>
        </v-list-item>



        <v-list-item @click="handleButtonClick" @click.stop="mini = true" v-if="$store.state.role.questionBank?.read" color="grey lighten-2"
           to="/psychometry"
         active-class="active slider white--text blue" :ripple="false">
         <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon class="" slider v-bind="attrs" v-on="on">
                  <img height="24px" width="24px" v-if="isNavItemActive('psychometry')" src="../../public/svgs/filled_psychometry_icon.svg"
              alt="SVG Image" />

            <img v-else height="24px" width="24px" src="../../public/svgs/psychometry_icon.svg" alt="SVG Image" />
              </v-list-item-icon>
              </template>
              <span>Psychometry</span>
            </v-tooltip>
          <v-list-item-content class="no-ripple">
            <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title class="" slider v-bind="attrs" v-on="on">
                  Psychometry
              </v-list-item-title>
          
              </template>
              <span>Psychometry</span>
            </v-tooltip>
          </v-list-item-content>
      
        </v-list-item>
        <v-list-item @click="handleButtonClick" @click.stop="mini = true" v-if="$store.state.role.candidatesListing?.read" color="grey lighten-2"
           to="/candidate-list"
         active-class="active slider white--text blue" :ripple="false">
         <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon class="" slider v-bind="attrs" v-on="on">
                  <img height="24px" width="24px" v-if="isNavItemActive('candidate-list')"  src="../../public/svgs/candidateListFilled.svg"
              alt="SVG Image" />

            <img v-else height="24px" width="24px" src="../../public/svgs/candidateList.svg" alt="SVG Image" />
              </v-list-item-icon>
              </template>
              <span>Candidate List</span>
            </v-tooltip>

        
          <v-list-item-content class="no-ripple">
            <v-tooltip bottom style="z-index: 999">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title class="" slider v-bind="attrs" v-on="on">
                    Candidate List
                </v-list-item-title>
            
                </template>
                <span>Candidate List</span>
              </v-tooltip>
         
          </v-list-item-content>
        </v-list-item>




        <v-list-item @click="handleButtonClick" @click.stop="mini = true" color="grey lighten-2" key="Roles" to="/roles"
          :class="isNavItemActive('roles') ? 'active slider white--text blue' : ''"
          :ripple="false" active-class="active slider white--text blue"
          v-if="$store.state.role.rolesAndPrivilage?.read">
          <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon class="" slider v-bind="attrs" v-on="on">
                  <img
              v-if="isNavItemActive('roles')"
              src="../../public/svgs/roles filled.svg" alt="SVG Image" />
            <img v-else src="../../public/svgs/roles.svg" alt="SVG Image" />
              </v-list-item-icon>
              </template>
              <span>Role</span>
            </v-tooltip>


          <v-list-item-content class="no-ripple">
            <v-tooltip bottom style="z-index: 999">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title class="" slider v-bind="attrs" v-on="on">
                    Role
                </v-list-item-title>
            
                </template>
                <span>Role</span>
              </v-tooltip>
          </v-list-item-content>
        </v-list-item>

          <v-list-item color="grey lighten-2" @click.stop="mini = true" class="common-link"
            @click="$router.push('/masters')"
            :class="isNavItemActive('masters') ? 'slider white--text blue' : ''"
            active-class="white--text  darken-4 slider blue" v-if="$store.state.role.masters?.read">
            <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon class="" slider v-bind="attrs" v-on="on">
                  <img
                v-if="isNavItemActive('masters')"
                src="../../public/svgs/Masters filled.svg" alt="SVG Image" /><img v-else src="../../public/svgs/masters.svg" alt="SVG Image" />
              </v-list-item-icon>
              </template>
              <span>Masters</span>
            </v-tooltip>

            <v-list-item-content class="no-ripple">
            <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title class="" slider v-bind="attrs" v-on="on">
                  Masters
              </v-list-item-title>
          
              </template>
              <span>Masters</span>
            </v-tooltip>
          </v-list-item-content>
          
          </v-list-item>

          <v-list-item @click="handleButtonClick" @click.stop="mini = true" color="grey lighten-2"
           to="/support"
         active-class="active slider white--text blue" :ripple="false">
         <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon class="" slider v-bind="attrs" v-on="on">
                  <img height="24px" width="24px" v-if="isNavItemActive('support')" src="../../public/svgs/support.svg"
              alt="SVG Image" />
            <img v-else height="24px" width="24px" src="../../public/svgs/support.svg" alt="SVG Image" />
              </v-list-item-icon>
              </template>
              <span>Support</span>
            </v-tooltip>
          <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title class="" slider v-bind="attrs" v-on="on">
                  Support
              </v-list-item-title>
          
              </template>
              <span>Support</span>
            </v-tooltip>
      
        </v-list-item>

        <v-list-item
    @click="logout"
    class="logout-item d-flex"
    color="grey lighten-2"
    style="position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%); width: auto; display: flex; flex-direction: column; justify-content: center; align-items: center;">
    <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon class="d-flex justify-center" slider v-bind="attrs" v-on="on">
                  <img src="../assets/Logout.svg" alt="Logout Icon" height="24px" width="24px" />
              </v-list-item-icon>
              </template>
              <span>Logout</span>
            </v-tooltip>
    <!-- <v-list-item-content class="d-flex justify-center">
        <v-list-item-title>Logout</v-list-item-title>
    </v-list-item-content> -->
    <v-tooltip bottom style="z-index: 999">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title class="" slider v-bind="attrs" v-on="on">
                  Logout
              </v-list-item-title>
          
              </template>
              <span>Logout</span>
            </v-tooltip>
</v-list-item>

        <!-- <v-list-item
          style="border-radius: 15px"
          active-class="active ma-2 white--text secondary"
          :ripple="false"
          :theme="false"
        >
          <v-list-item-icon class="ma-4" :theme="false">
            <img
              v-if="this.$route.path === '/settings'"
              src="../../public/svgs/settings filled.svg"
              alt="SVG Image"
            />
            <img v-else src="../../public/svgs/settings.svg" alt="SVG Image" />
          </v-list-item-icon>
          <v-list-item-content class="ma-4" :theme="false">
            <v-list-item-title :theme="false"> Settings </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->


        <!-- <v-list-item @click="handleButtonClick" @click.stop="mini = true" color="grey lighten-2" dense to="/settings"
          key="Settings" :ripple="false"
          active-class="active slider white--text blue" v-if="$store.state.role.rolesAndPrivilage?.read">
          <v-list-item-icon :theme="false">
            <img v-if="this.$route.path === '/settings'" src="../../public/svgs/settings filled.svg" alt="SVG Image" />
            <img v-else src="../../public/svgs/settings.svg" alt="SVG Image" />
          </v-list-item-icon>

          <v-list-item-content :theme="false">
            <v-list-item-title :theme="false" style="font-size: 16px; padding-bottom: 1px;"> Settings </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item
          dense
          to="/help"
          style="border-radius: 15px"
          active-class="active white--text ma-2 slider secondary"
        >
          <v-list-item-icon class="ma-4">            
            <v-icon size="25px" :color=" this.$route.path === '/help'? 'white':'black'">mdi-help-circle-outline</v-icon>            
          </v-list-item-icon>
          <v-list-item-content class="ma-4">
            <v-list-item-title > Help </v-list-item-title>
          </v-list-item-content>
        </v-list-item>  -->
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import AuthService from "@/services/AuthService";
const CryptoJS = require("crypto-js");
import { doc, onSnapshot } from "firebase/firestore";
import {db} from '../firebase'
import { liveListenerUnsubscriber} from "../mixins/liveListenerUnsubcriber";
import LogoComponent from "@/components/LogoComponent.vue";

export default {
  name: "NavDrawer",
  components: {
    LogoComponent
  },
  mixins: [liveListenerUnsubscriber],
  data() {
    return {
      drawer: true,
      mini: true,
      windowHeight: window.innerHeight,
      mastersTabOpen: false,
    };
  },
  computed: {
    user() {
      return AuthService.getUser();
    },
    getHeight() {
      return this.windowHeight - 300;
    },
  },
  methods: {
    isNavItemActive(item){
      let navCb = {
        'dashboard': ()=>{
          const paths = [
                '/',
                '/campaignjobseekersresult',
                '/campaignteachersresult',
                '/teachersappraisal',
                '/teacherspip',
                '/teacherstbd',
                '/jobseekersappraisal',
                '/jobseekerspip',
                '/jobseekerstbd',
                '/clustercampaignteachersresult',
                '/clustercampaignjobseekersresult',
                '/clusterteachersappraisal',
                '/clusterteacherstbd',
                '/clusterteacherspip',
                '/clusterjobseekersappraisal',
                '/clusterjobseekerspip',
                '/clusterjobseekerstbd',
                '/schoolcampaignteachersresult',
                '/schoolcampaignjobseekersresult',
                '/schoolteachersappraisal',
                '/schoolteacherspip',
                '/schoolteacherstbd',
                '/schooljobseekersappraisal',
                '/schooljobseekerspip',
                '/schooljobseekerstbd'
        ];
         return paths.includes(this.$route.path) || this.$route.name === 'ViewSchoolData'
        },
        'users': ()=>{
          const paths =  [
            '/users',
            '/teachers',
            '/jobseekers',
            '/create-teacher',
            '/create-jobseeker',
            '/update-teacher',
            '/update-jobseeker'
          ];
          return paths.includes(this.$route.path)
        },
        'campaigns': ()=>{
          const paths =  [
          '/campaign',
          '/campaignUser',
          '/CreateCampaign',
          '/campaignCardFullView',
          '/demoreport',
          '/userreport',
          '/testreport/:campaignId/:userId'
        ];
          return  paths.includes(this.$route.path)
        },
        'static-assessment': ()=>{
          return this.$route.path === '/static-assessment'
        },
        'question-bank': ()=>{
          return this.$route.path === '/question-bank'
        }, 
        'psychometry': ()=>{
          return this.$route.path === '/psychometry'
        },
        'candidate-list':()=>{
          return this.$route.path === '/candidate-list'
        },
        'roles':()=>{
          const paths = ['/roles', '/createRole', '/update-role']
          return paths.includes(this.$route.path)
        },
        'masters':()=>{
          const paths =  [
                '/masters',
                '/clusters',
                '/brands',
                '/schools',
                '/boards',
                '/levels',
                '/grades',
                '/subject-category',
                '/subjects',
                '/skills',
                '/lo-banks',
                '/league',
                '/skills-weightage-thresholds',
                '/demoTopics'
              ];
              return paths.includes(this.$route.path)
        },
        'support':()=>{
         return  this.$route.path === '/support'
        }
      }

      return navCb[item]()
    },
    adminRolePermission(){
      // console.log("admin method calling")
      // console.log("admin ",this.$store.state.userInfo)

      const userId = this.$store.state.userInfo.id
      // console.log("admin ",userId)

      const docRef = doc(db, "admins", userId); 
      // console.log("admin ",userId)

      let unsubscribe = onSnapshot(docRef, (snapshot) => {
        const data = snapshot.data();
        if (data) {
          // console.log("admin snapshot ",data);
          if(data.roleId)data.role.id = data.roleId;
          this.$store.state.role = data.role;
          AuthService.setUserInfo(data)
          this.$store.dispatch('setUserInfo', data);
        } else {
          this.logout();
            return;
        }
      }, (error) => {
        console.error("Error listening to document changes:", error);
      });
      this.liveListenersToUnsubscribeMixin.push(unsubscribe);
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },

    // handleMastersClick() {
    //   if (this.$route.path === '/clusters' || this.$route.path === '/brands' || this.$route.path === '/boards' || this.$route.path === '/brands' || this.$route.path === '/grades' || this.$route.path === '/league' || this.$route.path === '/levels' || this.$route.path === '/schools' || this.$route.path === '/skills' || this.$route.path === '/subjects' || this.$route.path === '/subject-category' || this.$route.path === '/lo-banks') {
    //     return;
    //   }
    //   this.$router.push("/clusters")

    // },
    handleButtonClick() {
      // Close the "masters" tab when another button is clicked
      this.mastersTabOpen = false;

      // Perform other actions related to the button click
      // ...
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    setTimeout(this.adminRolePermission, 5000);
    // setTimeout(, 10000);

  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    // if(this.$store.state.role.createdOn == undefined){
    //   this.logout();
    // }
    var storedItem = AuthService.getRole();
    const decryptedData = CryptoJS.AES.decrypt(storedItem, "sadfsafa").toString(CryptoJS.enc.Utf8);
    var userData = JSON.parse(decryptedData);
    userData.role.id = userData.roleId;
    this.$store.state.role = userData.role;
    this.$store.state.userInfo.id = userData.id
    this.$store.state.userInfo.name = userData.name;
    this.$store.state.userInfo.email = userData.email;
    if (this.$store.state.role.createdOn == undefined) {
      this.logout();
      return;
    }
   else if(this.$store.state.role.league == undefined){
    this.logout();
      return;
   }
  },
};
</script>

<style scoped>

.v-navigation-drawer {
  padding: 0 16px;
}

.fixedBottom {
  position: sticky !important;
  bottom: 0 !important;
  width: 100%;
}

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: unset;
}

.v-list {
  padding: 8px 0;
  img {
    width: 22px;
  }
}

.v-list-item__icon {
  margin: 0;
}

.v-navigation-drawer--mini-variant.v-navigation-drawer--custom-mini-variant .v-list-item, .v-list-item.v-list-item--link {
  min-height: 0;
  margin-bottom: 16px;
  padding: 10px 20px;
  border-radius: 10px;
}

.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 48px;
}

.v-navigation-drawer--mini-variant .v-list-item > *:first-child {
  overflow: hidden;
}

.v-list-item__content, .v-list-group__header.v-list-item.v-list-item--link {
  padding: 0;
}

.master-link {
  border-radius: 10px;
}

.masters-list .v-list-item--link {
  min-height: 0;
}

.v-list-item__title {
  margin-left: 10px;
}

.text {
  color: white;
}

.v-application .grey--text.text--lighten-2 {
  overflow: hidden;
}

.no-ripple .v-ripple {
  display: none !important;
}

.drawerIcon {
  overflow: hidden;
}

.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
  margin-bottom: 16px;
}

.maspad {
  padding-right: 60px;
}

.hideNavigationPanel {
  display: none;
}

.v-application .active {
  padding: 11px;
  border-radius: 10px;
}
</style>


<!-- .drawerIcon{
  color: transparent;
  box-shadow: none;
} -->