<template>
  <div>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
      <v-card-title class="pl-0 ml-0">
        <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/masters')">
          Masters
        </p>
        <v-icon class="breadcrumb-arrow-unselected" style="margin-top: 6px !important">mdi-chevron-right</v-icon>
        <v-menu offset-y class="menuZindex">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
              Skills
              <v-icon class="breadcrumb-arrow cursor" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </span>
          </template>
          <MastersDropdownComponent />
        </v-menu>
        <!-- <p class="breadcrumb-text" style="margin-top: 2px">Skills</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon> -->
      </v-card-title>
      <UserNavMenu/>
    </v-card>
    <div fluid class="px-8 pad">

      <v-row>
        <v-col>

          <v-dialog max-width="887px" class="cdz" v-model="dialog" center>
            <v-form ref="form" lazy-validation>
              <v-card>
                <v-card-title class="gray-02 mb-8">{{ formbtn() }} Skill</v-card-title>
                <v-card-text class="py-0 px-6">
                  <v-row class="py-0">
                    <v-col class="py-0" cols="6">
                      <v-text-field outlined class="rounded-xl" v-model="name" label="Skill Name*"
                        :rules="[(v) => !!v || 'Skill Name is required']" required></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="">
                      <v-autocomplete v-model="selectedSubjects" clearable deletable-chips
                        label="Select or Search Subject" outlined class="rounded-xl" small-chips :items="subjects"
                        multiple item-text="name" item-value="name">
                        <v-list-item slot="prepend-item" ripple @click="selectAll">
                          <v-checkbox @click="selectAll" :value="selectedSubjects.length == subjects.length">
                          </v-checkbox>
                          <v-btn text @click="selectAll">Select all</v-btn>
                        </v-list-item>
                        <v-divider slot="prepend-item" class="mt-2" />
                      </v-autocomplete>
                    </v-col> </v-row></v-card-text>
                <v-card-text class="pb-5 px-6">
                  <div style="size: 18px; color: black">
                    Select range for Competency Level
                  </div>
                </v-card-text>
                <v-card-text class="py-0 my-0 px-6">
                  <v-row class="py-0">
                    <v-col cols="3" class="py-0">
                      <div class="mt-4">Novice</div>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field type="number" outlined v-model="novicMin" class="rounded-xl py-0"
                        suffix="%"></v-text-field>
                    </v-col>
                    <v-col fluid cols="3" class="py-0">
                      <v-text-field type="number" outlined v-model="novicMax" class="rounded-xl py-0"
                        suffix="%"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="py-0">
                    <v-col cols="3" class="py-0">
                      <div type="number" class="mt-4">Advance beginner</div>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field type="number" outlined class="rounded-xl" v-model="advBegMin"
                        suffix="%"></v-text-field>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field type="number" outlined class="rounded-xl" v-model="advBegMax"
                        suffix="%"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="py-0">
                    <v-col cols="3" class="py-0">
                      <div class="mt-4">Competent</div>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field type="number" outlined class="rounded-xl" v-model="competentMin"
                        suffix="%"></v-text-field>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field type="number" outlined class="rounded-xl" v-model="competentMax"
                        suffix="%"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="py-0">
                    <v-col cols="3" class="py-0">
                      <div class="mt-4">Proficient</div>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field type="number" outlined class="rounded-xl" v-model="proficientMin"
                        suffix="%"></v-text-field>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field type="number" outlined class="rounded-xl" v-model="proficientMax"
                        suffix="%"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="py-0">
                    <v-col cols="3" class="py-0">
                      <div class="mt-4">Expert</div>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field type="number" outlined class="rounded-xl" v-model="expertMin"
                        suffix="%"></v-text-field>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field type="number" outlined class="rounded-xl" v-model="expertMax"
                        suffix="%"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="px-6 pb-6">
                  <small>*All fields are mandatory</small>
                  <v-spacer></v-spacer>
                  <v-btn width="102px" height="48px" rounded outlined class="btn outline-btn pa-4"
                    @click="dialog = false">Cancel</v-btn>
                  <v-btn width="102px" height="48px" :loading="loading" rounded @click="saveInputs" class="btn primary-btn pa-4">{{
                    formbtn() }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-col>
        <!-- <v-col cols="4">
        <v-text-field
          label="Search"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          clearable
        ></v-text-field
      ></v-col> -->
      </v-row>
      <v-row class="d-flex justify-center my-0 py-0">
        <v-col>
          <div class="text-h6">Skills</div>
        </v-col>

        <v-col cols="10" class="pl-0 ml-0">
          <v-row justify="end" class="mb-1 mt-0">
            <v-text-field solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search" hide-details
              class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="btn outline-btn elevation-0 mx-2 pl-4 pr-6"
                  :class="filterCount > 0 ? 'outline-disabled-btn' : ''"
                  rounded
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  :disabled="filterCount > 0"
                >
                <v-img
                v-bind="attrs" 
                v-on="on" 
                :style="filterCount > 0 ? 'filter: grayscale(100%) opacity(50%);' :''"
                :src="require(`@/assets/svg/sort.svg`)"
                />              
                  <span>SORT</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item 
                  v-for="option in sortOptions" 
                  :key="option.value" 
                  @click="selectSortOption(option.value)"
                  :class="{'selected-sort-option': selectedSort === option.value}"
                >
                  <v-list-item-title>
                    {{ option.label }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>           
              <v-btn depressed outlined rounded class="btn outline-btn mx-3"><v-icon class="pr-2">mdi-tune</v-icon><span
                v-if="filterCount > 0" @click="filterDialog = true">Filter ({{ filterCount }})</span>
              <span v-else @click="filterDialog = true">Filter</span></v-btn>
              <v-btn class="btn mx-3" rounded
              outlined :disabled="selected.length == 0"
              :class="!selected.length == 0 ? 'outline-btn' : 'outline-disabled-btn'"
              @click="deleteDialog = true"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>
            <!-- <v-btn class="primary mx-2" rounded
            ><v-icon>mdi-export</v-icon>Export</v-btn
          > -->
            <v-btn depressed outlined rounded class="btn outline-btn mx-3" @click="downlaodRoles"><v-icon>mdi-import
                mdi-rotate-90</v-icon></v-btn>

            <v-btn v-if="$store.state.role.skills.admin" @click="(dialog = true), newCreateSkills()"
              class="btn primary-btn mx-3 white--text" depressed rounded><v-icon class="pr-2">mdi-plus</v-icon>Create</v-btn>
            <v-dialog v-model="deleteDialog" max-width="366px" class="cdz" persistent>
              <v-card fluid>
                <v-container fluid class="pa-0">
                  <v-container></v-container>
                  <v-card-text class="text-center">
                    <v-icon size="70" class="pt-4">mdi-trash-can-outline</v-icon>
                    <p class="text-h5 pt-6 pb-0">Delete Skill</p>
                    <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>
                      This action will permanently delete the item . This cannot
                      be undone
                    </p>

                    <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                      <v-btn depressed class="btn outline-btn black--text" large width="157px" rounded @click="() => {
                        deleteDialog = false;
                        formbtnBool = false;
                      }
                        ">CANCEL</v-btn>
                      <v-btn class="btn primary-btn white--text" depressed large width="157px" rounded :loading="dLoading"
                        :disabled="selected.length == 0" @click="deleteData(selected)">DELETE</v-btn>
                    </div>
                  </v-card-text>
                </v-container>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table fixed-header height="calc(100vh - 230px)" class="rounded-table" :options.sync="options"  v-model="selected"
        :loading="dataTableLoading" loading-text="Loading Skills" :headers="headers" :items="tableData" show-select
        :items-per-page="options.itemsPerPage"
        @update:options="getSkills"
        :server-items-length="count"
        :single-select="singleSelect" :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 50, 100],
        }">
        <template v-slot:loading>
          <bubble-loader/>
        </template>
        <template v-slot:no-results>
          <div class="custom-no-data">
            <p>No Data Available</p>
          </div>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ getDate(item.createdOn) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="$store.state.role.skills.admin" icon class="mr-2 pa-4" @click="updateData(item)">
            <img width="36px" height="36px" class="cursor" src="../../assets/edit.svg" /> </v-btn>
        </template>
        <template v-slot:[`item.Subjects`]="{ item }">
          <span v-for="data in item.subjects" :key="data.id">{{ data.name }},
          </span>
        </template>
      </v-data-table>
      <v-dialog v-model="errorDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="primary" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteSuccessDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Skill Deleted</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p v-if="formbtnBool" class="text-h5 py-4">Skill Updated</p>

              <p v-else class="text-h5 py-4">New Skill Created</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- filter dialog-->

      <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
        <v-card width="570px" height="100%" class="filterDialogClass">
          <v-row class="mt-4 mx-1">
            <v-col>
              <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">Filter</div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div @click="clearFilter" class="clear-filter-btn">CLEAR FILTER </div>
            </v-col>
          </v-row>

          <v-tabs vertical>

            <div class="ml-3 mt-4 mr-4 filterLine">

              <v-tab class="black--text justify-start" active-class="activeTab">
                <span>Subject</span>
                <span
                  v-if="selectedSubjectFilter.length > 0" class="ml-1">({{ selectedSubjectFilter.length
                  }})</span></v-tab>

            </div>


            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="selectedSubjectFilter" active-class="active-chip" column :multiple="true">
                    <v-chip v-for="(SubjectType, index) in subjects" :key="index" :value="SubjectType.name" elevated>
                      {{ SubjectType.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs>
          <div class="filterDialogButton">
            <v-card-actions class="px-6 pb-6">
              <v-spacer></v-spacer>
              <v-btn rounded outlined class="btn outline-btn pa-4" @click="filterDialog = false">Cancel</v-btn>
              <v-btn :disabled="selectedSubjectFilter.length == 0" :loading="skillFilterLoading" rounded
                class="btn primary-btn pa-4" @click="filterSkills(selectedSubjectFilter), getFilterCount()">Apply</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

      <!-- <v-dialog v-model="filterDialog" max-width="400px">
        <v-card width="400px" height="100%">
          <v-card-text class="pa-6">
            <v-row>
              <v-col>
                <div class="pl-1 text-body1 font-weight-bold">FILTER</div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div @click="clearFilter" class="text-body1 font-weight-bold black--text cursor">
                  CLEAR FILTER
                </div>
              </v-col>
            </v-row>
            <div>
              <v-card height="450px" elevation="0" id="myScroll" class="pt-5 ,pb-5">
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text">
                      Subject
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group v-model="selectedSubjectFilter" active-class="primary" column :multiple="true">
                  <v-chip v-for="(SubjectType, index) in subjects" :key="index" :value="SubjectType.name" elevated>
                    {{ SubjectType.name }}
                  </v-chip>
                </v-chip-group>
              </v-card>
              <div>
                <v-card-actions class="px-6 pb-6">
                  <v-spacer></v-spacer>
                  <v-btn rounded outlined class="pa-4" @click="filterDialog = false">Cancel</v-btn>
                  <v-btn :disabled="selectedSubjectFilter.length == 0" :loading="skillFilterLoading" rounded
                    class="accent pa-4" @click="filterSkills(selectedSubjectFilter)">Apply</v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog> -->


    </div>

    <!-- Notification Dialog -->
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>

  </div>
</template>
<script>
import SkillsController from "@/controllers/SkillsController";
import SubjectController from "@/controllers/SubjectController";
import AuthService from "../../services/AuthService";
import Papa from "papaparse";
import Notification from "../Notification.vue";
import MastersDropdownComponent from "../../components/MastersDropdownComponent.vue"
import { getDateFromTimestamp } from "@/helpers/utils";
import BubbleLoader from '@/components/BubbleLoader.vue';
import UserNavMenu from "@/components/UserNavMenu.vue";

export default {
  name: "SkillsView",
  components: {
    Notification, MastersDropdownComponent,
    BubbleLoader,UserNavMenu
  },
  data() {
    return {
      notificationDialog: false,
      appliedFilter: false,
      filterCount: 0,
      pageSize: 10,
      page: 1,
      count: 0,
      errorDialog: false,
      successDialog: false,
      deleteSuccessDialog: false,
      skillFilterLoading: false,
      deleteDialog: false,
      dialog: false,
      dialogTitle: "Dialog Title",
      name: "",
      errorMessage: "",
      novicMin: null,
      novicMax: null,
      advBegMin: null,
      advBegMax: null,
      competentMin: null,
      competentMax: null,
      proficientMin: null,
      proficientMax: null,
      roficientMax: null,
      expertMin: null,
      expertMax: null,
      selectedSubjects: [],
      dLoading: false,
      filterData: false,
      options:{},
      sortCol:"name",
      sortOrder: "asc", // Default sort order (asc or desc)
      selectedSort: "skillAsc", // Default selected sort option
      sortOptions: [
        { value: "skillAsc", label: "Skill - Ascending (A-Z)" },
        { value: "skillDesc", label: "Skill - Descending (Z-A)" },
        { value: "dateNewest", label: "Date - Newest" },
        { value: "dateOldest", label: "Date - Oldest" },
      ],
      boards: [],
      board: null,
      formbtnBool: false,
      skillsData: null,
      singleSelect: false,
      selected: [],
      filterDialog: false,
      selectedSubjectFilter: [],
      subjects: [],
      search: "",
      loading: false,
      searchBool: false,
      dataTableLoading: true,
      headers: [
        { text: "Skill Name", value: "name", cellClass: "w-20" },
        { text: "Subjects", value: "subjects", cellClass: "w-25" },
        { text: "Created  On", value: "createdOn", cellClass: "w-20" },
        { text: "Actions", value: "actions" },
      ],
      tableData: [],
      rules: {
        required: (value) => !!value || "Field is required",
      },
    };
  },
  // watch: {
  //   options: {
  //     handler() {
  //       console.log(this.options);
  //       this.pageSize = this.options.itemsPerPage;
  //       this.page = this.options.page;
  //       if (this.filterData) {
  //         this.filterSkills(this.selectedSubjectFilter);
  //       } else if (this.searchBool) {
  //         this.searchData(this.search);
  //       } else {
  //         this.getSkills();
  //       }
  //     },
  //     deep: true,
  //   },
  //   search(newValue) {
  //     console.log(newValue);
  //     this.searchBool = true;
  //     this.pageSize = this.options.itemsPerPage;
  //     this.page = this.options.page;
  //     this.options.page = 1;
  //     this.searchData(newValue);
  //     if (newValue == "" || newValue == null) {
  //       this.getSkills();
  //       this.searchBool = false;
  //     }
  //   },
  // },
  watch: {
    search(newValue) {
      this.$router.replace({ 
        query: { 
          ...this.$route.query,
          search: newValue || undefined 
        }
      }).catch(() => {});
      this.options.page = 1
      this.search = newValue || "";
      this.getSkills();
    }

  },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    async downloadCSV(csvData, fileName) {

      const csv = Papa.unparse(csvData);


      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {

        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {

          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    async downlaodRoles() {
      const fileName = 'skills.csv';
      const response = await SkillsController.getSkills();
      const skills = response.data.skills;
      this.downloadCSV(skills, fileName);
    },
    // async searchData(search) {
    //   const response = await SkillsController.searchSkills(
    //     this.pageSize,
    //     this.page,
    //     search
    //   );
    //   console.log(response.data);
    //   console.log(this.searchBool);
    //   this.count = response.data.data.count;
    //   this.tableData = response.data.data.rows;
    // },
    getDate(timeStamp) {
      return getDateFromTimestamp(timeStamp);
    },
    selectSortOption(sortOption) {
      this.selectedSort = sortOption;
      switch (sortOption) {
        case "skillAsc":
          this.sortCol = "name";
          this.sortOrder = "asc";
          break;
        case "skillDesc":
          this.sortCol = "name";
          this.sortOrder = "desc";
          break;
        case "dateNewest":
          this.sortCol = "createdOn";
          this.sortOrder = "desc";
          break;
        case "dateOldest":
          this.sortCol = "createdOn";
          this.sortOrder = "asc";
          break;
        default:
          this.selectedSort = "skillAsc";
          this.sortCol = "name";
          this.sortOrder = "asc";
      }
      this.options.page = 1;
      this.getSkills();
    },
    updateData(item) {
      this.editId = item.id;
      // selected id for edit
      this.name = item.name;
      this.selectedSubjects = item.subjects;
      this.novicMin = item.noviceMin,
        this.novicMax = item.noviceMax,
        this.advBegMin = item.advanceBegineerMin,
        this.advBegMax = item.advanceBegineerMax,
        this.competentMin = item.competentMin,
        this.competentMax = item.competentMax,
        this.proficientMin = item.proficientMin,
        this.proficientMax = item.proficientMax,
        this.expertMin = item.expertMin,
        this.expertMax = item.expertMax,
        console.log(item);
      this.formbtnBool = true; // change update/create btn value
      this.dialog = true;
    },
    async getSubjects() {
      const response = await SubjectController.getSubject();
      if (response.status == 200) {
        if (response.data.subjects.length > 0) {
          this.subjects = response.data.subjects;
        }
      }
    },
    async newCreateSkills() {
      this.name = null;
      this.selectedSubjects = [];
      this.novicMin = null,
        this.novicMax = null,
        this.advBegMin = null,
        this.advBegMax = null,
        this.competentMin = null,
        this.competentMax = null,
        this.proficientMin = null,
        this.proficientMax = null,
        this.expertMin = null,
        this.expertMax = null,
        this.formbtnBool = false;
    },

    async saveInputs() {
      if (this.$refs.form.validate()) {
        var res;
        this.loading = true;
        // checking case for update/create
        if (this.formbtnBool == false) {
          const response = await SkillsController.createSkills({
            name: this.name,
            subjects: this.selectedSubjects,
            noviceMin: this.novicMin,
            noviceMax: this.novicMax,
            advanceBegineerMin: this.advBegMin,
            advanceBegineerMax: this.advBegMax,
            competentMin: this.competentMin,
            competentMax: this.competentMax,
            proficientMin: this.proficientMin,
            proficientMax: this.proficientMax,
            expertMin: this.expertMin,
            expertMax: this.expertMax,
          });
          console.log("create cate", response);
          res = response;
        } else {
          const response = await SkillsController.updateSkills(
            {
              name: this.name,
              subjects: this.selectedSubjects,
              noviceMin: this.novicMin,
              noviceMax: this.novicMax,
              advanceBegineerMin: this.advBegMin,
              advanceBegineerMax: this.advBegMax,
              competentMin: this.competentMin,
              competentMax: this.competentMax,
              proficientMin: this.proficientMin,
              proficientMax: this.proficientMax,
              expertMin: this.expertMin,
              expertMax: this.expertMax,
            },
            this.editId
          );
          console.log(response);
          res = response;
        }
        // Close the dialog

        this.formbtnBool == false;
        this.dialog = false;
        if (res.data.flag) {
          this.loading = false;
          this.successDialog = true;
        } else {
          this.loading = false;
          this.errorDialog = true;
          this.errorMessage = res.data.error;
        }
        this.getSkills();
      }
    },
    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },
    selectAll() {
      // Copy all v-select's items in your selectedItem array
      this.selectedSubjects = this.subjects.map((obj) => obj.name);
    },
    async getSkills() {
      this.page = this.options.page;
      this.pageSize = this.options.itemsPerPage;
      const params = {
        page: this.page,
        pageSize: this.pageSize,
        search: this.search,
        sortCol: this.sortCol,
        sortOrder: this.sortOrder,
        subjects: this.selectedSubjectFilter.join(','),
      }
      const query = "?"+(new URLSearchParams(params).toString());
      const response = await SkillsController.getSkillsByPagination(
        query,
      );
      if (response.status == 200) {
        this.dataTableLoading = false;

        this.count = response?.data?.count;
        if (response.data.skills.length > 0) {
          this.tableData = response.data.skills;
        }
      } else {
        this.dataTableLoading = false;
        // alert(response.data.error);
        alert("Something went wrong")
      }
    },
    async deleteData(data) {
      // if (data.length == 1) {
      //   const response = await SkillsController.deleteSkills(data[0].id);
      //   this.getSkills();
      //   console.log(response);
      //   this.deleteDialog = false;
      //   this.selected.length = 0;
      // } else {
      //   var ids = "";
      //   for (var i = 0; i < data.length; i++) {
      //     ids = ids + data[i].id;
      //     if (i != data.length - 1) {
      //       ids = ids + ",";
      //     }
      //   }
      this.dLoading = true;
      var ids = [];
      data.forEach((dataDelete) => {
        ids.push(dataDelete.id)
      })
      const response = await SkillsController.deleteBulkSkills(ids);
      if (response.data.flag) {
        this.getSkills();
        this.dLoading = false;
        console.log(response);
        this.deleteDialog = false;
        this.selected.length = 0;
      } else {
        this.dLoading = false;
        alert(response.data.error)
        this.getSkills();
        console.log(response);
        this.deleteDialog = false;
        this.selected.length = 0;
      }
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },
    sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },
    clearFilter() {
      this.appliedFilter = false;
      this.selectedSubjectFilter = [];
      this.filterDialog = false;
      this.selectedSort = "skillAsc";
      this.sortCol = "name";
      this.sortOrder = "asc";
      this.getSkills();
      this.filterData = false;
      this.filterCount = 0;
    },

    async filterSkills(selectSubject) {
      this.skillFilterLoading = true;
      this.selectedSort = "skillAsc";
      this.sortCol = "name";
      this.sortOrder = "asc";
      this.getSkills();
      this.skillFilterLoading = false;
      this.filterDialog = false;
      this.appliedFilter = true;
      this.filterData = true;

      },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedSubjectFilter.length > 0) {
        this.filterCount++;
      }
    },

  },
  created() {
    if (!this.$store.state.role.skills.read) {
      this.$router.push('/notFound')
    }
    const urlSearch = this.$route.query.search;
    if (urlSearch) {
      this.search = urlSearch;
    }
    // this.getSkills();
    this.getSubjects();
    this.$store.state.breadcrumb = "Skills";
  },
};
</script>
<style></style>