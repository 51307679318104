<template>
  <div>
    <!-- Filter Dialog -->
    <v-dialog v-model="filterDialog" class="cdz" max-width="570px" style="z-index:1000 !important">
      <v-card width="570px" height="100vh" class="filterDialogClass">
        <v-row class="mt-4 mx-1">
          <v-col>
            <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
              Filter
            </div>
          </v-col>
          <v-col class="d-flex justify-end">
            <div
              @click="clearFilter"
              class="clear-filter-btn"
            >
              CLEAR FILTER
            </div>
          </v-col>
        </v-row>
        <v-tabs vertical>   
          <div class="ml-3 mt-4 mr-4 filterLine">
            <v-tab key="stage" class="black--text justify-start" active-class="activeTab">
              Stage
              <span v-if="selectedStageFilter.length > 0" class="ml-1">
              ({{selectedStageFilter.length}})</span>
            </v-tab>
            <v-tab key="CoreSkillsSubject" class="black--text justify-start" active-class="activeTab">
              League
              <span v-if="selectedLeagueFilter.length > 0" class="ml-1">
                ({{ selectedLeagueFilter.length }})</span>
            </v-tab>
          </div>

          <v-tab-item key="stage">
            <v-card>
              <v-card-text>
                <v-chip-group v-model="selectedStageFilter" active-class="secondary" column :multiple="true">
                  <v-chip v-for="(stage,index) in stageOptions" 
                  :key="index"
                  :value="stage"
                  elevated>
                    {{stage}}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="CoreSkillsSubject">
            <v-card>
              <v-card-text>
                <v-chip-group v-model="selectedLeagueFilter" active-class="secondary" column :multiple="true">
                  <v-chip v-for="(league,index) in leagueOptions" 
                  :key="index"
                  :value="league"
                  elevated>
                    {{league}}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <div class="filterDialogButton">
          <v-card-actions class="px-6 pb-0">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              outlined
              class="accent--text pa-4"
              @click="closeFilter"
              >Cancel</v-btn
            >
            <v-btn
              class="accent pa-4"
              :disabled="selectedStageFilter.length==0 && selectedLeagueFilter.length==0"
              @click="filterTeachers(selectedStageFilter,selectedLeagueFilter),getFilterCount()">Apply</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>                
    </v-dialog>     
    
    <v-col>
      <v-card height="64px" width="100%"
        class="mx-4 pl-2 pt-5 pr-4 background elevation-0 d-flex flex-row justify-space-between align-center fixBar">
        <v-card-title class="pl-0 ml-0">
          <span @click="$router.push('/')" class="breadcrumb-text-unselected underline-on-hover cursor">Dashboard</span>

          <span><v-icon class="breadcrumb-arrow-unselected">mdi-chevron-right</v-icon></span>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="breadcrumb-text">
                Campaign Result
                <v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon>
              </span>
            </template>

            <!-- <v-list>
              <v-list-item @click="$router.push('/jobseekersappraisal')">No. of users recommended</v-list-item>
              <v-list-item @click="$router.push('/jobseekerspip')">No. of users rejected</v-list-item>
              <v-list-item @click="$router.push('/jobseekerstbd')">No. of users on TBD</v-list-item>
            </v-list> -->
          </v-menu>
        </v-card-title>
        <UserNavMenu/>
      </v-card>

      <div class="pt-12">

        <div class="background fixTop30 ml-2 pt-4 pb-2">

          <v-container class="funnel-contianer pb-0 pl-0 pr-7 ml-4" fluid>
            <div class="w-100 d-flex flex-row mr-1">
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-0 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      JOB SEEKERS
                    </p>
                  </v-card-title>

                  <v-card-text class="pt-0 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Users Invited</span>
                    <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="invited" propertyEnd="screeningStarted" funnelColor="#E0ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelJobSeekersData.invited -
                              funnelJobSeekersData.screeningStarted,
                            funnelJobSeekersData.invited
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelJobSeekersData.screeningStarted -
                              funnelJobSeekersData.screeningPassed,
                            funnelJobSeekersData.screeningStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING RESULT
                    </p>
                  </v-card-title>
                  <v-card-text class="pr-0 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelJobSeekersData.screeningPassed -
                              funnelJobSeekersData.mainsStarted,
                            funnelJobSeekersData.screeningPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">MAINS</p>
                  </v-card-title>


                  <v-card-text class="pr-0 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>

                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelJobSeekersData.mainsStarted -
                              funnelJobSeekersData.mainsPassed,
                            funnelJobSeekersData.mainsStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      MAINS RESULT
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="demoSubmitted"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelJobSeekersData.mainsPassed -
                              funnelJobSeekersData.demoSubmitted,
                            funnelJobSeekersData.mainsPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">DEMO</p>
                  </v-card-title>

                  

                  <v-card-text class="pr-0 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Submitted</span
                    >
                    <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyEnd="interviewSubmitted" propertyBegin="demoSubmitted"/>

                    <!-- <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelJobSeekersData.demoSubmitted -
                              funnelJobSeekersData.interviewSubmitted,
                            funnelJobSeekersData.demoSubmitted
                          )
                        }}%
                      </div>
                    </div> -->
                  </v-card-text>
                </v-card>
              </div>
              <!-- <div class="rounded-lg px-0 py-0 pl-1 w-20">
                <v-card class="rounded-lg pr-0 pl-0" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                    <p class="font-weight-bold pl-2 funnelText">CAMPAIGN SUMMARY</p>
                  </v-card-title>

                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="5.65vh">
                    Passed : {{ funnelJobSeekersData.mainsPassed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="5.65vh">
                    Rejected :  {{ funnelJobSeekersData.mainsFailed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="5.65vh">
                    For TBD : {{ funnelJobSeekersData.screeningFailed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                </v-card>
              </div> -->
            </div>
            <!-- <v-col cols="2.5">
                <FunnelChart/>
               </v-col>
               <v-col cols="2.5">
                <FunnelChart/>
               </v-col>
               <v-col cols="2.5">
                <FunnelChart/>
               </v-col> -->
          </v-container>

          <v-row class="d-flex justify-center pt-0 my-0 ml-3 pr-1" fluid>
            <v-col cols="4" md="4" sm="4" class="pl-1">
              <div class="text-h6 highEmphasis i-patch">
                Campaign Result
              </div>
            </v-col>

            <v-col cols="8" md="8" sm="8" class="d-flex flex-row justify-end">
              <v-card class="elevation-0 ma-0 pa-0 transparent" width="326px">
                <v-text-field hide-details solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search"
                  class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              </v-card>

              <v-btn depressed rounded outlined @click="showfilterdialogue"
              :class="!topTeacherInCluster.length==0 ? 'outline-btn' : 'outline-disabled-btn'"
              :disabled="topTeacherInCluster.length==0" class="btn mx-3"><v-icon>mdi-tune</v-icon>
                <span v-if="filterCount>0">FILTER({{filterCount}})</span>
                <span v-else>FILTER</span></v-btn>
              <!-- <v-btn  depressed rounded outlined class="border-button mx-3"><img src="../../public/svgs/SORT.svg" alt="" />SORT</v-btn> -->

              <!-- <v-btn
              class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
              <v-btn depressed outlined rounded @click="downloadCampaignJobSeekerResults"
              :class="!topTeacherInCluster.length==0 ? 'outline-btn' : 'outline-disabled-btn'"
              :disabled="topTeacherInCluster.length==0" class="btn mx-3"><v-icon>mdi-import
                  mdi-rotate-90</v-icon></v-btn>
            </v-col>
          </v-row>

        
        <v-card elevation="0" height="55px" class="d-flex align-center rounded-table mx-5">
          <v-row class="pl-4">
            <v-col cols="1" class="reportTableText"> S.No </v-col>
            <v-col cols="1" class="reportTableText"> Full Name </v-col>
            <v-col cols="1" class="reportTableText i-patch"> Campaign </v-col>
            <v-col cols="2" class=" text-center reportTableText"> SchoolName </v-col>
            <v-col cols="4" class="reportTableText">
              <div class="d-flex justify-space-between pr-4 i-patch">
                <div>Invited</div>
                <div>Screening</div>
                <div>Mains</div>
                <div>Demo</div>
                <div>Interview</div>
              </div>
            </v-col>
            <v-col cols="2" class="text-center reportTableText"> League </v-col>
            <v-col cols="1" class="reportTableText i-patch"> Action </v-col>

          </v-row>
        </v-card>
      </div>
        <v-card class="rounded-table mx-5 mt-2" id="myScroll" height="350px" elevation="0">
          <bubble-loader v-if="isLoading" />  
          <div v-else-if="displayedClusterTeachers.length === 0" class="custom-no-data">
            <p>No Data Available</p>
          </div>
          
          <v-list v-else elevation="0" class="px-0 py-0">
            <v-list-item class="px-0 pl-4 py-0" v-for="(item, index) in displayedClusterTeachers" :key="item.id">
              <v-row class="px-0 py-0 my-1">
                <v-col cols="1" class="d-flex align-center py-0">
                  {{ index + 1 }}
                </v-col>
                <v-col class="d-flex align-center pl-0 py-0" cols="1">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <td class="t-data" v-bind="attrs" v-on="on">
                        {{ `${item.firstName} ${item.lastName}` }}
                      </td>
                    </template>
                    <span style="font-size: 15px" v-if="item.teachingInterests!=undefined">
                      {{
                        "Grade : " +
                        item.teachingInterests.subjects +
                        ", " +
                        "Level : " +
                        item.teachingInterests.level +
                        ", " +
                        "Subject : " +
                        item.teachingInterests.school
                      }}
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="1" class="d-flex align-center px-0 py-0">
                  <div class="w-100">{{ item.campaignName }}</div>
                </v-col>
                <v-col cols="2" class="d-flex justify-center align-center px-0 py-0">
                  <div>{{ typeof item.schoolName=='string'? item.schoolName : item.schoolName[0] }}</div>
                </v-col>
                <v-col class="d-flex align-center py-0" cols="4">
                  <v-row class="d-flex flex-row align-center pl-2">
                    <v-icon medium :color="item.stepper.invitation?.iconColor">{{
                   item.stepper.invitation?.name
                    }}
                    </v-icon>
                    <v-divider :thickness="10" class="border-opacity-25 px-0 py-0"
                      :color="item.stepper.screening?.iconColor"></v-divider>
                    <v-icon medium :color="item.stepper.screening?.iconColor">
                      {{ item.stepper.screening?.name}}
                    </v-icon>
                    <v-divider :thickness="2" class="border-opacity-87 px-0 py-0"
                    :color="item.stepper.mains?.iconColor"></v-divider>
                    <v-icon medium  :color="item.stepper.mains?.iconColor">
                      {{ item.stepper.mains?.name}}</v-icon>                  
                  <v-divider
                    :thickness="10"
                    class="border-opacity-25 px-0 py-0"
                    :color="item.stepper.demo?.iconColor"
                  ></v-divider>
                  <v-icon
                 
                    medium
                    :color="item.stepper.demo?.iconColor"
                    >{{ item.stepper.demo?.name}}</v-icon
                  >
                  <v-divider 
                    :thickness="10"
                    class="border-opacity-25 px-0 py-0"
                    :color="item.stepper.interview?.iconColor"
                  ></v-divider>
                  <v-icon
                    medium
                    class="pr-4"
                    :color="item.stepper.interview?.iconColor"
                    >{{ item.stepper.interview?.name}}</v-icon
                  >
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <div v-if="item.coreSkillsSubject != undefined &&
                  item.coreSkillsSubject.length > 0
                  " class="text-subtitle-1 d-flex justify-center align-center">
                    <div v-if="!item.NoLeague" class="d-flex flex-wrap justify-space-around pt-4">
                      <div v-for="(coreSkill, index) in item.coreSkillsSubject.slice(0,3)" :key="index" class="w-fit">
                        <v-tooltip v-if="coreSkill.league!='Not Eligible'" top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="d-flex flex-column align-center mx-2">
                              <img v-if="coreSkill.league=='Gold'"
                              height="23px" width="25"
                              src="./../assets/gold.svg"/>
                              <img v-if="coreSkill.league=='Silver'"
                              height="23px" width="25"
                              src="./../assets/silver.svg"/>
                              <img v-if="coreSkill.league == 'Bronze' ||
                              coreSkill.league == 'Bonze'"
                              height="23px" width="25"
                              src="./../assets/bronze.svg"/>
                              <div style="font-size: 12px">
                                #{{ coreSkill.rank }}
                              </div>
                            </div>
                          </template>
                          <span style="font-size: 15px"
                            >{{ coreSkill.skillName }} -
                            {{ coreSkill.percentile?.toFixed(2) }}%
                          </span>
                        </v-tooltip>
                      </div>
                      <v-btn v-if="item.coreSkillsSubject.length > 3" :loading="btnLoading" @click="
                      openLeagueDialog(
                        item.coreSkillsSubject,
                        item.personalInfo.firstName,
                        item.personalInfo.lastName
                      )
                      " height="10px" width="10px" text color="#1B72E8" class="text-body-2 ml-9">View More</v-btn>
                    </div>
                    <div v-else class="d-flex justify-center align-center h-100">
                    -
                    </div>
                  </div>
                  <div v-else class="d-flex justify-center align-center h-100" >
                    -
                  </div>
                </v-col>
                <v-col cols="1" class="d-flex justify-center align-center">
                  <img @click="getResult(item)" class="cursor mr-3 mb-2" width="24px" src="../assets/Live.svg" />
                <span v-if="isInterviewStartedItem(item)">
                  <img @click="getFinalResult(item)" class="cursor  mb-2" width="24px" src="../assets/Final.svg" />
                </span>
                <span v-else>
                  <img class="mr-6 mb-1"
                    width="24px" src="../assets/user_report_disabled.svg" />
                </span>
                  <!-- <v-btn :loading="btnLoading" @click="getResult(item)" height="26px" outlined color="#1B72E8"
                    class="mr-2 mb-2 px-2" style="font-size: 12px">Live</v-btn> -->
                  <!-- <v-btn :loading="btnLoading" @click="getFinalResult" height="26px" outlined color="#1B72E8"
                    class="mr-2 mb-2 px-2" style="font-size: 12px">Final</v-btn> -->
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
          <!-- <v-pagination :length="1" class="cursor" v-model="page"></v-pagination> -->
        </v-card>



      </div>

    </v-col>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import CampaignController from "@/controllers/CampaignController";
import LeagueController from "@/controllers/LeagueController";
import Papa from "papaparse";
import FunnelChartCommon from "./FunnelChartCommon.vue";
import {liveListenerUnsubscriber} from "../mixins/liveListenerUnsubcriber";
import BubbleLoader from "@/components/BubbleLoader.vue";
import UserNavMenu from "@/components/UserNavMenu.vue";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  collectionGroup,
  query,
  where,
  QuerySnapshot,
} from "firebase/firestore";
import { db } from "../firebase";

export default {
  name: "CampaignResult",
  mixins: [liveListenerUnsubscriber],
  components: {
    FunnelChartCommon,
    BubbleLoader,
    UserNavMenu
  },
  data() {
    return {
      data: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
        },

        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
        },
      ],
      clusters: [],
      schools: [],
      topTeacherInCluster: [],
      invited: 0,
      screeningAttempted: 0,
      screeningResult: 0,
      mainsAttempted: 0,
      result: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      chartData: {},
      jobSeekerChartData: {},
      emptyJobSeekerChartData: {
        jobSeekerInvited: 0,
        jobSeekerScreeningStarted: 0,
        jobSeekerScreeningPassed: 0,
        jobSeekerScreeningFailed: 0,
        jobSeekerMainsStarted: 0,
        jobSeekerMainsPassed: 0,
        jobSeekerMainsFailed: 0,
        jobSeekerDemoSubmitted: 0,
        jobSeekerInterviewSubmitted: 0,
      },
      recommended: 0,
      jobSeekerRejected: 0,
      jobSeekerTBD: 0,
      isLoading:  false,
      funnelJobSeekersData: {
        invited: 0,
        screeningStarted: 0,
        screeningPassed: 0,
        screeningFailed: 0,
        mainsStarted: 0,
        mainsPassed: 0,
        mainsFailed: 0,
        demoSubmitted: 0,
        interviewSubmitted: 0, 
      },

      
      filterDialog: false,
      filterCount: 0,
      appliedFilter: false,
      selectedStageFilter : [],
      selectedLeagueFilter: [],
      filteredTeachers: [],
      stageOptions:["Invited","Screening","Mains","Demo", 'Interview'],
      // leagueOptions:["Gold","Silver","Bronze"],
      leagueOptions:[],
      search: "",
      searchedResults:[],
      appliedSearch:false,
      isLoadingTableData: false
    };
  },

  computed: {
    displayedClusterTeachers(){
      if(this.appliedFilter==false){
        if(this.appliedSearch==true){
          return this.searchedResults;
        }
        else{
          return this.topTeacherInCluster;
        }
        }
      else if(this.appliedFilter==true){
        if(this.appliedSearch==true){
          return this.searchedResults;
        }
        else{
          return this.filteredTeachers;
        }
      }
    },
    isInterviewStartedItem() {
      return (item) => {
      if (item && item.campaignStatus && Array.isArray(item.campaignStatus)) {
        return item.campaignStatus.some(obj => obj.status === "INTERVIEW STARTED");
      }
        return false;
      };
    }
  },

  watch:{
    search(newValue){
      if (newValue=='' || newValue == null) {
        this.appliedSearch=false;
      }
      else{
        this.searchData(newValue);
      }
      this.$router.replace({ 
        query: { 
          ...this.$route.query,
          search: newValue || undefined 
        }
      }).catch(() => {});
    },
  },

  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    async getLeague()
    { 
      const response = await LeagueController.getLeagues();
      if (response.status==200){
        for (const league of response.data.leagues){
          if (league?.name=="Bonze" || league?.name=="Bronze"){
          this.leagueOptions.push("Bronze");
          }
          else{
            this.leagueOptions.push(league?.name);
          }
        }
      }
    },

    searchData(search){
      let searchString = this.removeSpaces(search);
      if (this.appliedFilter==true){
        this.searchedResults=this.filteredTeachers.filter((teacher)=>{
          let searchByName = this.removeSpaces(teacher.firstName + teacher.lastName)
          let searchByCampaign = this.removeSpaces(teacher.campaignName);
          return new RegExp(searchString,"i").test(searchByName) || 
          new RegExp(searchString,"i").test(teacher.schoolName) ||
          new RegExp(searchString,"i").test(searchByCampaign)
        });
      }
      else{
        this.searchedResults=this.topTeacherInCluster.filter((teacher)=>{
          let searchByName = this.removeSpaces(teacher.firstName + teacher.lastName);
          let searchByCampaign = this.removeSpaces(teacher.campaignName);
          return new RegExp(searchString,"i").test(searchByName) || 
          new RegExp(searchString,"i").test(typeof teacher.schoolName!=='string'? teacher.schoolName[0]:teacher.schoolName) ||
          new RegExp(searchString,"i").test(searchByCampaign)
        });
      }
        //console.log(this.searchedResults);
        this.appliedSearch=true;
    },
    removeSpaces(str = ""){
      return str.replace(/\s/g, '');
    },

    showfilterdialogue() {
      this.filterDialog = true;
      for (const teacher of this.displayedClusterTeachers){
        // console.log(teacher)
      }
      
    },

    closeFilter(){
    this.filterDialog = false;
  },
  filterBasedOnStage(selectedStage){
     let lastStatus = '';
     lastStatus = selectedStage.slice(-1)[0]?.toLowerCase();
       this.filteredTeachers =   this.filteredTeachers.filter((item)=> item.campaignStatus.map(({status})=>status?.toLowerCase()).join("").includes(lastStatus))

  },
  filterBasedOnLeague(selectedLeague){
   this.filteredTeachers =  this.filteredTeachers.filter((teacher)=>{
      let teachersLeagueArr = teacher.coreSkillsSubject.map(({league})=> league?.toLowerCase())
      console.log(teachersLeagueArr)
      return selectedLeague.some((league)=> teachersLeagueArr?.includes(league?.toLowerCase()) )
        })
  },
  filterTeachers(
      selectedStage =[],
      selectedLeague =[]
    ) {

      let stageApplied = selectedStage?.length > 0
      let leagueApplied =  selectedLeague?.length > 0
      this.filteredTeachers = this.topTeacherInCluster
     
      if(stageApplied){  
       this.filterBasedOnStage(selectedStage);
      } 

      if(leagueApplied){
        this.filterBasedOnLeague(selectedLeague,);
      }
    
      this.appliedFilter=true;
      this.filterDialog=false;
    },

    clearFilter() {
      this.appliedFilter = false;
      this.selectedStageFilter = "",
      this.selectedLeagueFilter="",
      this.filterCount = 0,
      this.filteredTeachers=[];
      // this.filterDialog = false;
    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedStageFilter.length > 0) {
        this.filterCount++;
      }
      if (this.selectedLeagueFilter.length > 0) {
        this.filterCount++;
      }
    },

    downloadCampaignJobSeekerResults() {
        console.log(this.topTeacherInCluster[0].teachingInterests);
        const fileName = "CampaignJobSeekerResults.csv";
        const dataForDownload = [];
        for (const teacher of this.topTeacherInCluster) {
          const fullName = `${teacher.firstName} ${teacher.lastName}`;
          const campaign = teacher.campaignName;
          const schoolName = teacher.schoolName;
          const campstat = teacher.lastStatus.stageValue?teacher.lastStatus.stageValue:"-"
          
          dataForDownload.push({
              FullName : fullName,
              Campaign : campaign,
              SchoolName : schoolName,
              CurrentCampaignStatus : campstat
            });
          }
          this.downloadCSV(dataForDownload,fileName);
    },

    async downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    async getResult(data) {
      console.log("data" , data)
      const response = await CampaignController.getResult(data.userId, data.campaignId);
      if (response.status == 200) {
        if (response.data.length == 0) {
          alert("User test not started");
        } else {
          this.$router.push(`/testreport/${data.campaignId}/${data.userId}`);
        }
      } else {
        alert(response.data.error);
      }
    },


    async setupSnapshotListenerJobSeekerFunnel() {
      const id = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "jobSeekerCampaign"
      );
      // console.log("inside snapshot", id);
      if (id === undefined) {
        // console.log("isnide if");
        this.jobSeekerChartData = this.emptyJobSeekerChartData
        // console.log("isnide snap", this.jobSeekerChartData);
      }

      const data = await getDocs(id);
      // console.log("data>>", data);
      if (data === undefined) {
        // console.log("isnide if");
        this.jobSeekerChartData = this.emptyJobSeekerChartData
        // console.log("isnide snap", this.jobSeekerChartData);
      }
      // console.log("get Docs", data.docs[0]);
      const document = data.docs[0]
      if (document === undefined) {
        // console.log("isnide if");
        this.jobSeekerChartData = this.emptyJobSeekerChartData
        // console.log("isnide snap", this.jobSeekerChartData);
      } else {
        const docRef = doc(id, data.docs[0].id);

        let unsubscribe = onSnapshot(docRef, (snapshot) => {
          let funnelData = snapshot.data();

          if (
            funnelData.jobSeekerInvited === undefined ||
            funnelData.jobSeekerScreeningStarted === undefined ||
            funnelData.jobSeekerScreeningPassed === undefined ||
            funnelData.jobSeekerScreeningFailed === undefined ||
            funnelData.jobSeekerMainsStarted === undefined ||
            funnelData.jobSeekerMainsPassed === undefined ||
            funnelData.jobSeekerMainsFailed === undefined
          ) {
            // console.log("isnide if");
            this.jobSeekerChartData = this.emptyJobSeekerChartData
            // console.log("isnide snap", this.jobSeekerChartData);

          } else {
            // console.log("isnide else:", funnelData);
            this.jobSeekerChartData = funnelData;

            // console.log("inisde snap job", this.jobSeekerChartData);
            this.recommended = funnelData.jobSeekerMainsPassed;
            this.jobSeekerRejected = funnelData.jobSeekerMainsFailed;
            this.jobSeekerTBD = funnelData.jobSeekerScreeningFailed;
          }

        });
        this.liveListenersToUnsubscribeMixin.push(unsubscribe);
      }
    },

    async newJobSeekerFunnelChartDataListener() {
      const dashboardJobSeekersRef = doc(db, "dashboards", this.$store.state.role.id + "-jobseekers-dashboard", "results", "campaignresults")

      // const dashboardJobSeekersRef=doc(db, "dashboards", "12345-jobseekers-dashboard", "results", "campaignresults")
      this.isLoadingTableData = true
      let unsubscribe = onSnapshot(dashboardJobSeekersRef, (doc) => {
        const resultData = doc.data();
        if (resultData) {
          this.funnelJobSeekersData = {
            invited: resultData.invited ? resultData.invited : 0,
            screeningStarted: resultData.screeningStarted ? resultData.screeningStarted : 0,
            screeningPassed: resultData.screeningPassed ? resultData.screeningPassed : 0,
            screeningFailed: resultData.screeningFailed ? resultData.screeningFailed : 0,
            mainsStarted: resultData.mainsStarted ? resultData.mainsStarted : 0,
            mainsPassed: resultData.mainsPassed ? resultData.mainsPassed : 0,
            mainsFailed: resultData.mainsFailed ? resultData.mainsFailed : 0,
            demoSubmitted: resultData.demoSubmitted ? resultData.demoSubmitted : 0,
            interviewSubmitted: resultData.interviewSubmitted ? resultData.interviewSubmitted : 0,
          }
          this.isLoadingTableData = false
        }
      })
      this.liveListenersToUnsubscribeMixin.push(unsubscribe);
    },

    calculatePercentage(value, total) {
      if (total === 0) {
        // console.log("Error: Total cannot be zero.");
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        // console.log("Error: Invalid input. Please provide valid numbers.");
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      // console.log(roundedPercentage);
      return roundedPercentage;
    },
    getFinalResult(data) {
      this.$router.push({
        path: "/userreport",
        name: "UserReport", //use name for router push
        query: {
          campaignId: data.campaignId,
          userId: data.userId,
        },
      });
      // this.$router.push('/userreport')
    },
  
    calculateUsersForLeague(users) {
      users.forEach((user) => {
        var coreSkills = [];
        if (user.coreSkillsSubject !== undefined) {
          user.coreSkillsSubject.forEach((coreSkillSubject) => {
            if (coreSkillSubject.result == "PASSED") {
              coreSkills.push(coreSkillSubject);
            }
          });
        }
        user.coreSkillsSubject = coreSkills;
      });
      return users;
    },

    getCampaignJobseeker(campaignRef) {
    return new Promise(async (res, rej) => {
      let jobseekers = [];
      try {
        let unsubscribe = onSnapshot(campaignRef, async (snapshot) => {
          const promises = snapshot.docs.map(async (doc) => {
            const campaignName = doc.data()?.name;
            if (doc.data().audienceType?.toLowerCase() === "job_seeker") {
              const jobSeekerRef = collection(campaignRef, doc.id, "audience");
              const jobSeekDocs = await getDocs(jobSeekerRef);

              jobSeekDocs.forEach((d) => {
                let jobseekerDocument = d.data()
                const topTeachersClusterData = {
                  screeningTotal: jobseekerDocument.screeningTotal == null ? "-" :jobseekerDocument.screeningTotal,
                  mainsTotal: jobseekerDocument.mainsTotal == null ? "-" : jobseekerDocument.mainsTotal,
                  firstName: jobseekerDocument.personalInfo.firstName == null ? "" : jobseekerDocument.personalInfo.firstName,
                  lastName: jobseekerDocument.personalInfo.lastName == null ? "" : jobseekerDocument.personalInfo.lastName,
                  schoolName: jobseekerDocument?.teachingInterests?.schools == null ? "" :jobseekerDocument?.teachingInterests?.schools,
                  campaignStatus: jobseekerDocument.campaignStatus,
                  coreSkillsSubject: jobseekerDocument?.coreSkillsSubject,
                  teachingInterests: jobseekerDocument.teachingInterests,
                  campaignId: doc.id,
                  demoStatus: jobseekerDocument.demoStatus || false,
                  interViewFeedback: jobseekerDocument?.interViewFeedback || [],
                  userId: jobseekerDocument.userId,
                  lastStatus: (() => {
                    let stageValue = "";
                    let statusValue = "";

                    if (jobseekerDocument.campaignStatus && jobseekerDocument.campaignStatus.length > 0) {
                      const lastStatus = jobseekerDocument.campaignStatus[jobseekerDocument.campaignStatus.length - 1].status;
                      if (lastStatus === "INVITED") {
                        stageValue = "Screening";
                        statusValue = "Yet to Attempt";
                      } else if (lastStatus === "SCREENING PASSED") {
                        stageValue = "Mains";
                        statusValue = "Yet to Attempt";
                      } else if (lastStatus === "SCREENING FAILED") {
                        stageValue = "Screening";
                        statusValue = "Fail";
                      } else if (lastStatus === "MAINS PASSED") {
                        stageValue = "Mains";
                        statusValue = "Pass";
                      } else if (lastStatus === "MAINS FAILED") {
                        stageValue = "Mains";
                        statusValue = "Fail";
                      }
                    }

                    return {
                      stageValue: stageValue,
                      statusValue: statusValue,
                    };
                  })(),

                  campaignName: campaignName,
                };
                jobseekers.push(topTeachersClusterData);
              });
            }
        });
        
        await Promise.all(promises);
        jobseekers = this.calculateUsersForLeague(jobseekers);
        let sortedProducts = jobseekers.sort((p1, p2) =>
          p1.screeningTotal < p2.screeningTotal ? 1 : p1.screeningTotal > p2.screeningTotal ? -1 : 0
        );
        res(jobseekers);
      });
      this.liveListenersToUnsubscribeMixin.push(unsubscribe);
    } catch (error) {
      console.log("Error fetching jobseekers", error);
      rej(error);
    }
  });
},
getPassFailGenericVal(status){
      if(status !== null && status !== undefined && status !== ''){
        return status.split(' ')[1]?.toLowerCase().trim()
      } else{   return ''}
  
    },
getStepperIconAndColor(prevStatus = '', curStatus = ''){
      let key = prevStatus+curStatus.trim()
      let map = new Map([
        ['started', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['startedfailed', {name:'mdi-close-circle', iconColor: 'red'} ],
        ['startedpassed', {name: 'mdi-check-circle', iconColor: 'green'}],
        ['failed',{name:'mdi-circle-double', iconColor: 'grey'}],
        ['failedpassed', {name: 'mdi-check-circle', iconColor: 'green'}],
        ['failedstarted', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['passed', {name:'mdi-circle-double', iconColor: 'grey'}],
        ['passedfailed', {name:'mdi-close-circle', iconColor: 'red'} ],
        ['passedstarted', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['', {name:'mdi-circle-double', iconColor: 'grey'}],

      ])
      return map.get(key)
    },

async jobSeekerSchoolSnapshotListener() {
  this.isLoading = true;
  const campaignRef = collection(db, "campaigns");
  let schoolClusterTeacher = await this.getCampaignJobseeker(campaignRef);
  schoolClusterTeacher = schoolClusterTeacher.map((jobseeker) => {
    if (jobseeker.coreSkillsSubject.length > 0 && jobseeker.coreSkillsSubject != undefined) {
      let leagueArray = jobseeker.coreSkillsSubject.map((subject) => subject.league);
      jobseeker['NoLeague'] = leagueArray.every((item) => item == 'Not Eligible');
    }
    return jobseeker;
  });
  this.topTeacherInCluster = this.addDemoInterviewScore(schoolClusterTeacher)
  this.topTeacherInCluster = this.createStepperData(this.topTeacherInCluster);
  this.topTeacherInCluster = this.topTeacherInCluster.filter(item=>item?.stepper.screening?.iconColor);
  if(this.search) {
      this.appliedSearch = true;
      this.searchData(this.search);
    }
  this.isLoading = false;
},

addDemoInterviewScore(data){
  return data.map((user)=>{
        if(user?.demoStatus){
          if(user.userType !== 'Teacher' && user.campaignStatus[user.campaignStatus.length-1].status == 'MAINS PASSED') {
            user.enableScheduleInterview = true
          }
          if(user.campaignStatus.filter((item)=> item.status?.includes('DEMO')).length == 0){
            user.campaignStatus.push({status: 'DEMO STARTED'})
           user.campaignStatus.push({status: 'DEMO PASSED'}) 
          }
                  
        }
        if(user?.interViewFeedback.length > 0){
          if(user.campaignStatus.filter((item)=> item.status?.includes('INTERVIEW')).length == 0){
            user.campaignStatus.push({status: 'INTERVIEW STARTED'})
           user.campaignStatus.push({status: 'INTERVIEW PASSED'})
          }       
        }
        return user
      })
    
    },
    createStepperData(data){
     return data?.map((item)=>{
        let obj = {invitation: { name:'mdi-check-circle', iconColor: 'green'}, 
                 screening:{  },
                 mains:{ },
                 demo:{ },
                 interview:{}}
        let status = item?.campaignStatus;
        obj['screening'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[1]?.status), this.getPassFailGenericVal(status[2]?.status))
        obj['mains'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[3]?.status), this.getPassFailGenericVal(status[4]?.status))
        obj['demo'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[5]?.status), this.getPassFailGenericVal(status[6]?.status))
        obj['interview'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[7]?.status), this.getPassFailGenericVal(status[8]?.status))
        item['stepper'] = obj;
        obj = {};
        return item;
      })
    },
  },

  created() {
    this.search = this.$route.query.search
    this.jobSeekerSchoolSnapshotListener();
    this.setupSnapshotListenerJobSeekerFunnel();
    this.newJobSeekerFunnelChartDataListener();
    this.getLeague();
  },
};
</script>

<style scoped>
.tbl>.t-head>.t-row>.head {
  background-color: primary;
}

.tbl {
  height: 196px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.tbl th {
  padding: 8px;
}

.tbl td {
  padding: 5px;
}

.tbl>.t-body>.t-row {
  background-color: white;
}

.head {
  text-align: center;
}

.tbl>.t-body>.t-row>.t-data {
  text-align: center;
}

.tbl>tr:nth-child(even) {
  background-color: white !important;
}

.fail {
  color: var(--error);
  /* background-color: #ff0505; */

}

.pass {
  color: #06C270;
  /* background-color: #06c270; */
}

.yetToAttempt {
  color: #000000DE;
  /* background-color: #000000de; */
}

.dotyetToAttempt {
  width: 8px;
  height: 8px;
  background-color: #000000de;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dotfail {
  width: 8px;
  height: 8px;
  background-color: #ff0505;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dotpass {
  width: 8px;
  height: 8px;
  background-color: #06c270;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.reportTableText {
  color: var(--blue-01);
}
.custom-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.custom-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Adjust based on table size */
  font-size: 18px;
}
</style>
