import axios from "axios";
import AuthService from "@/services/AuthService";
const instance = axios.create({
  baseURL: process.env.VUE_APP_QUESTIONS_API_URL,
});

export default {
  createQuestion: async function (formData) {
    //console.log("creating data",formData)
    try {
      const response = await instance.post("question", formData, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  getFilteredQuestions: async function (query) {
    try {

      const res = await instance.get(`filteredQuestions`+query, {
        headers: {
          Authorization: AuthService.getToken(),
        }
      })
      //console.log("filteredQuestionRes :", res);
      return res.data
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  exportQuestions: async function (query) {
    try {

      const res = await instance.get(`exportQuestions${query}`, {
        headers: {
          Authorization: AuthService.getToken(),
        }
      })
     // console.log("filteredQuestionRes :", res);
      return res.data
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  updateQuestion: async function (data, id) {
    try {
      console.log("data from updata level controller", data, id);
      const response = await instance.put("question?id=" + id, data, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getQuestions: async function (pageSize, page) {
    try {
      const response = await instance.get(
        "questions?pageSize=" + pageSize + "&page=" + page
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },

  deleteBulkQuestion: async function (ids) {
    try {
      const response = await instance.delete(
        "questions",
        {
          data: {
            ids: ids,
          },
        },
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  doFetchFileContentFromDriveUrl: async function (id) {
    try {
      const response = await instance.get('fetch-file',
        {
          params: {
            id: id
          },
          responseType: 'blob'
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
};
