<!-- src/components/UserMenu.vue -->
<template>
    <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0;">
      <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
        <v-hover>
          <img @click="notificationDialog = true" class="cursor" src="../assets/bell 1.svg">
        </v-hover>
      </v-badge>
  
      <div>
        <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32">
      </div>
  
      <div class="d-flex flex-column d-justify-evenly user-details">
        <v-card-title class="ma-0 pa-0 elipsis">
          {{ userInfo.name }}
        </v-card-title>
      </div>
  
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
  
        <div class="d-flex align-center justify-center" style="max-width: 338px;">
          <div class="mx-4">
            <img src="../assets/avtar.png" class="rounded-xl mr-2" width="62" height="52">
          </div>
          <div class="pa-3">
            <p class="body-1">{{ userInfo.name }}</p>
            <p class="body-2">{{ userInfo.email }}</p>
            <p class="body-2" style="color: rgba(102, 102, 102, 1);">{{ userInfo.HRIS_unique_role }}</p>
          </div>
        </div>
      </v-menu>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UserMenu',
    data() {
      return {
        notificationDialog: false,
      };
    },
    computed: {
      userInfo() {
        return this.$store.state.userInfo;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add styles for this component here if needed */
  </style>
  