<template>
  <div class="bubble-loader">
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
  </div>
</template>
<script>
export default {
  name: "bubble-loader",
};
</script>
<style scoped>
.bubble {
  width: 15px;
  height: 15px;
  background-color: #3F41D1;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: orbit 1.4s infinite ease-in-out;
  z-index: 9;
}

.bubble:nth-child(1) {
  animation-delay: -0.32s;
}

.bubble:nth-child(2) {
  animation-delay: -0.16s;
}

.bubble:nth-child(3) {
  animation-delay: 0s;
}

@keyframes orbit {
  0% {
    transform: rotate(0deg) translate(30px) rotate(0deg);
  }

  100% {
    transform: rotate(360deg) translate(30px) rotate(-360deg);
  }
}
</style>