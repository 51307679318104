<template>
  <v-card class="pa-4">
    <div class="d-flex justify-space-between align-center">
      <div class="text-h6">Your Remark</div>
      <v-chip
        v-if="remarks?.status"
        :color="getStatusColor(remarks.status)"
        text-color="white"
        class="rounded-pill"
      >
        {{ formatStatus(remarks.status) }}
      </v-chip>
    </div>

    <div v-if="remarks">
      <div class="mt-4" v-html="remarks.remark"></div>
    </div>
    <div v-else class="text-center py-4">
      No remark available
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'RemarkCard',
  props: {
    remarks: {
      type: Object,
      default: null
    }
  },
  methods: {
    formatStatus(status) {
      const statusMap = {
        'ACCEPT': 'Accepted',
        'REJECT': 'Rejected',
        'ONHOLD': 'On Hold',
        'NOTIFY_HR': 'Notified HR',
        'SEND_TO_HIRING_MANAGER': 'Sent to Hiring Manager'
      };
      return statusMap[status] || status;
    },
    getStatusColor(status) {
      const colorMap = {
        'Accepted': '#56903E',
        'Rejected': '#FF2600',
        'On Hold': '#E39236',
        'Notified HR': '#5F9C4A',
        'Sent to Hiring Manager': '#97AD39'
      };
      console.log("status",status)
      const color = colorMap[status];
      console.log('Color:', color);
      return color || '#757575';
    }
  }
}
</script>

<style scoped>
.rounded-pill {
  border-radius: 24px;
}
</style>
